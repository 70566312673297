
import {Component, ElementRef, Inject, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CollaboratorsDatasource} from "../../../../../../../core/e-commerce/_data-sources/collaborators.datasource";
import {MAT_DIALOG_DATA, MatDialogRef, MatPaginator, MatSort} from "@angular/material";
import {SelectionModel} from "@angular/cdk/collections";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Subscription} from "rxjs";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../../../../../core/reducers";
import {LayoutUtilsService, MessageType, TypesUtilsService} from "../../../../../../../core/_base/crud";
import {CollaboratorActions, ProductRemarkModel} from "../../../../../../../core/e-commerce";
import {selectLastCreatedCollaboratorId} from "../../../../../../../core/e-commerce/_selectors/collaborator.selectors";
import {Update} from "@ngrx/entity";
import {CollaboratorModel} from "../../../../../../../core/e-commerce/_models/collaborator.model";

@Component({
    selector: 'kt-collaborator',
    templateUrl: './collaborator.component.html',
    styleUrls: ['./collaborator.component.scss']
})
export class EkCollaboratorComponent implements OnInit, OnDestroy {
    //the new params
    // Public properties

    dataSource: CollaboratorsDatasource;
    displayedColumns: string[] = [ 'id', 'firstname','lastname', 'actions'];

    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    // Selection
    selection = new SelectionModel<CollaboratorModel>(true, []);
    collaboratorResult: CollaboratorModel[] = [];
    @Input() configurationId:number;
    // Add and Edit
    isSwitchedToEditMode = false;
    loadingAfterSubmit = false;
    formGroup: FormGroup;
    collaboratorForEdit: CollaboratorModel;
    collaboratorForAdd: CollaboratorModel;
    private updater: string;

    // Private properties
    private componentSubscriptions: Subscription;


    constructor(public dialogRef: MatDialogRef<EkCollaboratorComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private fb: FormBuilder,
                private store: Store<AppState>,
                private layoutUtilsService: LayoutUtilsService,
                private typesUtilsService: TypesUtilsService,
    ) {
    }

    /**
     * On init
     */
    ngOnInit() {
        this.updater = JSON.parse(localStorage.getItem('currentUser')).username;

        this.dataSource = new CollaboratorsDatasource(this.store);
        this.dataSource.entitySubject.subscribe(res => {
            this.collaboratorResult = res;

        });
        this.loadCollaboratorList();
        this.createForm(null);

    }

    loadCollaboratorList() {
        // Call request from server
        this.store.dispatch(CollaboratorActions.CollaboratorsPageRequested({
            page: 0,
            size: 9999,
        }));
    }

    createForm(collaborator: CollaboratorModel) {
        if (!collaborator)
            this.formGroup = this.fb.group({
                editFirstname: ['', Validators.required],
                newFirstname: ['', Validators.required],
                editLastname: ['', Validators.required],
                newLastname: ['', Validators.required],


            });


        this.clearAddForm();
        this.clearEditForm();
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        if (this.componentSubscriptions) {
            this.componentSubscriptions.unsubscribe();
        }
    }

    clearAddForm() {
        const controls = this.formGroup.controls;
        controls.newFirstname.setValue('');
        controls.newFirstname.markAsPristine();
        controls.newFirstname.markAsUntouched();

        controls.newLastname.setValue('');
        controls.newLastname.markAsPristine();
        controls.newLastname.markAsUntouched();

        this.collaboratorForAdd = new CollaboratorModel();
        this.collaboratorForAdd.clear();

    }

    clearEditForm() {
        const controls = this.formGroup.controls;
        controls.editFirstname.setValue('');
        controls.editLastname.setValue('');


        this.collaboratorForEdit = new CollaboratorModel();
        this.collaboratorForEdit.clear();


    }

    /**
     * Check if Add Form is Valid
     */
    checkAddForm() {
        const controls = this.formGroup.controls;
        if (controls.newFirstname.invalid) {
            controls.newFirstname.markAsTouched();
            return false;
        }
        if (controls.newLastname.invalid) {
            controls.newLastname.markAsTouched();
            return false;
        }

        return true;
    }

    /**
     * Open Collaborator Add Form
     */
    addCollaboratorButtonOnClick() {
        this.clearAddForm();
        this.collaboratorForAdd._isEditMode = true;
        this.isSwitchedToEditMode = true;
    }

    /**
     * Close Remark Add Form
     */
    cancelAddButtonOnClick() {
        this.collaboratorForAdd._isEditMode = false;
        this.isSwitchedToEditMode = false;
    }

    /**
     *  Create new remark
     */
    saveNewRemark() {
        if (!this.checkAddForm()) {
            return;
        }

        const controls = this.formGroup.controls;
        this.loadingAfterSubmit = false;
        this.collaboratorForAdd._isEditMode = false;
        this.collaboratorForAdd.firstname = controls.newFirstname.value;
        this.collaboratorForAdd.lastname = controls.newLastname.value;



        this.store.dispatch(CollaboratorActions.CollaboratorCreated({collaborator: this.collaboratorForAdd}));
        this.componentSubscriptions = this.store.pipe(
            select(selectLastCreatedCollaboratorId)
        ).subscribe(res => {
            if (!res) {
                return;
            }

            const _saveMessage = `Collaborator has been created`;
            this.isSwitchedToEditMode = false;
            this.layoutUtilsService.showActionNotification(_saveMessage, MessageType.Create, 10000, true, true);
            this.clearAddForm();
        });
    }

    /**
     * Check is Edit Form valid
     */
    checkEditForm() {
        const controls = this.formGroup.controls;
        if (controls.editFirstname.invalid) {
            return false;
        };
        if (controls.editLastname.invalid) {
            return false;
        }
        return true;
    }


    /**
     * Update remark
     *
     * @param _item: BrandModel
     */
    editCollaboratorButtonOnClick(_item: CollaboratorModel) {
        const controls = this.formGroup.controls;
        controls.editFirstname.setValue(_item.firstname);
        controls.editLastname.setValue(_item.lastname);

        const updateCollaborator: Update<CollaboratorModel> = {
            id: _item.id,
            changes: {
                _isEditMode: true
            }
        };
        this.store.dispatch(CollaboratorActions.CollaboratorUpdated({collaborator: _item, partialCollaborator: updateCollaborator}));
        this.isSwitchedToEditMode = true;
    }

    /**
     * Cancel Collaborator
     *
     * @param _item: CollaboratorModel
     */
    cancelEditButtonOnClick(_item: CollaboratorModel) {
        const updateCollaborator: Update<CollaboratorModel> = {
            id: _item.id,
            changes: {
                _isEditMode: false
            }
        };
        this.store.dispatch(CollaboratorActions.CollaboratorUpdated({partialCollaborator: updateCollaborator, collaborator: _item}));
        this.isSwitchedToEditMode = false;
    }

    /**
     * Save Collaborator
     *
     * @param _item: Collaborator
     */
    saveUpdateCollaborator(_item: CollaboratorModel) {
        if (!this.checkEditForm()) {
            return;
        }

        this.loadingAfterSubmit = true;
        const controls = this.formGroup.controls;
        this.loadingAfterSubmit = false;
        const objectForUpdate = new CollaboratorModel();
        objectForUpdate.id = _item.id;
        objectForUpdate._isEditMode = _item._isEditMode;
        objectForUpdate.firstname = controls.editFirstname.value;
        objectForUpdate.lastname = controls.editLastname.value;


        objectForUpdate._isEditMode = false;
        const updatePromoCOde: Update<CollaboratorModel> = {
            id: _item.id,
            changes: objectForUpdate
        };

        this.store.dispatch(CollaboratorActions.CollaboratorUpdated({
            partialCollaborator: updatePromoCOde,
            collaborator: objectForUpdate
        }));
        const saveMessage = `Collaborator has been updated`;
        this.isSwitchedToEditMode = false;
        this.layoutUtilsService.showActionNotification(saveMessage, MessageType.Update, 10000, true, true);
    }


    //table

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        // const numSelected = this.selection.selected.length;
        // const numRows = this.dataSource.data.length;
        // return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        // this.isAllSelected() ?
        // this.selection.clear() :
        // this.dataSource.data.forEach(row => this.selection.select(row));
    }

    /** The label for the checkbox on the passed row */
    checkboxLabel(row?: CollaboratorModel): string {
        if (!row) {
            // return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
    }

    /** ACTIONS */
    /**
     * Delete Promo Code
     *
     * @param _item: CollaboratorModel
     */
    deleteCollaborator(_item: ProductRemarkModel) {
        const _title = 'Collaborator Delete';
        const _description = 'Are you sure to permanently delete this promo Code?';
        const _waitDesciption = 'Collaborator is deleting...';
        const _deleteMessage = `Collaborator been deleted`;

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(CollaboratorActions.CollaboratorDeleted({collaboratorId: _item.id}));
            this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
        });
    }


}
