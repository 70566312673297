import {createFeatureSelector, createSelector} from "@ngrx/store";
import {ConfigurationCharacteristicState} from "../_reducers/configuration-characteristic.reducers";
import {ConfigurationCharacteristicModel} from "../_models/configuration-characteristic.model";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";


export const selectConfigurationCharacteristicsState = createFeatureSelector<ConfigurationCharacteristicState>('configurationCharacteristics');

export const selectConfigurationCharacteristicById = (configurationCharacteristicId: number) => createSelector(
    selectConfigurationCharacteristicsState,
    configurationCharacteristicsState => configurationCharacteristicsState.entities[configurationCharacteristicId]
);

export const selectConfigurationCharacteristicsPageLoading = createSelector(
    selectConfigurationCharacteristicsState,
    configurationCharacteristicsState => configurationCharacteristicsState.listLoading
);

export const selectError = createSelector(
    selectConfigurationCharacteristicsState,
    configurationCharacteristicsState => configurationCharacteristicsState.error
);

export const selectlastAction = createSelector(
    selectConfigurationCharacteristicsState,
    configurationCharacteristicsState => configurationCharacteristicsState.lastAction
);

export const selectConfigurationCharacteristicsActionLoading = createSelector(
    selectConfigurationCharacteristicsState,
    configurationCharacteristicsState => configurationCharacteristicsState.actionsLoading
);

export const selectLastCreatedConfigurationCharacteristicId = createSelector(
    selectConfigurationCharacteristicsState,
    configurationCharacteristicsState => configurationCharacteristicsState.lastCreatedConfigurationCharacteristicId
);

export const selectConfigurationCharacteristicsShowInitWaitingMessage = createSelector(
    selectConfigurationCharacteristicsState,
    configurationCharacteristicsState => configurationCharacteristicsState.showInitWaitingMessage
);

export const selectConfigurationCharacteristicsInStore = createSelector(
    selectConfigurationCharacteristicsState,
    configurationCharacteristicsState => {
        const items: ConfigurationCharacteristicModel[] = [];
        each(configurationCharacteristicsState.entities, element => {
            items.push(element);
        });
        let newarr = items.sort((a, b) => a.ord - b.ord);
        newarr.map(ex => ex.ord);
        const httpExtension = new HttpExtenstionsModel();
        const result: ConfigurationCharacteristicModel[]= httpExtension.sortArray(items, configurationCharacteristicsState.lastQuery.sortField, configurationCharacteristicsState.lastQuery.sortOrder);
        return new QueryResultsModel(newarr, configurationCharacteristicsState.totalCount, '');
    }
);
