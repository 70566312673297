import {createAction, props} from '@ngrx/store';
import {QueryParamsModel} from '../../_base/crud';
import {ChangeHistoryModel} from "../ek-models/change-history-model";

export const ChangeHistoryPageRequested = createAction(
    '[ChangeHistory List Page] ChangeHistory Page Requested ',
    props<{ page: QueryParamsModel , firstDate:string , lastDate:string ,  folderState:string , email:string }>()
);

export const ChangeHistoryPageLoadedSuccessfully = createAction(
    '[ChangeHistory API] ChangeHistory Page Loaded Successfully ',
    props<{ ChangeHistory: ChangeHistoryModel[]; totalCount: number; page: number }>()
);

export const ChangeHistoryPageLoadFailed = createAction(
    '[ChangeHistory API] ChangeHistory Page Request Failed ',
    props<{ error: any }>()
);
