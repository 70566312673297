// Angular
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from "rxjs";
import {Page} from "../../_base/crud/models/page";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";

import {AbstractService} from "../../services/abstract-service.service";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {ProductModel} from "../../e-commerce/_models/product.model";



@Injectable()
export class EkProductsService extends AbstractService<ProductModel> {

    url: string;
    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}EK/products`;
    }

    public findByFilterSorted(page: number = 0, size: number = 100, sorting: string, direction: string, query: any): Observable<Page<ProductModel>> {
        const options = {
            params: new HttpParams()
                .append('pageNo', page.toString())
                .append('pageSize', size.toString())
                .append('sortBy', sorting)
                .append('direction', direction)
                .append('query', query.query)
        };
        const url = `${this.url}/search-sorted`;
        return this.http.get<Response<Page<ProductModel>>>(url, options).pipe(map(({body}) => body));
    }

}
