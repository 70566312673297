import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {AbstractService} from "../../services/abstract-service.service";
import {Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {NotificationRole} from "../ek-models/NotificationRole";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class NotificationSettingsService extends AbstractService<NotificationRole> {

    url = '';

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}NotificationRole`;
    }

    public getNotificationSettingsListing(): Observable<NotificationRole[]> {
        return this.http.get<Response<NotificationRole[]>>(this.url).pipe(map(({body}) => body));
    }


}
