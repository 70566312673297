import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {CollaboratorModel} from "../_models/collaborator.model";

@Injectable()
export class CollaboratorService extends AbstractService<CollaboratorModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}collaborator`;
    }



}
