import {BaseModel} from "../../_base/crud";

export interface ConfigurationsDetails {
    id: number;
    quantity: number;
    stockDepotId?: number;
    stockId?: number;
    sellingPrice: number;
}

type ProfessionalClientCategory = 'EMPLOYEE' | 'RETRIED' | 'MILITARY' | 'MILITARY_RETIRED';
type SocialClientCategory = 'SINGLE' | 'MARRIED' | 'DIVORCE' | 'WIDOWER';

export class OrderSplitAdmin extends BaseModel {
    id: number;
    clientId: number;
    configurationsDetails: ConfigurationsDetails[];
    dateOfBirthSimulation: string;
    email: string;
    numberOfMonths: number;
    phone: string;
    pointOfSaleId: number;
    professionalClientCategory: ProfessionalClientCategory;
    salarySimulation: number;
    socialClientCategory: SocialClientCategory;
}
