import {Component, OnInit} from '@angular/core';
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {loadParcelStates} from "../../../../../../core/e-commerce/_actions/parcel.actions";
import {ParcelStatesDatasource} from "../../../../../../core/e-commerce/_data-sources/parcelStates.datasource";
import {ParcelsDatasource} from "../../../../../../core/e-commerce/_data-sources/parcels.datasource";

@Component({
    selector: 'kt-parcel-states',
    templateUrl: './parcel-states.component.html',
    styleUrls: ['./parcel-states.component.scss']
})
export class ParcelStatesComponent implements OnInit {

    displayedColumns = ["date", 'state', 'address'];
    dataSource: ParcelStatesDatasource;
    tracking:string='';

    constructor(private store: Store<AppState>,
                private router: ActivatedRoute) {
    }

    ngOnInit(): void {
        this.loadParcelStates()
    }

    loadParcelStates() {
        this.router.paramMap.subscribe((res: ParamMap) => {
            this.tracking=res.get('tracking')
            this.store.dispatch(loadParcelStates({tracking: this.tracking}))
            this.dataSource = new ParcelStatesDatasource(this.store);
        });
    }

}
