import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {Action, createReducer, on} from "@ngrx/store";
import * as SmsHistoryActions from "../ek-actions/sms-history.actions";
import {SmsHistoryModel} from "../ek-models/sms-history-model";

export interface SmsHistoryState extends EntityState<SmsHistoryModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedSmsId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
    errorMessage: string;
}

export const adapter: EntityAdapter<SmsHistoryModel> =
    createEntityAdapter<SmsHistoryModel>();

export const initialSmsHistoryState: SmsHistoryState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedSmsId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
    errorMessage: '',
});
const posReducer = createReducer(
    initialSmsHistoryState,
    on(SmsHistoryActions.SmsHistoryPageRequested, (state, action) => ({
        ...state,
        actionsLoading: true,
        listLoading: true,
        showInitWaitingMessage: true,
        error: null,
        lastAction: '',
        lastQuery: action.page,
    })),
    on(SmsHistoryActions.SmsHistoryPageLoadedSuccessfully, (state, action) =>
        adapter.addAll(action.SmsHistory, {
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            totalCount: action.totalCount,
        })
    ),
    on(SmsHistoryActions.SmsHistoryPageLoadFailed, (state, action) => ({
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
        error: action.error,
    })),
);

export function smsHistoryReducer(state: SmsHistoryState | undefined, action: Action) {
    return posReducer(state, action);
}

