import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {Action, createReducer, on} from "@ngrx/store";
import * as agencyBankActions from "../ek-actions/agency-bank.actions";
import {AgencyBankModel} from "../ek-models/agency-bank-model";

export interface AgencyBankState extends EntityState<AgencyBankModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedAgencyBankId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
}

export const adapter: EntityAdapter<AgencyBankModel> = createEntityAdapter<AgencyBankModel>();

export const initialAgencyBankState: AgencyBankState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedAgencyBankId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
    entities: [],
});

const AgencyBankReducer = createReducer(
    initialAgencyBankState,
    //agency list
    on(agencyBankActions.AgencyBankPageRequested, (state, action) => ({
        ...state,
        actionsLoading: true,
        listLoading: true,
        showInitWaitingMessage: true,
        error: null,
        lastAction: '',
        lastQuery: action.page,
    })),
    on(agencyBankActions.AgencyBankPageLoadedSuccessfully, (state, action) =>
        adapter.addAll(action.agencyBank, {
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            totalCount: action.totalCount,
        })
    ),
    on(agencyBankActions.AgencyBankPageLoadFailed, (state, action) => ({
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
        error: action.error,
    })),

    //creation
    on(agencyBankActions.AgencyBankCreated,
        (state, action) => ({...state, actionsloading: true, error: null})),
    on(agencyBankActions.AgencyBankCreatedSuccessfully,
        (state, action) => (adapter.addOne(action.agencyBank, {
            ...state,
            actionsloading: false,
            lastCreatedAgencyBankId: action.agencyBank.id
        }))),
    on(agencyBankActions.AgencyBankCreationFailed,
        (state, action) => ({...state, actionsloading: false, error: action.error})),


    //update
    on(agencyBankActions.AgencyBankUpdated,
        (state, action) => ({...state, actionsloading: true, error: null})),
    on(agencyBankActions.AgencyBankUpdatedSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialAgencyBank, {...state, actionsloading: false, listLoading: false,}))),
    on(agencyBankActions.AgencyBankUpdateFailed,
        (state, action) => ({...state, error: action.error, actionsloading: false})),

    //delete
    on(agencyBankActions.OneAgencyBankDeleted,
        (state, action) => ({...state, actionsloading: true, error: null})),
    on(agencyBankActions.OneAgencyBankDeletedSuccessfully,
        (state, action) => (adapter.removeOne(action.id, {
            ...state,
            actionsloading: false,
            lastAction: 'delete Success'
        }))),
    on(agencyBankActions.OneAgencyBankDeleteFailed,
        (state, action) => ({...state, error: action.error, actionsloading: false}))
);

export function agencyBankReducer(state: AgencyBankState | undefined, action: Action) {
    return AgencyBankReducer(state, action)
}
