import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {AgencyBankService} from "../ek-services/agency-bank.service";
import * as agencyBankActions from "../ek-actions/agency-bank.actions";


@Injectable()
export class AgencyBankEffects {
    constructor(private actions$: Actions,
                private agencyBankService: AgencyBankService,) {
    }

    AgencyBankPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(agencyBankActions.AgencyBankPageRequested),
            switchMap((action) =>
                this.agencyBankService.getAllAgencyBank(action.page.pageNumber, action.page.pageSize, action.page.sortOrder, action.regionId)
                    .pipe(
                        map(res => agencyBankActions.AgencyBankPageLoadedSuccessfully(
                            {
                                agencyBank: res.body.content,
                                page: res.body.totalPages,
                                totalCount: res.body.totalElements
                            })),
                        catchError(error => of(agencyBankActions.AgencyBankPageLoadFailed({error})))
                    ))));


    AgencyBankCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(agencyBankActions.AgencyBankCreated),
            switchMap((action) =>
                this.agencyBankService.saveAgency(action.agencyBank)
                    .pipe(
                        map(agencyBank => agencyBankActions.AgencyBankCreatedSuccessfully({agencyBank})),
                        catchError(error => of(agencyBankActions.AgencyBankCreationFailed({error})))
                    ))));

    AgencyBankUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(agencyBankActions.AgencyBankUpdated),
            switchMap((action) =>
                this.agencyBankService.updateAgency(action.agencyBank)
                    .pipe(
                        map(agencyBank => agencyBankActions.AgencyBankUpdatedSuccessfully({
                            agencyBank,
                            partialAgencyBank: action.partialAgencyBank
                        })),
                        catchError(error => of(agencyBankActions.AgencyBankUpdateFailed({error})))
                    ))));

    AgencyBankDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(agencyBankActions.OneAgencyBankDeleted),
            switchMap((action) =>
                this.agencyBankService.delete(action.id)
                    .pipe(
                        map(() => agencyBankActions.OneAgencyBankDeletedSuccessfully({id: action.id})),
                        catchError(error => of(agencyBankActions.OneAgencyBankDeleteFailed({error})))
                    ))));

}
