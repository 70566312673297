import {BaseDataSource, QueryResultsModel} from "../../_base/crud";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {
    selectCollaboratorsInStore,
    selectCollaboratorsPageLoading,
    selectCollaboratorShowInitWaitingMessage
} from "../_selectors/collaborator.selectors";

export class CollaboratorsDatasource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectCollaboratorsPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectCollaboratorShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectCollaboratorsInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }

}
