import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {BehaviorSubject} from "rxjs";
import {ActivatedRoute} from "@angular/router";
import {
  ClientOcrInformationService
} from "../../../../../../../core/ek-e-commerce/ek-services/clientOcrInformation.service";
import {ClientInfoScoringDTO} from "../../../../../../../core/ek-e-commerce/ek-models/scoring.model";

@Component({
  selector: 'kt-rating-step',
  templateUrl: './rating-step.component.html',
  styleUrls: ['./rating-step.component.scss']
})
export class RatingStepComponent implements OnInit {

  isOpened = false;
  idFolder = 1;
  idOrder = 1;
  scoringSubject$ = new BehaviorSubject<ClientInfoScoringDTO>(null);
  readonly folderScoring$ = this.scoringSubject$.asObservable();

  constructor(private activatedRoute: ActivatedRoute,
              private clientOcrInformationService: ClientOcrInformationService,
              private cdRef: ChangeDetectorRef
  ) {
  }

  ngOnInit(): void {

    this.activatedRoute.paramMap.subscribe((params) => {
      this.idFolder = Number(params.get("idFolder"));
      this.idOrder = Number(params.get("idOrder"));
    });

    //scoring details
    this.getScoringByIdFolder();

  }


  getScoringByIdFolder() {
    this.clientOcrInformationService.folderScoring(this.idFolder, this.idOrder).subscribe({
      next: (response) => {
        this.scoringSubject$.next(response);
        this.cdRef.detectChanges();
      },
      error: (error) => {
        if (error.error.message) {
        }
        this.cdRef.detectChanges();
      }
    })
  }

}
