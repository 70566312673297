import { createFeatureSelector, createSelector } from '@ngrx/store';
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {ParcelState} from "../_reducers/parcel.reducer";
import {ParcelModel} from "../_models/parcel.model";
import {ParcelsResponseModel} from "../_models/parcelsResponse.model";


export const getParcelState = createFeatureSelector<ParcelState>('parcel');
export const selectParcelById = (ParcelId: number) => createSelector(
    getParcelState,
    ParcelState => {
        return ParcelState.entities[0].data[ParcelId]
    }
);

export const selectParcelStore = createSelector(
    getParcelState,
    (ParcelState: ParcelState) => {
        var parcelResponse: ParcelsResponseModel=new ParcelsResponseModel();
        parcelResponse = ParcelState.entities[0];
        return parcelResponse;
    }
);

export const selectParcelsInStore = createSelector(
    getParcelState,

    parcelState => {
        const items: ParcelModel[] = [];
        each(parcelState.entities, element => {
            each(element.data, parcel => {
                items.push(parcel);
            });
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ParcelModel[] = httpExtension.sortArray(items, parcelState.lastQuery.sortField, parcelState.lastQuery.sortOrder);
        return new QueryResultsModel(result, parcelState.totalCount, '');
    }
);

export const selectParcelsPageLoading = createSelector(
    getParcelState,
    parcelsState => parcelsState.listLoading
);

export const selectParcelsActionLoading = createSelector(
    getParcelState,
    parcelsState => parcelsState.actionsLoading
);

export const selectParcelsPageLastQuery = createSelector(
    getParcelState,
    parcelState => parcelState.lastQuery
);

export const selectParcelsInitWaitingMessage = createSelector(
    getParcelState,
    parcelState => parcelState.showInitWaitingMessage
);

export const selectHasParcelsInStore = createSelector(
    selectParcelsInStore,
    queryResult => {
        return queryResult.totalCount;


    }
);


