import {BaseModel} from "../../_base/crud";
import {PointOfSaleModel} from "./point-of-sale.model";
import {OrderItemModel} from "./orderItem.model";

export class OrderSplit extends BaseModel {
    id: number;
    clientId: number;
    state: string;
    orderItems: OrderItemModel[];
    salarySimulation: number;
    numberOfMonths: number;
    folderId: number
    dateOfBirthSimulation?: string;
    pointOfSaleTo?: PointOfSaleModel;
    email?: string;
    phone?: string;
}
