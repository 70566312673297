// Angular
import {Component, Input, OnInit} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {BehaviorSubject} from "rxjs";
import {User} from "../../../../../core/auth";
import {NotificationService} from "../../../../../core/e-commerce";
import {NotificationModel} from "../../../../../core/e-commerce/_models/notification.model";



@Component({
	selector: 'kt-notification',
	templateUrl: './notification.component.html',
	styleUrls: ['notification.component.scss']
})
export class NotificationComponent implements OnInit{

	// Show dot on top of the icon
	@Input() dot: string;

	@Input() pulseLight: boolean;

	// Set icon class name
	@Input() icon = 'flaticon2-shopping-cart kt-font-danger';
	@Input() iconType: '' | 'success';

	// Set true to icon as SVG or false as icon class
	@Input() useSVG: boolean;

	// Set bg image path
	@Input() bgImage: string;
	show:boolean=false;

	// Set skin color, default to light
	@Input() skin: 'light' | 'dark' = 'light';

	@Input() type: 'brand' | 'success' = 'success';
//	private readonly notificationsSubject = new BehaviorSubject<NotificationModel[]>([]);

	notifications: NotificationModel[]=[];

	// Show pulse on icon
	pulse$ = new BehaviorSubject(false);





	/**
	 * Component constructor
	 *
	 * @param sanitizer: DomSanitizer
	 */
	constructor(private sanitizer: DomSanitizer, private notificationService: NotificationService) {
	}

	ngOnInit() {

		this.findAllOpened();
		const user: User = JSON.parse(localStorage.getItem('currentUser'));

	}


	backGroundStyle(): string {
		if (!this.bgImage) {
			return 'none';
		}

		return 'url(' + this.bgImage + ')';
	}

	findAllOpened(){
		 this.notificationService.findAllOpened().subscribe(
		 	notifications=> this.notifications = notifications
		 )
	}


}
