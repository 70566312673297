import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DocumentService} from '../../../../../../core/services/document.service';
import {BehaviorSubject} from 'rxjs';
import {FoldersService} from '../../../../../../core/ek-e-commerce/ek-services/folders.service';
import {LayoutUtilsService, MessageType} from '../../../../../../core/_base/crud';

@Component({
  selector: 'kt-verify-ocr-dialog',
  templateUrl: './verify-ocr-dialog.component.html',
  styleUrls: ['./verify-ocr-dialog.component.scss']
})
export class VerifyOcrDialogComponent implements OnInit {

  private loading$ = new BehaviorSubject<boolean>(false);
  spinner$ = this.loading$.asObservable();

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private documentService: DocumentService,
              private layoutUtilsService: LayoutUtilsService,
              private foldersService: FoldersService,
              private dialogRef: MatDialogRef<VerifyOcrDialogComponent>) {
  }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close();
  }

  save(feedBack: boolean) {
    const aBoolean = String(feedBack);
    this.loading$.next(true);
    this.documentService.verifyOcr(this.data.folderId, aBoolean).subscribe({
      next: (res) => {
        if (res.body !== undefined && res.body !== null) {
          console.log('isCheck', !feedBack);
          this.foldersService.isCheckSbj$.next(!feedBack);
          this.loading$.next(false);
          this.dialogRef.close(aBoolean);
        }
      },
      error: () => {
        this.loading$.next(false);
        const message = `un erreur survenue, réessayez encore s'il vous plaît!`;
        this.layoutUtilsService.showActionNotification(
            message,
            MessageType.Create,
            5000,
            false,
            false
        );
        this.dialogRef.close();
      }
    });
  }
}
