import {BaseDataSource, QueryResultsModel} from "../../_base/crud";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {
    selectBrandsInStore,
    selectBrandsPageLoading,
    selectBrandsShowInitWaitingMessage
} from "../_selectors/brand.selector";

export class BrandsDatasource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectBrandsPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectBrandsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectBrandsInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }

}
