import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";

import {DeliveryModel} from "../_models/delivery.model";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";


@Injectable()
export class DeliveryService extends AbstractService<DeliveryModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}delivery`;
    }

    getDeliveryFie(wilaya: String, deliveryType: String) {
        let obj = {
            address: {wilaya: wilaya},
            livreurId: 1,
            deliveryType: deliveryType
        }
        return this.http.post(this.url + '/deliveryprice', obj)
    }

    updateDeliveryPrice(newPrice: number, deliveryId: number) {

        const params = new HttpParams()
            .set('price', String(newPrice))
            .set('deliveryId', String(deliveryId))

        const url = `${this.url}/updatePrice`

        return this.http.patch<Response<any>>(url, params).pipe(map(res => {
            return res
        }));

    }
}
