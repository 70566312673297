// Lodash
import {each} from 'lodash';
import {createFeatureSelector, createSelector} from "@ngrx/store";
import {CarouselState} from "./carousel.reducers";
import {CarouselModel} from "./carousel.model";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";

export const selectCarouselsState = createFeatureSelector<CarouselState>('carousels');

export const selectCarouselById = (carouselId: number) => createSelector(
    selectCarouselsState,
    carouselsState => carouselsState.entities[carouselId]
);

export const selectCarouselsPageLoading = createSelector(
    selectCarouselsState,
    carouselsState => carouselsState.listLoading
);

export const selectCarouselsActionLoading = createSelector(
    selectCarouselsState,
    customersState => customersState.actionsLoading
);

export const selectCarouselsPageLastQuery = createSelector(
    selectCarouselsState,
    carouselsState => carouselsState.lastQuery
);

export const selectLastCreatedCarouselId = createSelector(
    selectCarouselsState,
    carouselsState => carouselsState.lastCreatedCarouselId
);

export const selectCarouselsInitWaitingMessage = createSelector(
    selectCarouselsState,
    carouselsState => carouselsState.showInitWaitingMessage
);

export const selectCarousels = createSelector(
    selectCarouselsState,
    carouselsState => {
        const items: CarouselModel[] = [];
        each(carouselsState.entities, element => {
            items.push(element);
        });
        let newarr = items.sort((a, b) => a.ord - b.ord);
        newarr.map(ex => ex.ord);
        return newarr;
    }
);


export const selectCarouselsInStore = createSelector(
    selectCarouselsState,
    carouselsState => {
        const items: CarouselModel[] = [];
        each(carouselsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: CarouselModel[] = httpExtension.sortArray(items, carouselsState.lastQuery.sortField, carouselsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, carouselsState.totalCount, '');
    }
);

export const selectHasCarouselsInStore = createSelector(
    selectCarouselsInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);
