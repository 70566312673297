import {Injectable} from '@angular/core';
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient, HttpEvent, HttpParams, HttpRequest} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";
import {FooterImformationModel} from "../_models/footerImformation.model";
import { saveAs } from 'file-saver';

@Injectable()
export class FooterInformationService extends AbstractService<FooterImformationModel> {

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}contact-info`;
        this.questionsUrl = `${ADMIN_CONFIG.apiEndpoint}questions`;
    }

    protected url: string;
    private questionsUrl: string;

    public get(): Observable<FooterImformationModel> {
        const url = `${this.url}`;
        return this.http.get<Response<FooterImformationModel>>(url).pipe(map(({body}) => body));
    }


    updateQuestions(questionsFile): Observable<HttpEvent<{
        body: {
            body: {}
        }
    }>> {
        const data: FormData = new FormData();
        data.append('file', questionsFile.item(0));
        const newRequest = new HttpRequest('POST', this.questionsUrl + "/upload-from-file", data, {
            reportProgress: true,
            responseType: 'json'
        });
        return this.http.request(newRequest);
    }

    updateInfo(entity: FooterImformationModel): Observable<HttpEvent<{
        body: {
            body: {}
        }
    }>> {
        const params = new HttpParams()
            .set('generalConditionUrl', entity.generalConditionUrl)
            .set('legalMentionUrl', entity.legalMentionUrl)
            .set('wissalInfoEmail', entity.wissalInfoEmail)
            .set('wissalPhoneNumber', entity.wissalPhoneNumber)
            .set('wissalPhoneNumber2', entity.wissalPhoneNumber2)
            .set('latitude', entity.latitude)
            .set('longitude', entity.longitude)
            .set('address', entity.address)
            .set('faqFileName',entity.faqFileName);

        const newRequest = new HttpRequest('PUT', this.url, params);
        return this.http.request(newRequest);
    }

    downloadQuestion(){
        this.http.get(this.questionsUrl+'/export', {responseType: 'blob'})
            .subscribe((res) => {
                saveAs(res, "")
            })
    }


}
