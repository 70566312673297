import {Pipe, PipeTransform} from '@angular/core';
import {DocumentModel} from '../../../../core/ek-e-commerce/ek-models/document.model';


@Pipe({
    name: 'filterState'
})
export class FilterStatePipe implements PipeTransform {

    transform(docs: DocumentModel[], documentGender: string): string {
        let state = 'PENDING'
        if (docs) {
            const targetDoc = docs.find((document: DocumentModel) => document.documentsGender === documentGender);
            if (targetDoc) state = targetDoc.documentsState;
        } else return ''
        return state;
    }

}
