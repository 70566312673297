import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {OrdersService} from "../_services/orders.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import * as OrderActions from "../_actions/order.action";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";

@Injectable()
export class OrderEffects {


    constructor(private actions$: Actions, private orderService: OrdersService, private store: Store<AppState>) {
    }

    AllOrderPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.OrdersAllPageRequested),
            switchMap((action) =>
                this.orderService.getAllSearch(action.page.pageNumber, action.page.pageSize, action.page.sortField, action.page.sortOrder, action.page.filter, action.orderState, action.region, action.startingDate, action.endingDate, action.paymentMethod, action.orderType, action.deliveryType)
                    .pipe(
                        map(orderPage => OrderActions.OrdersAllPageLoadedSuccessfully(
                            {
                                orders: orderPage.content,
                                page: orderPage.totalElements,
                                totalCount: orderPage.totalElements
                            })),
                        catchError(error => of(OrderActions.OrdersAllPageLoadFailed({error})))
                    ))));

    AllOrderArchivedPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.OrdersArchivedAllPageRequested),
            switchMap((action) =>
                this.orderService.getAllArchivedOrderPageSearch(action.page.pageNumber, action.page.pageSize, action.page.sortField, action.page.sortOrder, action.page.filter, action.orderState, action.region, action.startingDate, action.endingDate)
                    .pipe(
                        map(orderPage => OrderActions.OrdersArchivedAllPageLoadedSuccessfully(
                            {
                                order: orderPage.content,
                                page: orderPage.totalElements,
                                totalCount: orderPage.totalElements
                            })),
                        catchError(error => of(OrderActions.OrdersArchivedAllPageLoadFailed({error})))
                    ))));
    OrderByClientRequested1$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.OrdersByClientPageRequested),
            switchMap((action) =>
                this.orderService.getByClientId(action.page.sortOrder, action.idClient, action.page.pageNumber, action.page.pageSize, "id")
                    .pipe(
                        map(orderPage => OrderActions.OrdersByClientPageLoadedSuccessfully(
                            {
                                orders: orderPage.content,
                                page: orderPage.totalElements,
                                totalCount: orderPage.totalElements
                            })),
                        catchError(error => of(OrderActions.OrdersByClientPageLoadFailed({error})))
                    ))));

    OrderCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.OrderCreated),
            switchMap((action) =>
                this.orderService.save(action.order)
                    .pipe(
                        map(data => OrderActions.OrderCreatedSuccessfully({order: data})),
                        catchError(error => of(OrderActions.OrderCreationFailed({error})))
                    ))));

    OrderUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.OrderUpdated),
            switchMap((action) =>
                this.orderService.update(action.order)
                    .pipe(
                        map(order => OrderActions.OrderUpdatedSuccessfully({
                            order,
                            partialOrder: action.partialOrder
                        })),
                        catchError(error => of(OrderActions.OrderUpdateFailed({error})))
                    ))));

    OrderDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.OrderDeleted),
            switchMap((action) =>
                this.orderService.delete(action.orderId)
                    .pipe(
                        map(() => OrderActions.OrderDeletedSuccessfully({orderId: action.orderId})),
                        catchError(error => of(OrderActions.OrderDeleteFailed({error})))
                    ))));

    OrderByWilaya$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.OrdersByWilayaRequested),
            switchMap((action) =>
                this.orderService.filterByWilaya(action.pageNumber, action.pageSize, action.sortField, action.sortOrder, action.type).pipe(
                    map((orders) => OrderActions.OrdersByWilayaRequestedSuccessfuly({
                        orders: orders.content,
                        totalCount: orders.totalElements
                    })),
                    catchError((err) => of(OrderActions.OrdersByWilayaRequestedFailed({error: err})))
                ))
        )
    )
    OrderPrecedentStateChanged$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.OrderPrecedentStateChangeRequested),
            switchMap((action) =>
                this.orderService.changeOrderState(action.orderId, action.event)
                    .pipe(
                        map(result =>
                            OrderActions.OrderPrecedentStateChangeRequestedSucessfuly({state: result})),
                        catchError(error => of(OrderActions.OrderPrecedentStateChangeRequestedFailure({error})))
                    ))));

    OrderYalidineStateChanged$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderActions.updateYalidineState),
            switchMap((action) =>
                this.orderService.updateStateOrderFromYalidine()
                    .pipe(
                        map(result =>
                            OrderActions.updateYalidineStateRequestedSucessfully({state: result})),
                        catchError(error => of(OrderActions.updateYalidineStateChangeRequestedFailure({error})))
                    ))));

}
