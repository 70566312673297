// Angular
import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
// Material
import {SelectionModel} from '@angular/cdk/collections';
import {MatDialog, MatPaginator, MatSnackBar, MatSort} from '@angular/material';
// RXJS
import {debounceTime, distinctUntilChanged, skip, tap} from 'rxjs/operators';
import {fromEvent, merge, Observable, Subscription} from 'rxjs';
// Translate Module
import {TranslateService} from '@ngx-translate/core';
// NGRX
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../../core/reducers';
// CRUD
import {LayoutUtilsService, MessageType, QueryParamsModel} from '../../../../../../core/_base/crud';
// Services and Models
import {getlastAction} from "../../../../../../core/ek-e-commerce/ek-selectors/client.selector";
import {ActivatedRoute, Router} from "@angular/router";
import {NgxPermissionsService} from "ngx-permissions";
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from "@ng-bootstrap/ng-bootstrap";
import {MatDialogConfig} from "@angular/material/dialog";
import {ClientsDatasource} from "../../../../../../core/ek-e-commerce/ek-data-sources/clients.datasource";
import {ClientModel} from "../../../../../../core/ek-e-commerce/ek-models/client.model";
import * as ClientActions from "../../../../../../core/ek-e-commerce/ek-actions/client.actions";
import {ClientsService} from "../../../../../../core/ek-e-commerce/ek-services/clients.service";
import {EkClientEmailComponent} from "../ek-client-email/ek-client-email.component";

@Component({
    selector: 'kt-ek-customers-list',
    templateUrl: './ek-customers-list.component.html',
    styleUrls: ['./ek-customers-list.component.scss']
})
export class EkCustomersListComponent implements OnInit, OnDestroy {

    dataSource: ClientsDatasource;
    displayedColumns = ['select', 'id', 'firstname', 'lastname', 'createdAt', 'clientStatus', 'ordersCount', 'profileImgUrl', 'edit'];
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild('sort1', {static: true}) sort: MatSort;
    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    filterStatus = '';
    // Selection
    selection = new SelectionModel<ClientModel>(true, []);
    customersResult: ClientModel[] = [];
    // Subscriptions
    private subscriptions: Subscription[] = [];
    lastAction$: Observable<string>;
    //Permissions
    private PERMISSIONS = ['ALL_CLIENT', 'UPDATE_CLIENT'];
    canEdit = false;
    addingOrder = false;
    fromDate: NgbDate = null;
    toDate: NgbDate = null;
    showDatePicker = true;
    filterByDateActivated = false;
    hoveredDate: NgbDate | null = null;
    loadingData = false;
    selectedStatus: string = 'Toutes';
    clinetActive$: Observable<boolean>;
    clientActive = false
    customer: ClientModel;
    file: File[];
    ids = [];
    idsForSend: number[] = [];
    select: number;
    nbrSelection: number;
    currentRole: string = '';

    /**
     * Component constructor
     *
     * @param dialog: MatDialog
     * @param snackBar: MatSnackBar
     * @param layoutUtilsService: LayoutUtilsService
     * @param translate: TranslateService
     * @param store: Store<AppState>
     * @param router
     * @param activatedRoute
     * @param ngxPermissionService: NgxPermissionService
     */
    constructor(
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private layoutUtilsService: LayoutUtilsService,
        private translate: TranslateService,
        private store: Store<AppState>,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private ngxPermissionService: NgxPermissionsService,
        public formatter: NgbDateParserFormatter,
        private calendar: NgbCalendar,
        private clientsService: ClientsService,
    ) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;

    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        if (this.router.url == '/ecommerce/addOrder/customers')
            this.changeToClientSelection();


        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        /* Data load will be triggered in two cases:
        - when a pagination event occurs => this.paginator.page
        - when a sort event occurs => this.sort.sortChange
        **/
        this.paginator._changePageSize(100);

        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => {
                if (this.selectedStatus) {
                    this.loadCustomersList(this.selectedStatus);
                    return;
                }
                this.loadCustomersList();
            })
        ).subscribe();
        this.subscriptions.push(paginatorSubscriptions);

        // Filtration, bind to searchInput
        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            // tslint:disable-next-line:max-line-length
            debounceTime(500), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            tap(() => {
                this.paginator.pageIndex = 0;
                this.loadCustomersList();
            })
        )
            .subscribe();
        this.subscriptions.push(searchSubscription);

        // Init DataSource
        this.dataSource = new ClientsDatasource(this.store);

        const entitiesSubscription = this.dataSource.entitySubject.pipe(
            skip(1),
            distinctUntilChanged()
        ).subscribe(res => {
            this.customersResult = res;
        });
        this.subscriptions.push(entitiesSubscription);

        // First load
        this.loadCustomersList();

        this.lastAction$ = this.store.select(getlastAction);
        this.checkPermissionToUpdate();
    }

    checkPermissionToUpdate() {
        this.ngxPermissionService.hasPermission(this.PERMISSIONS).then(hasPermission => {
            this.canEdit = hasPermission;
        });

    }

    /**
     * Load Customers List from service through data-source
     */
    loadCustomersList(selectedStatus?: string) {

        this.selectedStatus = selectedStatus;

        this.selection.clear();

        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize,
        );

        // Call request from server
        this.store.dispatch(ClientActions.clientsPageRequested({
            page: queryParams,
            firstDate: this.fromDate ? this.formatDate(this.fromDate) : null,
            lastDate: this.toDate ? this.formatDate(this.toDate) : null,
            clientState: selectedStatus ? selectedStatus : null
        }));

        this.selection.clear();
    }

    /**
     * Returns object for filter
     */
    filterConfiguration(): any {
        return this.searchInput.nativeElement.value;
    }

    /** ACTIONS */
    /**
     * Delete customer
     *
     * @param _item: ClientModel
     */
    deleteCustomer(_item: ClientModel) {
        const _title: string = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.TITLE');
        const _description: string = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.WAIT_DESCRIPTION');
        const _deleteMessage = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_SIMPLE.MESSAGE');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            this.store.dispatch(ClientActions.OneCustomerDeleted({id: _item.id}));
            const deleteSub = this.lastAction$.subscribe(result => {
                if (result == 'delete Success') {
                    this.layoutUtilsService.showActionNotification(_deleteMessage, MessageType.Delete);
                    return;
                } else
                    this.layoutUtilsService.showActionNotification("Deleting Client failed !", MessageType.Delete);

            });
            this.subscriptions.push(deleteSub);
        });
    }

    /**
     * Delete selected customers
     */
    deleteCustomers() {
        const _title: string = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.TITLE');
        const _description: string = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.DESCRIPTION');
        const _waitDesciption: string = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.WAIT_DESCRIPTION');
        const _deleteMessage = this.translate.instant('ECOMMERCE.CUSTOMERS.DELETE_CUSTOMER_MULTY.MESSAGE');

        const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
        dialogRef.afterClosed().subscribe(res => {
            if (!res) {
                return;
            }

            const idsForDeletion: number[] = [];
            for (let i = 0; i < this.selection.selected.length; i++) {
                idsForDeletion.push(this.selection.selected[i].id);
            }
        });
    }


    /**
     * Show add customer dialog
     */
    addCustomer() {
        const newCustomer = new ClientModel();
        newCustomer.clear(); // Set all defaults fields
        // this.editCustomer(newCustomer);
    }

    /**
     * Show Edit customer dialog and save after success close result
     * @param customer: ClientModel
     // */

    editCustomer(id) {
        this.router.navigate(['../customers/edit', id], {relativeTo: this.activatedRoute});
    }

    /**
     * Check all rows are selected
     */
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.customersResult.length;
        return numSelected == numRows;
    }

    /**
     * Toggle all selections
     */
    masterToggle() {
        if (this.selection.selected.length === this.customersResult.length) {
            this.selection.clear();
        } else {
            this.customersResult.forEach(row => this.selection.select(row));
        }
    }

    /** UI */
    /**
     * Retursn CSS Class Name by status
     *
     * @param status: boolean
     */
    getItemCssClassByStatus(status: boolean = false): string {

        switch (status) {
            // case 2:
            // 	return 'danger';
            case true:
                return 'success';
            case false:
                return 'metal';
        }
        return '';
    }

    getItemCssClassByBlocked(clientStatus): string {

        switch (clientStatus) {
            case 'BLOCKED':
                return 'danger';
            case 'ACTIVE' :
                return 'success';
            case 'WAITING':
                return 'metal';
        }
        return '';
    }

    onCustomerToggled(client: ClientModel) {
        this.selection.toggle(client);
        this.idsForSend.push(client.id);
        this.nbrSelection = this.idsForSend.length;
    }

    /**
     * Returns Item Status in string
     * @param state: boolean
     */

    getItemBlockedString(clientStatus): string {
        switch (clientStatus) {
            case 'BLOCKED':
                return 'Bloqué';
            case 'ACTIVE':
                return 'Activé';
            case 'WAITING':
                return 'En attente';
        }
        return '';
    }

//sent email
    sentEmailToClients() {


        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.height = '500px';
        dialogConfig.width = '400px';

        const dialogRef = this.dialog.open(EkClientEmailComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                this.clientsService.sendMails(this.idsForSend, data.description, data.file).subscribe(res => {
                    if (res.message === "success") {
                        this.layoutUtilsService.showActionNotification("Email envoyé avec succès!", MessageType.Update, 10000, true, true);
                    } else {
                        this.layoutUtilsService.showActionNotification("Erreur dans l'envoie de l'email", MessageType.Update, 10000, true, true);
                    }
                });


            }
        );
    }

    /**
     * Returns CSS Class Name by type
     * @param status: number
     */
    getItemCssClassByType(status: string = 'ENTREPRISE'): string {
        switch (status) {
            case 'PARTICULIER':
                return 'accent';
            case "ENTREPRISE":
                return 'primary';
            case '':
                return 'primary';
        }
        return 'primary';
    }

    /**
     * Returns Item Type in string
     * @param status: number
     */
    getItemTypeString(status: string = ''): string {
        switch (status) {
            case 'ENTREPRISE':
                return 'Business';
            case 'PARTICULIER':
                return 'Individual';
        }
        return '';
    }

    changeToClientSelection() {

        this.addingOrder = true;
        this.displayedColumns.pop()
        // remove clientstatus by his index
        this.displayedColumns.splice(6, 1)
        this.displayedColumns.shift()
    }

    ClientSelected(client) {
        if (this.addingOrder) {
            this.router.navigate(['/ecommerce/addOrder/orderdetail', client.id])
        }
    }

    exportclientActive() {

        this.clientActive = true;
        this.clientsService.ExportExcelCustomers().subscribe((response: any) => {
                if (response) {
                    this.clientActive = true;
                    if (response.message == 'success' && response.body) {
                        setTimeout(function () {

                            const byteCharacters = atob(response.body);
                            const byteNumbers = new Array(byteCharacters.length);
                            for (let i = 0; i < byteCharacters.length; i++) {
                                byteNumbers [i] = byteCharacters.charCodeAt(i);
                            }
                            const byteArray = new Uint8Array(byteNumbers);
                            let blob = new Blob([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                window.navigator.msSaveOrOpenBlob(blob);
                                return;
                            }

                            const url = window.URL.createObjectURL(blob);
                            const anchor = document.createElement('a');
                            anchor.download = `Client.xlsx`;
                            anchor.href = url;

                            anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
                        }, 8000);
                        this.clientActive = false;

                    }
                } else {

                    this.clinetActive$ = new Observable(ob => {
                        ob.next(false);
                    })
                }
            },
        );
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
            this.toDate = date;
            this.filterByDateActivated = true;
            this.loadCustomersList();
            this.showDatePicker = false;
        } else {
            this.toDate = null;
            this.fromDate = date;
        }
    }


    formatDate(date: NgbDate) {
        var month = '' + (date.month),
            day = '' + date.day,
            year = date.year;
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('/');
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    }

    disableFilterByDate(event) {
        if (event.checked == false) {
            this.filterByDateActivated = false;
            this.loadCustomersList();
        }
    }

    /**
     * On Destroy
     */

    ngOnDestroy() {
        this.subscriptions.forEach(el => el.unsubscribe());
    }

}
