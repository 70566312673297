import {Injectable} from '@angular/core';
import {AbstractService} from "../../services/abstract-service.service";
import {Discount_CustomerModel} from "../_models/discount_Customer.model";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";
import {Page} from "../../_base/crud/models/page";
import {act} from "@ngrx/effects";
import {ConfigurationModel} from "../_models/configuration.model";
import {LayoutUtilsService, MessageType} from "../../_base/crud";
import {CategoryModel} from "../_models/category.model";

@Injectable({
    providedIn: 'root'
})
export class CustomerDiscountService extends AbstractService<Discount_CustomerModel> {
    url: string;

    constructor(protected http: HttpClient, private layoutUtilsService: LayoutUtilsService) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}discount_Client`;

    }

    public checkDiscountClient(clientId: number): Observable<any> {
        const url = `${this.url}/client/use/${clientId}`;
        return this.http.get<Response<any>>(url)
        // .pipe(
        //   map((response: Response<any>) => {
        //     if (response.body) {
        //       return response.body;
        //     }
        //   })
        // );
      }

    public updateDiscountCostumer(id: number, active: boolean,event:any): Observable<Response<any>> {
        const url = `${this.url}/${id}/${active}`;
        return this.http.patch<Response<any>>(url,"").pipe(   map((response: any) => {

            if (response.body) {
                    return response;
            } else {
                const saveMessage = `Une seule remise client peut être activer a la fois`;
                this.layoutUtilsService.showActionNotification(saveMessage, MessageType.Update, 10000, true, true);
                throw new Error();
            }

        }))
    }

    public createDiscountClient (entity: Discount_CustomerModel) {
        const res = this.http.post<Response<Discount_CustomerModel>>(this.url, entity);
        return res;
    }


    public updateDiscount(entity: Discount_CustomerModel): Observable<string> {
        return this.http.put<Response<any>>(this.url, entity).pipe(map(({message}) => {
            return message
        }));
    }

    public deleteDiscount(id: number): Observable<Response<any>> {
        const url = `${this.url}/${id}`;
        return this.http.delete<Response<any>>(url).pipe(
            map((response: any) => {
                if (response) {
                    return response;
                } else {
                    throw new Error();
                }
            })
        )
    }

    public getClientDiscountById(id: number = 0 ): Observable<Discount_CustomerModel[]> {
        const url = `${this.url}/client/${id}`;
        return this.http.get<Response<Discount_CustomerModel[]>>(url).pipe(map(({body}) => body));
    }

    public filterDiscountByDate(page: number = 0, size: number = 100, startingDate: string, endingDate: string, clientId): Observable<Page<Discount_CustomerModel>> {

        let params = new HttpParams();
        params = clientId ? params.append('clientId', clientId.toString()) : params;
        params = startingDate ? params.append('startingDate', startingDate) : params;
        params = endingDate ? params.append('endingDate', endingDate) : params;
        params = page ? params.append('pageNumber', page.toString()) : params;
        params = size ? params.append('pageSize', size.toString()) : params;

        const options = { params };

        const url = `${this.url}/filterDate`;
       return  this.http.get<Response<Page<Discount_CustomerModel>>>(url, options).pipe(map(({body}) => body));
    }
    public searchSorted(direction: string,sortBy: string ,page: number = 0, size: number = 100, query: string = '', state:string ,clientId:number): Observable<Page<Discount_CustomerModel>> {

        let params = new HttpParams();

        params = clientId ? params.append('clientId', clientId.toString()) : params;
        params = direction ? params.append('direction', direction) : params;
        params = page ? params.append('pageNo', page.toString()) : params;
        params = size ? params.append('pageSize', size.toString()) : params;
        params = sortBy ? params.append('sortBy', sortBy) : params;
        params = state ? params.append('status', state) : params;
        params = query ? params.append('query', query) : params;


        const options = { params };

        const url =`${this.url}/filter`;
        return this.http.get<Response<Page<Discount_CustomerModel>>>(url, options).pipe(map(({body}) => body));
    }

}
