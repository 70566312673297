// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State

import {BestSellerState} from "../_reducers/bestSeller.reducers";
import {BestSellerModel} from "../_models/bestSeller.model";

export const selectBestSellerState = createFeatureSelector<BestSellerState>('bestSeller');

export const selectBestSellerById = (bestSellerId: number) => createSelector(
    selectBestSellerState,
    bestSellerState => bestSellerState.entities[bestSellerId]
);

export const selectBestSellerPageLoading = createSelector(
    selectBestSellerState,
    bestSellerState => bestSellerState.listLoading
);

export const selectBestSellerActionLoading = createSelector(
    selectBestSellerState,
    bestSellerState => bestSellerState.actionsLoading
);

export const selectBestSellerPageLastQuery = createSelector(
    selectBestSellerState,
    bestSellerState => bestSellerState.lastQuery
);

export const selectLastCreatedBestSellerId = createSelector(
    selectBestSellerState,
    bestSellerState => bestSellerState.lastCreatedBestSellerId
);

export const selectBestSellerInitWaitingMessage = createSelector(
    selectBestSellerState,
    bestSellerState => bestSellerState.showInitWaitingMessage
);

export const selectBestSellerInStore = createSelector(
    selectBestSellerState,
    bestSellerState => {
        const items: BestSellerModel[] = [];
        each(bestSellerState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: BestSellerModel[] = httpExtension.sortArray(items, bestSellerState.lastQuery.sortField, bestSellerState.lastQuery.sortOrder);
        return new QueryResultsModel(result, bestSellerState.totalCount, '');
    }
);

export const selectHasBestSellerInStore = createSelector(
    selectBestSellerInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);
