import { Pipe, PipeTransform } from '@angular/core';
import { DocumentModel } from '../../../../core/ek-e-commerce/ek-models/document.model';


@Pipe({
  name: 'filterUrl'
})
export class FilterUrlPipe implements PipeTransform {

  transform(value: DocumentModel[], documentGender: string): string {
    let imageUrl = ''
    const skeleton = 'assets/icons/svgs/backup.svg'
    if(value) {
      value.filter((document: DocumentModel) => {
        if (document.documentsGender && document.documentUrlPreSigned) {
          (document.documentsGender == documentGender) ? imageUrl = document.documentUrlPreSigned: skeleton
        }
      })
    } else return ''
    return imageUrl;
  }

}
