import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {LayoutUtilsService, MessageType} from "../../_base/crud";
import {MatDialog} from "@angular/material/dialog";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {PointOfSaleService} from "../ek-services/point-of-sale.service";
import * as POSActions from "../ek-actions/point-of-sale.action";



@Injectable()
export class PointOfSaleEffects {
    constructor(private actions$: Actions,
                private posService: PointOfSaleService,
                private layoutUtilsService: LayoutUtilsService,
    ) {}
    POSPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(POSActions.POSPageRequested),
            switchMap((action) =>
                this.posService.getAllPOS2(action.page.filter, action.page.sortField, action.page.sortOrder, action.page.pageNumber, action.page.pageSize, action.regionId)
                    .pipe(
                        map(posPage => POSActions.POSPageLoadedSuccessfully(
                            {
                                pos: posPage.body.content,
                                page: posPage.body.totalPages,
                                totalCount: posPage.body.totalElements
                            })),
                        catchError(error => of(POSActions.POSPageLoadFailed({error})))
                    ))));

    POSCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(POSActions.POSCreated),
            switchMap((action) =>
                this.posService.createPointOfSale(action.pos).pipe(
                    map((data) => POSActions.POSCreatedSuccessfully({ pos: data.body })),
                    catchError((error) => of(POSActions.POSCreationFailed({ error })))
                )
            )
        )
    );
    POSCreatedSuccessfully$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(POSActions.POSCreatedSuccessfully),
                switchMap((action) =>
                    of(
                        this.layoutUtilsService.showActionNotification(
                            'Le point de vente a été créé avec succès',
                            MessageType.Update,
                            3000,
                            false
                        )
                    )
                )
            ),
        { dispatch: false }
    );
    POSCreationFailed = createEffect(
        () =>
            this.actions$.pipe(
                ofType(POSActions.POSCreationFailed),
                switchMap((action) =>
                    of(
                        this.layoutUtilsService.showActionNotification(
                          'Une erreur est survenue !' + action.error,
                          MessageType.Update,
                          3000,
                          false
                        )
                    )
                )
            ),
        { dispatch: false }
    );

    posByUse$ = createEffect(() =>
        this.actions$.pipe(
            ofType(POSActions.posByUser),
            switchMap((action) =>
                this.posService.addPosByIdUser(action.userId, action.pointOfSaleId).pipe(
                    map((data) => POSActions.posByUserCreatedSuccessfully({ pos: data })),
                    catchError((error) => of(POSActions.posByUserCreatedFailed({ error })))
                )
            )
        )
    );
    posByUserCreatedSuccessfully$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(POSActions.posByUserCreatedSuccessfully),
                switchMap((action) =>
                    of(
                        this.layoutUtilsService.showActionNotification(
                            'Le point de vente a été créé avec succès',
                            MessageType.Update,
                            3000,
                            false
                        )
                    )
                )
            ),
        { dispatch: false }
    );
    posByUserCreatedFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(POSActions.posByUserCreatedFailed),
                switchMap((action) =>
                    of(
                        this.layoutUtilsService.showActionNotification(
                          'Une erreur est survenue !' + action.error,
                          MessageType.Update,
                          3000,
                          false
                        )
                    )
                )
            ),
        { dispatch: false }
    );

    POSUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(POSActions.POSUpdated),
            switchMap((action) =>
                this.posService.updatePointOfSale(action.pos)
                    .pipe(
                        map(pos => {
                            return POSActions.POSUpdatedSuccessfully({
                                pos: pos.body,
                                partialPOS: action.partialPOS
                            });
                        }),
                        catchError(error => of(POSActions.POSUpdateFailed({error})))
                    ))));
    POSUpdatedSuccessfully$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(POSActions.POSUpdatedSuccessfully),
                switchMap((action) =>
                    of(
                        this.layoutUtilsService.showActionNotification(
                            'Le point de vente a été modifié avec succès',
                            MessageType.Update,
                            3000,
                            false
                        )
                    )
                )
            ),
        { dispatch: false }
    );

    POSUpdateFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(POSActions.POSUpdateFailed),
                switchMap((action) =>
                    of(
                        this.layoutUtilsService.showActionNotification(
                          'Une erreur est survenue !' + action.error,
                          MessageType.Update,
                          3000,
                          false
                        )
                    )
                )
            ),
        { dispatch: false }
    );

    POSDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(POSActions.POSDeleted),
            switchMap((action) =>
                this.posService.deletePOS(action.posId)
                    .pipe(
                        map(res => POSActions.POSDeletedSuccessfully({posId:action.posId} )),
                        catchError(error => of(POSActions.POSDeleteFailed({error})))
                    ))));
    POSDeletedSuccessfully$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(POSActions.POSDeletedSuccessfully),
                switchMap((action) =>
                    of(
                        this.layoutUtilsService.showActionNotification(
                            'Le point de vente a été supprimé',
                            MessageType.Delete,
                            3000,
                            false
                        )
                    )
                )
            ),
        { dispatch: false }
    );

    POSDeleteFailed$ = createEffect(
        () =>
            this.actions$.pipe(
                ofType(POSActions.POSDeleteFailed),
                switchMap((action) =>
                    of(
                        this.layoutUtilsService.showActionNotification(
                            'Une erreur est survenue !',
                            MessageType.Delete,
                            3000,
                            false
                        )
                    )
                )
            ),
        { dispatch: false }
    );

    POSUpdatedStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(POSActions.ChangePOSStatus),
            switchMap((action) =>
                this.posService.updatePOSStatus(action.pos.id , action.pos.status)
                    .pipe(
                        map(res => POSActions.POSStatusChangedSuccessfully({pos: res , partialPOS: action.partialPOS})),
                        catchError(error => of(POSActions.POSStatusChangeFailed({error})))
                    ))));
}
