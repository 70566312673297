import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort, MatTableDataSource} from "@angular/material";
import {merge, Observable, of, Subscription} from "rxjs";
import {FormControl} from "@angular/forms";
import {SelectionModel} from "@angular/cdk/collections";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../../../core/reducers";
import {finalize, tap} from "rxjs/operators";
import {CategoryModel} from "../../../../../core/e-commerce/_models/category.model";
import {LayoutUtilsService, QueryParamsModel, QueryResultsModel} from "../../../../../core/_base/crud";
import {selectCategoriesInStore} from "../../../../../core/e-commerce/_selectors/category.selector";
import {ConfigurationModel} from "../../../../../core/e-commerce/_models/configuration.model";
import {ConfigurationService} from "../../../../../core/e-commerce/_services/confguration.service";
import {ArchivedConfigurationModel} from "../../../../../core/e-commerce/_models/archived-configuration.model";
import {ArchivedConfigurationService} from "../../../../../core/e-commerce";

@Component({
  selector: 'kt-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements  OnInit {
  viewLoading$ = null;
  updater: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  pageSize: 100;
  totalElements: number;

  columns = [
    // {"columnDef": 'start', header: '', cell: (element: ConfigurationModel) => '', selected: true},

    {
      columnDef: 'id',
      header: 'ID',
      type: 'number',
      cell: (element: ArchivedConfigurationModel) => `${element.configuration?.id}`,
      selected: true
    },
    {
      columnDef: 'name',
      type: '',
      header: 'Nom du produit',
      cell: (element: ArchivedConfigurationModel) => `${element.configuration?.name}`,
      selected: true
    },
    {
      columnDef: 'reference',
      header: 'reference',
      cell: (element: ArchivedConfigurationModel) => `${element.configuration?.reference}`,
      selected: true
    },
    {
      columnDef: 'image',
      header: 'image',
      cell: (element: ArchivedConfigurationModel) => `${element.configuration?.images[0].url}`,
      selected: true
    },

  ];

  displayedColumns = this.columns.filter(e => e.selected).map(c => c.columnDef);
  dataSource: MatTableDataSource<ArchivedConfigurationModel> = new MatTableDataSource(null)
  archivedConfigurations: ArchivedConfigurationModel [] = [];

  constructor(
      private archivedConfigurationService: ArchivedConfigurationService,
  ) {
  }

  ngOnInit(): void {
    this.updater=JSON.parse(localStorage.getItem('currentUser')).username;
    this.archivedConfigurationService.getAllPaged(0, this.pageSize).subscribe(
        confs => {
          this.dataSource.data = confs.content;
          this.archivedConfigurations=confs.content;
          this.totalElements=confs.totalElements;
        }
    )

  }

  pageEvent(event: any) {
    this.archivedConfigurationService.getAllPaged(event.pageIndex, event.pageSize).subscribe(
        confs => {
          this.dataSource.data = confs.content;
          this.archivedConfigurations=confs.content;
          this.totalElements=confs.totalElements;
          //  this.dataSource.paginator = this.paginator;
        }
    )
  }




}
