import {createFeatureSelector, createSelector} from "@ngrx/store";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {FolderState} from "../ek-reducers/folder.reducers";
import {ListingModel} from "../ek-models/listing-model";


export const selectFoldersState = createFeatureSelector<FolderState>('folders');

export const selectFolderById = (folderId: number) => createSelector(
    selectFoldersState,
    foldersState => {
        if (foldersState) return foldersState.entities[folderId];
    }
);

export const selectFoldersPageLoading = createSelector(
    selectFoldersState,
    foldersState => foldersState.listLoading
);

export const selectError = createSelector(
    selectFoldersState,
    foldersState => foldersState.error
);

export const selectLastAction = createSelector(
    selectFoldersState,
    foldersState => foldersState.lastAction
);


export const selectFoldersActionLoading = createSelector(
    selectFoldersState,
    foldersState => foldersState.actionsLoading
);

export const selectLastCreatedFolderId = createSelector(
    selectFoldersState,
    foldersState => foldersState.lastCreatedFolderId
);

export const selectFoldersShowInitWaitingMessage = createSelector(
    selectFoldersState,
    foldersState => foldersState.showInitWaitingMessage
);

export const selectFoldersPageLastQuerys = createSelector(
    selectFoldersState,
    foldersState => foldersState.lastQuery
);

export const selectFoldersInStore = createSelector(
    selectFoldersState,
    foldersState => {
        const items: ListingModel[] = [];
        each(foldersState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ListingModel[] = httpExtension.sortArray(items, foldersState.lastQuery.sortField, foldersState.lastQuery.sortOrder);
        return new QueryResultsModel(result, foldersState.totalCount, '');
    }
);
