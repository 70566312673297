import { Injectable } from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {LayoutUtilsService} from "../../_base/crud";
import * as ParcelActions from "../_actions/parcel.actions";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {ParcelService} from "../_services/parcel.service";



@Injectable()
export class ParcelEffects {



  constructor(private actions$: Actions,
              private parcelService: ParcelService,
              private layoutUtilsService: LayoutUtilsService) {}


  ParcelLoadRequesed$ = createEffect(() =>
      this.actions$.pipe(
          ofType(ParcelActions.loadParcels),
          switchMap((action) =>
              this.parcelService.findAll(action.page,action.pageSize,action.wilayaId,action.state)
                  .pipe(
                      map(parcelResponse => {
                        return ParcelActions.loadParcelsSuccess(
                            {
                              data: parcelResponse,
                            })
                      }),
                      catchError(error => this.showError(error))
                  ))));
  showError(error){
      this.layoutUtilsService.showActionNotification('Erreur !')
      return of(ParcelActions.loadParcelsFailure({error}));
  }


}
