import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'roundUpTo5',
})
export class RoundUpTo5 implements PipeTransform {
    transform(value: number): number {
        let secondFromLast = String(value)[String(value).length - 2];
        if (Number(secondFromLast) === 5){
            return value;
        }else{
            return Math.round(value/100) * 100 ;
        }
    }
}
