import { createFeatureSelector, createSelector } from '@ngrx/store';
import {ParcelStatesState} from "../_reducers/parceStates.reducers";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {ParcelStateModel} from "../_models/parcelState";


export const getParcelStatesState = createFeatureSelector<ParcelStatesState>('parcelStates');
export const selectRarcelStatesById = (parcelStatesId: number) => createSelector(
    getParcelStatesState,
    parcelStatesState => parcelStatesState.entities[parcelStatesId]
);


export const selectParcelStatesStore = createSelector(
    getParcelStatesState,
    (parcelStatesState: ParcelStatesState) => {
        const parcelStatess: ParcelStateModel[] = [];
        Object.entries(parcelStatesState.entities).forEach(([k,v]) => parcelStatess.push(v));
        return parcelStatess;
    }
);

export const selectParcelStatessInStore = createSelector(
    getParcelStatesState,
    parcelStatesState => {
        const items: ParcelStateModel[] = [];
        each(parcelStatesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: ParcelStateModel[] = httpExtension.sortArray(items, 'date_status', 'asc');
        return new QueryResultsModel(result, parcelStatesState.totalCount, '');
    }
);

export const selectParcelStatessPageLoading = createSelector(
    getParcelStatesState,
    parcelStatessState => parcelStatessState.listLoading
);

export const selectParcelStatessActionLoading = createSelector(
    getParcelStatesState,
    parcelStatessState => parcelStatessState.actionsLoading
);

export const selectParcelStatessPageLastQuery = createSelector(
    getParcelStatesState,
    parcelStatesState => parcelStatesState.lastQuery
);

export const selectParcelStatessInitWaitingMessage = createSelector(
    getParcelStatesState,
    parcelStatesState => parcelStatesState.showInitWaitingMessage
);

export const selectHasParcelStatessInStore = createSelector(
    selectParcelStatessInStore,
    queryResult => {
        return queryResult.totalCount;
    }
);

