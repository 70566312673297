import {Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation} from '@angular/core';
import {DocumentService} from "../../../../../../../core/services/document.service";
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {ocrInfo} from "../../../../../../../core/ek-e-commerce/ek-models/document-ocr-information";
import {
    forbiddenProvinceValidator,
    Wilaya,
    WilayaService
} from "../../../../../../../core/e-commerce/_services/wilaya.service";
import {Town} from "../../../../../../../core/e-commerce/_models/town";
import {map, startWith} from "rxjs/operators";
import {BehaviorSubject, Observable, of} from "rxjs";
import {ClientModel} from "../../../../../../../core/ek-e-commerce/ek-models/client.model";
import {TownService} from "../../../../../../../core/ek-e-commerce/ek-services/town.service";
import {
    ClientOcrInformationService
} from "../../../../../../../core/ek-e-commerce/ek-services/clientOcrInformation.service";
import {SocialProfessional} from "../../../../../../../core/ek-e-commerce/ek-models/social-professional";
import {DecimalFormatPipe} from "../../../../../../../core/_base/layout";
import {FoldersService} from "../../../../../../../core/ek-e-commerce/ek-services/folders.service";
import {MatDatepicker} from "@angular/material/datepicker";
import {BANK_LENDER} from "../../../../Shared/Constants/bank-lender";
import {FormsValidationService} from "../../../../Shared/Services/forms-validation.service";
import Keyboard from "simple-keyboard";
import layout from "simple-keyboard-layouts/build/layouts/arabic";


export interface List {
    name: string;
    value: string;
}

@Component({
    selector: 'kt-folder-step-three',
    encapsulation: ViewEncapsulation.None,
    templateUrl: './folder-step-three.component.html',
    styleUrls: ['./folder-step-three.component.scss'],
    providers: [DecimalFormatPipe]
})
export class FolderStepThreeComponent implements OnInit {

    @Input() ocrInfos!: ocrInfo;
    @Input() client: ClientModel;
    @Input() idFolder: number;
    @Output() clientInfoForm = new EventEmitter<FormGroup>();
    @Input() needSomeChanges: boolean;

    keyboardCommune: Keyboard;
    keyboardAdr: Keyboard;
    keyboardName: Keyboard;
    keyboardLastName: Keyboard;
    panelOpenState = false;
    panelOpenState0 = false;
    panelOpenState1 = false;
    panelOpenState2 = false;
    professionalClientCategory: string = '';
    socialClientCategory: string = '';
    selectedWilaya: string = '';
    form!: FormGroup;
    public wilayasList: Wilaya[] = [];
    socialProfessionalInfo: SocialProfessional;
    isCPAClient = false;

    wilayaSubject$ = new BehaviorSubject<string>('');
    readonly loadingWilaya$ = this.wilayaSubject$.asObservable();

    townsSubject$ = new BehaviorSubject<Town[]>([]);
    readonly town$ = this.townsSubject$.asObservable();

    wilaya = '';
    currentRole: string = '';
    public towns: Town[] = [];
    filteredOptions: Observable<Wilaya[]>;
    townFilteredOptions: Observable<Town[]>;
    activityDomainOptions: Observable<List[]>;
    professionOptions: Observable<List[]>;
    public birthWilayasList: Wilaya[] = [];
    filteredBirthWilayas: Observable<Wilaya[]>;


    folderState = '';

    formControl = new FormControl()

    bankLenderCreditOtherOptions: Observable<List[]>;
    carLoanLenderBankOptions: Observable<List[]>;
    mortgageLenderBankOptions: Observable<List[]>;

    persoSituList = [
        {name: 'Célibataire', value: 'SINGLE'},
        {name: 'Marié', value: 'MARRIED'},
        {name: 'Divorcé', value: 'DIVORCE'},
        {name: 'Veuf', value: 'WIDOWER'}
    ]


    instructionTypeList = [
        {name: 'Moins que le niveau secondaire', value: 'LESS_THAN_HIGH_SCHOOL'},
        {name: 'Niveau secondaire', value: 'SECONDARY_LEVEL'},
        {name: 'Bachelier', value: 'BACHELOR'},
        {name: 'Universitaire', value: 'UNIVERSITY'}
    ]

    genderList = [
        {name: 'Homme', value: 'Male'},
        {name: 'Femme', value: 'Female'},
    ]


    habiTypeList = [
        {name: 'Avec les parents', value: 'WITH_PARENTS'},
        {name: 'Propriétaire', value: 'OWNER'},
        {name: 'Logement de fonction', value: 'FUNCTIONAL_ACCOMMODATION'},
        {name: 'Location', value: 'LEASE'},
    ]

    profSituList = [
        {name: 'Retraité militaire', value: 'MILITARY_RETIRED'},
        {name: 'Employé', value: 'EMPLOYEE'},
        {name: 'Retraité', value: 'RETRIED'},
        {name: 'Militaire', value: 'MILITARY'}
    ]

    typeContratList = [
        {name: 'Contrat de travail à durée indéterminée (CDI)', value: 'CDI'},
        {name: 'Contrat à durée déterminée (CDD)', value: 'CDD'},
        {name: 'Retraité', value: 'RETIREMENT'},
        {name: 'Autres', value: 'OTHER'},
    ]
    @ViewChild('datePicker00') datePicker00!: MatDatepicker<Date>;
    @ViewChild('otherCreditEndDateDP') otherCreditEndDateDP!: MatDatepicker<Date>;
    @ViewChild('carCreditEndDateDP') carCreditEndDateDP!: MatDatepicker<Date>;
    @ViewChild('mortgageEndDateDP') mortgageEndDateDP!: MatDatepicker<Date>;
    @ViewChild('deliveryDateDP') deliveryDateDP!: MatDatepicker<Date>;
    @ViewChild('validationDateDP') validationDateDP!: MatDatepicker<Date>;
    @ViewChild('recruitmentDateDP') recruitmentDateDP!: MatDatepicker<Date>;
    @ViewChild('dateRetraiteDP') dateRetraiteDP!: MatDatepicker<Date>;
    keyboards: Keyboard[] = [];
    private ocrSbj$ = new BehaviorSubject<ocrInfo>(null);
    public ocrInfosObs$ = this.ocrSbj$.asObservable();
    showKeyboards: boolean[] = [];
    prevValue2: string[] = [];

    constructor(public documentService: DocumentService, private fb: FormBuilder, private wilayaService: WilayaService, private townService: TownService,
                private clientOcrInformationService: ClientOcrInformationService, private decimalFormatPipe: DecimalFormatPipe, private folderService: FoldersService, private formsValidationService: FormsValidationService,
    ) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    ngOnInit(): void {
        //detect changes in client social information
        this.clientOcrInformationService.loadingSocialProfessionalSubject.subscribe(change => {
            if (change) {
                this.socialProfessionalInfo = change;
            }
        });
        this.folderService.folderStateSubj$.subscribe(state => this.folderState = state);


        this.wilayasList = this.wilayaService.getWilayasItems();
        this.birthWilayasList = this.wilayaService.getWilayasItems();


        this.documentService.ocrSubject.subscribe(res => {
            if (res) {
                this.getWilayaByTownName(res.personnelInformation.commune);
                this.ocrInfos = res;
                this.ocrSbj$.next(res);
                this.createClientOcrFormGrp();
            }
        })

        this.loadingWilaya$.subscribe(wilaya => {
            if (wilaya) {
                this.form.get('wilaya').setValue(wilaya);
                this.selectedWilaya = wilaya;
            }
        });

        this.town$.subscribe(town => {
            if (town.length > 0) {
                this.towns = town;
            }
        });
        this.showKeyboards.push(false);
    }

    toggleKeyboard(index: number) {
        if (this.currentRole !== 'ROLE_COMMERCIAL_REGION_MANAGER' && this.currentRole !== 'ROLE_CONFORMITY_MANAGER') {
            this.showKeyboards[index] = !this.showKeyboards[index];
        }
    }

    createClientOcrFormGrp() {
        this.socialClientCategory = this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation.socialClientCategory : '';
        this.professionalClientCategory = this.ocrInfos.professionalSituation ? this.ocrInfos.professionalSituation.professionalClientCategory : '';
        this.isCPAClient = this.ocrInfos.financialSituation.isClient;

        this.form = this.fb.group({
            socialClientCategory: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation.socialClientCategory : '', Validators.compose([Validators.required])],
            educationalLevel: [this.ocrInfos.personnelSituation ? this.ocrInfos.personnelSituation.educationalLevel : ''],
            dependentChild: [this.ocrInfos.personnelSituation ? this.ocrInfos.personnelSituation.dependentChild : ''],
            dependentOtherPeople: [this.ocrInfos.personnelSituation ? this.ocrInfos.personnelSituation.dependentOtherPeople : '', Validators.compose([Validators.required])],
            habitation: [this.ocrInfos.personnelSituation ? this.ocrInfos.personnelSituation.habitation : ''],
            zipCode: [this.ocrInfos.personnelSituation ? this.ocrInfos.personnelSituation.zipCode : '', [this.formsValidationService.numericCharacters, Validators.minLength(5), Validators.maxLength(5)]],
            wilaya: new FormControl('', [forbiddenProvinceValidator(this.wilayasList)]),
            address: [this.ocrInfos.personnelSituation ? this.ocrInfos.personnelSituation.personnelSituationAddress : '', Validators.compose([Validators.required])],
            personnelSituationAddressFr: [this.ocrInfos.personnelSituation ? this.ocrInfos.personnelSituation.personnelSituationAddressFr : '', Validators.compose([Validators.required])],
            commune: new FormControl(this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation.commune : '', [Validators.required, this.forbiddenTownsValidator()]),
            communeArabic: new FormControl(this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation.communeArabic : ''),
            lastName: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation.lastName : '', Validators.compose([Validators.required, Validators.minLength(2), this.formsValidationService.alphabeticCharacters])],
            firstName: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation.firstName : '', Validators.compose([Validators.required, Validators.minLength(2), this.formsValidationService.alphabeticCharacters])],
            gender: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation.gender : '', Validators.compose([Validators.required])],
            lastNameEnArab: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation.lastNameEnArab : '', Validators.compose([Validators.required])],
            fistNameEnArab: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation.fistNameEnArab : '', Validators.compose([Validators.required])],
            placeOfBrith: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation.placeOfBrith : '', Validators.compose([Validators.required, Validators.minLength(2), this.formsValidationService.alphabeticCharacters])],

            cardType: ["Carte d'identité biométrique", Validators.compose([Validators.required])],
            idCardNumber: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation.idCardNumber : '', Validators.compose([Validators.required])],
            deliveryDaira: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation.deliveryDaira : '', Validators.compose([Validators.required])],
            nationalIdNumber: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation.nationalIdNumber : '', Validators.compose([Validators.required])],
            socialSecurityNumber: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation.socialSecurityNumber : ''],
            actOfBrithNumber: [this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation.actOfBrithNumber : ''],
            professionalClientCategory: [this.ocrInfos.professionalSituation ? this.ocrInfos.professionalSituation.professionalClientCategory : '', Validators.compose([Validators.required])],
            activityDomains: new FormControl(this.ocrInfos.professionalSituation ? this.ocrInfos.professionalSituation.activityDomain : ''),
            profession: new FormControl(this.ocrInfos.professionalSituation ? this.ocrInfos.professionalSituation.profession : ''),
            professionalPhone: [this.ocrInfos.professionalSituation ? this.ocrInfos.professionalSituation.professionalPhone : ''],
            contractType: [this.ocrInfos.professionalSituation ? this.ocrInfos.professionalSituation.contractType : '', Validators.compose([Validators.required])],
            montant_net: [
                this.ocrInfos.professionalSituation?.professionalClientCategory == 'RETRIED' || this.ocrInfos.professionalSituation?.professionalClientCategory == 'MILITARY_RETIRED' ?
                    this.decimalFormat(this.ocrInfos.professionalSituation.monthlySalaryretrait) :
                    (this.ocrInfos.professionalSituation?.salary ? this.decimalFormat(this.ocrInfos.professionalSituation.salary)
                        : this.ocrInfos.professionalSituation.netSalaryRENA ? this.decimalFormat(this.ocrInfos.professionalSituation.netSalaryRENA) : '')],
            employer: [this.ocrInfos.professionalSituation ? this.ocrInfos.professionalSituation.employer : ''],
            employerWilaya: [this.ocrInfos.professionalSituation ? this.ocrInfos.professionalSituation.employerWilaya : ''],
            employerAddress: [this.ocrInfos.professionalSituation ? this.ocrInfos.professionalSituation.employerAddress : ''],
            accountType: [this.ocrInfos.financialSituation ? this.ocrInfos.financialSituation.accountType : ''],
            birthDay: [this.ocrInfos.personnelInformation ? (this.ocrInfos.personnelInformation.birthDay ? this.formatDate_(this.ocrInfos.personnelInformation.birthDay) : '') : '', Validators.compose([Validators.required])],
            validationDate: [this.ocrInfos.personnelInformation ? (this.ocrInfos.personnelInformation.validationDate ? this.formatDate_(this.ocrInfos.personnelInformation.validationDate) : '') : '', Validators.compose([Validators.required])],
            recruitmentDate: [this.ocrInfos.professionalSituation ? (this.ocrInfos.professionalSituation.recruitmentDate ? this.formatDate_(this.ocrInfos.professionalSituation.recruitmentDate) : '') : ''],
            dateRetraite: [this.ocrInfos.professionalSituation
                ? (this.ocrInfos.professionalSituation.professionalClientCategory === 'MILITARY_RETIRED'
                    ? (this.ocrInfos.professionalSituation.radiationRecruitmentDate ? this.formatDate_(this.ocrInfos.professionalSituation.radiationRecruitmentDate) : '')
                    : (this.ocrInfos.professionalSituation.retraitDate ? this.formatDate_(this.ocrInfos.professionalSituation.retraitDate) : ''))
                : ''],
            deliveryDate: [this.ocrInfos.personnelInformation ? (this.ocrInfos.personnelInformation.deliveryDate ? this.formatDate_(this.ocrInfos.personnelInformation.deliveryDate) : '') : '', Validators.compose([Validators.required])],
            monthlySalary: [this.ocrInfos.professionalSituation ? (this.ocrInfos.professionalSituation.monthlySalaryretrait ? this.decimalFormat(this.ocrInfos.professionalSituation.monthlySalaryretrait) : '') : ''],
            accountNumberCCP: [this.ocrInfos.financialSituation ? this.ocrInfos.financialSituation.accountNumberCCP : ''],
            accountKey: [this.ocrInfos.financialSituation ? this.ocrInfos.financialSituation.accountKey : ''],
            accountNumber: [this.ocrInfos.financialSituation ? this.ocrInfos.financialSituation.accountNumber : '', Validators.compose([Validators.required])],
            isClient: [this.ocrInfos.financialSituation ? this.ocrInfos.financialSituation.isClient : '', Validators.compose([Validators.required])],
            seniority: [this.ocrInfos.financialSituation ? this.ocrInfos.financialSituation.seniority : '0', Validators.compose([Validators.required])],
            mortgageAmount: [this.ocrInfos.financialSituation?.mortgageAmount ? this.decimalFormat(this.ocrInfos.financialSituation?.mortgageAmount) : ""],
            mortgageLenderBank: [this.ocrInfos.financialSituation?.mortgageLenderBank ? this.translateLenderBank(this.ocrInfos.financialSituation?.mortgageLenderBank) : ''],
            mortgageEndDate: [this.ocrInfos.financialSituation?.mortgageEndDate ? (this.ocrInfos.financialSituation?.mortgageEndDate ? this.formatDate_(this.ocrInfos.financialSituation?.mortgageEndDate) : "") : ""],
            carLoanAmount: [this.ocrInfos.financialSituation?.carLoanAmount ? this.decimalFormat(this.ocrInfos.financialSituation?.carLoanAmount) : ''],
            carLoanLenderBank: [this.translateLenderBank(this.ocrInfos.financialSituation?.carLoanLenderBank)],
            carCreditEndDate: [this.ocrInfos.financialSituation?.carCreditEndDate ? this.formatDate_(this.ocrInfos.financialSituation?.carCreditEndDate) : ""],
            otherCreditAmount: [this.ocrInfos.financialSituation?.otherCreditAmount ? this.decimalFormat(this.ocrInfos.financialSituation?.otherCreditAmount) : ''],
            otherCreditEndDate: [this.ocrInfos.financialSituation?.otherCreditEndDate ? this.formatDate_(this.ocrInfos.financialSituation?.otherCreditEndDate) : ""],
            bankLenderCreditOther: [this.ocrInfos.financialSituation?.bankLenderCreditOther ? this.translateLenderBank(this.ocrInfos.financialSituation?.bankLenderCreditOther) : ''],
            birthWilaya: new FormControl(this.ocrInfos.personnelInformation ? this.ocrInfos.personnelInformation.birthWilaya : '', forbiddenProvinceValidator(this.birthWilayasList)),
        });
        this.clientInfoForm.emit(this.form);

        this.filteredOptions = this.form.get('wilaya').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this._filter(name.toString()) : this.wilayasList.slice())
            );
        this.filteredBirthWilayas = this.form.get('birthWilaya').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this.birthWilayaFilter(name.toString()) : this.birthWilayasList.slice())
            );

        this.activityDomainOptions = this.form.get('activityDomains').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this.activityDomainfilter(name.toString()) : this.ActivityDomainList.slice())
            );

        this.bankLenderCreditOtherOptions = this.form.get('bankLenderCreditOther').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this.bankLenderCreditOtherFilter(name.toString()) : BANK_LENDER.slice())
            );

        this.carLoanLenderBankOptions = this.form.get('carLoanLenderBank').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this.bankLenderCreditOtherFilter(name.toString()) : BANK_LENDER.slice())
            );

        this.mortgageLenderBankOptions = this.form.get('mortgageLenderBank').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this.bankLenderCreditOtherFilter(name.toString()) : BANK_LENDER.slice())
            );


        this.professionOptions = this.form.get('profession').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this.professionfilter(name.toString()) : this.ProfessionList.slice())
            );


        this.form.get('isClient').valueChanges.subscribe((value) => {
            if (!value) {
                this.form.controls.seniority.setValue(0);
            }
        });

        this.form.valueChanges.subscribe(() => {

            this.socialClientCategory = this.form.get('socialClientCategory').value;
            this.professionalClientCategory = this.form.get('professionalClientCategory').value;

            //validators management...
            if (this.socialClientCategory === 'SINGLE') {
                this.form.get('dependentChild').clearValidators();
            } else {
                this.form.get('dependentChild').setValidators([Validators.required]);
            }

            if (this.professionalClientCategory === 'RETRIED' || this.professionalClientCategory === 'MILITARY_RETIRED' || this.form.get('contractType').value === 'RETIREMENT') {

                this.form.get('dateRetraite').setValidators([Validators.required]);
                this.form.get('mensuel')?.setValidators([Validators.required]);

                this.form.get('activityDomains').clearValidators();
                this.form.get('profession').clearValidators();
                this.form.get('recruitmentDate').clearValidators();

            } else {

                this.form.get('dateRetraite').clearValidators();
                this.form.get('mensuel')?.clearValidators();

                this.form.get('activityDomains').setValidators([Validators.required]);
                this.form.get('profession').setValidators([Validators.required]);
                this.form.get('recruitmentDate').setValidators([Validators.required]);

            }

            this.form.get('activityDomains').updateValueAndValidity({onlySelf: true})
            this.form.get('profession').updateValueAndValidity()
            this.form.get('recruitmentDate').updateValueAndValidity({onlySelf: true})
            this.form.get('dateRetraite').updateValueAndValidity({onlySelf: true})
            this.form.get('mensuel')?.updateValueAndValidity({onlySelf: true})
            this.form.get('dependentChild').updateValueAndValidity({onlySelf: true})

            this.clientInfoForm.emit(this.form);

        });

    }


    onActivityDomainInput(event) {
        this.activityDomainOptions = of(this.activityDomainfilter(event.target.value.toString()));
    }


    onProfessionInput(event) {
        this.professionOptions = of(this.professionfilter(event.target.value.toString()));
    }

    decimalFormat(value) {
        if (value) {
            let v_ = value.toString().replace(' ', '');
            return this.decimalFormatPipe.transform(v_);
        } else {
            return value
        }
    }

    //format date from dd-mm-yy to EEE MMM dd yyyy HH:mm:ss 'GMT'ZZZZZ (zzzz) format
    formatDate_(inputDateString) {
        if (inputDateString) {
            const [day, month, year] = inputDateString.split("-");
            return new Date(Number(year), Number(month) - 1, Number(day));
        } else {
            return null; // Return null instead of an empty string
        }
    }

    getWilayaTowns(event) {

        this.selectedWilaya = event.option.value;

        this.form.get('commune').reset();

        if (this.form.controls.wilaya.dirty) {
            this.form.controls.commune.enable();
        }

        this.getTowns(this.wilayasList.filter(value => {
            if (value.value == this.selectedWilaya)
                return value
        })[0].id)
    }

    private townFilter(name: string): Town[] {
        const filterValue = name.toLowerCase();
        return this.towns.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private _filter(name: string): Wilaya[] {
        const filterValue = name.toLowerCase();
        return this.wilayasList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private birthWilayaFilter(name: string): Wilaya[] {
        const filterValue = name.toLowerCase();
        return this.birthWilayasList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private activityDomainfilter(name: string): List[] {
        const filterValue = name.toLowerCase();
        return this.ActivityDomainList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private bankLenderCreditOtherFilter(name: string): List[] {
        const filterValue = name.toLowerCase();
        return BANK_LENDER.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    private professionfilter(name: string): List[] {
        const filterValue = name.toLowerCase();
        return this.ProfessionList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    translateLenderBank(value: string) {
        if (value === '') return 'NONE';
        return BANK_LENDER.find(option => option.value === value)?.name;
    }

    getWilayaTownsDefault(selectedWilaya) {

        if (this.selectedWilaya) {

            if (this.form.controls.wilaya.dirty) {
                this.form.controls.commune.enable();
            }

            this.getTowns(this.wilayasList.filter(value => {
                if (value.value == selectedWilaya)
                    return value
            })[0].id)
        }
    }


    getTowns(wilayaId) {
        this.wilayaService.getTownsByWilayaId(wilayaId).subscribe(res => {
            this.towns = res;
            this.townsSubject$.next(res);
            this.townFilteredOptions = this.form.get('commune').valueChanges
                .pipe(
                    startWith(''),
                    map(value => typeof value === 'string' ? value : value),
                    map(name => name ? this.townFilter(name.toString()) : this.towns.slice())
                );
        });
    }

    filteredbirthWilayaExecuted() {
        this.filteredBirthWilayas = this.form.get('birthWilaya').valueChanges

            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this.birthWilayaFilter(name.toString()) : this.birthWilayasList.slice())
            );
    }

    selectBirthWilaya(name: string) {
        let selectedBirthWilaya = this.birthWilayasList.find((c) => c.value === name);
        this.form
            .get('birthWilaya')
            .setValue(selectedBirthWilaya?.value);
    }

    forbiddenTownsValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (this.towns.length > 0) {// below findIndex will check if control.value is equal to one of our options or not
                const index = this.towns.findIndex(town => {
                    return (new RegExp('\^' + town.name + '\$')).test(control.value);
                });
                return index < 0 ? {'forbiddentowns': {value: control.value}} : null;
            }
        };
    }

    getWilayaByTownName(townName) {
        if (townName) {
            this.townService.getWilayaByTownName(townName).subscribe(res => {
                if (res) {
                    this.selectedWilaya = res;
                    this.getWilayaTownsDefault(res);
                    this.wilayaSubject$.next(res);
                    return;
                }
            });
        }
    }

    openDatePicker(): void {
        if (this.datePicker00) {
            this.datePicker00.open();
        }
    }

    openOtherCreditEndDateDP(): void {
        if (this.otherCreditEndDateDP) {
            this.otherCreditEndDateDP.open();
        }
    }

    openCarCreditEndDateDP(): void {
        if (this.carCreditEndDateDP) {
            this.carCreditEndDateDP.open();
        }
    }

    openMortgageEndDateDP(): void {
        if (this.mortgageEndDateDP) {
            this.mortgageEndDateDP.open();
        }
    }

    opendeliveryDatePicker(): void {
        if (this.deliveryDateDP) {
            this.deliveryDateDP.open();
        }
    }

    openValidationDatePicker(): void {
        if (this.validationDateDP) {
            this.validationDateDP.open();
        }
    }

    openRecruitmentDatePicker(): void {
        if (this.recruitmentDateDP) {
            this.recruitmentDateDP.open();
        }
    }

    openDateRetraitePicker(): void {
        if (this.dateRetraiteDP) {
            this.dateRetraiteDP.open();
        }
    }

    ActivityDomainList = [
        {name: 'publique', value: 'PUBLIC'},
        {name: 'privée', value: 'PRIVATE'},
        {name: 'non concerné', value: 'NOT_CONCERNED'},
    ];

    ProfessionList = [
        {
            name: 'Medecins et membres des professions assimiles a la sante',
            value: 'Medecins et membres des professions assimiles a la sante'
        },
        {
            name: "Techniciens superieurs de sante tss et sages-femmes",
            value: "Techniciens superieurs de sante tss et sages-femmes"
        },
        {name: 'Infirmiers', value: 'Infirmiers'},
        {name: 'Gardes d enfants aides-soignants et assimiles', value: 'Gardes d enfants aides-soignants et assimiles'},
        {
            name: 'Cadres superieurs de l\'administration publique',
            value: 'Cadres superieurs de l\'administration publique'
        },
        {name: 'Directeurs', value: 'Directeurs'},
        {name: 'Cadres de direction specialises', value: 'Cadres de direction specialises'},
        {
            name: 'Specialistes des fonctions administratives et commerciales des entreprises',
            value: 'Specialistes des fonctions administratives et commerciales des entreprises'
        },
        {name: 'Specialistes et ingenieurs en informatique', value: 'Specialistes et ingenieurs en informatique'},
        {name: 'Juristes', value: 'Juristes'},
        {
            name: 'Professions intermediaires de la gestion administrative',
            value: 'Professions intermediaires de la gestion administrative'
        },
        {
            name: 'Secretaires dactylos stenodactylos et assimiles',
            value: 'Secretaires dactylos stenodactylos et assimiles'
        },
        {name: 'Autres employes de bureau', value: 'Autres employes de bureau'},
        {name: 'Autres operateurs de materiels informatiques', value: 'Autres operateurs de materiels informatiques'},
        {
            name: 'Autres professions de services directs aux particuliers',
            value: 'Autres professions de services directs aux particuliers'
        },
        {
            name: 'Employes des services comptables et financiers',
            value: 'Employes des services comptables et financiers'
        },
        {name: 'Caissiers, guichetiers et assimiles', value: 'Caissiers, guichetiers et assimiles'},
        {
            name: 'Ouvriers d entretien de batiments et laveurs de vitres',
            value: 'Ouvriers d entretien de batiments et laveurs de vitres'
        },
        {name: 'Eboueurs, balayeurs et assimiles', value: 'Eboueurs, balayeurs et assimiles'},
        {name: 'Coursiers porteurs et gardiens et assimiles', value: 'Coursiers porteurs et gardiens et assimiles'},
        {
            name: 'Aides de maison personnel de nettoyage et travailleurs assimiles',
            value: 'Aides de maison personnel de nettoyage et travailleurs assimiles'
        },
        {
            name: 'Agents d accueil ou d information de la clientele',
            value: 'Agents d accueil ou d information de la clientele'
        },
        {
            name: 'Professeurs d universite et d etablissements d enseignement superieur',
            value: 'Professeurs d universite et d etablissements d enseignement superieur'
        },
        {name: 'Professeurs de l enseignement secondaire', value: 'Professeurs de l enseignement secondaire'},
        {
            name: 'Professions intermediaires de l enseignement primaire',
            value: 'Professions intermediaires de l enseignement primaire'
        },
        {name: 'Autres specialistes de l enseignement', value: 'Autres specialistes de l enseignement'},
        {
            name: 'Autres professions intermediaires de l enseignement',
            value: 'Autres professions intermediaires de l enseignement'
        },
        {
            name: 'Professions intermediaires de l administration publique et inspecteurs de police',
            value: 'Professions intermediaires de l administration publique et inspecteurs de police'
        },
        {
            name: 'Inspecteurs de la police judiciaire et detectives',
            value: 'inspecteurs de la police judiciaire et detectives'
        },
        {
            name: 'Personnel des services de protection et de securite',
            value: 'Personnel des services de protection et de securite'
        },
        {name: 'Artisans et ouvriers de la mecanique', value: 'Artisans et ouvriers de la mecanique'},
        {
            name: 'Conducteurs de locomotives et autres travailleurs des manoeuvres des vehicules sur rail',
            value: 'Conducteurs de locomotives et autres travailleurs des manoeuvres des vehicules sur rail'
        },
        {name: 'Conducteurs de vehicules a moteur', value: 'Conducteurs de vehicules a moteur'},
        {
            name: 'Autres conducteurs de machines fixes et ouvriers de l assemblage',
            value: 'Autres conducteurs de machines fixes et ouvriers de l assemblage'
        },
        {name: 'Professions du forestage et assimilees', value: 'Professions du forestage et assimilees'},
        {name: 'Autres ingenieurs architectes et urbanistes', value: 'Autres ingenieurs architectes et urbanistes'},
        {name: 'Retraites', value: 'Retraites'},

    ]

    ngAfterViewInit() {
        const inputs = document.querySelectorAll('.input-arabic');
        inputs.forEach((input, index) => {
            const prevValue = input as HTMLInputElement
            this.prevValue2[index] = prevValue.value
            const keyboardClass = '.simple-keyboard' + `${index}`
            const keyboard = new Keyboard(keyboardClass, {
                onChange: input => this.onChange(input, index),
                onKeyPress: button => this.onKeyPress(button, index),
                ...layout,
                rtl: true
            });
            this.keyboards.push(keyboard);
            input.addEventListener('input', (event: any) => {
                const targetInput = event.target as HTMLInputElement;
                this.keyboards[index].setInput(targetInput.value);
            });
        });
    }

    onChange = (input: string, index: number) => {
        const inputs = document.querySelectorAll('.input-arabic');
        const targetInput = inputs[index] as HTMLInputElement;
        input = this.prevValue2[index] + input
        targetInput.value = input;
    };

    onKeyPress = (button: string, index: number) => {
        const inputs = document.querySelectorAll('.input-arabic');
        const targetInput = inputs[index] as HTMLInputElement;
        if (button === "{bksp}") {
            targetInput.value = targetInput.value.slice(0, -1); // Remove the last character
            this.prevValue2[index] = targetInput.value == '' ? this.prevValue2[index] = '' : this.prevValue2[index] // Remove the last character
        }
        if (button === "{shift}" || button === "{lock}") {
            this.handleShift(index);
        }
    };

    handleShift = (index: number) => {
        let currentLayout = this.keyboards[index].options.layoutName;
        let shiftToggle = currentLayout === "default" ? "shift" : "default";

        this.keyboards[index].setOptions({
            layoutName: shiftToggle
        });
    };


    isClientChange(value) {
        this.isCPAClient = value;
        if (value) {
            this.form.controls.seniority.setValue(0);
        }
    }
}


