import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from "@angular/common";

@Pipe({
    name: 'decimalFormat',
})
export class DecimalFormatPipe extends DecimalPipe implements PipeTransform {

    transform(value: number): string {
        if (value) {
            let v_ = value.toString().replace(' ','');
            const formattedValue = super.transform(v_);
            let finalV = formattedValue.replace(/,/g, ' ');
            return finalV;
        }else{
            return '';
        }
    }


}
