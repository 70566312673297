import {createAction, props} from "@ngrx/store";
import {Update} from "@ngrx/entity";
import {ConfigurationCharacteristicModel} from "../_models/configuration-characteristic.model";


export const ConfigurationCharacteristicsPageRequested = createAction('[ConfigurationCharacteristic List Page] ConfigurationCharacteristics Page Requested ',props<{configurationId: number}>());
export const ConfigurationCharacteristicsPageLoadedSuccessfully = createAction('[ConfigurationCharacteristic API] ConfigurationCharacteristics Page Loaded Successfully ',props<{configurationCharacteristics: ConfigurationCharacteristicModel[]}>());
export const ConfigurationCharacteristicsPageLoadFailed = createAction('[ConfigurationCharacteristic API] ConfigurationCharacteristics Page Request Failed ',props<{error: any}>());

export const ConfigurationCharacteristicCreated = createAction('[ConfigurationCharacteristic List Page] ConfigurationCharacteristic Created ',props<{configurationCharacteristic: ConfigurationCharacteristicModel}>());
export const ConfigurationCharacteristicCreatedSuccessfully = createAction('[ConfigurationCharacteristic List Page] ConfigurationCharacteristic Created Successfully ',props<{configurationCharacteristic: ConfigurationCharacteristicModel}>());
export const ConfigurationCharacteristicCreationFailed = createAction('[ConfigurationCharacteristic List Page] ConfigurationCharacteristic Creation Failed ',props<{error: any}>());

export const ConfigurationCharacteristicUpdated = createAction('[ConfigurationCharacteristic List Page] ConfigurationCharacteristic Updated ',props<{configurationCharacteristic: ConfigurationCharacteristicModel, partialConfigurationCharacteristic: Update<ConfigurationCharacteristicModel>}>());
export const ConfigurationCharacteristicUpdatedSuccessfully = createAction('[ConfigurationCharacteristic List Page] ConfigurationCharacteristic Updated Successfully ',props<{configurationCharacteristic: ConfigurationCharacteristicModel, partialConfigurationCharacteristic: Update<ConfigurationCharacteristicModel>}>());
export const ConfigurationCharacteristicUpdateFailed = createAction('[ConfigurationCharacteristic List Page] ConfigurationCharacteristic Update Failed ',props<{error: any}>());

export const ConfigurationCharacteristicDeleted = createAction('[ConfigurationCharacteristic List Page] ConfigurationCharacteristic Deleted ',props<{configurationCharacteristicId: number}>());
export const ConfigurationCharacteristicDeletedSuccessfully = createAction('[ConfigurationCharacteristic List Page] ConfigurationCharacteristic deleted Successfully ',props<{configurationCharacteristicId: number}>());
export const ConfigurationCharacteristicDeleteFailed = createAction('[ConfigurationCharacteristic List Page] ConfigurationCharacteristic delete Failed ',props<{error: any}>());

export const ConfigurationCharacteristicDeletedSaveUpdater = createAction('[ConfigurationCharacteristic List Page] ConfigurationCharacteristic Deleted and Save Updatet ',props<{configurationCharacteristicId: number,updater:string}>());
export const ConfigurationCharacteristicDeletedSuccessfullySaveUpdate = createAction('[ConfigurationCharacteristic List Page] ConfigurationCharacteristic deleted Successfully ',props<{configurationCharacteristicId: number,updater:string}>());
export const ConfigurationCharacteristicDeleteFailedSaveUpdater = createAction('[ConfigurationCharacteristic List Page] ConfigurationCharacteristic delete Failed ',props<{error: any}>());

export const ConfigurationCharacteristicCreatedWithUpdater = createAction('[ConfigurationCharacteristic List Page] ConfigurationCharacteristic Created ',props<{configurationCharacteristic: ConfigurationCharacteristicModel,updater:string}>());
export const ConfigurationCharacteristicCreatedSuccessfullyWithUpdater = createAction('[ConfigurationCharacteristic List Page] ConfigurationCharacteristic Created Successfully ',props<{configurationCharacteristic: ConfigurationCharacteristicModel,updater:string}>());
export const ConfigurationCharacteristicCreationFailedWithUpdater = createAction('[ConfigurationCharacteristic List Page] ConfigurationCharacteristic Creation Failed ',props<{error: any}>());

export const ConfigurationCharacteristicUpdatedSaveUpdater = createAction('[ConfigurationCharacteristic List Page] ConfigurationCharacteristic Updated ',props<{configurationCharacteristic: ConfigurationCharacteristicModel, partialConfigurationCharacteristic: Update<ConfigurationCharacteristicModel>,updater:string}>());
export const ConfigurationCharacteristicUpdatedSuccessfullySaveUpdater = createAction('[ConfigurationCharacteristic List Page] ConfigurationCharacteristic Updated Successfully ',props<{configurationCharacteristic: ConfigurationCharacteristicModel, partialConfigurationCharacteristic: Update<ConfigurationCharacteristicModel>,updater:string}>());
export const ConfigurationCharacteristicUpdateFailedSaveUpdater = createAction('[ConfigurationCharacteristic List Page] ConfigurationCharacteristic Update Failed ',props<{error: any}>());
