import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {
    BrandActions,
    CategoryActions, ProductActions,
    ProductModel,
    ProductsService, selectLastCreatedProductId,
    selectProductById
} from "../../../../../../core/e-commerce";
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {CategoryModel} from "../../../../../../core/e-commerce/_models/category.model";
import {BrandModel} from "../../../../../../core/e-commerce/_models/brand.model";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import {ActivatedRoute, Router} from "@angular/router";
import {LayoutUtilsService, MessageType, QueryParamsModel, TypesUtilsService} from "../../../../../../core/_base/crud";
import {LayoutConfigService, SubheaderService} from "../../../../../../core/_base/layout";
import {MatDialog} from "@angular/material/dialog";
import {selectCategoriesInStore} from "../../../../../../core/e-commerce/_selectors/category.selector";
import {selectBrandsInStore} from "../../../../../../core/e-commerce/_selectors/brand.selector";
import {map, startWith} from "rxjs/operators";
import {ClipboardService} from "ngx-clipboard";

@Component({
    selector: 'kt-product2-edit',
    templateUrl: './product2-edit.component.html',
    styleUrls: ['./product2-edit.component.scss']
})
export class EkProduct2EditComponent implements OnInit {

    // Public properties
    product: ProductModel;
    productId$: Observable<number>;
    oldProduct: ProductModel;
    loadingSubject = new BehaviorSubject<boolean>(true);
    loading$: Observable<boolean>;
    productForm: FormGroup;
    hasFormErrors = false;
    // Private password
    private componentSubscriptions: Subscription[] = [];
    // sticky portlet header margin
    private headerMargin: number;
    private updater: string;

    // data
    categories: CategoryModel[];
    availableBrands: BrandModel[];
    filteredBrands: Observable<BrandModel[]>;

    constructor(private store: Store<AppState>,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                private typesUtilsService: TypesUtilsService,
                private productFB: FormBuilder,
                public dialog: MatDialog,
                private subheaderService: SubheaderService,
                private layoutUtilsService: LayoutUtilsService,
                private layoutConfigService: LayoutConfigService,
                private productService: ProductsService,
                private cdr: ChangeDetectorRef,
                private clipboardApi: ClipboardService
    ) {
    }

    ngOnInit(): void {
        this.updater = JSON.parse(localStorage.getItem('currentUser')).username;
        this.loadCategories();
        this.loadBrands();
        this.loading$ = this.loadingSubject.asObservable();
        this.loadingSubject.next(true);
        this.activatedRoute.params.subscribe(params => {
            const id = params.id;
            if (id && id > 0) {
                this.store.pipe(
                    select(selectProductById(id))
                ).subscribe(result => {
                    if (result == undefined) {
                        this.loadProductFromService(id);
                        return;
                    }

                    this.loadProduct(result);
                });
            } else {
                const newProduct = new ProductModel();
                newProduct.clear();
                this.loadProduct(newProduct);
            }
        });

        // sticky portlet header
        window.onload = () => {
            const style = getComputedStyle(document.getElementById('kt_header'));
            this.headerMargin = parseInt(style.height, 0);
        };

        //brands filter
        this.filteredBrands = this.productForm.controls.brand.valueChanges.pipe(
            startWith(''),
            map(value => this._filter(value))
        );
    }


    private loadCategories() {
        this.store.dispatch(CategoryActions.CategoriesPageRequested({page: new QueryParamsModel('', 'asc', 'id', 0, 200)}));
        const categories$ = this.store.select(selectCategoriesInStore).subscribe(
            results => {
                this.categories = results.items;
            }
        );
    }

    private loadBrands() {
        this.store.dispatch(BrandActions.BrandsPageRequested({size: 500, page: 0}));
        const brands$ = this.store.select(selectBrandsInStore).subscribe(
            result => {
                this.availableBrands = result.items;
            }
        );
        this.componentSubscriptions.push(brands$)
    }

    loadProduct(_product, fromService: boolean = false) {
        if (!_product) {
            // this.goBack('');
        }
        this.product = _product;
        this.productId$ = of(_product.id);
        this.oldProduct = Object.assign({}, _product);
        this.initProduct();
        if (fromService) {
            this.cdr.detectChanges();
        }
    }

    // If product didn't find in store
    loadProductFromService(productId) {
        this.productService.getById(productId).subscribe(res => {
            this.loadProduct(res, true);
        });
    }

    /**
     * On destroy
     */
    ngOnDestroy() {
        this.componentSubscriptions.forEach(sub => sub.unsubscribe())
    }

    /**
     * Init product
     */
    initProduct() {
        this.createForm();
        this.loadingSubject.next(false);
        if (!this.product.id) {
            this.subheaderService.setBreadcrumbs([
                {title: 'eCommerce', page: `/ecommerce`},
                {title: 'Products', page: `/ecommerce/products`},
                {title: 'Create product', page: `/ecommerce/products/add`}
            ]);
            return;
        }
        this.subheaderService.setTitle('Edit product');
        this.subheaderService.setBreadcrumbs([
            {title: 'eCommerce', page: `/ecommerce`},
            {title: 'Products', page: `/ecommerce/products`},
            {title: 'Edit product', page: `/ecommerce/products/edit`, queryParams: {id: this.product.id}}
        ]);
    }

    /**
     * Create form
     */
    createForm() {
        this.productForm = this.productFB.group({
            name: [this.product.name, Validators.required],
            // tag: [''],
            category: [this.product.categoryId, Validators.required],
            brand: [this.product.brand.name, Validators.required],
        });
    }

    //autocomplete for brands
    private _filter(value: string): BrandModel[] {
        const filterValue = value.toLowerCase();
        const res = this.availableBrands.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
        return res;
    }

    getBrandId(brandName: string): number {
        const brand = this.availableBrands.filter(res => res.name == brandName);
        if (brand[0])
            return brand[0].id;
    }

    /**
     * Save data
     *
     * @param withBack: boolean
     */
    onSumbit(withBack: boolean = false) {
        this.hasFormErrors = false;
        const controls = this.productForm.controls;
        /** check form */
        if (this.productForm.invalid) {
            Object.keys(controls).forEach(controlName =>
                controls[controlName].markAsTouched()
            );

            this.hasFormErrors = true;
            return;
        }

        // tslint:disable-next-line:prefer-const
        let editedProduct = this.prepareProduct();
        editedProduct.updater = this.updater;

        // if (editedProduct.id > 0) {
        //   // this.updateProduct(editedProduct, withBack);
        //   return;
        // }

        this.addProduct(editedProduct, withBack);
    }

    /**
     * Returns object for saving
     */
    prepareProduct(): ProductModel {
        const controls = this.productForm.controls;
        const _product = new ProductModel();
        _product.id = this.product.id;
        _product.name = controls.name.value;
        _product.categoryId = controls.category.value;
        const brand = new BrandModel();
        brand.id = this.getBrandId(controls.brand.value);
        brand.name = controls.brand.value;
        _product.brand = brand;
        return _product;
    }


    /**
     * Add product
     *
     * @param _product: ProductModel
     * @param withBack: boolean
     */
    addProduct(_product: ProductModel, withBack: boolean = false) {
        _product.activate = true;
        this.loadingSubject.next(true);
        this.store.dispatch(ProductActions.ProductCreated({product: _product}));
        const storesub$ = this.store.pipe(
            // delay(1000),
            select(selectLastCreatedProductId)
        ).subscribe(newId => {
            if (!newId) {
                return;
            }
            this.loadingSubject.next(false);
            if (withBack) {
                // this.goBack(newId);
            } else {
                const message = `New product successfully has been added.`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, true);
                // this.refreshProduct(true, newId);
            }
        });

        this.componentSubscriptions.push(storesub$);
    }


    copyReference() {
        this.productService.generateRefrence().subscribe(res=>
        {
            this.clipboardApi.copyFromContent(res["body"]);
            const message = `Référence copiée avec succès.`;
            this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, false);


        });

    }

}
