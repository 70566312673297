// Angular
import {Injectable} from '@angular/core';
// RxJS
import {catchError, map, switchMap} from 'rxjs/operators';
// NGRX
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// State
import {AppState} from '../../../core/reducers';
// Actions
import {of} from 'rxjs';

import * as NewArrivalActions from "../_actions/newArrival.action";
import {NewArrivalService} from "../_services/newArrival.service";


@Injectable()
export class NewArrivalEffects {


    constructor(private actions$: Actions, private newArrivalService: NewArrivalService, private store: Store<AppState>) {
    }

    NewArrivalPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NewArrivalActions.NewArrivalPageRequested),
            switchMap((action) =>
                this.newArrivalService.getAllPaged(action.page.pageNumber, action.page.pageSize)
                    .pipe(
                        map(newArrivalPage => NewArrivalActions.NewArrivalPageLoadedSuccessfully(
                            {
                                newArrival: newArrivalPage.content,
                                page: newArrivalPage.totalElements,
                                totalCount: newArrivalPage.totalElements
                            })),
                        catchError(error => of(NewArrivalActions.NewArrivalPageLoadFailed({error})))
                    ))));

    NewArrivalCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NewArrivalActions.NewArrivalCreated),
            switchMap((action) =>
                this.newArrivalService.save(action.newArrival)
                    .pipe(
                        map(data => NewArrivalActions.NewArrivalCreatedSuccessfully({newArrival: data})),
                        catchError(error => of(NewArrivalActions.NewArrivalCreationFailed({error})))
                    ))));

    NewArrivalUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NewArrivalActions.NewArrivalUpdated),
            switchMap((action) =>
                this.newArrivalService.update(action.newArrival)
                    .pipe(
                        map(newArrival => NewArrivalActions.NewArrivalUpdatedSuccessfully({
                            newArrival,
                            partialNewArrival: action.partialNewArrival
                        })),
                        catchError(error => of(NewArrivalActions.NewArrivalUpdateFailed({error})))
                    ))));


    NewArrivalDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NewArrivalActions.NewArrivalDeleted),
            switchMap((action) =>
                this.newArrivalService.delete(action.newArrivalId)
                    .pipe(
                        map(() => NewArrivalActions.NewArrivalDeletedSuccessfully({newArrivalId: action.newArrivalId})),
                        catchError(error => of(NewArrivalActions.NewArrivalDeleteFailed({error})))
                    ))));


}
