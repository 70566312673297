import {createAction, props} from "@ngrx/store";
import {CategoryModel} from "../_models/category.model";
import {Update} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";


export const CategoriesPageRequested = createAction('[Category List Page] Categories Page Requested ',props<{page: QueryParamsModel}>());
export const CategoriesPageLoadedSuccessfully = createAction('[Category API] Categories Page Loaded Successfully ',props<{categories: CategoryModel[],totalCount: number, page: number}>());
export const CategoriesPageLoadFailed = createAction('[Category API] Categories Page Request Failed ',props<{error: any}>());

export const CategoryCreated = createAction('[Category List Page] Category Created ',props<{category: CategoryModel}>());
export const CategoryCreatedSuccessfully = createAction('[Category List Page] Category Created Successfully ',props<{category: CategoryModel}>());
export const CategoryCreationFailed = createAction('[Category List Page] Category Creation Failed ',props<{error: any}>());

export const CategoryUpdated = createAction('[Category List Page] Category Updated ',props<{category: CategoryModel, partialCategory: Update<CategoryModel>}>());
export const CategoryUpdatedSuccessfully = createAction('[Category List Page] Category Updated Successfully ',props<{category: CategoryModel, partialCategory: Update<CategoryModel>}>());
export const CategoryUpdateFailed = createAction('[Category List Page] Category Update Failed ',props<{error: any}>());

export const CategoryUpdatedActivate = createAction('[Category List Page] Category Updated activate',props<{category: CategoryModel, partialCategory: Update<CategoryModel>, activate:boolean, updater: string}>());
export const CategoryUpdatedActivateSuccessfully = createAction('[Category List Page] Category Updated Successfully ',props<{category: CategoryModel, partialCategory: Update<CategoryModel>,activate:boolean, updater: string}>());
export const CategoryUpdateActivateFailed = createAction('[Category List Page] Category Update Failed ',props<{error: any}>());

export const CategoryDeleted = createAction('[Category List Page] Category Deleted ',props<{categoryId: number}>());
export const CategoryDeletedSuccessfully = createAction('[Category List Page] Category deleted Successfully ',props<{categoryId: number}>());
export const CategoryDeleteFailed = createAction('[Category List Page] Category delete Failed ',props<{error: any}>());

export const CategoryUploadProgressed = createAction('[Category API] Category Upload Progressed', props<{progress: number}>());

export const SubCategoriesActive = createAction('[SubCategories List Page] SubCategories Page Requested',props<{page: QueryParamsModel}>());
export const SubCategoriesActiveLoadedSuccessfully = createAction('[SubCategories API] SubCategories Page Loaded Successfully ',props<{categories: CategoryModel[],totalCount: number, page: number}>());
export const SubCategoriesActiveLoadFailed = createAction('[SubCategories API] SubCategories Page Request Failed ',props<{error: any}>());


