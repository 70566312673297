import {AgencyBankModel} from './../ek-models/agency-bank-model';
import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {Page} from "../../_base/crud/models/page";
import {AgencyBankListModel} from "../ek-models/agency-bank-List-model";
import {map} from "rxjs/operators";

@Injectable()
export class AgencyBankService extends AbstractService<AgencyBankModel> {


    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}agencyBank`;
    }

    public getAllAgencyBank(pageNumber: number = 0, pageSize: number = 100, direction: string, regionId: string = ""): Observable<Response<Page<AgencyBankModel>>> {

        const url = `${this.url}/listing`;
        let params = new HttpParams();

        params = params.append("pageSize", pageSize.toString());
        params = params.append("direction", direction);
        params = params.append("sortBy", 'id');
        params = params.append("pageNo", pageNumber.toString());
        params = regionId ? params.append("regionId", regionId) : params;

        const options = {params};
        return this.http.get<Response<Page<AgencyBankModel>>>(url, options);
    }

    public getAllAgencyBankWithNumFolder(): Observable<Response<AgencyBankListModel []>> {

        const url = `${this.url}/folder`;

        return this.http.get<Response<AgencyBankListModel[]>>(url);
    }

    public getAcencyByFolderId(folderId: number): Observable<Response<AgencyBankModel>> {
        const url = `${this.url}/folder/${folderId}`
        return this.http.get<Response<AgencyBankModel>>(url)
    }

    assignBanker(agencyBankId: number, wsUserId: number) {
        let params = new HttpParams
        params = params.append("agencyBankId", agencyBankId.toString())
        const url = `${this.url}/agencyBank/${wsUserId}`
        return this.http.patch<Response<any>>(url, params)
    }

    getAgencyNames(): Observable<Response<any>> {
        const url = `${this.url}/names`
        return this.http.get<Response<any>>(url)
    }

    public updateAgency(entity: AgencyBankModel): Observable<AgencyBankModel> {
        const url = `${this.url}/modify`
        return this.http.put<Response<AgencyBankModel>>(url, entity).pipe(map(({body}) => body));
    }

    public saveAgency(entity: AgencyBankModel): Observable<AgencyBankModel> {
        const url = `${this.url}/create`
        const res = this.http.post<Response<AgencyBankModel>>(url, entity).pipe(map(({body}) => body));
        return res;
    }


}