import {createFeatureSelector, createSelector} from "@ngrx/store";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {NotificationRole} from "../ek-models/NotificationRole";
import {NotificationRoleState} from "../ek-reducers/notificationManagement.reducer";


export const selectNotificationSettingsState = createFeatureSelector<NotificationRoleState>('notification-management');

export const selectNotificationSettingsById = (NotificationSettingsId: number) => createSelector(
    selectNotificationSettingsState,
    agencyBankState => {
        if (agencyBankState) return agencyBankState.entities[NotificationSettingsId];
    }
);

export const selectNotificationSettingsPageLoading = createSelector(
    selectNotificationSettingsState,
    agencyBankState => agencyBankState.listLoading
);

export const selectError = createSelector(
    selectNotificationSettingsState,
    agencyBankState => agencyBankState.error
);

export const selectLastAction = createSelector(
    selectNotificationSettingsState,
    notificationRoleState => notificationRoleState.lastAction
);


export const selectNotificationSettingsActionLoading = createSelector(
    selectNotificationSettingsState,
    notificationRoleState => notificationRoleState.actionsLoading
);

export const selectLastCreatedNotificationSettingsId = createSelector(
    selectNotificationSettingsState,
    notificationRoleState => notificationRoleState.lastCreatedNotificationId
);

export const selectNotificationSettingsShowInitWaitingMessage = createSelector(
    selectNotificationSettingsState,
    notificationRoleState => notificationRoleState.showInitWaitingMessage
);

export const selectNotificationSettingsPageLastQuerys = createSelector(
    selectNotificationSettingsState,
    notificationRoleState => notificationRoleState.lastQuery
);

export const getLastAction = createSelector(
    selectNotificationSettingsState,
    notificationRoleState => notificationRoleState.lastAction
);

export const selectNotificationSettingsInStore = createSelector(
    selectNotificationSettingsState,
    notificationRoleState => {
        const items: NotificationRole[] = [];
        each(notificationRoleState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: NotificationRole[] = httpExtension.sortArray(items, notificationRoleState.lastQuery.sortField, notificationRoleState.lastQuery.sortOrder);
        return new QueryResultsModel(result, notificationRoleState.totalCount, '');
    }
);
