import {DeliveryAddressModel} from "./delivery-address.model";


export class DeliveryModel {
    id: number;
    createdAt: string;
    updatedAt: string;
    deliveryType: string;
    deliveryAddress: DeliveryAddressModel;
    orderId: number;
    livreurId: number;
    deliveryPrice: number;
    tracking :string;
}
