import {Injectable} from "@angular/core";
import {act, Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import * as ConfigurationsActions from "../_actions/configurations.actions";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {ConfigurationService} from "../_services/confguration.service";
import * as PromoCodeActions from "../_actions/promoCode.action";
import * as CategoryActions from "../_actions/category-popular.action";



@Injectable()
export class ConfigurationsEffects {


    constructor(private actions$: Actions, private configurationService: ConfigurationService, private store: Store<AppState>) {
    }

    ConfigurationPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationsActions.ConfigurationsPageRequested),
            switchMap((action) =>
                this.configurationService.searchsorted(action.page.sortOrder,action.page.sortField,action.page.pageNumber,action.page.pageSize,action.page.filter,action.categoryIdFilter)
                    .pipe(
                        map(configurations => ConfigurationsActions.ConfigurationsPageLoadedSuccessfully(
                            {
                                configurations: configurations.content,
                                page: configurations.totalElements,
                                totalCount: configurations.totalElements,
                                lastRequest: action.page
                            })),
                        catchError(error => of(ConfigurationsActions.ConfigurationsPageLoadFailed({error})))
                    ))));


    ConfigurationPageRequestedAll$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationsActions.search),
            switchMap((action) =>
                this.configurationService.search(action.page.pageNumber, action.page.pageSize,action.page.sortField,action.page.sortOrder,action.page.filter,action.active,action.categoryId,action.includeInOrderSplit)
                    .pipe(
                        map(configurations => ConfigurationsActions.ConfigurationsPageLoadedSuccessfully(
                            {
                                configurations: configurations.content,
                                page: configurations.totalElements,
                                totalCount: configurations.totalElements,
                                lastRequest: action.page
                            })),
                        catchError(error => of(ConfigurationsActions.ConfigurationsPageLoadFailed({error})))
                    ))));

    ConfigurationFilterByDateRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationsActions.ConfigurationsFilterByDateRequested),
            switchMap((action) =>
                this.configurationService.filterByDate(action.page.sortOrder,action.page.sortField,action.page.pageNumber,action.page.pageSize,action.startDate,action.endDate)
                    .pipe(
                        map(configurations => ConfigurationsActions.ConfigurationsFilterByDateLoadedSuccessfully(
                            {
                                configurations: configurations.content,
                                page: configurations.totalElements,
                                totalCount: configurations.totalElements,
                                lastRequest: action.page
                            })),
                        catchError(error => of(ConfigurationsActions.ConfigurationsFilterByDateLoadFailed({error})))
                    ))));

    ConfigurationCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationsActions.ConfigurationCreated),
            switchMap((action) =>
                this.configurationService.save(action.configuration)
                    .pipe(
                        map(data => ConfigurationsActions.ConfigurationCreatedSuccessfully({configuration: data})),
                        catchError(error => of(ConfigurationsActions.ConfigurationCreationFailed({error})))
                    ))));

    ConfigurationUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationsActions.ConfigurationUpdated),
            switchMap((action) =>
                this.configurationService.update(action.configuration)
                    .pipe(
                        map(configuration => ConfigurationsActions.ConfigurationUpdatedSuccessfully({
                            configuration,
                            partialConfiguration: action.partialConfiguration
                        })),
                        catchError(error => of(ConfigurationsActions.ConfigurationUpdateFailed({error})))
                    ))));

    ConfigurationUpdatedActivate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationsActions.ConfigurationUpdatedActivate),
            switchMap((action) =>
                this.configurationService.changeActivateProduct(action.configuration.id,action.activate,action.updater,action.event)
                    .pipe(
                        map(configuration =>
                            ConfigurationsActions.ConfigurationUpdatedActivateSuccessfully({
                            configuration : action.configuration,
                            partialConfiguration: action.partialConfiguration,
                            activate:action.activate,
                            updater:action.updater
                        })),
                        catchError(error => of(ConfigurationsActions.ConfigurationUpdateActivateFailed({error})))
                    ))));

    ConfigurationDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationsActions.ConfigurationDeleted),
            switchMap((action) =>
                this.configurationService.delete(action.configurationId)
                    .pipe(
                        map(() => ConfigurationsActions.ConfigurationDeletedSuccessfully({configurationId: action.configurationId})),
                        catchError(error => of(ConfigurationsActions.ConfigurationDeleteFailed({error})))
                    ))));
    DiscountPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationsActions.DiscountPageRequested),
            switchMap((action) =>
                this.configurationService.getAllDiscountPagesSearch(action.page.filter,
                    action.page.sortField, action.page.sortOrder,
                    action.page.pageNumber,action.page.pageSize,action.startingDate,
                    action.endingDate,
                    action.state
                )
                    .pipe(
                        map(configurations =>
                            {
                                return ConfigurationsActions.DiscountLoadedSuccessfully(
                                {
                                    Discount : configurations.content,
                                    page: configurations.totalElements,
                                    totalCount: configurations.totalElements,
                                })}
                        ),
                        catchError(error => of(ConfigurationsActions.DiscountLoadFailed({error})))
                    ))));
    VentFlashPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationsActions.VenteFlashPageRequested),
            switchMap((action) =>
                this.configurationService.getAllVenteFlashPagesSearch(action.page.filter,
                    action.page.sortField, action.page.sortOrder,
                    action.page.pageNumber,action.page.pageSize,action.startingDate,
                    action.endingDate,
                    action.active
                )
                    .pipe(
                        map(configuration =>
                            {
                                return ConfigurationsActions.VenteFlashLoadedSuccessfully(
                                    {
                                        VenteFlash : configuration.content,
                                        page: configuration.totalElements,
                                        totalCount: configuration.totalElements,
                                    })}
                        ),
                        catchError(error => of(ConfigurationsActions.DiscountLoadFailed({error})))
                    ))));
    PromotionUpdatedActivate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationsActions.PromotionUpdatedActivate),
            switchMap((action) =>
                this.configurationService.changeActivatePromotion(action.Promotion.discount.id,action.activate)
                    .pipe(
                        map(pro => ConfigurationsActions.PromotionUpdatedActivateSuccessfully({
                            Promotion:action.Promotion,
                            partialPromotion: action.partialPromotion,
                            activate:action.activate,
                           // updater:action.updater
                        })),
                        catchError(error => of(ConfigurationsActions.PromotionUpdateActivateFailed({error})))
                    ))));

    DisplayUpdatedActivate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationsActions.displayConfiguration),
            switchMap((action) =>
                this.configurationService.displayInguichet(action.configurationId,action.display)
                    .pipe(
                        map(configuration =>
                            ConfigurationsActions.displayConfigurationSuccessfully({
                                display:action.display
                            })),
                        catchError(error => of(ConfigurationsActions.displayConfigurationFailed({error})))
                    ))));
}
