import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";
import {DiscountModel} from "../_models/discount.model";
import {Page} from "../../_base/crud/models/page";
import {ConfigurationModel} from "../_models/configuration.model";


@Injectable()
export class DiscountsService extends AbstractService<DiscountModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}product-configurations`;
    }

    public saveDiscount(entity: DiscountModel) {
        return this.http.post<Response<any>>(this.url+'/discount', entity);
    }

    public deleteDiscount(id: number): Observable<Response<any>> {
        const url = `${this.url}/${id}/delete-discount`;
        return this.checkExceptions(this.http.delete<Response<void>>(url));
    }



}
