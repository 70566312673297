import {OrderItemModel} from "./OrderItem.model";
import {CallModel} from "./call.model";
import {DeliveryModel} from "./delivery.model";
import {PromotionCodeModel} from "./promotionCode.model";
import {Discount_CustomerModel} from "./discount_Customer.model";

export class OrderModel2 {
    id: number;
    createdAt: string;
    updatedAt: string;
    creationDate: string;
    clientId: number;
    delivery: DeliveryModel;
    paymentId: number;
    paymentMethod: string;
    previousStatus: string;
    recoveryMode : string;
    totalTTC: number;
    tva:number;
    totalEuro:number;
    totalDollar:number;
    total:number;
    orderType:string;
    source: string;
    orderItems : OrderItemModel[];
    deliveryDate: string;
    confirmationDate:string;
    calls: CallModel[];
    state: string;
    beneficiaryMargin :number;
    updater :string;
    remarkStatus:string;
    tracking:string;
    paymentState: string;
    promoCodeTO:PromotionCodeModel;
    discountClient:Discount_CustomerModel;
}
