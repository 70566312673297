// Angular
import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, RoutesRecognized} from '@angular/router';
// RxJS
import {Observable, of} from 'rxjs';
import { tap } from 'rxjs/operators';
// NGRX
import { select, Store } from '@ngrx/store';
// Auth reducers and selectors
import { AppState} from '../../../core/reducers/';
import * as AuthSelectors from '../_selectors/auth.selectors'
@Injectable()
export class AuthGuard implements CanActivate {
    previousUrl:string;
    constructor(private store: Store<AppState>, private router: Router) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean>  {

        return this.store
            .pipe(
                select(AuthSelectors.isLoggedIn),
                tap(loggedIn => {
                    if (!loggedIn) {
                        /**
                         * stock previous url in query params
                         */
                        this.router.navigate(['/auth/login'],{queryParams:{returnUrl:state.url}});
                    }
                })
            );
    }
}
