// Angular
import {Injectable} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
// RxJS
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {of} from 'rxjs';
// NGRX
import {Actions, createEffect, Effect, ofType} from '@ngrx/effects';
// Auth actions
import {AuthService} from "../_services/auth.service";
import * as AuthActions from '../_actions/auth.actions';
import {UserService} from "../../services/user.service";
import {NgxPermissionsService} from "ngx-permissions";
import {JwtHelperService} from "@auth0/angular-jwt";
import {CPA_ENVIRONMENTS, PDVA_ENVIRONMENTS} from "../../../views/pages/apps/Shared/Constants/environement-constants";


@Injectable()
export class AuthEffects {
    returnUrl: string;
    currentRole: string = '';

    constructor(private authService: AuthService, private userService: UserService, private actions$: Actions, private router: Router,
                private ngxPermissionsService: NgxPermissionsService, private route: ActivatedRoute) {

        let currentUser = localStorage.getItem('currentUser');
        if (currentUser) this.currentRole = JSON.parse(currentUser)?.roles;

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                /**
                 * retrieve previous   url
                 */
                this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
            }
        });
    }

    login$ = createEffect(() => {

            return this.actions$.pipe(
                ofType(AuthActions.login),
                switchMap((action) => this.authService.login(action.email, action.password)
                    .pipe(
                        map(token => AuthActions.loginSucceeded({token, email: action.email})),
                        catchError(error => of(AuthActions.loginFailed({error})))
                    ))
            )
        }
    );

    loginSucceed$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuthActions.loginSucceeded),
            switchMap((action) => this.userService.getUserByEmail()
                .pipe(
                    map(user => AuthActions.getLoggedInUserSucceed({user})),
                    catchError(error => of(AuthActions.getLoggedInUserFailed({error})))
                ))
        );
    });

    @Effect({dispatch: false})
    loginFailed$ = this.actions$.pipe(
        ofType(AuthActions.loginFailed),
        tap((action) => {
            // localStorage.setItem(environment.authTokenKey,action.token.access_token);
            // this.router.navigate(['/dashboard']);
        })
    );

    @Effect({dispatch: false})
    getLoggedInUserSucceed$ = this.actions$.pipe(
        ofType(AuthActions.getLoggedInUserSucceed),
        tap((action) => {
            /**
             * Redirect to previous url
             */
            this.router.navigateByUrl(this.returnUrl);
            this.notifyMe(action.user);
            localStorage.setItem('currentUser', JSON.stringify(action.user));
        })
    );

    @Effect({dispatch: false})
    loginSucceeded$ = this.actions$.pipe(
        ofType(AuthActions.loginSucceeded),
        tap((action) => {
            localStorage.setItem('accessToken', action.token.access_token);
            this.loadPermissions();

        })
    );

    @Effect({dispatch: false})
    logout$ = this.actions$.pipe(
        ofType(AuthActions.logout),
        tap((action) => {

            const currentUser = localStorage.getItem('currentUser');
            if (currentUser) { this.currentRole = JSON.parse(currentUser)?.roles; }

            const  handleLogOut = () => {
                localStorage.setItem('accessToken', null);
                localStorage.setItem('currentUser', null);
                this.ngxPermissionsService.flushPermissions();
            };

            if (this.currentRole && this.currentRole === 'ROLE_BANKER_EK') {

                console.log('banker is disconnected...');

                handleLogOut();

                // login page
                this.router.navigate(['/auth/login']);

            } else {

                handleLogOut();

                console.log(this.currentRole , ' is disconnected...');

                switch (location.origin) {

                    // prod
                    case CPA_ENVIRONMENTS.productionUrl:
                        this.openExternalUrl(PDVA_ENVIRONMENTS.productionUrl);
                        break;

                    // staging
                    case CPA_ENVIRONMENTS.stagingUrl:
                        this.openExternalUrl(PDVA_ENVIRONMENTS.stagingUrl);
                        break;

                    // dev
                    case CPA_ENVIRONMENTS.devUrl:
                        this.openExternalUrl(PDVA_ENVIRONMENTS.devUrl);
                        break;

                    // local
                    case CPA_ENVIRONMENTS.localHostUrl:
                        this.openExternalUrl(PDVA_ENVIRONMENTS.devUrl);
                        break;
                }
            }
        })
    );

    private openExternalUrl(url: string) {
        //prevent the newly opened window from having access to the parent window && remove the referrer information
        const newWindow = window.open(url, '_self', 'noopener,noreferrer');
        if (newWindow) {
            newWindow.opener = null; // Ensure no access to the opening window
        }
    }

    /**
     * NGX Permissions, init roles
     */
    loadPermissions() {
        const res = this.getPermissions();
        if (!res || res.length === 0) {
            return;
        }
        this.ngxPermissionsService.flushPermissions();
        res.forEach((pm: string) => {
            this.ngxPermissionsService.addPermission(pm)
        });

    }

    getPermissions(): string [] {
        const helper = new JwtHelperService();
        const token = localStorage.getItem('accessToken')
        if (token != "null")
            return helper.decodeToken(token).authorities;
        return null;
    }

    notifyMe(user) {
        if (!("Notification" in window)) {
            alert("This browser does not support desktop notification");
        } else if (Notification.permission === "granted") {
            var notification = new Notification("welcome " + user.firstname + ' !');
        } else if (Notification.permission !== "denied") {
            Notification.requestPermission().then(function (permission) {
                if (permission === "granted") {
                    var notification = new Notification("welcome " + user.firstname + ' !');
                }
            });
        }
    }

}
