import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {CommentFolderService} from "../../../../../../../../core/ek-e-commerce/ek-services/comment-folder.service";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

import {ActivatedRoute} from "@angular/router";
import {CommentFolderModel} from "../../../../../../../../core/ek-e-commerce/ek-models/CommentFolder.model";
import {FoldersService} from "../../../../../../../../core/ek-e-commerce/ek-services/folders.service";
import {FolderModel} from "../../../../../../../../core/ek-e-commerce/ek-models/folder.model";
import {BehaviorSubject} from "rxjs";
import {LayoutUtilsService, MessageType} from "../../../../../../../../core/_base/crud";
import {ChecklistService} from "../../../../../../../../core/ek-e-commerce/ek-services/checklist.service";
import {DocumentService} from "../../../../../../../../core/services/document.service";
import {OrderSplitService} from "../../../../../../../../core/ek-e-commerce/ek-services/order-split.service";
import {GenericObject} from "../../../../../../../../core/ek-e-commerce/ek-models/generic-object.model";
import {MenuConfigService} from '../../../../../../../../core/_base/layout/services/menu-config.service';


@Component({
    selector: 'kt-add-comment',
    templateUrl: './add-comment.component.html',
    styleUrls: ['./add-comment.component.scss']
})
export class AddCommentComponent implements OnInit {


    folderId!: number;
    comment: CommentFolderModel = new CommentFolderModel();
    clientId: number | undefined;
    idfolder: number;
    role: any;
    step: string | undefined;
    idOrder: number;
    content = "";
    title: string;

    @Input() idFolder: number;
    @Input() folder: FolderModel;
    @Input() isNewOrder: boolean = false;
    @Output() commentAdded: EventEmitter<any> = new EventEmitter<any>();

    private loading$ = new BehaviorSubject<boolean>(false);
    spinner$ = this.loading$.asObservable()
    currentRole: string = '';

    constructor(private commentService: CommentFolderService,
                public dialogRef: MatDialogRef<AddCommentComponent>,
                private route: ActivatedRoute,
                private folderService: FoldersService,
                @Inject(MAT_DIALOG_DATA) public data: any,
                private layoutUtilsService: LayoutUtilsService,
                private checklistService: ChecklistService,
                private documentService: DocumentService,
                private orderSplitService: OrderSplitService,
                private menuConfigService: MenuConfigService,

    ) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    ngOnInit(): void {

        this.title = (this.data.editMode && this.data.editMode == true) ? 'Modifier un commentaire' : 'Ajouter un commentaire';


        this.comment.folderId = this.data.idFolder;
        this.comment.title = this.data.title;

        this.content = this.data.comment ? this.data.comment.content : "";

        this.route.queryParams.subscribe((params: any) => {
            this.step = String(params["step"]);
        });

        this.folderService.selectedFolderSubject$.subscribe((res) => {
            if (res) {
                this.folder = res;
                if (!this.isNewOrder) this.folderService.folderStateSubj$.next(res.folderState);
                this.comment.folderState = res.folderState;
            }
        })

    }

    close() {
        this.dialogRef.close();
    }
    sendCommentBulkReserve(){
        this.dialogRef.close(this.content);
    }

    addCommentReserved() {

        if(this.content.length > 0) {
            if(this.data.nextFolderState) {
                let folderState;

                switch(this.data.nextFolderState) {
                    case "EK_RETURN_FOLDER" :{
                        folderState = "EK_FOLDER_RESERVE"
                        break;
                    }
                    case "EK_BANK_RETURN_FOLDER" :{
                        folderState = "EK_BANK_RESERVE"
                        break;
                    }
                    case "EK_BANK_RETURN_PHYSICAL_FOLDER" :{
                        folderState = "EK_BANK_PHYSICAL_FOLDER_RESERVE"
                        break;
                    }
                }

                this.loading$.next(true);
    
                this.comment.content = this.content;
                this.comment.username = JSON.parse(localStorage.getItem('currentUser')).username;
                this.comment.userId = JSON.parse(localStorage.getItem('currentUser')).id;
                this.comment.createdAt = new Date().toISOString();
                this.comment.folderState = folderState
                this.comment.email = JSON.parse(localStorage.getItem('currentUser')).email;
    
    

            this.commentService.addCommentFolder(this.comment).subscribe(
                (response: any) => {
                    this.loading$.next(false);
                    this.folderService.changeState(this.folder.id, this.data.nextFolderState).subscribe({
                        next: (res) => {
                            this.refresh();
                            this.getFoldersStatisticsForMenuConfigs();
                            this.dialogRef.close(res);
                            this.folderService.folderStateSubj$.next(res);
                        },
                        error: (error) => {
            
                            if (error.error.code === 401) {
                                const message = `vous pouvez pas faire cette opération !`;
                                this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
                            }
            
                            if (error.status === 403) {
                                const message = `vous pouvez pas changer le statu !`;
                                this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
                            }
                        },
                    });
                    this.dialogRef.close();
                },
                (error: any) => {
                    const message = `Une erreur est survenue !`;
                    this.layoutUtilsService.showActionNotification(
                        message,
                        MessageType.Update,
                        5000,
                        true,
                        true
                    );
                    this.dialogRef.close();
                }
            );
        } else return
        } else {
            const message = `Vous devez saisir un commentaire!`;
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
        }
    }

    refresh() {
        this.folderService.getFolderById(this.folder.id).subscribe(res => {
            if (res) {
                this.folderService.folderStateSubj$.next(res.folderState)
                this.folderService.selectedFolderSubject$.next(res);
            }
        });

        //reload aside left folders list
        this.getFoldersStatisticsForMenuConfigs();
    }




    getFoldersStatisticsForMenuConfigs() {

        this.folderService.FoldersStatistics().subscribe({
            next: (res) => {
                this.menuConfigService.statisticsSubject$.next(null as any);
                this.menuConfigService.statisticsSubject$.next(res.countFolderState);
            }
        });

        if (this.currentRole === 'ROLE_ADMIN'
            || this.currentRole === 'ROLE_SUPERADMIN'
            || this.currentRole === 'ROLE_BANKER_EK'
        ) {
            this.folderService.GlobalFolderStatistics().subscribe({
                next: (res) => {
                    this.menuConfigService.GlobalFolderStatisticsSubject$.next(null as any);
                    this.menuConfigService.GlobalFolderStatisticsSubject$.next(res.countFolderState);
                }
            });
        }


    }





    addComment() {

        if (this.content.length > 0) {

            this.loading$.next(true);

            this.comment.content = this.content;
            this.comment.username = JSON.parse(localStorage.getItem('currentUser')).username;
            this.comment.userId = JSON.parse(localStorage.getItem('currentUser')).id;
            this.comment.createdAt = new Date().toISOString();
            this.comment.folderState = this.data.folderState;
            this.comment.email = JSON.parse(localStorage.getItem('currentUser')).email;


            //refuse folder from folder steps
            if (this.data.cancelingOrder && this.data.cancelingOrder === true) {
                this.saveCancelRemark();
                return;
            }

            //cancel order from listing
            if (this.data.cancelingOrderFromListing && this.data.cancelingOrderFromListing == true && this.step === 'undefined') {
                this.cancelOrderSplit();
                return;
            }


            // cancel folder from steps
            if (this.data.isCancelFolder && this.data.isCancelFolder === true) {
                this.performFolderCancellation();
                return;
            }

            if (this.step === 'undefined') {

                //check list management
                if (this.data.documentId && this.data.documentId !== 0) {


                    //PHOTO_IDENTITY case just update state
                    if (this.data.documentGender === 'PHOTO_IDENTITY' || this.data.documentGender == 'FEES_STATEMENT') {

                        this.documentService.updateDocumentState(this.data.documentId, this.data.state, this.data.currentDocOpeningDate).subscribe(res => {
                            if (res) {
                                this.dialogRef.close();
                                const message = `le document a été réfusé avec succès !`;
                                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
                            }
                        })

                        return;
                    }


                    //refuse case => if user does not check any checklist => replace document check list states by corresponding events ...
                    for (let key in this.data.genericChecklist) {
                        this.data.genericChecklist[key] = this.checklistService.convertDocStateToEvent(this.data.genericChecklist[key]);
                    }

                    let finalCheck: GenericObject<any> = this.data.genericChecklist;

                    //if user check any checklist add its event to finalCheck
                    this.data.checklistEvents.forEach((ch, i) => {
                        for (let key in finalCheck) {
                            if (key === ch.key) {
                                finalCheck[key] = ch.checkEventName;
                            }
                        }
                    });

                    this.commentService.addCommentFolder(this.comment).subscribe({
                        next: (res) => {
                            this.documentService.updateDocumentState(this.data.documentId, this.data.state, this.data.currentDocOpeningDate).subscribe(res => {
                                this.checklistService.updateChecklist(this.data.idFolder, this.data.documentGender, this.data.documentTtitle, finalCheck).subscribe((response) => {
                                    if (res) {
                                        const message = `le document a été réfusé avec succès !`;
                                        this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
                                        this.commentAdded.emit({comment: this.comment});
                                        this.dialogRef.close(this.step == '8' ? this.data.comments : response.body);
                                    }
                                })
                            })
                        },
                        error: () => {
                            const message = `error , try again !`;
                            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
                        }
                    });
                }

                //comments management
                else {
                    if (this.data.editMode && this.data.editMode == true) {

                        this.comment.id = this.data.comment.id;

                        this.commentService.updateComment(this.comment).subscribe(res => {
                            if (res) {
                                this.loading$.next(false);
                                this.dialogRef.close(res);
                            } else {
                                this.loading$.next(false);
                                const message = `Une erreur est survenue !`;
                                this.layoutUtilsService.showActionNotification(
                                    message,
                                    MessageType.Update,
                                    5000,
                                    true,
                                    true
                                );
                                this.dialogRef.close();
                            }
                        });

                    } else {
                        this.commentService.addCommentFolder(this.comment).subscribe(
                            (response: any) => {
                                this.loading$.next(false);
                                this.dialogRef.close(response);
                            },
                            (error: any) => {
                                const message = `Une erreur est survenue !`;
                                this.layoutUtilsService.showActionNotification(
                                    message,
                                    MessageType.Update,
                                    5000,
                                    true,
                                    true
                                );
                                this.dialogRef.close();
                            }
                        );
                    }
                }
            }


        } else {
            const message = `Vous devez saisir un commentaire!`;
            const message2 = `Motif d'annulation est obligatoire pour annuler le dossier!`;
            this.layoutUtilsService.showActionNotification(this.step == '1' ? message2 : message, MessageType.Update, 5000, true, true);

        }
    }


    performFolderCancellation(): void {
        this.folderService.cancelFolder(this.data.idFolder).subscribe({
            next: (res) => {
                // Reload aside left folders list
                this.getFoldersStatisticsForMenuConfigs();
                this.addCommentFolder();
            },
            error: (error) => {
                this.refresh();
                const message = `An error occurred!`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
                this.dialogRef.close();
            },
        });
    }

    addCommentFolder() {
        this.commentService.addCommentFolder(this.comment).subscribe(
            (response: any) => {
                this.loading$.next(false);
                this.dialogRef.close(response);
            },
            (error: any) => {
                const message = `Une erreur est survenue !`;
                this.layoutUtilsService.showActionNotification(
                    message,
                    MessageType.Update,
                    5000,
                    true,
                    true
                );
                this.dialogRef.close();
            }
        );
    }

    saveCancelRemark() {

        switch (this.data.folderState) {
            case 'EK_BANK_IN_PROGRESS':
                //supposed res : EK_BANK_REFUSED
                this.folderService.changeState(this.data.idFolder, 'EK_BANK_REFUSE_FOLDER').subscribe({
                    next: (res) => {
                        this.cancelOrderSplit(res);
                    }
                });
                break;



            case 'EK_BANK_PHYSICAL_FOLDER_IN_PROGRESS':
                //supposed res : EK_BANK_PHYSICAL_FOLDER_REFUSED
                this.folderService.changeState(this.data.idFolder, 'EK_REFUSE_BANK_PHYSICAL_FOLDER').subscribe({
                    next: (res) => {
                        this.cancelOrderSplit(res)
                    }
                });
                break;

            case 'EK_PHYSICAL_FOLDER_IN_PROGRESS':
                //supposed res : EK_PHYSICAL_FOLDER_REFUSED
                this.folderService.changeState(this.data.idFolder, 'EK_REFUSE_PHYSICAL_FOLDER').subscribe({
                    next: (res) => {
                        this.cancelOrderSplit(res);
                    }
                });
                break;

            case 'EK_PHYSICAL_FOLDER_PENDING':
                //supposed res : EK_PHYSICAL_FOLDER_REFUSED
                this.folderService.changeState(this.data.idFolder, 'EK_REFUSE_PHYSICAL_FOLDER').subscribe({
                    next: (res) => {
                        this.cancelOrderSplit(res);
                    }
                });
                break;

            case 'GUARTENTEES_SIGNED_IN_PROGRESS_BANK':
                //supposed res : EK_FOLDER_REFUSED
                this.folderService.changeState(this.data.idFolder, 'GUARTENTEES_SIGNED_IN_PROGRESS_BANK_TO_EK_BANK_REFUSED').subscribe({
                    next: (res) => {
                        this.cancelOrderSplit(res);
                    }
                });
                break;

            case 'EK_FOLDER_IN_PROGRESS':
                //supposed res : EK_FOLDER_REFUSED
                this.folderService.changeState(this.data.idFolder, 'EK_REFUSE_FOLDER').subscribe({
                    next: (res) => {
                        this.cancelOrderSplit(res);
                    }
                });
                break;

            case 'BANK_NOTIFICATION':
                //supposed res : EK_BANK_REFUSED
                this.folderService.changeState(this.data.idFolder, 'BANK_NOTIFICATION_TO_EK_BANK_REFUSED').subscribe({
                    next: (res) => {
                        this.cancelOrderSplit(res);
                    }
                });
                break;

        }

    }

    cancelOrderSplit(newState?: any) {

        if (this.currentRole === 'ROLE_BANKER_EK') {

            //update folder state
            this.folderService.folderStateSubj$.next(newState);

            //....
            this.loading$.next(false);

            //close dialog
            this.dialogRef.close(this.content);

            return;
        }

        let date = new Date();

        let cancelObj = {
            cancellingDate: date.toISOString(),
            cancellingReason: this.content
        }

        //return status : EK_CLIENT_ORDER_CANCELED
        this.orderSplitService.cancelOrderSplit(this.data.idOrder, cancelObj).subscribe(res => {
            if (res) {
                const message = `la commande a été bien annulée!`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
                //update displayed state
                if (newState) {
                    this.folderService.folderStateSubj$.next(newState);
                } else {
                    this.orderSplitService.orderSplitState$.next(true);
                }
                this.loading$.next(false);
            }
        });

        this.addCommentFolder();

        //close dialog
        this.dialogRef.close(this.content);
    }
}
