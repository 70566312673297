import {Injectable} from '@angular/core';
import {AbstractService} from "../../services/abstract-service.service";
import {RegionModel} from "../_models/region.model";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {User} from "../../auth";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class RegionService extends AbstractService<RegionModel> {

    protected url: string;
    protected wsUserUrl: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint + 'regions'}`; 
        this.wsUserUrl = `${ADMIN_CONFIG.apiEndpoint + 'ws-users/commercial'}`;


    }

    public findAll(query: string, commercialId: number): Observable<RegionModel> {
        //  console.log(query)
        //  console.log(commercialId)
        const options = {
            params: new HttpParams()
                .append('query', query ? query : '')
                .append('commercialId', commercialId ? commercialId.toString() : '-1')
        };
        return this.http.get<RegionModel>(this.url + '/search', options);
    }

    public getUsedWilayas() {
        return this.http.get(this.url + '/wilayas');
    }

    public findAllCommercials(): Observable<User[]> {
        const users$ = this.http.get<Response<User[]>>(this.wsUserUrl).pipe(map(({body}) => body));
        return users$;
    }


}
