import {createAction, props} from "@ngrx/store";
import {QueryParamsModel} from "../../_base/crud";
import {SmsHistoryModel} from "../ek-models/sms-history-model";

export const SmsHistoryPageRequested = createAction(
    '[SmsHistory List Page] SmsHistory Page Requested ',
    props<{ page: QueryParamsModel , firstDate:string , lastDate:string }>()
);

export const SmsHistoryPageLoadedSuccessfully = createAction(
    '[SmsHistory API] SmsHistory Page Loaded Successfully ',
    props<{ SmsHistory: SmsHistoryModel[]; totalCount: number; page: number }>()
);

export const SmsHistoryPageLoadFailed = createAction(
    '[SmsHistory API] SmsHistory Page Request Failed ',
    props<{ error: any }>()
);
