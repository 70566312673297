import {Component, OnInit, Inject} from '@angular/core';
import {MAT_SNACK_BAR_DATA, MatSnackBar} from '@angular/material/snack-bar';
@Component({
  selector: 'app-ek-snackbar',
  templateUrl: './ek-snackbar.component.html',
  styleUrls: ['./ek-snackbar.component.scss']
})
export class EkSnackbarComponent implements OnInit {
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, private snackBar: MatSnackBar

  ){
}



  ngOnInit() {}

  get getIcon() {
    switch (this.data.snackType) {
      case 'success':
        return {type: this.data.snackType, icon: 'check', action: 'ok'};
      case 'error':
        return {type: this.data.snackType, icon: 'faults', action: 'ok'};
      case 'warn':
        return {type: this.data.snackType, icon: 'warning_outline', action: 'ok'};
      case 'info':
        return {type: this.data.snackType, icon: 'info', action: 'ok'};
      default:
      return null
    };
    }
   exit(){
     this.snackBar.dismiss();
   }
}
