// NGRX
import {createAction, props} from '@ngrx/store';
// Models
import {Update} from '@ngrx/entity';
import {QueryParamsModel} from "../../_base/crud";
import {NewArrivalModel} from "../_models/newArrival.model";

export const NewArrivalPageRequested = createAction('[NewArrival List Page] NewArrival Page Requested ',props<{page: QueryParamsModel}>());
export const NewArrivalPageLoadedSuccessfully = createAction('[NewArrival API] NewArrival Page Loaded Successfully ',props<{newArrival: NewArrivalModel[],totalCount: number, page: number}>());
export const NewArrivalPageLoadFailed = createAction('[NewArrival API] NewArrival Page Request Failed ',props<{error: any}>());

export const NewArrivalCreated = createAction('[NewArrival List Page] NewArrival Created ',props<{newArrival: NewArrivalModel}>());
export const NewArrivalCreatedSuccessfully = createAction('[NewArrival List Page] NewArrival Created Successfully ',props<{newArrival: NewArrivalModel}>());
export const NewArrivalCreationFailed = createAction('[NewArrival List Page] NewArrival Creation Failed ',props<{error: any}>());

export const NewArrivalUpdated = createAction('[NewArrival List Page] NewArrival Updated ',props<{newArrival: NewArrivalModel, partialNewArrival: Update<NewArrivalModel>}>());
export const NewArrivalUpdatedSuccessfully = createAction('[NewArrival List Page] NewArrival Updated Successfully ',props<{newArrival: NewArrivalModel, partialNewArrival: Update<NewArrivalModel>}>());
export const NewArrivalUpdateFailed = createAction('[NewArrival List Page] NewArrival Update Failed ',props<{error: any}>());


export const NewArrivalDeleted = createAction('[NewArrival List Page] NewArrival Deleted ',props<{newArrivalId: number}>());
export const NewArrivalDeletedSuccessfully = createAction('[NewArrival List Page] NewArrival deleted Successfully ',props<{newArrivalId: number}>());
export const NewArrivalDeleteFailed = createAction('[NewArrival List Page] NewArrival delete Failed ',props<{error: any}>());


