import {createFeatureSelector, createSelector} from "@ngrx/store";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {SmsHistoryState} from "../ek-reducers/sms-history.reducers";
import {SmsHistoryModel} from "../ek-models/sms-history-model";


export const selectSmsState = createFeatureSelector<SmsHistoryState>('history-sms');

export const selectSmsById = (smsId: number) => createSelector(
    selectSmsState,
    SMSState => {
        if (SMSState) return SMSState.entities[smsId];
    }
);

export const selectSmsPageLoading = createSelector(
    selectSmsState,
    SMSState => SMSState.listLoading
);

export const selectError = createSelector(
    selectSmsState,
    SMSState => SMSState.error
);

export const selectLastAction = createSelector(
    selectSmsState,
    SMSState => SMSState.lastAction
);


export const selectSmsActionLoading = createSelector(
    selectSmsState,
    SMSState => SMSState.actionsLoading
);

export const selectLastCreatedSmsId = createSelector(
    selectSmsState,
    SMSState => SMSState.lastCreatedSmsId
);

export const selectSmsShowInitWaitingMessage = createSelector(
    selectSmsState,
    SMSState => SMSState.showInitWaitingMessage
);

export const selectSmsPageLastQuerys = createSelector(
    selectSmsState,
    SMSState => SMSState.lastQuery
);

export const selectSmsInStore = createSelector(
    selectSmsState,
    SMSState => {
        const items: SmsHistoryModel[] = [];
        each(SMSState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: SmsHistoryModel[] = httpExtension.sortArray(items, SMSState.lastQuery.sortField, SMSState.lastQuery.sortOrder);
        return new QueryResultsModel(result, SMSState.totalCount, '');
    }
);
