import {Injectable} from '@angular/core';
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient, HttpEvent, HttpParams, HttpRequest} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";
import { saveAs } from 'file-saver';
import {EkFooterInformationModel} from "../ek-models/EkfooterInformation.model";

@Injectable()
export class EkFooterInformationService extends AbstractService<EkFooterInformationModel> {

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}contact-infoEk`;
        this.questionsUrl = `${ADMIN_CONFIG.apiEndpoint}questions`;
    }

    protected url: string;
    private questionsUrl: string;

    public get(): Observable<EkFooterInformationModel> {
        const url = `${this.url}`;
        return this.http.get<Response<EkFooterInformationModel>>(url).pipe(map(({body}) => body));
    }


    updateQuestions(questionsFile): Observable<HttpEvent<{
        body: {
            body: {}
        }
    }>> {
        const data: FormData = new FormData();
        data.append('file', questionsFile.item(0));
        const newRequest = new HttpRequest('POST', this.questionsUrl + "/upload-from-file", data, {
            reportProgress: true,
            responseType: 'json'
        });
        return this.http.request(newRequest);
    }

    updateInfo(entity: EkFooterInformationModel): Observable<any> {
        const params = new HttpParams()
            .set('generalConditionUrl', entity.generalConditionUrl)
            .set('legalMentionUrl', entity.legalMentionUrl)
            .set('ekInfoEmail', entity.ekInfoEmail)
            .set('ekPhoneNumber', entity.ekPhoneNumber)
            .set('ekPhoneNumber2', entity.ekPhoneNumber2)
            .set('address', entity.address)
            .set('faqFileName',entity.faqFileName)
            .set('urlGps', entity.urlGps)
            .set('id', entity.id.toString())
            .set('updatedAt', entity.updatedAt)
            .set('createdAt', entity.createdAt)
        ;

        //const newRequest = new HttpRequest('PUT', this.url, params);
      //  return this.http.request(newRequest);
        return this.http.put(this.url, entity);
    }

    downloadQuestion(){
        this.http.get(this.questionsUrl+'/export', {responseType: 'blob'})
            .subscribe((res) => {
                saveAs(res, "")
            })
    }


}
