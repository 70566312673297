import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {NotificationModel} from "../../../../../core/e-commerce/_models/notification.model";
import {BehaviorSubject} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";
import {RxStompService} from "@stomp/ng2-stompjs";
import {NotificationService} from "../../../../../core/e-commerce";
import {User} from "../../../../../core/auth";
import {Message} from "@stomp/stompjs";
import {ConnectedPosition} from "@angular/cdk/overlay";
import {NotificationType} from "../../../../../core/e-commerce/_models/notificationType.model";

@Component({
    selector: 'kt-registration-notifications',
    templateUrl: './registration-notifications.component.html',
    styleUrls: ['./registration-notifications.component.scss']
})
export class RegistrationNotificationsComponent implements OnInit {

    // Show dot on top of the icon
    @Input() dot: string;

    @Input() pulseLight: boolean;

    // Set icon class name
    @Input() icon = 'flaticon2-bell-alarm-symbol';
    @Input() iconType: '' | 'success';

    // Set true to icon as SVG or false as icon class
    @Input() useSVG: boolean;

    // Set bg image path
    @Input() bgImage: string;

    // Set skin color, default to light
    @Input() skin: 'light' | 'dark' = 'light';

    @Input() type: 'brand' | 'success' = 'success';

    @Output() emitShow = new EventEmitter<boolean>();

    ReNotifications: NotificationModel[] = []; //registrations

    private ReNotificationsSbj$ = new BehaviorSubject<NotificationModel []>([]);

    maxWidth: number = 0;

    // Show pulse on icon
    pulse$ = new BehaviorSubject(false);
    public receivedMessages$ = new BehaviorSubject<{
        id: number,
        value: string,
        type: string,
        date: string,
        wsUser: string
    }[]>([]);
    public messagesNmbr$ = new BehaviorSubject(0);
    private messages: { id: number, type: string, value: string, date: string, wsUser: string }[] = [];
    public notOpenedRegistrations$ = new BehaviorSubject(0);
    private notOpenedRegistrations: number = 0;

    @ViewChild('trigger', {static: true}) private trigger!: ElementRef;

    /**
     * Component constructor
     *
     * @param sanitizer: DomSanitizer
     */
    constructor(private sanitizer: DomSanitizer, private rxStompService: RxStompService, private notificationService: NotificationService) {
    }

    ngOnInit() {

        this.maxWidth = this.trigger.nativeElement.offsetWidth;
/*

        this.findAllRegistrationNotifications();

        const user: User = JSON.parse(localStorage.getItem('currentUser'));
        this.rxStompService.watch('/user/' + user.username + '/topic/clients').subscribe((message: Message) => {
            const objMessage = JSON.parse(message.body);
            this.messages.push({
                id: objMessage.id,
                type: objMessage.type,
                value: objMessage.value,
                date: objMessage.date,
                wsUser: objMessage.wsUser
            })
            this.messagesNmbr$.next(this.messages.length);
            this.notOpenedRegistrations$.next(this.notOpenedRegistrations + 1);
            this.receivedMessages$.next(this.messages);
            this.pulse$.next(this.messagesNmbr$.value !== 0)
        });
*/


    }

    showMenu = false;

    positions: ConnectedPosition[] = [
        {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'bottom',
        },
    ];


    onSendMessage() {
        const message = `Message generated at ${new Date}`;
        this.rxStompService.publish({destination: '/topic/orders', body: message});
    }


    backGroundStyle(): string {
        if (!this.bgImage) {
            return 'none';
        }

        return 'url(' + this.bgImage + ')';
    }

    open(id: number, opened: boolean) {

        this.showMenu = false;

        this.emitShow.emit(false);

        //change notification to opened
        this.notificationService.changeOpened(id, true).subscribe();

        //reload list of notifications
        this.findAllRegistrationNotifications();
    }

    findAllRegistrationNotifications() {
        this.notificationService.FindNotificationsByType(NotificationType.NEW_CLIENT).subscribe(
            notifications => {
                this.ReNotificationsSbj$.next(notifications)
                this.ReNotifications = notifications;
                this.notOpenedRegistrations = notifications.length;
                this.notOpenedRegistrations$.next(this.notOpenedRegistrations);
                this.notificationService.registrations$.next(this.notOpenedRegistrations);
            }
        )
    }
}
