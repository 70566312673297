import { Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {ADMIN_CONFIG} from '../../../../environments/environment';
import {AbstractService} from '../../services/abstract-service.service';
import {Response} from '../../_base/crud/models/response';


@Injectable({
    providedIn: 'root',
})
export class OtpService extends AbstractService<any> {

    url = '';

    constructor(
        protected http: HttpClient,
    ) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}otp`;
    }

    public generateValidationCode(phoneNumber: string) {
        const url = `${this.url}/generate?phoneNumber=${phoneNumber}`;
        return this.http.post(url, {}).pipe(
            map((res) => {
                return res;
            })
        );
    }

    public checkCode(phoneNumber: string, otp: string): Observable<any> {
        const url = `${this.url}/validate?phoneNumber=${phoneNumber}&otp=${otp}`;
        return this.http.get<Response<any>>(url, {}).pipe(
            map((res) => {
                if (res.body) {
                    return res.body;
                }
            })
        );
    }
}
