import {BaseModel} from "../../_base/crud";
import {PhoneNumberModel} from "./phone-number.model";

export class PointRelaitModel extends BaseModel{
    id: number;
    wilaya: string;
    originalPrixDomicile: number;
    originalPrixPointRelait: number;
    remiseDomicile: number;
    remisePointRelait: number;
    prixDomicile: number;
    prixPointrelait: number;
    phoneNumbers:PhoneNumberModel[];
    updater: string;
}