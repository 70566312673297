import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import * as OrderSplitActions from "../ek-actions/orderSplit.actions";
import {OrderSplitService} from "../ek-services/order-split.service";


@Injectable()
export class OrderSplitEffects {
    constructor(private actions$: Actions,
                private orderSplitService: OrderSplitService) {
    }

    OrderSplitCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderSplitActions.OrderSplitCreated),
            switchMap((action) =>
                this.orderSplitService.createOrderSplit(action.OrderSplit)
                    .pipe(
                        map(data => {
                            return OrderSplitActions.OrderSplitCreatedSuccessfully({
                                response: data,
                            });
                        }),
                        catchError(error => of(OrderSplitActions.OrderSplitCreationFailed({error})))
                    ))));

    OrderSplitUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(OrderSplitActions.OrderSplitUpdated),
            switchMap((action) =>
                this.orderSplitService.updateOrderSplitByAdmin(action.idClient , action.idOrderSplit , action.OrderSplit)
                    .pipe(
                        map(orderSplit => {
                            return OrderSplitActions.OrderSplitUpdatedSuccessfully({
                                OrderSplit: orderSplit,
                                partialOrderSplit: action.partialOrderSplit
                            });
                        }),
                        catchError(error => of(OrderSplitActions.OrderSplitUpdateFailed({error})))
                    ))));

}
