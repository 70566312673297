import {createFeatureSelector, createSelector} from "@ngrx/store";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {POSState} from "../ek-reducers/point-of-sale.reducers";
import {PointOfSaleModel} from "../ek-models/point-of-sale.model";


export const selectPOSsState = createFeatureSelector<POSState>('pos');

export const selectPOSById = (posId: number) => createSelector(
    selectPOSsState,
    possState => {
        if (possState) return possState.entities[posId];
    }
);

export const selectPOSsPageLoading = createSelector(
    selectPOSsState,
    possState => possState.listLoading
);

export const selectError = createSelector(
    selectPOSsState,
    possState => possState.error
);

export const selectLastAction = createSelector(
    selectPOSsState,
    possState => possState.lastAction
);


export const selectPOSsActionLoading = createSelector(
    selectPOSsState,
    possState => possState.actionsLoading
);

export const selectLastCreatedPOSId = createSelector(
    selectPOSsState,
    possState => possState.lastCreatedPOSId
);

export const selectPOSsShowInitWaitingMessage = createSelector(
    selectPOSsState,
    possState => possState.showInitWaitingMessage
);

export const selectPOSsPageLastQuerys = createSelector(
    selectPOSsState,
    possState => possState.lastQuery
);

export const selectPOSsInStore = createSelector(
    selectPOSsState,
    possState => {
        const items: PointOfSaleModel[] = [];
        each(possState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: PointOfSaleModel[] = httpExtension.sortArray(items, possState.lastQuery.sortField, possState.lastQuery.sortOrder);
        return new QueryResultsModel(result, possState.totalCount, '');
    }
);
