import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {Response} from "../../_base/crud/models/response";
import {Action, createReducer, on} from "@ngrx/store";
import {OrderSplitAdmin} from "../ek-models/OrderSplitAdmin";
import * as OrderSplitActions from "../ek-actions/orderSplit.actions";
import * as PromoCodeActions from "../../e-commerce/_actions/promoCode.action";

export interface OrderSplitState extends EntityState<OrderSplitAdmin> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedOrderSplitId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
    startingDate: string;
    endingDate: string;
}

export const adapter: EntityAdapter<OrderSplitAdmin> = createEntityAdapter<OrderSplitAdmin>();

export const initialOrderSplitState: OrderSplitState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedOrderSplitId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
    entities: [],
    startingDate: '',
    endingDate: '',
});

const orderSplitReducer = createReducer(
    initialOrderSplitState,
    //creation
    on(OrderSplitActions.OrderSplitCreated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(OrderSplitActions.OrderSplitCreatedSuccessfully,
        (state, action) => (adapter.addOne(action.response, {
            ...state,
            actionsLoading: true,
            lastCreatedOrderSplitId: action.response,
            lastAction: 'EDIT.ADD_MESSAGE'
        }))),
    on(OrderSplitActions.OrderSplitCreationFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),

    //Update
    on(OrderSplitActions.OrderSplitUpdated,
        (state) => ({...state, actionsLoading: true, error: null})),
    on(OrderSplitActions.OrderSplitUpdatedSuccessfully,
        (state, action) => {
            if (action.partialOrderSplit){
                return (adapter.updateOne(action.partialOrderSplit, {
                    ...state,
                    actionsLoading: false,
                    lastAction: 'EDIT.UPDATE_MESSAGE',
                }))
            }
        }
    ),
    on(OrderSplitActions.OrderSplitUpdateFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),

);

export function orderSplitsReducer(state: OrderSplitState | undefined, action: Action) {
    return orderSplitReducer(state, action)
}
