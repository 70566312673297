// THE HORIZONTAL MENU THAT CONTAINS THE PROFILE ICON
// Angular
import {Component, OnInit} from '@angular/core';
import {NotificationService} from "../../../../core/e-commerce";

@Component({
    selector: 'kt-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent implements OnInit {
    registrations: number;
    outOfStock: number;
    orders: number;
    showInscNotif: boolean = false;
    showOutStockNotif: boolean = false;
    showNewOrderNotif: boolean = false;

    currentRole = '';

    constructor(private notificationService: NotificationService) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    ngOnInit() {
        this.notificationService.orders.subscribe(res => {
            this.orders = res
        });
        this.notificationService.outOfStock.subscribe(res => {
            this.outOfStock = res
        });
        this.notificationService.registrations.subscribe(res => {
            this.registrations = res
        })
    }

}
