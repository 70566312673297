import { createFeatureSelector, createSelector } from '@ngrx/store';
import {RegionState} from "../_reducers/region.reducer";
import {QuestionAnswerModel} from "../../home-page/question-answer/questionanswer.model";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {RegionModel} from "../_models/region.model";

 
export const getRegionState = createFeatureSelector<RegionState>('region');
export const selectRegionById = (regionId: number) => createSelector(
    getRegionState,
    regionState => regionState.entities[regionId]
);


export const selectRegionStore = createSelector(
    getRegionState,
    (regionState: RegionState) => {
        const regions: RegionModel[] = [];
        Object.entries(regionState.entities).forEach(([k,v]) => regions.push(v));
        return regions;
    }
);

export const selectRegionsInStore = createSelector(
    getRegionState,
    regionState => {
        const items: RegionModel[] = [];
        each(regionState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: RegionModel[] = httpExtension.sortArray(items, regionState.lastQuery.sortField, regionState.lastQuery.sortOrder);
        return new QueryResultsModel(result, regionState.totalCount, '');
    }
);

export const selectRegionsPageLoading = createSelector(
    getRegionState,
    regionsState => regionsState.listLoading
);

export const selectRegionsActionLoading = createSelector(
    getRegionState,
    regionsState => regionsState.actionsLoading
);

export const selectRegionsPageLastQuery = createSelector(
    getRegionState,
    regionState => regionState.lastQuery
);

export const selectLastCreatedRegionId = createSelector(
    getRegionState,
    regionState => regionState.lastCreatedRegionId
);

export const selectRegionsInitWaitingMessage = createSelector(
    getRegionState,
    regionState => regionState.showInitWaitingMessage
);

export const selectHasRegionsInStore = createSelector(
    selectRegionsInStore,
    queryResult => {
        return queryResult.totalCount;


    }
);

export const selectCommercials = createSelector(
    getRegionState,
    state => {
        return state.commercials
    }
)

export const areRegionssLoaded = createSelector(
    getRegionState,
    state => {
        return state.allRegionsLoaded
    }
)

export const areCommercialsLoaded = createSelector(
    getRegionState,
    state => {
        return state.allCommercialsLoaded
    }
)

