import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NotificationModel} from "../../../../../core/e-commerce/_models/notification.model";
import {BehaviorSubject} from "rxjs";
import {DomSanitizer} from "@angular/platform-browser";
import {RxStompService} from "@stomp/ng2-stompjs";
import {NotificationService} from "../../../../../core/e-commerce";
import {User} from "../../../../../core/auth";
import {Message} from "@stomp/stompjs";
import {ConnectedPosition} from "@angular/cdk/overlay";
import {Router} from "@angular/router";
import {NotificationType} from "../../../../../core/e-commerce/_models/notificationType.model";

@Component({
  selector: 'kt-order-notifications',
  templateUrl: './order-notifications.component.html',
  styleUrls: ['./order-notifications.component.scss']
})
export class OrderNotificationsComponent implements OnInit {

  // Show dot on top of the icon
  @Input() dot: string;
  show:boolean=false;

  @Input() pulseLight: boolean;

  // Set icon class name
  @Input() icon = 'flaticon2-bell-alarm-symbol';
  @Input() iconType: '' | 'success';

  // Set true to icon as SVG or false as icon class
  @Input() useSVG: boolean;
  @Output() emitShow =  new EventEmitter<boolean>();

  // Set bg image path
  @Input() bgImage: string;

  // Set skin color, default to light
  @Input() skin: 'light' | 'dark' = 'light';

  @Input() type: 'brand' | 'success' = 'success';

  notifications: NotificationModel[]=[];
  oNotifications: NotificationModel[]=[]; //orders

  // Show pulse on icon
  pulse$ = new BehaviorSubject(false);
  public receivedMessages$ = new BehaviorSubject<{id: number, value: string, type: string, date: string, wsUser: string}[]> ([]);
  public messagesNmbr$ = new BehaviorSubject(0);
  private messages: {id: number, type: string, value: string, date: string, wsUser:string}[] = [];
  public notOpenedOrders$ = new BehaviorSubject(0);
  private notOpenedOrders: number = 0;




  positions: ConnectedPosition[] = [
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'bottom',
    },
  ];

  /**
   * Component constructor
   *
   * @param rxStompService
   * @param notificationService
   * @param router
   */
  constructor(private rxStompService: RxStompService,
              private notificationService: NotificationService,
              private router: Router,
  ) {
  }

  ngOnInit() {

   // this.findAllOrdersNotifications();

    const user: User = JSON.parse(localStorage.getItem('currentUser'));
    this.rxStompService.watch('/user/'+user.username+'/topic/orders').subscribe((message: Message) => {
      const objMessage = JSON.parse(message.body);
      this.messages.push({id: objMessage.id, type: objMessage.type, value: objMessage.value, date: objMessage.date, wsUser: objMessage.wsUser});
      this.messagesNmbr$.next(this.messages.length);
      this.receivedMessages$.next(this.messages);
      this.pulse$.next(this.messagesNmbr$.value !== 0);
      this.notificationService.orders$.next(this.notOpenedOrders+1)
    });

  }

  backGroundStyle(): string {
    if (!this.bgImage) {
      return 'none';
    }

    return 'url(' + this.bgImage + ')';
  }

  open(id: number, opened: boolean){

    this.show = false ; this.emitShow.emit(false);

    //change notification to opned
    this.notificationService.changeOpened(id, true).subscribe();

    //reload list of notifications
    this.findAllOrdersNotifications();
  }

  navigate(value){

    const numbers = value.split('/');

    const orderId = numbers[0]; // "123"
    const folderId = numbers[1]; // "33"

    this.router.navigateByUrl('/ek-ecommerce/folder/edit/' + orderId + '/' + folderId + '?step=1')

  }
  findAllOrdersNotifications(){
    this.notificationService.FindNotificationsByType(NotificationType.NEW_ORDER).subscribe(
        notifications=>{
          this.oNotifications= notifications;
          this.notOpenedOrders= notifications.length;
          this.notOpenedOrders$.next(notifications.length);
          this.notificationService.orders$.next(this.notOpenedOrders);

        }
    )
  }

}
