import { User } from './../../auth/_models/user.model';
import { createAction, props } from '@ngrx/store';
import {RegionModel} from "../_models/region.model";

export const loadRegions = createAction('[Region] Load Regions',props<{ query:string,commercialId:number}>());
export const loadRegionsSuccess = createAction('[Region] Load Regions Success', props<{ data: RegionModel[] }>());
export const loadRegionsFailure = createAction('[Region] Load Regions Failure', props<{ error: any }>());

export const loadCommercials = createAction('[Commercial] Load Commercials')
export const loadCommercialsSucess = createAction('[Commercial] Commercials Loaded Successfuly', props<{users: User[]}>())
export const loadCommercialsFailure = createAction('[Commercial] Commercials Loaded Failed', props<{error : any}>())


export const RegionDeleted = createAction('[Region Delete] Region Deleted ',props<{RegionId: number}>());
export const RegionDeletedSuccessfully = createAction('[Region Delete] Region deleted Successfully ',props<{RegionId: number}>());
export const RegionDeleteFailed = createAction('[Region Delete] Region delete Failed ',props<{error: any}>());

export const RegionRequested = createAction('[Region Requested] Region Requested ',props<{RegionId: number}>());
export const RegionRequestedSuccessfully = createAction('[Region Requested] Region Requested Successfully ',props<{Region: RegionModel}>());
export const RegionRequestedFailed = createAction('[Region Requested] Region Requested Failed ',props<{error: any}>());

export const SaveRegionRequested = createAction('[Region save Requested] Region save Requested ',props<{Region: RegionModel}>());
export const SaveRegionSuccessfully = createAction('[Region save Requested] Region save Successfully ',props<{Region: RegionModel}>());
export const SaveRegionFailed = createAction('[Region save Requested] Region save Failed ',props<{error: any}>()); 
