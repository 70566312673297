import {Component, OnInit} from '@angular/core';
import {
    NotificationManagementByRoleComponent
} from './notification-management-by-role/notification-management-by-role.component';
import {MatDialog} from '@angular/material/dialog';
import {NotificationRole} from '../../../../../core/ek-e-commerce/ek-models/NotificationRole';
import {
    NotificationManagementDatasource
} from '../../../../../core/ek-e-commerce/ek-data-sources/notificationManagement.datasource';
import {SelectionModel} from '@angular/cdk/collections';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Store} from '@ngrx/store';
import {AppState} from '../../../../../core/reducers';
import {NotificationSettingsService} from "../../../../../core/ek-e-commerce/ek-services/notificayion-settings.service";

@Component({
    selector: 'kt-notification-settings',
    templateUrl: './notification-settings.component.html',
    styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit {

    displayedColumns: string[] = ['status', 'admin', 'superAdmin', 'CA', 'banker', 'adminGuest', 'pos', 'client', 'action'];

    dataSource: NotificationManagementDatasource;
    selection = new SelectionModel<NotificationRole>(true, []);

    constructor(public dialog: MatDialog,
                public snackBar: MatSnackBar,
                private notificationSettingsService: NotificationSettingsService,
                private store: Store<AppState>) {
    }

    ngOnInit(): void {

        // Init DataSource
        this.dataSource = new NotificationManagementDatasource(this.store);

        this.loadNotificationRoleList();

    }

    loadNotificationRoleList() {

        this.notificationSettingsService.getNotificationSettingsListing().subscribe({
            next: (res) => {
                this.dataSource.entitySubject.next(res);
            }
        });

        // this.selection.clear();
        //
        // // Call request from store
        // this.store.dispatch(notificationManagementPageRequested());
        //
        // this.selection.clear();
    }

    openNotificationManagement(selectedRow: NotificationRole) {
        const dialogRef = this.dialog.open(NotificationManagementByRoleComponent, {
            width: '38rem',
            data: {
                notificationRow: selectedRow
            }
        });

        dialogRef.afterClosed().subscribe(res => {
            if (res === true) {
                this.loadNotificationRoleList();
            }
        });
    }

}
