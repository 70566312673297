import {BaseModel} from "../../_base/crud";
import {ParcelModel} from "./parcel.model";


export class ParcelsResponseModel extends BaseModel{
    id:number;
    has_more: boolean;
    total_data: number;
    data: ParcelModel[];
    links: string;
}
