import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as ParcelActions from '../_actions/parcel.actions';
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {ParcelService} from "../_services/parcel.service";


@Injectable()
export class ParcelStatesEffects {

    constructor(private actions$: Actions,
                private parcelService: ParcelService,
               ) {}

    ParcelStatesLoadRequesed$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ParcelActions.loadParcelStates),
            switchMap((action) =>
                this.parcelService.findParcelStates(action.tracking)
                    .pipe(
                        map(parcelStatesList => {
                            return ParcelActions.loadParcelStatesSuccess(
                                {
                                    data: parcelStatesList,
                                })
                        }),
                        catchError(error => of(ParcelActions.loadParcelStatesFailure({error})))
                    ))));

}
