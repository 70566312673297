import { HttpClient, HttpParams } from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {AbstractEntity} from "../_base/crud/models/abstract-entity";
import {Page} from "../_base/crud/models/page";
import {Response} from "../_base/crud/models/response";
import {map, mergeMap, tap} from "rxjs/operators";


export abstract class AbstractService<E extends AbstractEntity> {

    protected abstract url: string;

    constructor(protected http: HttpClient) { }

    public getById(id: number): Observable<E> {
        const url = `${this.url}/${id}`;
        return this.http.get<Response<E>>(url).pipe(map(({body}) => body));
    }

    public getAll(): Observable<E[]> {
        return this.http.get<Response<E[]>>(this.url).pipe(map(({body}) => body));
    }

    public getByOrderId(id: number): Observable<E[]>{
        const url = `${this.url}/order/${id}`;
        return this.http.get<Response<E[]>>(url).pipe(map(({body}) => body));

    }

    public getAllPaged(page: number = 0, size: number = 100): Observable<Page<E>> {
        const options = {
            params: new HttpParams()
                .append('page', page.toString())
                .append('size', size.toString())
        };
        const url = `${this.url}/pages`;
        return this.http.get<Response<Page<E>>>(url, options).pipe(map(({body}) => body));
    }

    public save(entity: E): Observable<E> {
        const res = this.http.post<Response<E>>(this.url, entity).pipe(map(({body}) => body));
        return res;
    }

    public saveAll(entities: E[]): Observable<E[]> {
        const url = `${this.url}/all`;
        const res =  this.http.post<Response<E[]>>(url, entities).pipe(map(({body}) => body));
        return res;
    }

    public update(entity: E): Observable<E> {
        return this.http.put<Response<E>>(this.url, entity).pipe(map(({body}) => body));
    }

    public delete(id: number): Observable<Response<any>> {
        const url = `${this.url}/${id}`;
        return this.checkExceptions(this.http.delete<Response<void>>(url));
    }

    public checkExceptions(response: Observable<Response<any>>): Observable<Response<any>>{
        return response.pipe(
            mergeMap(result => result.code == 0 ?
            of(result): throwError(result))
        )
    }



}
