import {BaseModel} from "../../_base/crud";
import {DeliveryAddressModel} from "./delivery-address.model";

export class OrderConfirmationModel extends BaseModel{
    clientId:number;
    paymentMethod:string;
    address:DeliveryAddressModel;
    recoveryMode:string;
    livreurId:number;
    deliveryType:string;
    promoCodeId?:  number
}
