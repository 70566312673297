import {createFeatureSelector, createSelector} from "@ngrx/store";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {PromoCodeState} from "../_reducers/promoCode.reducers";
import {PromotionCodeModel} from "../_models/promotionCode.model";


export const selectPromoCodesState = createFeatureSelector<PromoCodeState>('promoCodes');

export const selectPromoCodeById = (promoCodeId: number) => createSelector(
    selectPromoCodesState,
    promoCodesState => {
        if (promoCodesState) return promoCodesState.entities[promoCodeId];
    }
);

export const selectPromoCodesPageLoading = createSelector(
    selectPromoCodesState,
    promoCodesState => promoCodesState.listLoading
);

export const selectError = createSelector(
    selectPromoCodesState,
    promoCodesState => promoCodesState.error
);

export const selectlastAction = createSelector(
    selectPromoCodesState,
    promoCodesState => promoCodesState.lastAction
);

export const selectlastState = createSelector(
    selectPromoCodesState,
    promoCodesState => promoCodesState.promoState
);

export const selectlastStartingDate = createSelector(
    selectPromoCodesState,
    promoCodesState => promoCodesState.startingDate
);

export const selectlastEndingDate = createSelector(
    selectPromoCodesState,
    promoCodesState => promoCodesState.endingDate
);

export const selectlastPromotionCodeType = createSelector(
    selectPromoCodesState,
    promoCodesState => promoCodesState.promotionCodeType
);


export const selectPromoCodesActionLoading = createSelector(
    selectPromoCodesState,
    promoCodesState => promoCodesState.actionsLoading
);

export const selectLastCreatedPromoCodeId = createSelector(
    selectPromoCodesState,
    promoCodesState => promoCodesState.lastCreatedPromoCodeId
);

export const selectPromoCodesShowInitWaitingMessage = createSelector(
    selectPromoCodesState,
    promoCodesState => promoCodesState.showInitWaitingMessage
);

export const selectPromoCodesPageLastQuerys = createSelector(
    selectPromoCodesState,
    configurationsState => configurationsState.lastQuery
);

export const selectPromoCodesInStore = createSelector(
    selectPromoCodesState,
    promoCodesState => {
        const items: PromotionCodeModel[] = [];
        each(promoCodesState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: PromotionCodeModel[] = httpExtension.sortArray(items, promoCodesState.lastQuery.sortField, promoCodesState.lastQuery.sortOrder);
        return new QueryResultsModel(result, promoCodesState.totalCount, '');
    }
);
