import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { ADMIN_CONFIG } from '../../../../environments/environment';
import { AbstractService } from '../../services/abstract-service.service';
import { StockModel } from '../ek-models/stock.model';

@Injectable({
    providedIn: 'root',
})
export class StocksService extends AbstractService<StockModel> {
    url: string;
    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}stockDepot/listStockDepot`;
    }
}