import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {ConfigurationService} from "../_services/confguration.service";
import {Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import * as ConfigurationsActions from "../_actions/product-trending.actions";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";

@Injectable()
export class ProductTrendingEffects {


    constructor(private actions$: Actions, private configurationService: ConfigurationService, private store: Store<AppState>) {
    }
    TrendingPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationsActions.TrendingPageRequested),
            switchMap((action) =>
                this.configurationService.getTrending(action.page.pageNumber, action.page.pageSize, action.page.filter)
                    .pipe(
                        map(trendingPage => ConfigurationsActions.TrendingPageLoadedSuccessfully(
                            {
                                trending: trendingPage.content,
                                page: trendingPage.totalElements,
                                totalCount: trendingPage.totalElements
                            })),
                        catchError(error => of(ConfigurationsActions.TrendingPageLoadFailed({error})))
                    ))));

    TrendingUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ConfigurationsActions.TrendingUpdated),
            switchMap((action) =>
                this.configurationService.changeDisplay(action.trendingId, action.display, action.updater)
                    .pipe(
                        map(trending => ConfigurationsActions.TrendingUpdatedSuccessfully({
                            trending: trending,
                            partialTrending: action.partialTrending
                        })),
                        catchError(error => of(ConfigurationsActions.TrendingUpdateFailed({error})))
                    )
            )
        ));
}
