import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatRadioChange} from "@angular/material/radio";
import {animate, style, transition, trigger} from '@angular/animations';
import {Observable} from "rxjs";
import {PointOfSaleModel} from "../../../../../../../core/ek-e-commerce/ek-models/point-of-sale.model";
import {map, startWith} from "rxjs/operators";
import {FormControl} from "@angular/forms";
import {selectPOSsInStore} from "../../../../../../../core/ek-e-commerce/ek-selectors/point-of-sale.selector";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../../core/reducers";
import {ClientModel} from "../../../../../../../core/ek-e-commerce/ek-models/client.model";
import {OrderSplit} from "../../../../../../../core/ek-e-commerce/ek-models/orderSplit";
import {
    ShippingAddressFolderModel
} from "../../../../../../../core/ek-e-commerce/ek-models/shipping-address-folder.model";
import {RelayPointModel} from "../../../../../../../core/ek-e-commerce/ek-models/relay-point.model";
import {FoldersService} from "../../../../../../../core/ek-e-commerce/ek-services/folders.service";
import {EkDeliveryAddressModel} from "../../../../../../../core/ek-e-commerce/ek-models/ek-delivery-address.model";
import relay from '../../../../Shared/jsonFiles/relayPoint.json'
import {ClientsService} from "../../../../../../../core/ek-e-commerce/ek-services/clients.service";
import {Address} from "../../../../../../../core/e-commerce/_models/address.model";
import {MatDialog} from "@angular/material/dialog";
import {NewAddressPopUpComponent} from "./new-address-pop-up/new-address-pop-up.component";
import {PointOfSaleService} from "../../../../../../../core/ek-e-commerce/ek-services/point-of-sale.service";

@Component({
    selector: 'kt-folder-step-seven',
    templateUrl: './folder-step-seven.component.html',
    styleUrls: ['./folder-step-seven.component.scss'],
    animations: [
        trigger(
            'inOutAnimation',
            [
                transition(
                    ':enter',
                    [
                        style({height: 0, opacity: 0}),
                        animate('.3s linear',
                            style({
                                transform: 'scale(0,1)', opacity: 0.3,
                            }))
                    ]
                ),
                transition(
                    ':leave',
                    [
                        //style({ height: 300, opacity: 1 }),
                        animate('1s ease-in',
                            style({height: 0, opacity: 0}))
                    ]
                )
            ]
        )]
})
export class FolderStepSevenComponent implements OnInit {
    @Output() folderShipping = new EventEmitter<ShippingAddressFolderModel>();
    @Input() order: OrderSplit;
    @Output() isEditable = new EventEmitter<boolean>();
    @Input() client: ClientModel;
    selected = 'EkiClic_RELAIT';
    filteredPOSOptions!: Observable<PointOfSaleModel[]>;
    filteredOptions!: Observable<RelayPointModel[]>;
    ekiFormControl = new FormControl();
    pointOfSaleList: PointOfSaleModel[] = [];
    selectedPOSRelay: PointOfSaleModel;
    selectedRelayPoint = '0';
    shippingFolderId: number;
    selectedRelay: RelayPointModel;
    relayPointList: RelayPointModel[] = relay;
    yalidineForm = new FormControl();
    address: Address[] = [];
    defaultAddress = 0;
    deliveryAddress!: Address;
    selectedHomeAddress = 0;
    domicileFormControl = new FormControl();
    currentRole: string = '';

    constructor(private store: Store<AppState>,
                private folderService: FoldersService,
                private clientService: ClientsService,
                private dialog: MatDialog,
                private pointOfSaleService: PointOfSaleService) {

        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;

        this.filteredPOSOptions = this.ekiFormControl.valueChanges
            .pipe(
                // @ts-ignore
                startWith(""),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this._filter(name.toString()) : this.pointOfSaleList.slice())
            );
        this.filteredOptions = this.yalidineForm.valueChanges
            .pipe(
                // @ts-ignore
                startWith(""),
                map(name => name ? this.filterRelayPoint(name.toString()) : this.relayPointList.slice())
            );

    }

    ngOnInit(): void {
        const posList$ = this.store
            .select(selectPOSsInStore)
            .subscribe((results) => {
                if (results.items.length > 0) {
                    this.pointOfSaleList = results.items;
                } else {
                    this.pointOfSaleService.getPointOfSale().subscribe((res: { body: PointOfSaleModel[]; }) => {
                        this.pointOfSaleList = res.body;
                        this.pointOfSaleService.posSbj$.next(res.body);
                    })
                }
            });
        /*this.store.pipe(select(selectClientById(1))).subscribe((c) => {
            if (c) {this.client = c;}
            else {
                this.clientService.getClientById(1).subscribe((c)=> {
                    this.client = c;
                    this.address = c.addresses;
                    this.defaultAddress = this.client.defaultAddress;
                    this.deliveryAddress = this.address.find(a => a.id === this.defaultAddress)!;
                })
            }
        })*/
        this.address = this.client.addresses;
        this.defaultAddress = this.client.defaultAddress;
        this.deliveryAddress = this.address.find(a => a.id === this.defaultAddress)!;

        // retrieve addresses
        /*this.addressesService.addressSubject.subscribe(
            (address: Address []) => {
                this.addresses = address;
                if (address.length && this.client.defaultAddress) {
                    this.defaultAddress = this.client.defaultAddress;
                    this.deliveryAddress = this.addresses.find(a => a.id === this.defaultAddress)!;
                }
            }
        );*/
        this.filteredPOSOptions = this.ekiFormControl!.valueChanges
            .pipe(
                // @ts-ignore
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map((name: any) => name ? this._filter(name.toString()) : this.pointOfSaleList.slice())
            );
    }

    onChange(radio: MatRadioChange) {

        switch (radio.value.toString()) {

            case 'option1': {
                this.selected = 'EkiClic_RELAIT';
                break;
            }

            case 'option2': {
                this.selected = 'POINT_DE_RELAIT';
                break;
            }

            case 'option3': {
                this.selected = 'DOMICILE';
                break;
            }
        }
    }

    filteredPointsExecuted() {
        if (this.currentRole!=='ROLE_COMMERCIAL_REGION_MANAGER' && this.currentRole !=='ROLE_GUEST_ADMIN')
        {
            this.filteredPOSOptions = this.ekiFormControl.valueChanges
                .pipe(
                    // @ts-ignore
                    startWith(''),
                    map(value => typeof value === 'string' ? value : value),
                    map((name: any) => name ? this._filter(name.toString()) : this.pointOfSaleList.slice())
                );
        }
    }

    private _filter(name: string): PointOfSaleModel[] {
        const filterValue = name.toLowerCase();
        return this.pointOfSaleList.filter(option => (option.posWilaya.toLowerCase().indexOf(filterValue) === 0)
            || (option.posCommune.toLowerCase().indexOf(filterValue) === 0) || (option.namePos.toLowerCase().indexOf(filterValue) === 0));
    }

    ngOnChanges() {
        if (this.order) {
            if (this.order.folderId) {
                this.folderService.getShippingAddressFolder(this.order.folderId).subscribe((res) => {
                    if (res.body) {
                        let ship = res.body;
                        this.folderShipping.emit(ship);
                        this.shippingFolderId = ship.id;
                        this.isEditable.emit(true);
                        switch (ship.fileShippingType) {
                            case 'POINT_OF_SALE':
                                this.pointOfSaleService.posSbj$.subscribe((pos: PointOfSaleModel[]) => {
                                    if (pos) {
                                        this.selected = 'EkiClic_RELAIT';
                                        this.selectedPOSRelay = this.pointOfSaleList.find(item => item.id === ship.id)!;
                                        this.ekiFormControl.setValue(this.selectedPOSRelay.namePos +', '+ this.selectedPOSRelay.posWilaya);

                                        // this.selectedPOSRelay = this.pointOfSaleList.find(list => ship.wilaya === (list.posWilaya
                                        //     || list.posCommune || list.namePos))!;
                                        // this.ekiFormControl.setValue(this.selectedPOSRelay ? this.selectedPOSRelay.namePos : '');
                                    }
                                });

                                break;
                            case "RELAY_POINT":
                                this.selected = 'POINT_DE_RELAIT';
                                // this.selectedPOSRelay =  <PointOfSale>{};
                                this.selectedRelay = this.relayPointList.find(list => list.value === ship.wilaya);
                                this.yalidineForm.setValue(this.selectedRelay?.province)
                                break;
                            case "RESIDENCE":
                                this.selected = 'DOMICILE';
                                // this.selectedPOSRelay =  <PointOfSale>{};
                                let deliveryAddress: Address = {
                                    addressLine2: "",
                                    clientId: this.client.id!,
                                    country: "",
                                    firstname: this.client.firstname,
                                    id: 0,
                                    zipCode: "",
                                    addressLine1: ship.address,
                                    city: ship.city,
                                    province: ship.wilaya,
                                    phoneNumber: ship.phoneClient,
                                    createdAt: '',
                                    updatedAt: '',
                                    type: '',
                                    clear() {
                                    },
                                    fullName: '',
                                    _isEditMode: null,
                                    lastname: this.client.lastname
                                };
                                this.deliveryAddress = deliveryAddress;
                                this.domicileFormControl.setValue(this.deliveryAddress.addressLine1)
                                break;
                        }
                    } else {

                        if (this.order.pointOfSaleTo) {
                            this.isEditable.emit(false);
                            this.selectedPOSRelay = this.order.pointOfSaleTo;
                            this.selectedRelayPoint = this.order.pointOfSaleTo?.posWilaya!;
                            this.ekiFormControl.setValue(this.order.pointOfSaleTo?.namePos);
                            const shippingFolder: ShippingAddressFolderModel = {
                                id: 0,
                                fileShippingType: 'POINT_OF_SALE',
                                folderId: this.order.folderId,
                                address: this.selectedPOSRelay.addressPos,
                                city: this.selectedPOSRelay.posCommune,
                                firstNameClient: this.client.firstname,
                                lastNameClient: this.client.lastname,
                                livreurId: 0,
                                namePos: this.selectedPOSRelay.namePos,
                                phoneClient: this.client.phone,
                                wilaya: this.selectedPOSRelay.posWilaya,
                                createdAt: '',
                                updatedAt: '',
                            };
                            this.folderShipping.emit(shippingFolder);
                        }
                    }
                })

            }

        }
    }

    getRelayPointPOS(idPOS: number) {

        this.selectedPOSRelay = this.pointOfSaleList.find(item => item.id === idPOS)!;

        this.selectedRelayPoint = this.selectedPOSRelay.posWilaya;

        this.selectRelayPoint();

    }

    getRelayPoint(selectedRelayPoint: string) {
        if (this.currentRole!=='ROLE_COMMERCIAL_REGION_MANAGER' && this.currentRole !=='ROLE_GUEST_ADMIN')
        {
            this.selectedRelayPoint = selectedRelayPoint;
            this.selectRelayPoint(true);
        }
    }

    selectRelayPoint(find?: boolean) {
        if (this.selected == 'EkiClic_RELAIT') {
            // this.ekiFormControl.setValue(this.selectedPOSRelay.namePos);
            this.ekiFormControl.setValue(this.selectedPOSRelay.namePos +', '+ this.selectedPOSRelay.posWilaya);
            if (this.selectedPOSRelay) {
                const shippingFolder: ShippingAddressFolderModel = {
                    id: this.shippingFolderId ? this.shippingFolderId : 0,
                    fileShippingType: 'POINT_OF_SALE',
                    folderId: this.order.folderId,
                    address: this.selectedPOSRelay.addressPos,
                    city: this.selectedPOSRelay.posCommune,
                    firstNameClient: this.client.firstname,
                    lastNameClient: this.client.lastname,
                    livreurId: 0,
                    namePos: this.selectedPOSRelay.namePos,
                    phoneClient: this.client.phone,
                    wilaya: this.selectedPOSRelay.posWilaya,
                    createdAt: '',
                    updatedAt: '',
                };
                this.folderShipping.emit(shippingFolder);
            }
        } else if (this.selected == 'POINT_DE_RELAIT') {
            this.selectedRelay = this.relayPointList.find(list => list.value === this.selectedRelayPoint);
            if (this.selectedRelay) {
                this.selectedRelay.province = this.selectedRelay?.province.replace(/[0-9]/g, '');
                const shippingFolder: ShippingAddressFolderModel = {
                    id: this.shippingFolderId ? this.shippingFolderId : 0,
                    fileShippingType: 'RELAY_POINT',
                    folderId: this.order.folderId,
                    address: this.selectedRelay.address[0],
                    city: '',
                    firstNameClient: this.client.firstname,
                    lastNameClient: this.client.lastname,
                    livreurId: 0,
                    namePos: '',
                    phoneClient: this.client.phone,
                    wilaya: this.selectedRelay.value.replace(/[0-9]/g, ''),
                    createdAt: '',
                    updatedAt: '',
                };
                this.folderShipping.emit(shippingFolder);
            }
        }
    }

    selectAddress() {
        if (this.selectedHomeAddress === 0) {
            this.deliveryAddress = this.address.find(a => a.id === this.defaultAddress)!;
        } else {
            this.deliveryAddress = this.address.find(a => a.id === this.selectedHomeAddress)!;
        }
        if (this.deliveryAddress) {

            //save address
            const shippingFolder: ShippingAddressFolderModel = {
                id: this.shippingFolderId ? this.shippingFolderId : 0,
                fileShippingType: 'RESIDENCE',
                folderId: this.order.folderId,
                address: this.deliveryAddress.addressLine1,
                city: this.deliveryAddress.city,
                firstNameClient: this.client.firstname,
                lastNameClient: this.client.lastname,
                livreurId: 0,
                namePos: '',
                phoneClient: this.client.phone,
                wilaya: this.deliveryAddress.province,
                createdAt: '',
                updatedAt: '',
            };
            this.folderShipping.emit(shippingFolder);
        }
    }

    // add new Address
    addNewAddress() {
        const dialogRef = this.dialog.open(NewAddressPopUpComponent,
            {
                //  data: this.client
                data: {
                    address: new Address(),
                    id: undefined,
                    isNew: true,
                    client: this.client
                },
                width: '450px'
            });
        dialogRef.afterClosed().subscribe((result: any) => {
            if (result) {
                this.address.push(result);
                this.deliveryAddress = result;
                this.selectedHomeAddress = this.deliveryAddress.id!;
                const newAdrDelivery: EkDeliveryAddressModel = {
                    addressLine1: this.deliveryAddress.addressLine1,
                    addressLine2: this.deliveryAddress.addressLine2,
                    firstname: this.deliveryAddress.firstname,
                    lastname: this.deliveryAddress.lastname,
                    phone: this.deliveryAddress.phoneNumber,
                    postalCode: Number(this.deliveryAddress.zipCode),
                    wilaya: this.deliveryAddress.province,
                    ville: this.deliveryAddress.city,
                    email: this.client.email,
                    createdAt: '',
                    updatedAt: ''
                };
            }
        });
    }

    public filterRelayPoint(name: string): RelayPointModel[] {
        const filterValue = name.toLowerCase();
        return this.relayPointList.filter(option => option.value.toLowerCase().indexOf(filterValue) === 0);
    }
}
