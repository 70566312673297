import {
  areCommercialsLoaded,
  areRegionssLoaded,
  selectCommercials,
} from "./../../../../../core/e-commerce/_selectors/region.selectors";
import { loadCommercials } from "./../../../../../core/e-commerce/_actions/region.actions";
import {
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from "@angular/core";
import { RegionDatasource } from "../../../../../core/e-commerce/_data-sources/region.datasource";
import { FormControl } from "@angular/forms";
import {
  LayoutUtilsService,
  MessageType,
} from "../../../../../core/_base/crud";
import { Store } from "@ngrx/store";
import { AppState } from "../../../../../core/reducers";
import {
  loadRegions,
  RegionDeleted,
} from "../../../../../core/e-commerce/_actions/region.actions";
import { User } from "../../../../../core/auth";
import { fromEvent } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";

@Component({
  selector: "kt-region",
  templateUrl: "./region.component.html",
  styleUrls: ["./region.component.scss"],
})
export class RegionComponent implements OnInit {
  @ViewChild("searchInput", { static: true }) searchInput: ElementRef;
  dataSource: RegionDatasource;
  regionId: number;
  filterStatus = new FormControl("");
  displayedColumns = ["id", "region", "wilaya", "commercial", "action"];
  commercials: User[];
  selectedCommercialId: number;
  commercialsLoaded: boolean = false;
  regionsLoaded: any;

  constructor(
    private store: Store<AppState>,
    private layoutUtilsService: LayoutUtilsService,
  ) {}

  ngOnInit(): void {
    this.loadRegions();
    this.setUpSearch();
    this.dataSource = new RegionDatasource(this.store);
  }

  loadRegions() {
    this.store
      .select(areCommercialsLoaded)
      .subscribe((bool) => (this.commercialsLoaded = bool));

    this.store.select(areRegionssLoaded).subscribe((bool) => {
      this.regionsLoaded = bool;
    });

    // if (!this.commercialsLoaded) {
      this.store.dispatch(loadCommercials());
    //   return;
    // }

    this.store.select(selectCommercials).subscribe((commercials) => {
      this.commercials = commercials;
      let firstChoice: User = new User();
      firstChoice.firstname = "Tout";
      this.commercials.unshift(firstChoice);
    });
    if (!this.regionsLoaded) {
      this.store.dispatch(loadRegions({ query: null, commercialId: null }));
      return;
    }
  }

  deleteRegion(id) {
    this.store.dispatch(RegionDeleted({ RegionId: id }));
    this.showNotification("Element Deleted Successfully !", MessageType.Delete);
  }

  showNotification(messageText: string, messageType: MessageType) {
    this.layoutUtilsService.showActionNotification(
      messageText,
      messageType,
      10000,
      true,
      false
    );
  }

  setUpSearch() {
    fromEvent(this.searchInput.nativeElement, "keyup")
      .pipe(
        debounceTime(700),
        distinctUntilChanged(),
        tap(() => {
          this.store.dispatch(
            loadRegions({
              query: this.searchInput.nativeElement.value,
              commercialId: this.selectedCommercialId,
            })
          );
        })
      )
      .subscribe();
  }
  selectCommercial(event) {
    this.selectedCommercialId = event.value.id;
    this.store.dispatch(
      loadRegions({
        query: this.searchInput.nativeElement.value,
        commercialId: this.selectedCommercialId,
      })
    );
  }
}
