import {createAction, props} from "@ngrx/store";
import {Update} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {OptimisedTrendingConfModel} from "../_models/optimised-trending-conf.model";



export const TrendingPageRequested = createAction('[Trending List Page] Trending Page Requested ',props<{page: QueryParamsModel}>());
export const TrendingPageLoadedSuccessfully = createAction('[Trending API] Trending Page Loaded Successfully ',props<{trending: OptimisedTrendingConfModel[],totalCount: number, page: number}>());
export const TrendingPageLoadFailed = createAction('[Trending API] Trending Page Request Failed ',props<{error: any}>());

export const TrendingUpdated = createAction('[Trending List Page] Trending Updated ',props<{trendingId: number , partialTrending:Update<OptimisedTrendingConfModel> ,display: boolean, updater: string}>());
export const TrendingUpdatedSuccessfully = createAction('[Trending List Page] Trending Updated Successfully ',props<{trending: OptimisedTrendingConfModel, partialTrending: Update<OptimisedTrendingConfModel>}>());
export const TrendingUpdateFailed = createAction('[Trending List Page] Trending Update Failed ',props<{error: any}>());
