import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'isUsedConverter',
})
export class IsUsedConverter implements PipeTransform {
    transform(isUsed: string): string {

        if (isUsed==="NEUF_SOUS_EMBALLAGE")
            return "Neuf sous emballage";

        else if (isUsed==="OCCASION_10")
            return "Occasion 10/10";

        else if (isUsed==="OCCASION_9")
            return "Occasion 9/10";
        else
            return  isUsed;
    }
}
