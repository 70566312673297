import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {Router} from "@angular/router";
import {LayoutUtilsService, MessageType} from "../../../../../../core/_base/crud";
import {EkFooterInformationService} from "../../../../../../core/ek-e-commerce/ek-services/ek-footer-information.service";
import {EkFooterInformationModel} from "../../../../../../core/ek-e-commerce/ek-models/EkfooterInformation.model";

@Component({
  selector: 'kt-ekfooter',
  templateUrl: './ekfooter.component.html',
  styleUrls: ['./ekfooter.component.scss']
})
export class EKFooterComponent implements OnInit {

  contactInformationForm: FormGroup;
  footerInformations: EkFooterInformationModel;

  private PHONE_REGEX = /^(00213|\+213|0)(5|6|7)(\s*?[0-9]\s*?){5,8}$/;
  currentRole = '';
  constructor(
      private footerService: EkFooterInformationService,
      private cdr: ChangeDetectorRef,
      private router: Router,
      private layoutUtilsService: LayoutUtilsService,
      private formBuilder: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    this.footerService.getById(1).subscribe(res => {
      this.footerInformations = res
      this.initForm();
      // this.updateFileNames()
      this.cdr.detectChanges()
    });
  }

  initForm() {
    this.contactInformationForm = this.formBuilder.group({
      wissalInfoEmail: [this.footerInformations.ekInfoEmail, [Validators.required, Validators.email]],
      wissalPhoneNumber: [this.footerInformations.ekPhoneNumber, Validators.compose([Validators.required, Validators.minLength(9), Validators.pattern(this.PHONE_REGEX)])],
      wissalPhoneNumber2: [this.footerInformations.ekPhoneNumber2, Validators.compose([Validators.required, Validators.minLength(9), Validators.pattern(this.PHONE_REGEX)])],
      address: [this.footerInformations.address, [Validators.required]],
      urlGps: [this.footerInformations.urlGps, [Validators.required]],
    });
  }

  prepareFooter(): EkFooterInformationModel {
    const controls = this.contactInformationForm.controls;
    const _footer = this.footerInformations
    _footer.address = controls.address.value;
    _footer.ekInfoEmail = controls.wissalInfoEmail.value;
    _footer.ekPhoneNumber = controls.wissalPhoneNumber.value;
    _footer.ekPhoneNumber2 = controls.wissalPhoneNumber2.value;
    _footer.createdAt = '';
    _footer.updatedAt = '';
    _footer.legalMentionUrl = this.footerInformations.legalMentionUrl;
    _footer.generalConditionUrl = this.footerInformations.generalConditionUrl;
    _footer.faqFileName = this.footerInformations.faqFileName;
    _footer.id = this.footerInformations.id
    _footer.urlGps = controls.urlGps.value;
    return _footer;
  }

  onSubmitForm() {
    if (this.contactInformationForm.invalid) {
      this.contactInformationForm.markAsTouched();
      return;
    }
    if (!this.contactInformationForm.dirty) {
      const message = `Aucune Modification.`;
      this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, false);
    } else {
      const _footer = this.prepareFooter();
      this.footerService.updateInfo(_footer).subscribe((res) => {
        const message = `Modification avec succès.`;
        this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, false);
      });
      }
  }

  faq() {
    this.router.navigateByUrl('/ek-ecommerce/ek-home-page/ek-footer/ek-question-answer', {replaceUrl: true});
  }

  legalMention() {
    this.router.navigateByUrl('ek-ecommerce/ek-home-page/ek-footer/legal-mention');
  }

  generalCondition() {

    this.router.navigateByUrl('ek-ecommerce/ek-home-page/ek-footer/general-condition');
  }

}
