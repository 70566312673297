import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient, HttpEvent, HttpParams, HttpRequest} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {CategoryModel} from "../_models/category.model";
import {Observable} from "rxjs";
import {Page} from "../../_base/crud/models/page";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";
import {CategoryPopularModel} from "../_models/category-popular.model";

@Injectable()
export class CategoriesService extends AbstractService<CategoryModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}categories`;
    }

    public getAllSorted(page: number = 0, size: number = 100, sorting: string, direction: string, label: string): Observable<Page<CategoryModel>> {
        const options = {
            params: new HttpParams()
                .append('pageNo', page.toString())
                .append('pageSize', size.toString())
                .append('sortBy', sorting)
                .append('direction', direction)
                .append('label', label)
        };
        const url = `${this.url}/find`;
        return this.http.get<Response<Page<CategoryModel>>>(url, options).pipe(map(({body}) => body));
    }


    public getSubCategoriesSearch(page: number = 0, size: number = 100, sorting: string = '', direction: string = '', label: string = ''): Observable<Page<CategoryModel>> {
        const options = {
            params: new HttpParams()
                .append('direction', direction)
                .append('label', label)
                .append('pageNo', page.toString())
                .append('pageSize', size.toString())
                .append('sortBy', sorting)

        };
        const url = `${this.url}/subCategory`;
        return this.http.get<Response<Page<CategoryModel>>>(url, options).pipe(map(({body}) => body));
    }

    public getCategoriesByIds(categoriesIds: number[]): Observable<CategoryModel[]> {
        const options = {
            params: new HttpParams()
                .append('ids', categoriesIds.toString())

        };
        const url = `${this.url}/categoriesByIds`;
        return this.http.get<Response<CategoryModel[]>>(url, options).pipe(map(({body}) => body));
    }

    public createCategory(category: CategoryModel): Observable<HttpEvent<CategoryModel>> {
        const formData = new FormData();
        for (let categoryKey in category) {
            if (categoryKey != 'constructor' && categoryKey != 'clear' && category[categoryKey] != null) {
                formData.append(categoryKey.toString(), category[categoryKey.toString()]);
            }
        }
        const options = {
            reportProgress: true
        };
        const req = new HttpRequest(
            'POST',
            `${this.url}`,
            formData,
            options
        );
        return this.http.request(req);
    }

    public updateCategory(category: CategoryModel): Observable<HttpEvent<CategoryModel>> {
        const formData = new FormData();
        for (let categoryKey in category) {
            if (categoryKey != 'constructor' && categoryKey != 'clear' && category[categoryKey] != null) {
                formData.append(categoryKey.toString(), category[categoryKey.toString()]);
            }
        }
        const options = {
            reportProgress: true
        };
        const req = new HttpRequest(
            'PUT',
            `${this.url}`,
            formData,
            options
        );
        return this.http.request(req);
    }
    public changeActivateCategory(id: number, activate: boolean, updater: string): Observable<Response<CategoryModel>> {
        const url = `${this.url}/${id}/${activate}/${updater}`;
        return this.http.patch<Response<CategoryModel>>(url,"").pipe(
            map((response: Response<CategoryModel>) => {
                if (response.body) {
                    return response;
                } else {
                    throw new Error();
                }
            })
        );
    }

    public getParentCategoryByIdProduct(ProductId: number): Observable<string>{
        const url = `${this.url}/get-parent-name-by-productId/${ProductId}`;
        return this.http.get<Response<string>>(url).pipe(map(({body}) => body));
    }

    public getPopular(): Observable<Response<CategoryPopularModel[]>> {
        const url = `${this.url}/popular`;
        return this.http.get<Response<CategoryPopularModel[]>>(url);
    }

}
