import {Directive, ElementRef, HostListener} from "@angular/core";

@Directive({
    selector: "[tenDigitsOnly]"
})
export class PhoneNumberMaxLengthDirective {
    //@Input() tenDigitsOnly;
    constructor(private el: ElementRef) {
    }

    @HostListener("keydown", ["$event"]) onKeydown(event) {

        const keycode = event.which || event.keycode;
        const allowedKeycodes = [8, 13, 46, 37, 38, 39, 40]
        const keyCodeIndex = allowedKeycodes.indexOf(keycode);
        const value = event.target.value;
        const maxLength = 10;
        const initalValue = this.el.nativeElement.value;

        this.el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');

        if (initalValue !== this.el.nativeElement.value)
            return false;

        if ((value.length > maxLength - 1) && (keyCodeIndex === -1))
            return false;
    }
}
