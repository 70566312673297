/**
 * Datasource that doesnt use NGRX
 * check this course https://blog.angular-university.io/angular-material-data-table/
 */
import {BaseDataSource, QueryResultsModel} from "../../_base/crud";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {
    selectRegionsInitWaitingMessage,
    selectRegionsInStore,
    selectRegionsPageLoading
} from "../_selectors/region.selectors";
import {
    selectParcelStatessInitWaitingMessage, selectParcelStatessInStore,
    selectParcelStatessPageLoading
} from "../_selectors/parcelStates.selectors";

export class ParcelStatesDatasource extends BaseDataSource {
    constructor( private store: Store<AppState>) {
        super();
        this.loading$ = this.store.pipe(
            select(selectParcelStatessPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectParcelStatessInitWaitingMessage)
        );

        this.store.pipe(
            select(selectParcelStatessInStore)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }
}

