import { areCommercialsLoaded } from './../_selectors/region.selectors';
import { User } from './../../auth/_models/user.model';
import {Action, createReducer, on, State} from '@ngrx/store';
import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import * as RegionActions from '../_actions/region.actions';
import {RegionModel} from "../_models/region.model";


export const regionFeatureKey = 'region';

export interface RegionState extends EntityState<RegionModel> { 
    listLoading: boolean;
    actionsLoading: boolean;
    lastCreatedRegionId: number;
    lastQuery: QueryParamsModel;
    totalCount: number;
    showInitWaitingMessage: boolean;
    error: any;
    errorMessage: string;
    lastAction: string;
    progress: number;
    commercials: User[],
    allCommercialsLoaded: boolean,
    allRegionsLoaded: boolean
}

export const adapter: EntityAdapter<RegionModel> = createEntityAdapter<RegionModel>();

export const initialRegionState: RegionState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    lastCreatedRegionId: undefined,
    totalCount: 0,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    errorMessage: '',
    lastAction: '',
    progress: 0,
    commercials: [],
    allCommercialsLoaded: false,
    allRegionsLoaded: false
});


export const regionReducer = createReducer(
  initialRegionState,

    on(RegionActions.loadRegions,
        (state, action) => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: ''

        })),
    on(RegionActions.loadRegionsSuccess,
        (state, action) =>
            (adapter.addAll(
                action.data,
                {
                    ...state,
                    actionsLoading: false,
                    listLoading: false,
                    showInitWaitingMessage: false,
                    totalCount: action.data.length,
                    allRegionsLoaded: true
                }))),
    on(RegionActions.loadRegionsFailure,
        (state, action) => ({
            ...state,
            actionsLoading: true,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error,
            allRegionsLoaded: false
        })),
    on(RegionActions.RegionDeleted,
        (state, action) => ({...state, actionsLoading: true, lastAction: ''})),
    on(RegionActions.RegionDeletedSuccessfully,
        (state, action) => (adapter.removeOne(action.RegionId, {
            ...state,
            actionsLoading: false,
            lastAction: 'DELETE.DELETE_REGION_SIMPLE.DELETE_SUCCESS'
        }))),
    on(RegionActions.RegionDeleteFailed,
        (state, action) => ({...state, error: action.error, actionsLoading: false})),

    on(RegionActions.RegionRequested,
        (state, action) => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: ''

        })),
    on(RegionActions.RegionRequestedSuccessfully,
        (state, action) =>
            (adapter.addOne(
                action.Region,
                {
                    ...state,
                    actionsLoading: false,
                    listLoading: false,
                    showInitWaitingMessage: false,
                }))),
    on(RegionActions.RegionRequestedFailed,
        (state, action) => ({
            ...state,
            actionsLoading: true,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    on(RegionActions.SaveRegionRequested,
        (state, action) => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: ''

        })),
    on(RegionActions.SaveRegionSuccessfully,
        (state, action) =>
            (adapter.addOne(
                action.Region,
                {
                    ...state,
                    actionsLoading: false,
                    listLoading: false,
                    showInitWaitingMessage: false
                }))),
    on(RegionActions.SaveRegionFailed,
        (state, action) => ({
            ...state,
            actionsLoading: true,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    on(RegionActions.loadCommercialsSucess,
        (state, action) : RegionState => {
            return {
                ...state,
                commercials: action.users,
                allCommercialsLoaded: true
            }
        }
        )

);

export function RegionReducer(state: RegionState | undefined, action: Action) {
    return regionReducer(state, action)
}

