import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import * as CarouselActions from "../../home-page/carousel/carousel.action";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {CarouselsService} from "./carousels.service";


@Injectable()
export class CarouselEffects {

    constructor(private actions$: Actions, private carouselService: CarouselsService, private store: Store<AppState>) {
    }


    CarouselsRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CarouselActions.CarouselsPageRequested),
            switchMap((action) =>
                this.carouselService.getAll()
                    .pipe(
                        map(carousels => CarouselActions.CarouselsPageLoadedSuccessfully(
                            {
                                carousels: carousels,
                            })),
                        catchError(error => of(CarouselActions.CarouselsPageLoadFailed({error})))
                    ))));

    CarouselDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CarouselActions.CarouselDeleted),
            switchMap((action) =>
                this.carouselService.delete(action.carouselId)
                    .pipe(
                        map(() => CarouselActions.CarouselDeletedSuccessfully({carouselId: action.carouselId})),
                        catchError(error => of(CarouselActions.CarouselDeleteFailed({error})))
                    ))));


    CarouselCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CarouselActions.CarouselCreated),
            switchMap((action) =>
                this.carouselService.save(action.carousel)
                    .pipe(
                        map(data => CarouselActions.CarouselCreatedSuccessfully({carousel: data})),
                        catchError(error => of(CarouselActions.CarouselCreationFailed({error})))
                    ))));

    CarouselUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CarouselActions.CarouselUpdated),
            switchMap((action) =>
                this.carouselService.update(action.carousel)
                    .pipe(
                        map(data => CarouselActions.CarouselUpdatedSuccessfully({
                            carousel: data, partialCarousel: action.partialCarousel
                        })),
                        catchError(error => of(CarouselActions.CarouselUpdateFailed({error})))
                    ))));

}
