import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {Response} from "../../_base/crud/models/response";
import {Action, createReducer, on} from "@ngrx/store";
import * as FolderActions from "../ek-actions/folder.action";
import {ListingModel} from "../ek-models/listing-model";

export interface FolderState extends EntityState<ListingModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedFolderId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
    startingDate: string;
    endingDate: string;
}

export const adapter: EntityAdapter<ListingModel> = createEntityAdapter<ListingModel>();

export const initialFolderState: FolderState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedFolderId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
    entities: [],
    startingDate: '',
    endingDate: '',
});

const folderReducer = createReducer(
    initialFolderState,
    //folder list
    on(FolderActions.foldersListingPageRequested, (state, action) => ({
        ...state,
        actionsLoading: true,
        listLoading: true,
        showInitWaitingMessage: true,
        error: null,
        lastAction: '',
        lastQuery: action.page,
    })),
    on(FolderActions.foldersListingPageLoadedSuccessfully, (state, action) =>
        adapter.addAll(action.folders, {
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            totalCount: action.totalCount,
        })
    ),
    on(FolderActions.foldersListingPageLoadFailed, (state, action) => ({
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
        error: action.error,
    })),
);

export function foldersReducer(state: FolderState | undefined, action: Action) {
    return folderReducer(state, action)
}
