// NGRX
import {createAction, props} from '@ngrx/store';
// Models
import {Update} from '@ngrx/entity';
import {ProductModel} from "../_models/product.model";
import {QueryParamsModel} from "../../_base/crud";

export const ProductsPageRequested = createAction('[Product List Page] Products Page Requested ',props<{page: QueryParamsModel}>());
export const ProductsPageLoadedSuccessfully = createAction('[Product API] Products Page Loaded Successfully ',props<{products: ProductModel[],totalCount: number, page: number}>());
export const ProductsPageLoadFailed = createAction('[Product API] Products Page Request Failed ',props<{error: any}>());

export const ProductCreated = createAction('[Product List Page] Product Created ',props<{product: ProductModel}>());
export const ProductCreatedSuccessfully = createAction('[Product List Page] Product Created Successfully ',props<{product: ProductModel}>());
export const ProductCreationFailed = createAction('[Product List Page] Product Creation Failed ',props<{error: any}>());

export const ProductUpdated = createAction('[Product List Page] Product Updated ',props<{product: ProductModel, partialProduct: Update<ProductModel>}>());
export const ProductUpdatedSuccessfully = createAction('[Product List Page] Product Updated Successfully ',props<{product: ProductModel, partialProduct: Update<ProductModel>}>());
export const ProductUpdateFailed = createAction('[Product List Page] Product Update Failed ',props<{error: any}>());

export const ProductUpdatedActivate = createAction('[Product List Page] Product Updated activate',props<{product: ProductModel, partialProduct: Update<ProductModel>, activate:boolean, updater:string}>());
export const ProductUpdatedActivateSuccessfully = createAction('[Product List Page] Product Updated Successfully ',props<{product: ProductModel, partialProduct: Update<ProductModel>,activate:boolean, updater:string}>());
export const ProductUpdateActivateFailed = createAction('[Product List Page] Product Update Failed ',props<{error: any}>());


export const ProductDeleted = createAction('[Product List Page] Product Deleted ',props<{productId: number}>());
export const ProductDeletedSuccessfully = createAction('[Product List Page] Product deleted Successfully ',props<{productId: number}>());
export const ProductDeleteFailed = createAction('[Product List Page] Product delete Failed ',props<{error: any}>());


