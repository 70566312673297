// Angular
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {AbstractService} from "../../services/abstract-service.service";
import {ADMIN_CONFIG} from "../../../../environments/environment";

import {BestSellerModel} from "../_models/bestSeller.model";

@Injectable()
export class BestSellerService extends AbstractService<BestSellerModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}products/best-sellers`;
    }


}
