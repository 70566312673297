import {BaseModel} from "../../_base/crud";

export class Discount_CustomerModel extends BaseModel {

    id: number;
    clientId: number;
    startingDate: string;
    endingDate: string;
    value: number;
    active: boolean;
    numberMaxDiscountClient: number;


    _isEditMode: boolean;

    clear() {
        this.value = 0;
        this._isEditMode = false;
    }
}
