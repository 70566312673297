import {StockModel} from '../../../../../../../../core/ek-e-commerce/ek-models/stock.model';
import {StocksService} from '../../../../../../../../core/ek-e-commerce/ek-services/stocks.service';
import {CreditModel} from '../../../../../../../../core/ek-e-commerce/ek-models/credit.model';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {
    ConfigurationsDetails,
    OrderSplitAdmin
} from "../../../../../../../../core/ek-e-commerce/ek-models/OrderSplitAdmin";
import {OrderItemsComponent} from "../../../order-items/order-items.component";
import {DatePipe} from "@angular/common";
import {MatDialog} from "@angular/material/dialog";
import {OrderSplitService} from "../../../../../../../../core/ek-e-commerce/ek-services/order-split.service";
import {ActivatedRoute} from "@angular/router";
import {LayoutUtilsService, MessageType} from "../../../../../../../../core/_base/crud";
import {BehaviorSubject} from "rxjs";
import {OrderSplit} from "../../../../../../../../core/ek-e-commerce/ek-models/orderSplit";
import {OrderItemModel} from "../../../../../../../../core/ek-e-commerce/ek-models/orderItem.model";
import {SafeUrl} from "@angular/platform-browser";
import {MonthlyPayCPAPipe} from "../../../../../../../../core/_base/layout";

interface OldQuantityList {
    id: number;
    quantity: number;
}

@Component({
    selector: 'kt-order-split-products',
    templateUrl: './order-split-products.component.html',
    styleUrls: ['./order-split-products.component.scss'],
    providers: [MonthlyPayCPAPipe]
})


export class OrderSplitProductsComponent implements OnInit {

    @Input() selectedMonths: number = 12;
    @Input() folderState: string = '';
    @Input() orderSplit: OrderSplit;
    @Input() tempEntity: OrderSplitAdmin;
    @Output() productAdded = new EventEmitter<boolean>();
    @Input() folderRequestNumber: string = '';

    dataSource = new MatTableDataSource<OrderItemModel>();
    configurationsList: ConfigurationsDetails [] = [];
    math = Math;
    subTotal: number = 0;
    total: number = 0;
    displayedColumnsForAdmin = ['prod', 'img', 'stock-name', 'prix-unit-mois', 'prix-unit', 'quantité', 'discount', 'Prix-total-Mois', 'prix-total', 'actions'];
    displayedColumns = ['prod', 'img', 'stock-name', 'prix-unit-mois', 'prix-unit', 'quantité', 'Prix-total-Mois', 'prix-total', 'actions'];
    folderId: number;
    isNewOrder = false;
    idOrder = 0;
    idFolder = 0;
    loadingRecapSubject = new BehaviorSubject<boolean>(false);
    readonly loadingRecap$ = this.loadingRecapSubject.asObservable();
    currentRole: string = '';
    stocks: StockModel[];
    prevQuantityList: OldQuantityList [] = [];
    credit: CreditModel;

    imagePath!: string | SafeUrl;

    loadingSimulationSubject$ = new BehaviorSubject<boolean>(false);
    readonly loadingSimulation$ = this.loadingSimulationSubject$.asObservable();


    private creditSbj$ = new BehaviorSubject<CreditModel>(null);
    readonly creditObs$ = this.creditSbj$.asObservable();

    insurancePrime: number = 0;

    constructor(
        private dialog: MatDialog,
        public orderSplitService: OrderSplitService,
        private route: ActivatedRoute,
        private layoutUtilsService: LayoutUtilsService,
        private stockService: StocksService,
        private monthlyPayCPAPipe: MonthlyPayCPAPipe
    ) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }

    ngOnInit(): void {

        // Empty Order in new order
        this.route.paramMap.subscribe(params => {

            let clientId = Number(params.get('idClient'));
            this.idOrder = Number(params.get('idOrder'));
            this.idFolder = Number(params.get('idFolder'));

            //adding new order split
            if (clientId > 0) {
                this.isNewOrder = true;
            }
        });

        this.stockService.getAll().subscribe(stocks => {
            if (stocks) {
                this.stocks = stocks
            }
        })

        this.orderSplitService.creditSubject.subscribe(cr => {
            if (cr) {
                this.credit = cr;
                this.creditSbj$.next(cr);
            }
        });

        this.dataSource.data = this.orderSplit.orderItems;

        this.calculateTotal();

    }

    getCreditDeets() {
        this.orderSplitService.getCredit(this.idOrder).subscribe((cr) => {
            this.credit = cr;
            this.orderSplitService.creditSubject.next(cr);
        });
    }

    getStockNameById(id: number): string {
        let stockName = ''
        if (id && this.stocks) {
            stockName = this.stocks.find(stock => stock.id === id).name
        }
        return stockName
    }

    addOrderItem() {
        const dialogRef = this.dialog.open(OrderItemsComponent, {
            data: {
                order: this.orderSplit,
                addingOrder: this.isNewOrder,
                idFolder: this.idFolder,
                posId: this.orderSplit.pointOfSaleTo.id
            }
        });
        dialogRef.afterClosed().subscribe(res => {
            if (this.isNewOrder) {
                if (res) {
                    this.productAdded.emit(true);
                    this.addItemToEmptyOrder(res);
                }
            } else {
                this.refresh();
                this.productAdded.emit(true);
            }
        });
    }

    refresh() {
        this.orderSplitService.getById(this.orderSplit.id).subscribe({
            next: (OS) => {
                this.dataSource.data = OS.orderItems;
                this.orderSplit = OS;
                this.orderSplitService.selectedOrderSubject.next(OS);
                this.getCreditDeets();
                this.calculateTotal();
            }
        });

    }

    downloadProforma() {

        this.loadingRecapSubject.next(true);

        this.orderSplitService.downloadProformaPdf(this.idOrder).subscribe((response: any) => {
            if (response.message == 'success' && response.body) {
                const byteCharacters = atob(response.body);

                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                    byteNumbers [i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                const nav = (window.navigator as any);
                let blob = new Blob([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                if (nav && nav.msSaveOrOpenBlob) {
                    nav.msSaveOrOpenBlob(blob);
                    return;
                }
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.download = `Facture proforma ` + this.idOrder + new DatePipe('FR').transform(Date.now(), 'yyyy-MM-dd_HH-mm-ss') + '.pdf';
                anchor.href = url;

                anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
                setTimeout(function () {
                    window.URL.revokeObjectURL(url);
                    anchor.remove();
                }, 100)

                this.loadingRecapSubject.next(false);

            }
        });

    }

    downloadSimulation() {

        if (this.orderSplit?.clientId) {

            this.loadingSimulationSubject$.next(true);

            const NUMBER_OF_TERMS_IN_A_YEAR = 12
            const ANNUAL_RATE = 0.0952

            let monthlyRate = ANNUAL_RATE / NUMBER_OF_TERMS_IN_A_YEAR
            let hamicheElDjidia = 0
            let total = this.total;
            let fundingAmount = total - hamicheElDjidia;
            this.insurancePrime = (fundingAmount * 0.01) + 790
            let monthlyPayement = +(this.monthlyPayCPAPipe.transform(this.total, this.selectedMonths)).toFixed(2);
            let salePriceCPAWithTax = +((this.selectedMonths - 2) * monthlyPayement).toFixed(2);

            //send request 
            this.orderSplitService.downloadSimulationPdf(
                this.orderSplit.clientId!,
                this.orderSplit?.pointOfSaleTo.id,
                monthlyPayement,
                this.orderSplit.phone,
                this.selectedMonths,
                hamicheElDjidia,
                total,
                salePriceCPAWithTax,
                fundingAmount,
                this.folderRequestNumber,
                this.insurancePrime
            ).subscribe({
                next: (response) => {
                    if (response.message == 'success' && response.body) {
                        const byteCharacters = atob(response.body);
                        const byteNumbers = new Array(byteCharacters.length);
                        for (let i = 0; i < byteCharacters.length; i++) {
                            byteNumbers [i] = byteCharacters.charCodeAt(i);
                        }
                        const byteArray = new Uint8Array(byteNumbers);
                        const nav = (window.navigator as any);
                        let blob = new Blob([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                        if (nav && nav.msSaveOrOpenBlob) {
                            nav.msSaveOrOpenBlob(blob);
                            return;
                        }
                        const url = window.URL.createObjectURL(blob);
                        const anchor = document.createElement('a');
                        anchor.download = `simulation` + new DatePipe('FR').transform(Date.now(), 'yyyy-MM-dd_HH-mm-ss') + '.pdf';
                        anchor.href = url;

                        anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));

                        this.loadingSimulationSubject$.next(false);

                        setTimeout(function () {
                            window.URL.revokeObjectURL(url);
                            anchor.remove();
                        }, 100)
                    }
                },
                error: () => {
                    this.loadingSimulationSubject$.next(false);
                    this.layoutUtilsService.showActionNotification('error while downloading simulation information !', MessageType.Delete);
                }
            })
        }
    }

    addItemToEmptyOrder(res) {

        //if the product already exist
        const found = this.orderSplit.orderItems.find((element) => {
            return element.configurationTO.id === res.configuration.id;
        });

        if (found) {
            this.orderSplit.orderItems.forEach(item => {
                if (item.configurationTO.id === found.configurationTO.id) {
                    item.quantity = Number(item.quantity) + Number(res.quantity);
                    item.sellingPrice = res.configQuan.sellingPrice * item.quantity;
                    item.stockDepotId = res.configQuan.stockDepotId ? res.configQuan.stockDepotId : 0;
                    item.stockEkId = res.configQuan.stockEkId ? res.configQuan.stockEkId : 0;
                }
            });
            this.configurationsList.forEach(item => {
                if (item.id === found.configurationTO.id) {
                    item.quantity = Number(item.quantity) + Number(res.quantity);
                    item.sellingPrice = res.configQuan.sellingPrice * item.quantity;
                    item.stockDepotId = res.configQuan.stockDepotId ? res.configQuan.stockDepotId : 0;
                    item.stockId = res.configQuan.stockEkId ? res.configQuan.stockEkId : 0;
                }
            });

        } else {
            let item = new OrderItemModel();
            item.configurationTO = res.configuration;
            item.productName = res.configuration.productName
            item.sellingPrice = res.configQuan.sellingPrice * res.quantity;
            item.quantity = res.quantity;
            item.stockDepotId = res.configQuan.stockDepotId ? res.configQuan.stockDepotId : 0;
            item.stockEkId = res.configQuan.stockEkId ? res.configQuan.stockEkId : 0;

            this.configurationsList.push({
                id: res.configuration.id,
                quantity: res.quantity,
                stockDepotId: res.configQuan.stockDepotId ? res.configQuan.stockDepotId : 0,
                stockId: res.configQuan.stockEkId ? res.configQuan.stockEkId : 0,
                sellingPrice: res.configQuan.sellingPrice * res.quantity
            });

            this.tempEntity.configurationsDetails = this.configurationsList;

            this.orderSplit.orderItems.push(item);
            this.orderSplitService.createdNewOrder$.next(this.tempEntity);
        }

        this.dataSource.data = this.orderSplit.orderItems;
        this.calculateTotal();

    }

    findItemIndex(itemToFind: OrderItemModel): number {
        return this.orderSplit.orderItems.indexOf(itemToFind);
    }

    deleteOrderItem(product: OrderItemModel) {
        if (this.isNewOrder) {
            this.configurationsList = [];
            const itemIndex = this.findItemIndex(product);
            if (itemIndex !== -1) {
                this.orderSplit.orderItems.splice(itemIndex, 1); // Remove the item from the order items list
            }
            this.dataSource.data = this.orderSplit.orderItems;
            this.orderSplit.orderItems.forEach(item => {
                this.configurationsList.push(
                    {
                        id: item.configurationTO.id,
                        quantity: item.quantity,
                        stockId: item.stockEkId ? item.stockEkId : 0,
                        stockDepotId: item.stockDepotId ? item.stockDepotId : 0,
                        sellingPrice: item.sellingPrice
                    }
                );
            });
            this.tempEntity.configurationsDetails = this.configurationsList;
            this.orderSplitService.createdNewOrder$.next(this.tempEntity);
            this.calculateTotal();
        } else {
            const _title: string = 'Delete Order Item';
            const _description: string = 'You want to delete this Item ?';
            const _waitDesciption: string = 'Deleting Order Item ...';

            const dialogRef = this.layoutUtilsService.deleteElement(_title, _description, _waitDesciption);
            dialogRef.afterClosed().subscribe(res => {
                if (!res) {
                    return;
                }
                //delete order item

                this.orderSplitService.deleteItemSplit(product.orderProductId, this.idFolder).subscribe(result => {
                    if (result.code == 0)
                        this.layoutUtilsService.showActionNotification('Le porduit a été Supprimé', MessageType.Delete);
                    else if (result.code == 8)
                        this.layoutUtilsService.showActionNotification('Une commande doit contenir au moins un produit!', MessageType.Delete, 10000, true, false);
                    else
                        this.layoutUtilsService.showActionNotification('Une erreur s\'est produite!', MessageType.Delete, 10000, true, false);

                    this.refresh();
                })
            });

        }
    }

    onQuantityChange(produit, quantity) {

        if (quantity <= 0) {
            this.layoutUtilsService.showActionNotification('invalid quantity!', MessageType.Update);
            return;
        }

        if (this.isNewOrder) {

            const itemIndex = this.findItemIndex(produit);

            if (itemIndex !== -1) {

                const orderItem = this.orderSplit.orderItems[itemIndex];

                orderItem.sellingPrice = orderItem.sellingPrice * quantity / orderItem.quantity;
                orderItem.quantity = Number(quantity);

                this.configurationsList.forEach(item => {
                    if (item.id === orderItem.configurationTO.id) {
                        item.quantity = Number(quantity);
                    }
                });

            }
        } else {

            const orderItem = this.orderSplit.orderItems.find(item => item.orderProductId === produit.orderProductId);

            let obj: OldQuantityList = {
                id: produit.orderProductId,
                quantity: orderItem.quantity
            }

            if (this.prevQuantityList?.length > 0) {

                this.prevQuantityList.forEach(item => {
                    if (item.id !== produit.orderProductId) {
                        this.prevQuantityList.push(obj);
                    }
                })
            } else {
                this.prevQuantityList.push(obj);
            }

            this.orderSplit.orderItems.forEach(item => {
                if (item.orderProductId === produit.orderProductId) {
                    item.sellingPrice = item.sellingPrice * quantity / item.quantity;
                    item.quantity = Number(quantity);

                    this.configurationsList.forEach(item2 => {
                        if (item2.id === item.configurationTO.id && (item2.stockDepotId === item.stockDepotId || item2.stockId === item.stockEkId)) {
                            item2.quantity = Number(quantity);
                        }
                    });

                }
            })

        }


        this.tempEntity.configurationsDetails = this.configurationsList;
        this.orderSplitService.createdNewOrder$.next(this.tempEntity);


        this.calculateTotal();

    }

    updateQuantity(produit, orderItemId, newQuantity) {

        if (newQuantity <= 0) {
            this.layoutUtilsService.showActionNotification('invalid quantity!', MessageType.Update);
            return;
        }

        const isCartLimited = this.orderSplitService.checkCartLimit();

        if (isCartLimited.isLimited) {
            this.layoutUtilsService.showActionNotification(isCartLimited.messages, MessageType.Delete, 10000, true, false);
            return;
        }

        const prevQuantity = this.prevQuantityList.find(item => item.id === produit.orderProductId).quantity;

        let addedQuantity = Number(newQuantity) - Number(prevQuantity);

        this.orderSplitService.updateOrderSplitItemQuantity(orderItemId, addedQuantity, this.idFolder).subscribe({
            next: (res) => {
                this.layoutUtilsService.showActionNotification('quantity updated successfully', MessageType.Update);
                this.refresh();
            },
            error: (error) => {
                if (error && error.status == 400) {
                    this.layoutUtilsService.showActionNotification('La quantité de produit n\'est pas disponible !!', MessageType.Delete);
                } else {
                    this.layoutUtilsService.showActionNotification('error !!', MessageType.Delete);
                }

            }
        });
    }

    calculateTotal() {
        this.total = 0;
        this.orderSplit.orderItems.forEach(item => {
            this.total = this.total + item.sellingPrice;
        });
        this.orderSplitService.total = this.total
        let hamicheElDjidia = 0
        let total = this.total;
        let fundingAmount = total - hamicheElDjidia;
        this.insurancePrime = (fundingAmount * 0.01) + 790
    }

    applyDiscount(orderProductId: number, discount: number, sellingPrice: number) {
        this.orderSplitService.updateDiscount(orderProductId, discount, sellingPrice).subscribe({
            next: () => {
                this.layoutUtilsService.showActionNotification('La remise a été appliqué avec succes', MessageType.Delete);
                this.refresh();
            },
            error: () => {

            }
        });
    }

}
