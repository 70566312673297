import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {CharacteristicModel} from "../_models/characteristic.model";
import {QueryParamsModel} from "../../_base/crud";
import {Response} from "../../_base/crud/models/response";
import {Action, createReducer, on} from "@ngrx/store";
import * as CharacteristicActions from "../_actions/characteristic.actions";


export interface CharacteristicState extends EntityState<CharacteristicModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedCharacteristicId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
}

export const adapter: EntityAdapter<CharacteristicModel> = createEntityAdapter<CharacteristicModel>();

export const initialCharacteristicState: CharacteristicState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedCharacteristicId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
});

const characteristicReducer = createReducer(
    initialCharacteristicState,
    on(CharacteristicActions.CharacteristicsPageRequested,
        state => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: ''
        })),
    on(CharacteristicActions.CharacteristicsPageLoadedSuccessfully,
        (state, action) => (adapter.addAll(action.characteristics,
            {
                ...state,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                totalCount: action.totalCount
            }))),
    on(CharacteristicActions.CharacteristicsPageLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    //creation
    on(CharacteristicActions.CharacteristicCreated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(CharacteristicActions.CharacteristicCreatedSuccessfully,
        (state, action) => (adapter.addOne(action.characteristic, {
            ...state,
            actionsLoading: true,
            lastCreatedCharacteristicId: action.characteristic.id,
            lastAction: 'EDIT.ADD_MESSAGE'
        }))),
    on(CharacteristicActions.CharacteristicCreationFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    //Update
    on(CharacteristicActions.CharacteristicUpdated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(CharacteristicActions.CharacteristicUpdatedSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialCharacteristic, {
                ...state,
                actionsLoading: false,
                lastAction: 'EDIT.UPDATE_MESSAGE'
            }))),
    on(CharacteristicActions.CharacteristicUpdateFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    //Delete
    on(CharacteristicActions.CharacteristicDeleted,
        (state, action) => ({...state, actionsLoading: true, lastAction: ''})),
    on(CharacteristicActions.CharacteristicDeletedSuccessfully,
        (state, action) => (adapter.removeOne(action.characteristicId, {
            ...state,
            actionsLoading: false,
            lastAction: 'DELETE.DELETE_CHARACTERISTIC_SIMPLE.DELETE_SUCCESS'
        }))),
    on(CharacteristicActions.CharacteristicDeleteFailed,
        (state, action) => ({...state, error: action.error, actionsLoading: false}))
);

export function characteristicsReducer(state: CharacteristicState | undefined, action: Action) {
    return characteristicReducer(state, action)
}
