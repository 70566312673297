// Angular
import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
// RxJS
import {Observable, Subject} from 'rxjs';
// Translate
import {TranslateService} from '@ngx-translate/core';
// Store
import {select, Store} from '@ngrx/store';
import {AppState} from '../../../../core/reducers';
// Auth
import {AuthActions, AuthNoticeService, currentToken, error, isLoading} from '../../../../core/auth';
import {Token} from "../../../../core/auth/_models/token";
import {EkFooterInformationModel} from "../../../../core/ek-e-commerce/ek-models/EkfooterInformation.model";
import {EkFooterInformationService} from "../../../../core/ek-e-commerce/ek-services/ek-footer-information.service";
import {currentUserRoles} from "../../../../core/auth/_selectors/auth.selectors";
import {UserService} from "../../../../core/services/user.service";

// Selectors
/**
 * ! Just example => Should be removed in development
 */


@Component({
	selector: 'kt-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	errors: any = [];
	private unsubscribe: Subject<any>;
	private returnUrl: any;

	//My Observables
	isLoggedIn$: Observable<boolean>;
	error$: Observable<any>;
	isLoading$: Observable<boolean>;
	currentUserRoles$: Observable<string>;
	token$: Observable<Token>;
	hide = true;
	phoneNumber = '0799664433';
	footerInformations: EkFooterInformationModel;

	// Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private footerService: EkFooterInformationService,
		private userService : UserService,
	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit(): void {

		this.initLoginForm();

		this.footerService.getById(1).subscribe(res => {
			this.footerInformations = res;
			this.phoneNumber = res.ekPhoneNumber;
			this.cdr.detectChanges()
		});

		this.isLoading$ = this.store.pipe(select(isLoading));
		this.token$ = this.store.pipe(select(currentToken));
		this.error$ = this.store.pipe(select(error));
		this.currentUserRoles$ = this.store.pipe(select(currentUserRoles));

		// redirect back to the returnUrl before login
		//todo move this to the effect
		// this.route.queryParams.subscribe(params => {
		// 	this.returnUrl = params.returnUrl || '/';
		// });
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initalization
	 * Default params, validators
	 */
	initLoginForm() {


		this.loginForm = this.fb.group({
			email: ['', Validators.compose([
				Validators.required,
				Validators.email,
				Validators.minLength(3),
				Validators.maxLength(320) // https://stackoverflow.com/questions/386294/what-is-the-maximum-length-of-a-valid-email-address
			])
			],
			password: ['', Validators.compose([
				Validators.required,
				Validators.minLength(3),
				Validators.maxLength(100)
			])
			]
		});
	}

	/**
	 * Form Submit
	 */
	submit() {
		const controls = this.loginForm.controls;
		/** check form */
		if (this.loginForm.invalid) {
			Object.keys(controls).forEach(controlName =>
				controls[controlName].markAsTouched()
			);
			return;
		}

		// this.loading = true;

		const authData = {
			email: controls.email.value,
			password: controls.password.value
		};

		this.store.dispatch(AuthActions.login({email:authData.email, password:authData.password}));
		this.isLoading$.subscribe((response)=> this.loading = response);
		this.error$.subscribe((response)=>{
			if(response){
				this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
			}
		});
		this.currentUserRoles$.subscribe((roles) => this.userService.selectedUserRolesSubject.next(roles));
	}

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		return control.hasError(validationType) && (control.dirty || control.touched);
	}
}
