import {createAction, props} from "@ngrx/store";
import {ConfigurationModel} from "../_models/configuration.model";
import {Update} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";

export const BestSellsPageRequested = createAction('[BestSells List Page] BestSells Page Requested ',props<{page: QueryParamsModel}>());
export const BestSellsPageLoadedSuccessfully = createAction('[BestSells API] BestSells Page Loaded Successfully ',props<{configurations: ConfigurationModel[],totalCount: number, page: number}>());
export const BestSellsPageLoadFailed = createAction('[BestSells API] BestSells Page Request Failed ',props<{error: any}>());

export const BestSellsCreated = createAction('[BestSells List Page] BestSells Created ',props<{configuration: ConfigurationModel}>());
export const BestSellsCreatedSuccessfully = createAction('[BestSells List Page] BestSells Created Successfully ',props<{configuration: ConfigurationModel}>());
export const BestSellsCreationFailed = createAction('[BestSells List Page] BestSells Creation Failed ',props<{error: any}>());

export const BestSellsUpdated = createAction('[BestSells List Page] BestSells Updated ',props<{configuration: ConfigurationModel, partialConfiguration: Update<ConfigurationModel>}>());
export const BestSellsUpdatedSuccessfully = createAction('[BestSells List Page] BestSells Updated Successfully ',props<{configuration: ConfigurationModel, partialConfiguration: Update<ConfigurationModel>}>());
export const BestSellsUpdateFailed = createAction('[BestSells List Page] BestSells Update Failed ',props<{error: any}>());

export const BestSellsUpdatedActivate = createAction('[BestSells List Page] BestSells Updated',props<{configuration: ConfigurationModel, partialConfiguration: Update<ConfigurationModel>, activate:boolean,updater:string,event: any}>());
export const BestSellsUpdatedActivateSuccessfully = createAction('[BestSells List Page] BestSells Updated Successfully',props<{configuration: ConfigurationModel, partialConfiguration: Update<ConfigurationModel>,activate:boolean,updater:string}>());
export const BestSellsUpdateActivateFailed = createAction('[BestSells List Page] BestSells Update Failed',props<{error: any}>());

export const BestSellsDeleted = createAction('[BestSells List Page] BestSells Deleted ',props<{configurationId: number}>());
export const BestSellsDeletedSuccessfully = createAction('[BestSells List Page] BestSells deleted Successfully ',props<{configurationId: number}>());
export const BestSellsDeleteFailed = createAction('[BestSells List Page] BestSells delete Failed ',props<{error: any}>());


