
import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {Response} from "../../_base/crud/models/response";
import {Action, createReducer, on} from "@ngrx/store";
import * as BestSellerActions from '../_actions/bestSeller.action';
import {BestSellerModel} from "../_models/bestSeller.model";


export interface BestSellerState extends EntityState<BestSellerModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedBestSellerId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
}

export const adapter: EntityAdapter<BestSellerModel> = createEntityAdapter<BestSellerModel>();

export const initialBestSellerState: BestSellerState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedBestSellerId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
});

const bestSellerReducer = createReducer(
    initialBestSellerState,
    on(BestSellerActions.BestSellerPageRequested,
        (state, action) => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: '',
            lastQuery: action.page
        })),
    on(BestSellerActions.BestSellerPageLoadedSuccessfully,
        (state, action) => (adapter.addAll(action.bestSeller,
            {
                ...state,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                totalCount: action.totalCount
            }))),
    on(BestSellerActions.BestSellerPageLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    //creation
    on(BestSellerActions.BestSellerCreated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(BestSellerActions.BestSellerCreatedSuccessfully,
        (state, action) => (adapter.addOne(action.bestSeller, {
            ...state,
            actionsLoading: true,
            lastCreatedBestSellerId: action.bestSeller.id,
            lastAction: 'EDIT.ADD_MESSAGE'
        }))),
    on(BestSellerActions.BestSellerCreationFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    //Update
    on(BestSellerActions.BestSellerUpdated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(BestSellerActions.BestSellerUpdatedSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialBestSeller, {
                ...state,
                actionsLoading: false,
                lastAction: 'EDIT.UPDATE_MESSAGE'
            }))),
    on(BestSellerActions.BestSellerUpdateFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    //Delete
    on(BestSellerActions.BestSellerDeleted,
        (state, action) => ({...state, actionsLoading: true, lastAction: ''})),
    on(BestSellerActions.BestSellerDeletedSuccessfully,
        (state, action) => (adapter.removeOne(action.bestSellerId, {
            ...state,
            actionsLoading: false,
            lastAction: 'DELETE.DELETE_BESTSELLER_SIMPLE.DELETE_SUCCESS'
        }))),
    on(BestSellerActions.BestSellerDeleteFailed,
        (state, action) => ({...state, error: action.error, actionsLoading: false}))
);

export function bestSellersReducer(state: BestSellerState | undefined, action: Action) {
    return bestSellerReducer(state, action)
}

