import {createFeatureSelector, createSelector} from "@ngrx/store";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {BrandState} from "../_reducers/brand.reducers";
import {BrandModel} from "../_models/brand.model";


export const selectBrandsState = createFeatureSelector<BrandState>('brands');

export const selectBrandById = (brandId: number) => createSelector(
    selectBrandsState,
    brandsState => brandsState.entities[brandId]
);

export const selectBrandsPageLoading = createSelector(
    selectBrandsState,
    brandsState => brandsState.listLoading
);

export const selectError = createSelector(
    selectBrandsState,
    brandsState => brandsState.error
);

export const selectlastAction = createSelector(
    selectBrandsState,
    brandsState => brandsState.lastAction
);

export const selectBrandsActionLoading = createSelector(
    selectBrandsState,
    brandsState => brandsState.actionsLoading
);

export const selectLastCreatedBrandId = createSelector(
    selectBrandsState,
    brandsState => brandsState.lastCreatedBrandId
);

export const selectBrandsShowInitWaitingMessage = createSelector(
    selectBrandsState,
    brandsState => brandsState.showInitWaitingMessage
);

export const selectBrandsInStore = createSelector(
    selectBrandsState,
    brandsState => {
        const items: BrandModel[] = [];
        each(brandsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: BrandModel[] = httpExtension.sortArray(items, brandsState.lastQuery.sortField, brandsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, brandsState.totalCount, '');
    }
);
