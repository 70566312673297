import {createFeatureSelector, createSelector} from "@ngrx/store";
import {each} from 'lodash';
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {CommentState} from '../_reducers/comment.reducers';
import {CommentModel} from '../_models/comment.model';


export const selectCommentsState = createFeatureSelector<CommentState>('comment');

export const selectCommentById = (commentById: number) => createSelector(
    selectCommentsState,
    commentstate => commentstate.entities[commentById]
);
export const selectCommentsPageLastQuery = createSelector(
    selectCommentsState,
    (commentstate) => commentstate.lastQuery
);
export const selectCommentsPageLoading = createSelector(
    selectCommentsState,
    commentstate => commentstate.listLoading
);

export const selectError = createSelector(
    selectCommentsState,
    commentstate => commentstate.error
);

export const selectlastAction = createSelector(
    selectCommentsState,
    commentstate => commentstate.lastAction
);

export const selectCommentsActionLoading = createSelector(
    selectCommentsState,
    commentstate => commentstate.actionsLoading
);

export const selectLastCreatedCommentId = createSelector(
    selectCommentsState,
    commentstate => {
       return  commentstate.lastCreatedCommentId

    },
);
export const selectCommentsShowInitWaitingMessage = createSelector(
    selectCommentsState,
    commentstate => commentstate.showInitWaitingMessage
);
export const selectCommentProgress = createSelector(
    selectCommentsState,
    commentstate => commentstate.progress
);
export const selectCommentsInStore = createSelector(
    selectCommentsState,
    commentstate => {
        const items: CommentModel[] = [];
        each(commentstate.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: CommentModel[] = httpExtension.sortArray(items, commentstate.lastQuery.sortField, commentstate.lastQuery.sortOrder);
        return new QueryResultsModel(result, commentstate.totalCount, '');
    }
);
