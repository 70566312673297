import {Injectable} from '@angular/core';
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {ThresholdModel} from "../ek-models/threshold.model";

@Injectable({
    providedIn: 'root'
})
export class ThresholdService extends AbstractService<ThresholdModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}threshold`;
    }



}
