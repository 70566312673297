import {Action, createReducer, on} from "@ngrx/store";
import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {Response} from "../../_base/crud/models/response";
import {DiscountModel} from "../_models/discount.model";
import * as DiscountActions from "../_actions/discount.action";


export interface DiscountState extends EntityState<DiscountModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedDiscountId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
}

export const adapter: EntityAdapter<DiscountModel> = createEntityAdapter<DiscountModel>();

export const initialDiscountState: DiscountState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedDiscountId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
});

const discountReducer = createReducer(
    initialDiscountState,
//creation
    on(DiscountActions.DiscountCreated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(DiscountActions.DiscountCreatedSuccessfully,
        (state, action) => (adapter.addOne(action.response, {
            ...state,
            discount: action.response
        }))),
    on(DiscountActions.DiscountCreationFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
);

export function discountsReducer(state: DiscountState | undefined, action: Action) {
    return discountReducer(state, action)
}
