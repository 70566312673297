import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ConfigurationModel} from "../../../../../../core/e-commerce/_models/configuration.model";
import {ConfigurationService, ProductBestSellerDataSource} from "../../../../../../core/e-commerce";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import {QueryParamsModel} from "../../../../../../core/_base/crud";
import * as ConfigurationsActions from "../../../../../../core/e-commerce/_actions/products-best-seller.actions";
import {Update} from "@ngrx/entity";
import {ProductsBestSellerModel} from "../../../../../../core/e-commerce/_models/products-best-seller.model";

interface DisplayFilter {
    value: Boolean;
    viewValue: String;
}

@Component({
    selector: 'kt-products-best-seller',
    templateUrl: './products-best-seller.component.html',
    styleUrls: ['./products-best-seller.component.scss']
})
export class ProductsBestSellerComponent implements OnInit {
    viewLoading$ = null;
    updater: string;
    totalElements: number;
    pageSize: number;
    filterOptions: DisplayFilter[] = [
        {viewValue: 'Toutes', value: null},
        {viewValue: 'Affichage Activé', value: true},
        {viewValue: 'Affichage désactivé', value: false}
    ];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    configuration: ConfigurationModel;
    columns = [
        {"columnDef": 'start', header: '', cell: (element: ConfigurationModel) => '', selected: true},

        {
            columnDef: 'id',
            header: 'ID',
            type: 'number',
            cell: (element: ConfigurationModel) => `${element.id}`,
            selected: true
        },
        {
            columnDef: 'creationDate',
            header: 'Date de création du produit',
            type: 'Date',
            cell: (element: ConfigurationModel) => `${element.createdAt}`,
            selected: true
        },
        {
            columnDef: 'name',
            type: '',
            header: 'Nom du produit',
            cell: (element: ConfigurationModel) => `${element.name}`,
            selected: true
        },
        {
            columnDef: 'updatedAt',
            type: '',
            header: 'Date de modification',
            cell: (element: ConfigurationModel) => `${element.updatedAt}`,
            selected: true
        },
        {
            columnDef: 'updater',
            type: '',
            header: 'updater',
            cell: (element: ConfigurationModel) => `${element.updater}`,
            selected: true
        },
        {
            columnDef: 'actions',
            type: '',
            header: 'Actions',
            selected: true
        },

        {columnDef: 'end', header: '', cell: (element: any) => ``, selected: true},
    ];

    displayedColumns = this.columns.filter(e => e.selected).map(c => c.columnDef);
    dataSource: ProductBestSellerDataSource;
    configurations: ConfigurationModel [] = [];

    constructor(
        private configurationService: ConfigurationService,
        private store: Store<AppState>
    ) {
    }

    ngOnInit(): void {
        this.updater = JSON.parse(localStorage.getItem('currentUser')).username;
        this.dataSource = new ProductBestSellerDataSource(this.store);
        this.loadBestSellerList();
    }
    loadBestSellerList( event?) {
        const queryParams = new QueryParamsModel(
            event ? event?.value : null,
            this.sort.direction,
            this.sort.active,
            0,
            10
        );
        this.store.dispatch(ConfigurationsActions.BestSellerPageRequested({page: queryParams}));
    }

    onChange(value, bestSeller: ProductsBestSellerModel, updater: string) {
        updater = this.updater;
        const updatedBestSeller: Update<ProductsBestSellerModel> = {
            id: bestSeller.id,
            changes: bestSeller
        };
        this.store.dispatch((ConfigurationsActions.BestSellerUpdated({bestSellerId: bestSeller.id,partialBestSeller: updatedBestSeller, display: value.checked, updater: updater})));
        this.configurationService.getById(bestSeller.id).subscribe(conf=>conf.updater=this.updater);
    }
}
