import {BaseDataSource, QueryResultsModel} from "../../_base/crud";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {
    selectCHInStore,
    selectCHPageLoading,
    selectCHShowInitWaitingMessage
} from "../ek-selectors/changeHistory.selector";

export class ChangeHistoryDatasource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();
        this.loading$ = this.store.pipe(
            select(selectCHPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectCHShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectCHInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }
}
