import {createFeatureSelector, createSelector} from "@ngrx/store";
import {OrderState} from "../_reducers/order.reducers";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {OrderModel2} from "../_models/order2.model";


export const selectOrdersState = createFeatureSelector<OrderState>('orders');

export const selectOrderById = (orderId: number) => createSelector(
    selectOrdersState,
    ordersState => ordersState.entities[orderId]
);

export const selectOrdersPageLoading = createSelector(
    selectOrdersState,
    ordersState => ordersState.listLoading
);

export const selectError = createSelector(
    selectOrdersState,
    ordersState => ordersState.error
);

export const selectlastAction = createSelector(
    selectOrdersState,
    ordersState => ordersState.lastAction
);

export const selectOrdersActionLoading = createSelector(
    selectOrdersState,
    ordersState => ordersState.actionsLoading
);

export const selectLastCreatedOrderId = createSelector(
    selectOrdersState,
    ordersState => ordersState.lastCreatedOrderId
);

export const selectOrdersShowInitWaitingMessage = createSelector(
    selectOrdersState,
    ordersState => ordersState.showInitWaitingMessage
);

export const areOrdersLoaded = createSelector(
    selectOrdersState,
    ordersState => ordersState.areOrdersLoaded
)

export const selectOrdersInStore = createSelector(
    selectOrdersState,
    ordersState => {
        const items: OrderModel2[] = []; 
        each(ordersState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: OrderModel2[] = httpExtension.sortArray(items, ordersState.lastQuery.sortField, ordersState.lastQuery.sortOrder);
        return new QueryResultsModel(result, ordersState.totalCount, '');
    }
);
