import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductsTrendingComponent } from './products-trending/products-trending.component';
import {MatDividerModule} from "@angular/material/divider";
import {MatButtonModule} from "@angular/material/button";
import {MatDialogModule} from "@angular/material/dialog";
import {MatMenuModule} from "@angular/material/menu";
import {MatSelectModule} from "@angular/material/select";
import {MatInputModule} from "@angular/material/input";
import {MatTableModule} from "@angular/material/table";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatRadioModule} from "@angular/material/radio";
import {MatIconModule} from "@angular/material/icon";
import {MatNativeDateModule} from "@angular/material/core";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatCardModule} from "@angular/material/card";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatSortModule} from "@angular/material/sort";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatTabsModule} from "@angular/material/tabs";
import {MatTooltipModule} from "@angular/material/tooltip";
import {PortletModule} from "../../../../partials/content/general/portlet/portlet.module";
import { CarouselImagesComponent } from './carousel-images/carousel-images.component';
import { ProductsBestSellerComponent } from './products-best-seller/products-best-seller.component';
import { ProductsNewArrivalComponent } from './products-new-arrival/products-new-arrival.component';
import {NgbDatepickerModule, NgbProgressbarModule} from "@ng-bootstrap/ng-bootstrap";
import { FooterComponent } from './footer/footer.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import { QuestionAnswerComponent } from './footer/question-answer/question-answer.component';
import {RouterModule} from "@angular/router";
import { QuestionAnswerEditComponent } from './footer/question-answer/question-answer-edit/question-answer-edit.component';
import {PartialsModule} from "../../../../partials/partials.module";
import { MatTableExporterModule } from 'mat-table-exporter';
import {CdkTableModule} from "@angular/cdk/table";
import {MatSlideToggleModule} from "@angular/material";
import { RelevantCategoriesComponent } from './relevant-categories/relevant-categories.component';
import { FooterFilesComponent } from './footer/footer-files/footer-files.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {CoreModule} from "../../../../../core/core.module";
import { PromotionComponent } from './promotion/promotion.component';
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import { DislpayComponent } from './dislpay/dislpay.component';
import { PrmotionExcelComponent } from './prmotion-excel/prmotion-excel.component';
import {FlexLayoutModule, FlexModule} from "@angular/flex-layout";
import { VenteFlashComponent } from './vente-flash/vente-flash.component';



@NgModule({
    declarations: [ProductsTrendingComponent, CarouselImagesComponent, ProductsBestSellerComponent, ProductsNewArrivalComponent, FooterComponent, QuestionAnswerComponent, QuestionAnswerEditComponent, RelevantCategoriesComponent, FooterFilesComponent,PromotionComponent, DislpayComponent, PrmotionExcelComponent,VenteFlashComponent],
    exports: [
        DislpayComponent
    ],
    imports: [
        CommonModule,
        MatDialogModule,
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        DragDropModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatSortModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTooltipModule,
        MatDividerModule,
        PortletModule,
        NgbProgressbarModule,
        ReactiveFormsModule,
        RouterModule,
        PartialsModule,
        MatTableExporterModule,
        CdkTableModule,
        MatSlideToggleModule,
        CoreModule,
        NgbDatepickerModule,
        MatButtonToggleModule,
        FormsModule,
        FlexModule,
        FlexLayoutModule
    ]
})
export class HomePageModule { }
