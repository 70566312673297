import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {CategoryModel} from "../_models/category.model";
import {QueryParamsModel} from "../../_base/crud";
import {Action, createReducer, on} from "@ngrx/store";
// actions
import * as CategoryActions from '../_actions/category.action';
import {Response} from "../../_base/crud/models/response";

export interface CategoryState extends EntityState<CategoryModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedCategoryId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
    progress: number;
}

export const adapter: EntityAdapter<CategoryModel> = createEntityAdapter<CategoryModel>();

export const initialCategoryState: CategoryState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedCategoryId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
    progress: 0
});

const categoryReducer = createReducer(
    initialCategoryState,
    on(CategoryActions.CategoriesPageRequested,
        (state, action) => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: '',
            progress: 0,
            lastQuery: action.page
        })),
    on(CategoryActions.CategoryUploadProgressed,
        (state, action) => ({...state, progress: action.progress})),

    on(CategoryActions.CategoriesPageLoadedSuccessfully,
        (state, action) => (adapter.addAll(action.categories,
            {
                ...state,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                totalCount: action.totalCount,
            }))),
    on(CategoryActions.CategoriesPageLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),

    //sub Categories
    on(CategoryActions.SubCategoriesActive,
        (state, action) => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: '',
            progress: 0,
            lastQuery: action.page
        })),

    on(CategoryActions.SubCategoriesActiveLoadedSuccessfully,
        (state, action) => (adapter.addAll(action.categories,
            {
                ...state,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                totalCount: action.totalCount,
            }))),

    on(CategoryActions.SubCategoriesActiveLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),

    //creation
    on(CategoryActions.CategoryCreated,
        (state) => ({...state, actionsLoading: false, lastAction: ''})),
    on(CategoryActions.CategoryCreatedSuccessfully,
        (state, action) => (adapter.addOne(action.category, {
            ...state,
            actionsLoading: true,
            lastCreatedCategoryId: action.category.id,
            lastAction: 'EDIT.ADD_MESSAGE',
            progress: 0
        }))),
    on(CategoryActions.CategoryCreationFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error, progress: 0})),
    //Update
    on(CategoryActions.CategoryUpdated,
        (state) => ({...state, actionsLoading: true, lastAction: '', progress: 0})),
    on(CategoryActions.CategoryUpdatedSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialCategory, {
                ...state,
                actionsLoading: false,
                lastAction: 'EDIT.UPDATE_MESSAGE',
                progress: 0
            }))),
    on(CategoryActions.CategoryUpdateFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error, progress: 0})),

    // Update activate
    on(CategoryActions.CategoryUpdatedActivate,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(CategoryActions.CategoryUpdatedActivateSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialCategory, {
                ...state,
                actionsLoading: false,
                lastAction: 'EDIT.UPDATE_MESSAGE'
            }))),
    on(CategoryActions.CategoryUpdateActivateFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    //Delete
    on(CategoryActions.CategoryDeleted,
        (state, action) => ({...state, actionsLoading: true, lastAction: ''})),
    on(CategoryActions.CategoryDeletedSuccessfully,
        (state, action) => (adapter.removeOne(action.categoryId, {
            ...state,
            actionsLoading: false,
            lastAction: 'DELETE.DELETE_CATEGORY_SIMPLE.DELETE_SUCCESS'
        }))),
    on(CategoryActions.CategoryDeleteFailed,
        (state, action) => ({...state, error: action.error, actionsLoading: false}))

    /////////////////////////

);

export function categoriesReducer(state: CategoryState | undefined, action: Action) {
    return categoryReducer(state, action)
}
