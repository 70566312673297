import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {AgencyBankModel} from "../../../../../../core/ek-e-commerce/ek-models/agency-bank-model";
import {BehaviorSubject, Observable, of, Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {SubheaderService} from "../../../../../../core/_base/layout";
import {LayoutUtilsService, MessageType} from "../../../../../../core/_base/crud";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import {Location} from "@angular/common";
import {Actions, ofType} from "@ngrx/effects";
import * as AgencyBankActions from "../../../../../../core/ek-e-commerce/ek-actions/agency-bank.actions";
import {
    AgencyBankCreatedSuccessfully, AgencyBankCreationFailed,
    AgencyBankUpdatedSuccessfully, AgencyBankUpdateFailed
} from "../../../../../../core/ek-e-commerce/ek-actions/agency-bank.actions";
import {Update} from "@ngrx/entity";
import {
    selectAgencyBankById,
    selectAgencyBanksActionLoading
} from "../../../../../../core/ek-e-commerce/ek-selectors/agency-bank.selector";
import {AgencyBankService} from "../../../../../../core/ek-e-commerce/ek-services/agency-bank.service";
import {Town} from "../../../../../../core/e-commerce/_models/town";
import {
    forbiddenProvinceValidator,
    Wilaya,
    WilayaService
} from "../../../../../../core/e-commerce/_services/wilaya.service";
import {map, startWith} from "rxjs/operators";
import {FormsValidationService} from "../../../Shared/Services/forms-validation.service";

@Component({
    selector: 'kt-agency-bank-edit',
    templateUrl: './agency-bank-edit.component.html',
    styleUrls: ['./agency-bank-edit.component.scss']
})
export class AgencyBankEditComponent implements OnInit {

    agencyBank: AgencyBankModel;
    agencyBank$: Observable<AgencyBankModel>;
    loading$: Observable<boolean>;
    agencyBankId$: Observable<number>;
    selectedTab = 0;

    agencyBankForm: FormGroup;
    hasFormErrors = false;
    PHONE_REGEX = /^(00213|\+213|0)([0-9]|4[0-9]|7[0-9])(\s*?[0-9]\s*?){5,8}$/;
    oldAgencyBank: AgencyBankModel
    subscriptions: Subscription[] = [];
    townFilteredOptions: Observable<Town[]>;
    filteredOptions: Observable<Wilaya[]>;
    selectedWilaya: string = '';
    wilayasList: Wilaya[] = [];
    towns: Town[] = [];
    townsSubject$ = new BehaviorSubject<Town[]>([]);
    wilayaSubject$ = new BehaviorSubject<string>('');
    readonly loadingWilaya$ = this.wilayaSubject$.asObservable();
    readonly town$ = this.townsSubject$.asObservable();
    jobPositions: string[] = ['مدير الوكالة', 'مديرة الوكالة'];
    currentRole = '';

    constructor(private activatedRoute: ActivatedRoute,
                private router: Router,
                private formBuilder: FormBuilder,
                private subheaderService: SubheaderService,
                private layoutUtilsService: LayoutUtilsService,
                private formsValidationService: FormsValidationService,
                private store: Store<AppState>,
                private cdr: ChangeDetectorRef,
                private location: Location,
                private agencyBankService: AgencyBankService,
                private _actions$: Actions,
                private wilayaService: WilayaService) {
    }


    ngOnInit(): void {

        this.loading$ = this.store.pipe(select(selectAgencyBanksActionLoading))
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;

        const routeSubscription = this.activatedRoute.params.subscribe(params => {
            const id = params.id;
            if (id && id > 0) {

                this.agencyBank$ = this.store.select(selectAgencyBankById(id));
                this.store.pipe(select(selectAgencyBankById(id))).subscribe(result => {
                    if (result == undefined) {

                        this.loadAgencyBankFromService(id);
                    } else {
                        this.loadAgencyBank(result);
                    }
                });
            } else {
                // Assign here
                this.agencyBank = {
                    id: null,
                    nameAgency: null,
                    firstNameManager: null,
                    lastNameManager: null,
                    codeAgency: null,
                    addressAgency: null,
                    fax: null,
                    phone: null,
                    jobPosition: '',
                    wilaya: null,
                    commune: null,

                };
                this.oldAgencyBank = Object.assign({}, this.agencyBank);
                this.initAgencyBank();
            }
        });
        this.subscriptions.push(routeSubscription);

        this.wilayasList = this.wilayaService.getWilayasItems();

        this.loadingWilaya$.subscribe(wilaya => {
            if (wilaya) {
                this.agencyBankForm.get('wilaya').setValue(wilaya);
                this.selectedWilaya = wilaya;
            }
        });

        this.town$.subscribe(town => {
            if (town.length > 0) {
                this.towns = town;
            }
        });


    }

    private _filter(name: string): Wilaya[] {
        const filterValue = name.toLowerCase();
        return this.wilayasList.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    getComponentTitle() {
        let result = 'Nouvelle agence';
        if (!this.agencyBank || !this.agencyBank.id) {
            return result;
        }
        result = `Modification de l'agence bancaire - ${this.agencyBank.codeAgency}`;
        return result;
    }

    goBack() {
        this.location.back()
    }

    phoneNumberValidator(control: FormControl) {
        const phoneNumber = control.value;
        if (!phoneNumber || phoneNumber.length < 9 || phoneNumber.length > 10) {
            return null;
        }
        const validPrefixes = ['0'];
        const isValid = validPrefixes.some(prefix => phoneNumber.startsWith(prefix));
        return isValid ? null : {invalidPrefix: true};
    }

    onSumbit(withBack: boolean) {

        this.hasFormErrors = false;
        const controls = this.agencyBankForm.controls;
        if (this.agencyBankForm.invalid) {

            Object.keys(controls).forEach(key =>
                controls[key].markAsTouched());
            this.hasFormErrors = true;
            this.selectedTab = 0;
            return;
        }

        const editAgencyBank = this.prepareAgencyBank();

        if (editAgencyBank.id > 0) {

            this.updateAgencyBank(editAgencyBank, withBack);
            return;
        }
        this.addAgencyBank(editAgencyBank, withBack);


    }


    private updateAgencyBank(agencyBank: AgencyBankModel, withBack: boolean) {
        const updatedAgencyBank: Update<AgencyBankModel> = {
            id: agencyBank.id,
            changes: agencyBank
        }

        this.store.dispatch(AgencyBankActions.AgencyBankUpdated({
            agencyBank: agencyBank,
            partialAgencyBank: updatedAgencyBank
        }));

        //updated successfully
        this._actions$.pipe(ofType(AgencyBankUpdatedSuccessfully))
            .subscribe((data: any) => {
                this.loading$ = this.store.pipe(select(selectAgencyBanksActionLoading));
                const message = `Agency bank successfully has been updated.`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
                this.goBackWithId();
            });

         //updated failed
        this._actions$.pipe(
            ofType(AgencyBankUpdateFailed))
            .subscribe((data: any) => {
                this.loading$ = this.store.pipe(select(selectAgencyBanksActionLoading));
                const message = `la modification a échoué, la Wilaya sélectionnée n'appartient à aucune région !`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Delete, 5000, true, true);
            });

    }

    private addAgencyBank(addAgencyBank: AgencyBankModel, withBack: boolean) {

        addAgencyBank.id = undefined;

        this.store.dispatch(AgencyBankActions.AgencyBankCreated({agencyBank: addAgencyBank}));

        //created successfully
        this._actions$.pipe(
            ofType(AgencyBankCreatedSuccessfully))
            .subscribe((data: any) => {
                this.loading$ = this.store.pipe(select(selectAgencyBanksActionLoading));
                const message = `L'agence a été enregistrée avec succès`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 5000, true, true);
                this.goBackWithId();
            });

        //created failed
        this._actions$.pipe(
            ofType(AgencyBankCreationFailed))
            .subscribe((data: any) => {
                this.loading$ = this.store.pipe(select(selectAgencyBanksActionLoading));
                const message = `la création a échoué, la Wilaya sélectionnée n'appartient à aucune région !`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Delete, 5000, true, true);
            });
    }

    goBackWithId() {
        const url = `/ek-ecommerce/agencyBank`;
        this.router.navigateByUrl(url, {relativeTo: this.activatedRoute});
    }

    private prepareAgencyBank(): AgencyBankModel {

        const controls = this.agencyBankForm.controls;
        const agencyBankModel: AgencyBankModel = {} as AgencyBankModel; // Initialize as an empty object

        agencyBankModel.id = this.agencyBank.id;
        agencyBankModel.firstNameManager = controls.firstname.value;
        agencyBankModel.lastNameManager = controls.lastname.value;
        agencyBankModel.codeAgency = controls.code.value;
        agencyBankModel.addressAgency = controls.address.value;
        agencyBankModel.fax = controls.fax.value;
        agencyBankModel.phone = controls.phone.value;
        agencyBankModel.nameAgency = controls.nameAgency.value;
        agencyBankModel.wilaya = controls.wilaya.value;
        agencyBankModel.commune = controls.commune.value;
        agencyBankModel.jobPosition = controls.jobPosition?.value ? controls.jobPosition?.value : '';

        return agencyBankModel;
    }

    onAlertClose($event) {
        this.hasFormErrors = false;
    }


    private loadAgencyBankFromService(id: number) {
        this.agencyBankService.getById(id).subscribe(res => {
            this.loadAgencyBank(res, true)
        })
    }

    private loadAgencyBank(result: AgencyBankModel, fromService: boolean = false) {
        this.agencyBank = result;
        this.agencyBankId$ = of(result.id)
        this.oldAgencyBank = Object.assign({}, result)
        this.initAgencyBank()
        if (fromService) {
            this.cdr.detectChanges();
        }
    }

    private initAgencyBank() {
        this.createForm();
        if (!this.agencyBank.id) {
            this.subheaderService.setTitle('Create Agency Bank');
            this.subheaderService.setBreadcrumbs([
                {title: 'Agency Bank Management', page: `AgencyBank-management`},
                {title: 'Agency Bank', page: `AgencyBank-management/AgencyBank`},
                {title: 'Create Agency Bank', page: `AgencyBank-management/AgencyBank/add`}
            ]);
            return;
        }
        this.subheaderService.setTitle('Edit Agency Bank');
        this.subheaderService.setBreadcrumbs([
            {title: 'Agency Bank Management', page: `AgencyBank-management`},
            {title: 'Agency Bank', page: `AgencyBank-management/AgencyBank`},
            {
                title: 'Edit Agency Bank',
                page: `AgencyBank-management/AgencyBank/edit`,
                queryParams: {id: this.agencyBank.id}
            }
        ]);
    }

    private createForm() {
        if (this.agencyBank.id) {

            this.agencyBankForm = this.formBuilder.group({
                firstname: [this.agencyBank.firstNameManager, Validators.compose([Validators.required, Validators.minLength(2), this.arabicAlphabetValidator])],
                jobPosition: [this.agencyBank.jobPosition ? this.agencyBank.jobPosition : '', Validators.required],
                nameAgency: [this.agencyBank.nameAgency, Validators.required],
                lastname: [this.agencyBank.lastNameManager, Validators.compose([Validators.required, Validators.minLength(2), this.arabicAlphabetValidator])],
                code: [this.agencyBank.codeAgency, Validators.required],
                address: [this.agencyBank.addressAgency, Validators.required],
                fax: [this.agencyBank.fax, Validators.required],
                phone: [this.agencyBank.phone, Validators.compose([Validators.required, this.formsValidationService.phoneNumberValidator()])],
                wilaya: [this.agencyBank.wilaya, forbiddenProvinceValidator(this.wilayasList)],
                commune: new FormControl(this.agencyBank ? this.agencyBank.commune : '', [Validators.required, this.forbiddenTownsValidator()]),
            });

            if (this.wilayasList.length > 0) {
                this.getTowns(this.wilayasList.filter(value => {
                    if (value.value == this.agencyBank.wilaya)
                        return value
                })[0].id);
            }


        } else {
            this.agencyBankForm = this.formBuilder.group({
                nameAgency: ['', Validators.required],
                jobPosition: ['', Validators.required],
                firstname: ["", Validators.compose([Validators.required, Validators.minLength(2), this.arabicAlphabetValidator])],
                lastname: ["", Validators.compose([Validators.required, Validators.minLength(2), this.arabicAlphabetValidator])],
                code: ["", Validators.required],
                address: ["", Validators.required],
                fax: ["", Validators.required],
                phone: ["", Validators.compose([this.formsValidationService.phoneNumberValidator(), Validators.required])],
                wilaya: ["", forbiddenProvinceValidator(this.wilayasList)],
                commune: ['', this.forbiddenTownsValidator()],

            });
        }

        this.filteredOptions = this.agencyBankForm.get('wilaya').valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map(name => name ? this._filter(name.toString()) : this.wilayasList.slice()),
            );

    }

    getWilayaTowns(selectedWilaya: string) {

        if (selectedWilaya) {

            this.selectedWilaya = selectedWilaya;

            this.agencyBankForm.get('commune').reset();

            if (this.agencyBankForm.controls.wilaya.dirty) {
                this.agencyBankForm.controls.commune.enable();
            }

            let cc = this.wilayasList.filter(value => {
                if (value.value == this.selectedWilaya)
                    return value
            })[0].id;

            this.getTowns(this.wilayasList.filter(value => {
                if (value.value == this.selectedWilaya)
                    return value
            })[0].id);
        }

    }

    getTowns(wilayaId) {
        this.wilayaService.getTownsByWilayaId(wilayaId).subscribe(res => {
            this.towns = res;
            this.townsSubject$.next(res);
            this.townFilteredOptions = this.agencyBankForm.get('commune').valueChanges
                .pipe(
                    startWith(''),
                    map(value => typeof value === 'string' ? value : value),
                    map(name => name ? this.townFilter(name.toString()) : this.towns.slice())
                );
        });
    }

    private townFilter(name: string): Town[] {
        const filterValue = name.toLowerCase();
        return this.towns.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    }

    forbiddenTownsValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (this.towns.length > 0) {
                const index = this.towns.findIndex(town => {
                    return (new RegExp('\^' + town.name + '\$')).test(control.value);
                });
                return index < 0 ? {'forbiddentowns': {value: control.value}} : null;
            }

        };
    }

    arabicAlphabetValidator(control) {

        const arabicAlphabetPattern = /^[\u0600-\u06FF\s]+$/; // Regular expression to match arabic characters

        if (arabicAlphabetPattern.test(control.value)) {
            return null; // Valid input
        } else {
            return {invalidArabicAlphabet: true}; // Invalid input
        }
    }


}

