import {BaseModel} from "../../_base/crud";

export class PointOfSaleModel extends BaseModel {
    id: number;
    addressPos:	string;
    namePos:	string;
    posCommune:	string;
    posPhoneOne:	string;
    posPhoneTwo:	string;
    posWilaya:	string;
    status:	string;
    codification:string;
    nameRegion?:string;
    regionId: number;
    wsUserIds: number[];
}
