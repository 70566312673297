import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';
import { QueryParamsModel } from '../../_base/crud';
import {PointOfSaleModel} from "../ek-models/point-of-sale.model";

export const POSPageRequested = createAction(
    '[POS List Page] POS Page Requested ',
    props<{ page: QueryParamsModel, regionId: number}>()
);
export const POSPageLoadedSuccessfully = createAction(
    '[POS API] POS Page Loaded Successfully ',
    props<{ pos: PointOfSaleModel[]; totalCount: number; page: number }>()
);
export const POSPageLoadFailed = createAction(
    '[POS API] POS Page Request Failed ',
    props<{ error: any }>()
);

export const POSCreated = createAction(
    '[POS] POS Created ',
    props<{ pos: PointOfSaleModel }>()
);
export const POSCreatedSuccessfully = createAction(
    '[POS] POS Created Successfully ',
    props<{ pos: PointOfSaleModel }>()
);
export const POSCreationFailed = createAction( 
    '[POS] POS Creation Failed ',
    props<{ error: any }>()
);

export const posByUser = createAction(
    '[POS] Pos By User Id ',
    props<{ userId: number; pointOfSaleId: number; }>()
);
export const posByUserCreatedSuccessfully = createAction(
    '[POS] Pos By User Id Created Successfully ',
    props<{ pos: any }>()
);
export const posByUserCreatedFailed = createAction(
    '[POS] Pos By User Id Creation Failed ',
    props<{ error: any }>()
);

export const POSUpdated = createAction(
    '[POS] POS Updated ',
    props<{ pos: PointOfSaleModel; partialPOS: Update<PointOfSaleModel> }>()
);
export const POSUpdatedSuccessfully = createAction(
    '[POS] POS Updated Successfully ',
    props<{ pos: PointOfSaleModel; partialPOS: Update<PointOfSaleModel> }>()
);
export const POSUpdateFailed = createAction(
    '[POS] POS Update Failed ',
    props<{ error: any }>()
);

export const POSDeleted = createAction(
    '[POS] POS Deleted ',
    props<{ posId: number }>()
);
export const POSDeletedSuccessfully = createAction(
    '[POS] POS deleted Successfully ',
    props<{ posId: number }>()
);
export const POSDeleteFailed = createAction(
    '[POS] POS delete Failed ',
    props<{ error: any }>()
);
export const ChangePOSStatus = createAction(
    '[POS Status] Change POS Status',
    props<{ pos: PointOfSaleModel; partialPOS: Update<PointOfSaleModel>}>()
);
export const POSStatusChangedSuccessfully = createAction(
    '[POS Status] POS Status changed Successfully ',
    props<{ pos: PointOfSaleModel; partialPOS: Update<PointOfSaleModel> }>()
);
export const POSStatusChangeFailed = createAction(
    '[POS Status] POS Status change Failed ',
    props<{ error: any }>()
);
