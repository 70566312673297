import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
    selector: 'app-second-step-confirmation-popup',
    templateUrl: './second-step-confirmation-popup.component.html',
    styleUrls: ['./second-step-confirmation-popup.component.scss']
})
export class SecondStepConfirmationPopupComponent implements OnInit {

    loading = false;
    timeLeft: number = 60;
    interval: any;
    display: any;

    constructor(dialogRef: MatDialogRef<SecondStepConfirmationPopupComponent>) {
    }

    ngOnInit(): void {

        this.startTimer();
        }
        startTimer() {
          this.interval = setInterval(() => {
            if(this.timeLeft > 0) {
              this.timeLeft--;
            } else {
              this.timeLeft = 30;
            }
          },1000)
        }
}
