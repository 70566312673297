import {createFeatureSelector, createSelector} from "@ngrx/store";
import {CharacteristicState} from "../_reducers/characteristic.reducers";
import {CharacteristicModel} from "../_models/characteristic.model";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";


export const selectCharacteristicsState = createFeatureSelector<CharacteristicState>('characteristics');

export const selectCharacteristicById = (characteristicId: number) => createSelector(
    selectCharacteristicsState,
    characteristicsState => characteristicsState.entities[characteristicId]
);

export const selectCharacteristicsPageLoading = createSelector(
    selectCharacteristicsState,
    characteristicsState => characteristicsState.listLoading
);

export const selectError = createSelector(
    selectCharacteristicsState,
    characteristicsState => characteristicsState.error
);

export const selectlastAction = createSelector(
    selectCharacteristicsState,
    characteristicsState => characteristicsState.lastAction
);

export const selectCharacteristicsActionLoading = createSelector(
    selectCharacteristicsState,
    characteristicsState => characteristicsState.actionsLoading
);

export const selectLastCreatedCharacteristicId = createSelector(
    selectCharacteristicsState,
    characteristicsState => characteristicsState.lastCreatedCharacteristicId
);

export const selectCharacteristicsShowInitWaitingMessage = createSelector(
    selectCharacteristicsState,
    characteristicsState => characteristicsState.showInitWaitingMessage
);

export const selectCharacteristicsInStore = createSelector(
    selectCharacteristicsState,
    characteristicsState => {
        const items: CharacteristicModel[] = [];
        each(characteristicsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: CharacteristicModel[] = httpExtension.sortArray(items, characteristicsState.lastQuery.sortField, characteristicsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, characteristicsState.totalCount, '');
    }
);
