
import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {Response} from "../../_base/crud/models/response";
import {Action, createReducer, on} from "@ngrx/store";
import * as NewArrivalActions from '../_actions/newArrival.action';
import {NewArrivalModel} from "../_models/newArrival.model";


export interface NewArrivalState extends EntityState<NewArrivalModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedNewArrivalId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
}

export const adapter: EntityAdapter<NewArrivalModel> = createEntityAdapter<NewArrivalModel>();

export const initialNewArrivalState: NewArrivalState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedNewArrivalId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
});

const newArrivalReducer = createReducer(
    initialNewArrivalState,
    on(NewArrivalActions.NewArrivalPageRequested,
        (state, action) => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: '',
            lastQuery: action.page
        })),
    on(NewArrivalActions.NewArrivalPageLoadedSuccessfully,
        (state, action) => (adapter.addAll(action.newArrival,
            {
                ...state,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                totalCount: action.totalCount
            }))),
    on(NewArrivalActions.NewArrivalPageLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    //creation
    on(NewArrivalActions.NewArrivalCreated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(NewArrivalActions.NewArrivalCreatedSuccessfully,
        (state, action) => (adapter.addOne(action.newArrival, {
            ...state,
            actionsLoading: true,
            lastCreatedNewArrivalId: action.newArrival.id,
            lastAction: 'EDIT.ADD_MESSAGE'
        }))),
    on(NewArrivalActions.NewArrivalCreationFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    //Update
    on(NewArrivalActions.NewArrivalUpdated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(NewArrivalActions.NewArrivalUpdatedSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialNewArrival, {
                ...state,
                actionsLoading: false,
                lastAction: 'EDIT.UPDATE_MESSAGE'
            }))),
    on(NewArrivalActions.NewArrivalUpdateFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    //Delete
    on(NewArrivalActions.NewArrivalDeleted,
        (state, action) => ({...state, actionsLoading: true, lastAction: ''})),
    on(NewArrivalActions.NewArrivalDeletedSuccessfully,
        (state, action) => (adapter.removeOne(action.newArrivalId, {
            ...state,
            actionsLoading: false,
            lastAction: 'DELETE.DELETE_NEWARRIVAL_SIMPLE.DELETE_SUCCESS'
        }))),
    on(NewArrivalActions.NewArrivalDeleteFailed,
        (state, action) => ({...state, error: action.error, actionsLoading: false}))
);

export function newArrivalsReducer(state: NewArrivalState | undefined, action: Action) {
    return newArrivalReducer(state, action)
}

