import {DocumentsMessagesPopupComponent} from './documents-messages-popup/documents-messages-popup.component';
import {FolderVerificationService} from '../../../../../../core/services/folder-verification.service';
import {ClientOcrInformationService} from '../../../../../../core/ek-e-commerce/ek-services/clientOcrInformation.service';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {BehaviorSubject} from 'rxjs';
import {ClientModel} from '../../../../../../core/ek-e-commerce/ek-models/client.model';
import {DocumentModel} from '../../../../../../core/ek-e-commerce/ek-models/document.model';
import {OneByOneValidations} from '../../../../../../core/ek-e-commerce/ek-models/ocr-validations';
import {OrderSplit} from '../../../../../../core/ek-e-commerce/ek-models/orderSplit';
import {ClientsService} from '../../../../../../core/ek-e-commerce/ek-services/clients.service';
import {OrderSplitService} from '../../../../../../core/ek-e-commerce/ek-services/order-split.service';
import {DocumentService} from '../../../../../../core/services/document.service';
import {MatDialog} from '@angular/material';
import {first} from 'rxjs/operators';


@Component({
    selector: "kt-second-step",
    templateUrl: "./second-step.component.html",
    styleUrls: ["./second-step.component.scss"],
})
export class SecondStepComponent implements OnInit {
    orderId: number;

    constructor(
        private clientsService: ClientsService,
        private route: ActivatedRoute,
        private orderSplitService: OrderSplitService,
        private documentService: DocumentService, // private accountService: AccountService
        public clientOcrInformationService: ClientOcrInformationService,
        private folderVerificationService: FolderVerificationService,
        private dialog: MatDialog,
    ) {
    }

    // client: ClientModel;
    @Input() orderSplit: OrderSplit;
    @Input() oneByOneValidations?: OneByOneValidations = {
        ficheFamilliale: true,
        extraitDeNaissance: true,
        cni: true,
        cni_verso: true,
        residence: true,
        chifa: true,
        FichePaie1: true,
        FichePaie2: true,
        FichePaie3: true,
        ccp: true,
        attestationDeTravail: true,
        retraite: true,
        faces: true,
        rena: true,
        radiation: true,
        releveDeCompte: true,
        cheque: true,
        incomeMilitary: true
    };

    @Output() goToNstep = new EventEmitter<number>();

    folderId!: number;
    @Input() civileDocuments!: DocumentModel[];
    @Input() profDocuments!: DocumentModel[];

    private _client$ = new BehaviorSubject<ClientModel>(null);
    private _civileDocuments$ = new BehaviorSubject<DocumentModel[]>(null);
    public civileDocuments$ = this._civileDocuments$.asObservable();
    private _profDocuments$ = new BehaviorSubject<DocumentModel[]>(null);
    public profDocuments$ = this._profDocuments$.asObservable();

    listOfDocsIds: number[] = [];

    ngOnInit(): void {
        this.route.paramMap.subscribe((params: any) => {
            this.folderId = Number(params.get("idFolder"));
            this.orderId = Number(params.get("idOrder"));

            this.documentService
                .getDocumentsByFolderId(this.folderId)
                .subscribe((res: any) => {
                    this.folderVerificationService.documents$.next(res.body)
                    this._civileDocuments$.next(
                        res.body.filter(
                            (document: DocumentModel) =>
                                document.documentsType === "CIVILIAN_STATE"
                        )
                    );
                    this._profDocuments$.next(
                        res.body.filter(
                            (document: DocumentModel) =>
                                document.documentsType === "PROFESSIONAL"
                        )
                    );

                    //getAll Documents ids
                    this.listOfDocsIds = res.body.filter(document => document.documentsType === 'CIVILIAN_STATE' || document.documentsType === 'PROFESSIONAL').map(item => item.id);
                    localStorage.setItem('listOfDocsIds', JSON.stringify(this.listOfDocsIds));
                });
        });

        this.clientOcrInformationService.getSocialProfessionalInformation(this.folderId).subscribe(res => {
            this.clientOcrInformationService.loadingSocialProfessionalSubject.next(res.body)
        });

        this.orderSplitService.selectedOrder$.subscribe((res) => {
            if (res) {
                this.clientsService.getById(res.clientId).subscribe((res) => {
                    if (res) {
                        this._client$.next(res);
                    }
                });
            }
        });

        this.folderVerificationService.needSomeChanges$
            .pipe(first())
            .subscribe((res) => {
                if (res == true) {
                    this.openMessagesPopup()
                }
            });

    }

    openMessagesPopup() {
        const routeInfo = {
            orderId: this.orderId,
            folderId: this.folderId
        }
        this.dialog.open(DocumentsMessagesPopupComponent, {
            height: "fit-content",
            width: "700px",
            maxHeight: "550px",
            panelClass: 'ocr-messages-popup',
            data: routeInfo
        });
    }
}
