import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {PointOfSaleModel} from '../../../../../../../core/ek-e-commerce/ek-models/point-of-sale.model';
import {map, startWith} from 'rxjs/operators';
import {PointOfSaleService} from '../../../../../../../core/ek-e-commerce/ek-services/point-of-sale.service';
import {OrderSplit} from '../../../../../../../core/ek-e-commerce/ek-models/orderSplit';
import {OrderSplitService} from '../../../../../../../core/ek-e-commerce/ek-services/order-split.service';
import {ActivatedRoute} from '@angular/router';
import {OrderSplitAdmin} from '../../../../../../../core/ek-e-commerce/ek-models/OrderSplitAdmin';
import {ClientModel} from '../../../../../../../core/ek-e-commerce/ek-models/client.model';
import {ClientOcrInformationService} from '../../../../../../../core/ek-e-commerce/ek-services/clientOcrInformation.service';
import {SocialProfessional} from '../../../../../../../core/ek-e-commerce/ek-models/social-professional';
import {DecimalFormatPipe} from '../../../../../../../core/_base/layout';
import {DatePipe} from '@angular/common';
import {MatDatepicker} from "@angular/material/datepicker";


export interface CartMessages {
    isLimited: boolean;
    messages: string;
}

@Component({
    selector: 'kt-folder-step-one',
    templateUrl: './folder-step-one.component.html',
    styleUrls: ['./folder-step-one.component.scss'],
    providers: [DecimalFormatPipe, DatePipe]
})
export class FolderStepOneComponent implements OnInit {

    constructor(
        private fb: FormBuilder,
        private pointOfSaleService: PointOfSaleService,
        private orderSplitService: OrderSplitService,
        private route: ActivatedRoute,
        private clientOcrInformationService: ClientOcrInformationService,
        private decimalFormatPipe: DecimalFormatPipe,
    ) {
        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
    }


    @Input() client: ClientModel = null as any;
    @Input() orderSplit: OrderSplit;
    @Input() folderState = '';
    @Input() folderRequestNumber = '';
    @Output() cartCapacityEmitter = new EventEmitter<number>();
    @Output() simulationInfoFG = new EventEmitter<FormGroup>();
    @Output() sendTotal = new EventEmitter<number>();
    @ViewChild('datePicker00') datePicker00!: MatDatepicker<Date>;

    pointOfSaleList: PointOfSaleModel[] = [];
    selectedRelayPoint = '0';
    selectedRelay!: PointOfSaleModel;
    subTotal = 0;
    total = 0;
    displayedColumns: string[] = ['prod', 'img', 'prix-unit-mois', 'prix-unit', 'quantité', 'Prix-total-Mois', 'prix-total', 'actions'];
    tempEntity: OrderSplitAdmin;
    folderId: number;
    isNewOrder = false;
    idOrder = 0;
    idFolder = 0;
    cartCapacity = 0;
    socialProfessional: SocialProfessional;
    selectedMonths = 12;
    orderCreationEntity: OrderSplitAdmin;

    form: FormGroup = this.fb.group({
        firstName: [this.client ? this.client.firstname : '', Validators.compose([Validators.required])],
        lastName: [this.client ? this.client.lastname : '', Validators.compose([Validators.required])],
        profSit: ['', Validators.compose([Validators.required])],
        salary: ['', Validators.compose([Validators.required, Validators.min(30000)])],
        persoSit: ['', Validators.compose([Validators.required])],
        monthlyPayment: ['', Validators.compose([Validators.required, this.forbiddenMonth()])],
        phoneNumber: ['', Validators.compose([Validators.required])],
        dateOfBitrh: ['', Validators.compose([Validators.required, this.forbiddenDateOfBirth()])],
        pointOfSale: ['', Validators.compose([Validators.required])],
        email: ['', Validators.compose([Validators.required, Validators.email])],
    });

    pointOfSleFilteredOptions!: Observable<PointOfSaleModel[]>;

    min = 100000;
    max = 2000000 ;

    private PHONE_REGEX = /^(00213|\+213|0)(5|6|7)(\s*?[0-9]\s*?){5,8}$/;
    numberOfMonths: number;

    currentRole: string = '';

    ngOnInit(): void {

        this.tempEntity = new OrderSplitAdmin();
        this.orderSplitService.createdNewOrder$.next(this.tempEntity);

        // Empty Order in new order
        this.route.paramMap.subscribe(params => {

            const clientId = Number(params.get('idClient'));
            this.idOrder = Number(params.get('idOrder'));
            this.idFolder = Number(params.get('idFolder'));

            this.getSocialClientInfo();

            // adding new order split
            if (clientId > 0) {
                this.isNewOrder = true;
                this.loadEmptyOrder(clientId);
            } else {
                // order split information
                this.loadOrderSplitStore(this.orderSplit);
            }
        });



        //list of pos
        this.pointOfSaleService.getPosList('ACTIVE').subscribe((res) => {
            this.pointOfSaleList = res;
        });


        this.createEmptyForm();

        this.pointOfSleFilteredOptions = this.form.get('pointOfSale')!.valueChanges
            .pipe(
                // @ts-ignore
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map((name: any) => name ? this._filter(name.toString()) : this.pointOfSaleList.slice())
            );

        this.orderSplitService.createdNewOrder$.subscribe((res) => {
            if (res) {
                this.orderCreationEntity = res;
            }
        });


        this.prepareOrder();

    }

    formatDate_(inputDateString) {
        if (inputDateString) {
            const [day, month, year] = inputDateString.split("-");
            const formattedDate = new Date(Number(year), Number(month) - 1, Number(day));
            return formattedDate;
        } else {
            return null; // Return null instead of an empty string
        }
    }

    createEmptyForm() {

        this.form = this.fb.group({
            firstName: [this.client ? this.client.firstname : '', Validators.compose([Validators.required])],
            lastName: [this.client ? this.client.lastname : '', Validators.compose([Validators.required])],
            profSit: [this.socialProfessional ? this.socialProfessional.professionalClientCategory : '', Validators.compose([Validators.required])],
            salary: [this.orderSplit ? this.decimalFormatPipe.transform(this.orderSplit.salarySimulation) : '', Validators.compose([Validators.required, Validators.min(30000)])],
            persoSit: [this.socialProfessional ? this.socialProfessional.socialClientCategory : '', Validators.compose([Validators.required])],
            monthlyPayment: [this.orderSplit ? (this.currentRole === 'ROLE_BANKER_EK' ? this.selectedMonths : this.orderSplit.numberOfMonths) : '', Validators.compose([Validators.required , this.forbiddenMonth()])],
            phoneNumber: [this.orderSplit ? (this.orderSplit.phone ? this.orderSplit.phone : this.client.phone) : (this.client.phone ? this.client.phone : ''), Validators.compose([Validators.minLength(10), Validators.pattern(this.PHONE_REGEX), Validators.required])],
            dateOfBitrh: [this.orderSplit ? this.formatDate_(this.orderSplit.dateOfBirthSimulation) : '', Validators.compose([Validators.required, this.forbiddenDateOfBirth()])],
            pointOfSale: [this.orderSplit.pointOfSaleTo ? (this.orderSplit.pointOfSaleTo?.namePos + ', ' + this.orderSplit.pointOfSaleTo?.posWilaya ) : '', Validators.compose([Validators.required])],
            email: [this.orderSplit ? (this.orderSplit.email ? this.orderSplit.email : this.client.email) : (this.client ? this.client.email : ''), Validators.compose([Validators.required, Validators.email])],
        });
        this.form.get('monthlyPayment').valueChanges.subscribe(res => {
            this.selectedMonths = res;
        });
    }

    createFolderForm(_orderSplit) {

        if (_orderSplit) {

            this.form = this.fb.group({
                firstName: [this.client ? this.client.firstname : '', Validators.compose([Validators.required])],
                lastName: [this.client ? this.client.lastname : '', Validators.compose([Validators.required])],
                profSit: [this.socialProfessional ? this.socialProfessional.professionalClientCategory : '', Validators.compose([Validators.required])],
                salary: [this.orderSplit ? this.decimalFormatPipe.transform(this.orderSplit.salarySimulation) : '', Validators.compose([Validators.required, Validators.min(30000)])],
                persoSit: [this.socialProfessional ? this.socialProfessional.socialClientCategory : '', Validators.compose([Validators.required])],
                monthlyPayment: [this.orderSplit ? (this.currentRole === 'ROLE_BANKER_EK' ? this.selectedMonths : this.orderSplit.numberOfMonths ): '', Validators.compose([Validators.required , this.forbiddenMonth()])],
                phoneNumber: [_orderSplit ? _orderSplit.phone : '', Validators.compose([Validators.minLength(10), Validators.pattern(this.PHONE_REGEX), Validators.required])],
                dateOfBitrh: [_orderSplit ? this.formatDate_(_orderSplit.dateOfBirthSimulation) : '', Validators.compose([Validators.required, this.forbiddenDateOfBirth()])],
                pointOfSale: [this.orderSplit.pointOfSaleTo ? (this.orderSplit.pointOfSaleTo?.namePos + ', ' + this.orderSplit.pointOfSaleTo?.posWilaya ) : '', Validators.compose([Validators.required])],
                email: [_orderSplit ? _orderSplit.email : '', Validators.compose([Validators.required, Validators.email])],
            });

        }

        this.form.controls.salary.valueChanges.subscribe(change => {
            this.calculateCartCapacity(change);
        });


    }

    loadOrderSplitStore(_orderSplit) {
        this.createFolderForm(_orderSplit);
        this.selectedMonths = _orderSplit.numberOfMonths;
        // this.setMonthlyPay(_orderSplit.numberOfMonths);
        this.calculateCartCapacity(_orderSplit.salarySimulation);
    }

    loadEmptyOrder(clientId) {
        this.orderSplit = new OrderSplit();
        this.orderSplit.clientId = clientId;
        this.orderSplit.pointOfSaleTo = new PointOfSaleModel();
        this.orderSplit.orderItems = [];
    }

    private _filter(name: string): PointOfSaleModel[] {
        const filterValue = name.toLowerCase();
        return this.pointOfSaleList.filter(option => (option.posWilaya.toLowerCase().indexOf(filterValue) === 0)
            || (option.posCommune.toLowerCase().indexOf(filterValue) === 0) || (option.namePos.toLowerCase().indexOf(filterValue) === 0));
    }

    filteredPointsExecuted() {
        this.pointOfSleFilteredOptions = this.form.get('pointOfSale')!.valueChanges
            .pipe(
                startWith(''),
                map(value => typeof value === 'string' ? value : value),
                map((name: any) => name ? this._filter(name.toString()) : this.pointOfSaleList.slice())
            );
    }

    getRelayPoint(idPOS: number) {

        this.selectedRelay = this.pointOfSaleList.find(item => item.id === idPOS)!;

        this.selectedRelayPoint = this.selectedRelay.posWilaya;

        this.form.get('pointOfSale')?.setValue(this.selectedRelay.namePos +', '+ this.selectedRelay.posWilaya);

        this.selectRelayPoint();

    }

    selectRelayPoint() {

        this.orderSplitService.selectedRelaySubject$.next(this.selectedRelay);

        this.tempEntity.pointOfSaleId = this.selectedRelay ? this.selectedRelay.id : null;

        this.orderSplitService.createdNewOrder$.next(this.tempEntity);

        if (this.selectedRelay) {
            let wilaya: string;
            switch (this.selectedRelay.posWilaya) {
                case 'ALGER2':
                    wilaya = 'ALGER';
                    break;
                case 'ORAN2':
                    wilaya = 'ORAN';
                    break;
                case 'OUARGLA2':
                    wilaya = 'OUARGLA';
                    break;
                case 'OUARGLA3':
                    wilaya = 'OUARGLA';
                    break;

                default:
                    wilaya = this.selectedRelay.posWilaya;
                    break;
            }
        }
    }

    calculateTotal() {
        this.total = 0;
        this.orderSplit.orderItems.forEach(item => {
            this.total = this.total + item.sellingPrice;

        });

    }

    prepareOrder() {

        this.form.valueChanges.subscribe(() => {

            this.tempEntity.id = null;
            this.tempEntity.clientId = this.orderSplit.clientId;
            this.tempEntity.pointOfSaleId = this.selectedRelay ? this.selectedRelay.id : null;
            this.tempEntity.configurationsDetails = this.orderCreationEntity.configurationsDetails;
            this.tempEntity.dateOfBirthSimulation = this.form.get('dateOfBitrh').value;
            this.tempEntity.salarySimulation = this.form.get('salary').value;
            this.tempEntity.email = '';
            this.tempEntity.phone = this.form.get('phoneNumber').value;
            this.tempEntity.numberOfMonths = this.form.get('monthlyPayment').value;
            this.tempEntity.professionalClientCategory = this.form.get('profSit').value;
            this.tempEntity.socialClientCategory = this.form.get('persoSit').value;
            this.tempEntity.email = this.form.get('email').value;

            this.simulationInfoFG.emit(this.form);

            //
            if (!this.isNewOrder && this.idOrder && this.orderSplit) {
                this.tempEntity.configurationsDetails = this.orderSplit.orderItems;
            }

            this.orderSplitService.createdNewOrder$.next(this.tempEntity);
            this.orderSplit.orderItems.forEach(item => {
                this.total = this.total + item.sellingPrice;
            });
        });
    }

    calculateCartCapacity(salary: any) {

        const temp = Number(salary) * 0.3 * this.selectedMonths;

        //cartCapacity should not exceed the max
        if (temp) {

            this.cartCapacity = temp < this.max ? temp : this.max;

            this.cartCapacityEmitter.emit(this.cartCapacity);

        }

    }

    productAdded(event: boolean) {
        if (event) {
            console.log(event);

        }
    }

    forbiddenDateOfBirth(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const greaterYear = new Date('01/01/2023').setFullYear(new Date().getFullYear() - 18);
            const lowerYear = new Date('01/01/2023').setFullYear(new Date().getFullYear() - 75);
            return new Date(control?.value).getTime() <= greaterYear && new Date(control?.value).getTime() >= lowerYear
                ? null : {dateValid: true};
        };
    }

    forbiddenMonth(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            return control?.value <= 60 && control?.value >= 12
                ? null : {monthValid: true};
        };
    }

    getSocialClientInfo() {
        this.clientOcrInformationService.getSocialProfessionalInformation(this.idFolder).subscribe({
            next: (res) => {
                this.socialProfessional = res.body;
                this.form.get('profSit').setValue(this.socialProfessional?.professionalClientCategory);
                this.form.get('persoSit').setValue(this.socialProfessional?.socialClientCategory);
                this.clientOcrInformationService.loadingSocialProfessionalSubject.next(res.body);
            },
            error: () => {
                console.log('error ....');
            }
        });
    }

    openDatePicker(): void {
        if (this.datePicker00) {
            this.datePicker00.open();
        }
    }
}


