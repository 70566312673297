import {Injectable} from '@angular/core';
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {ScaleIntervalModel} from "../ek-models/scaleInterval.model";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ScaleIntervalService extends AbstractService<ScaleIntervalModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}scaleInterval`;

    }


    /**
     * Save all socio-professional elements
     * @param elements List of elements to save
     * @returns Observable<Response>
     */
    saveList(elements: ScaleIntervalModel[]): Observable<Response> {
        const url = `${this.url}/save-all`;
        return this.http.post<Response>(url, elements);
    }


    /**
     * Update all socio-professional elements
     * @param elements List of elements to update
     * @returns Observable<Response>
     */
    updateAll(elements: ScaleIntervalModel[]): Observable<Response> {
        const url = `${this.url}/update-all`;
        return this.http.put<Response>(url, elements);


    }
}
