import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {CdkDragDrop, moveItemInArray, transferArrayItem} from "@angular/cdk/drag-drop";

@Component({
  selector: 'kt-settings-tab-config',
  templateUrl: './settings-tab-config.component.html',
  styleUrls: ['./settings-tab-config.component.scss']
})
export class EkSettingsTabConfigComponent implements OnInit {

    selected = [];
    remaining = [];

    constructor(private dialogRef: MatDialogRef<EkSettingsTabConfigComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any[]) { }

    ngOnInit(): void {
        this.selected = this.data.filter( c => c.selected && (c.columnDef != 'name')  && (c.columnDef != 'actions'));
        this.remaining = this.data.filter( c => !(c.selected));

        this.dialogRef.backdropClick().subscribe(() => {
            this.remaining.map(r => r.selected = false);
            this.selected.map(s => s.selected = true);
            let response = [this.data[0], ...this.selected, ...this.remaining, this.data[this.data.length-1]];
            this.dialogRef.close(response)
        })
    }



    drop(event: CdkDragDrop<string[]>) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            transferArrayItem(event.previousContainer.data,
                event.container.data,
                event.previousIndex,
                event.currentIndex);
        }
    }

    uncheckItem(item: any){
        item.selected = false;
        this.remaining.push(item);
        this.selected = this.selected.filter( i => i.header != item.header)
    }

    checkItem(item: any){
        item.selected = true;
        this.selected.push(item);
        this.remaining = this.remaining.filter( i => i.header != item.header);
    }

}
