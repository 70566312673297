import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Action, Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {CategoriesService} from "../_services/categories.service";
//Actions
import * as CategoryActions from '../_actions/category-popular.action';

@Injectable()
export class CategoryPopularEffects {


    constructor(private actions$: Actions, private categoryService: CategoriesService, private store: Store<AppState>) {
    }


    CategoriesPopularPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CategoryActions.CategoriesPopularPageRequested),
            switchMap((action) =>
                this.categoryService.getPopular()
                    .pipe(
                        map(categoryPage => CategoryActions.CategoriesPopularPageLoadedSuccessfully(
                            {
                                categories: categoryPage.body,
                                totalCount: categoryPage.body.length
                            })),
                        catchError(error => of(CategoryActions.CategoriesPopularPageLoadFailed({error})))
                    ))));
    CategoryPopularUpdatedActivate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CategoryActions.CategoryPopularUpdatedActivate),
            switchMap((action) =>
                this.categoryService.changeActivateCategory(action.category.id,action.activate, action.updater)
                    .pipe(
                        map(category => CategoryActions.CategoryPopularUpdatedActivateSuccessfully({
                            category:action.category,
                            partialCategory: action.partialCategory,
                            activate:action.activate,
                            updater:action.updater
                        })),
                        catchError(error => of(CategoryActions.CategoryPopularUpdateActivateFailed({error})))
                    ))));

}
