import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {Response} from "../../_base/crud/models/response";
import {Action, createReducer, on} from "@ngrx/store";
import {ClientModel} from "../ek-models/client.model";
import * as ClientActions from "../ek-actions/client.actions";

export interface ClientState extends EntityState<ClientModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedClientId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
    startingDate: string;
    endingDate: string;
}

export const adapter: EntityAdapter<ClientModel> = createEntityAdapter<ClientModel>();

export const initialClientState: ClientState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedClientId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
    entities: [],
    startingDate: '',
    endingDate: '',
});

const ClientReducer = createReducer(
    initialClientState,
    //Client list page
    on(ClientActions.clientsPageRequested, (state, action) => ({
        ...state,
        actionsLoading: true,
        listLoading: true,
        showInitWaitingMessage: true,
        error: null,
        lastAction: '',
        lastQuery: action.page,
    })),
    on(ClientActions.clientsPageLoadedSuccessfully, (state, action) =>
        adapter.addAll(action.clients, {
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            totalCount: action.totalCount,
        })
    ),
    on(ClientActions.clientsPageLoadFailed, (state, action) => ({
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
        error: action.error,
    })),

    //creation
    on(ClientActions.CustomerCreated,
        (state, action) => ({...state, actionsloading: true, error: null})),
    on(ClientActions.CustomerCreatedSuccessfully,
        (state, action) => (adapter.addOne(action.customer, {
            ...state,
            actionsloading: false,
            lastCreatedCustomerId: action.customer.id
        }))),
    on(ClientActions.CustomerCreationFailed,
        (state, action) => ({...state, actionsloading: false, error: action.error})),


    //update
    on(ClientActions.CustomerUpdated,
        (state, action) => ({...state, actionsloading: true, error: null})),
    on(ClientActions.CustomerUpdatedSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialCustomer, {...state, actionsloading: false,listLoading: false,}))),
    on(ClientActions.CustomerUpdateFailed,
        (state, action) => ({...state, error: action.error, actionsloading: false})),

    //delete customer
    on(ClientActions.OneCustomerDeleted,
        (state, action) => ({...state, actionsloading: true, error: null})),
    on(ClientActions.OneCustomerDeletedSuccessfully,
        (state, action) => (adapter.removeOne(action.id, {
            ...state,
            actionsloading: false,
            lastAction: 'delete Success'
        }))),
    on(ClientActions.OneCustomerDeleteFailed,
        (state, action) => ({...state, error: action.error, actionsloading: false})),
    on(ClientActions.clientsOrdersPageRequested, (state, action) => ({
        ...state,
        actionsLoading: true,
        listLoading: true,
        showInitWaitingMessage: true,
        error: null,
        lastAction: '',
        lastQuery: action.page,
    })),
    on(ClientActions.clientsOrdersPageLoadedSuccessfully, (state, action) =>
        adapter.addAll(action.clients, {
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            totalCount: action.totalCount,
        })
    ),
    on(ClientActions.clientsOrdersPageLoadFailed, (state, action) => ({
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
        error: action.error,
    })),
);

export function clientsReducer(state: ClientState | undefined, action: Action) {
    return ClientReducer(state, action)
}
