import {createAction, props} from '@ngrx/store';
import {ChangeHistoryModel} from "../ek-models/change-history-model";

export const FolderStateChangePageRequested = createAction(
    '[FolderStateChange List Page] FolderStateChange Page Requested ',
    props<{ idFolder: number }>()
);
export const FolderStateChangePageLoadedSuccessfully = createAction(
    '[FolderStateChange API] FolderStateChange Page Loaded Successfully ',
    props<{ FolderStatesChange: ChangeHistoryModel []; totalCount: number; page: number }>()
);
export const FolderStateChangePageLoadFailed = createAction(
    '[FolderStateChange API] FolderStateChange Page Request Failed ',
    props<{ error: any }>()
);
