import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatTableDataSource} from "@angular/material/table";
import {EkSnackBarService} from "../../../../../../core/services/ek-snackBar.service";
import {UserService} from "../../../../../../core/services/user.service";
import {BankerModel} from "../../../../../../core/ek-e-commerce/ek-models/BankerModel";
import {AgencyBankService} from "../../../../../../core/ek-e-commerce/ek-services/agency-bank.service";

@Component({
    selector: 'kt-banker-list-popup',
    templateUrl: './banker-list-popup.component.html',
    styleUrls: ['./banker-list-popup.component.scss']
})
export class BankerListPopupComponent implements OnInit {

    dataSource: MatTableDataSource<BankerModel>;
    displayedColumns: string[] = ['selected_row', 'id', 'firstname', 'lastname', 'email', 'nameAgency', 'wilaya'];
    currentBankerId: number;
    constructor(
        public dialogRef: MatDialogRef<BankerListPopupComponent>,
        private ekSnackBar: EkSnackBarService,
        private userService: UserService,
        private agencyBankService: AgencyBankService,
        @Inject(MAT_DIALOG_DATA) public data: number
    ) {

    }

    ngOnInit(): void {
        this.userService.getBankerList().subscribe(res => {
            console.log(res);
            if (res) {
                const dataWithDefaultValues = res.map((item: BankerModel) => ({
                    ...item,
                    nameAgency: item.nameAgency ?? 'Non attribué', // nullish coalescing operator
                    wilaya: item.wilaya ?? 'Non attribué' // nullish coalescing operator
                }));
                this.dataSource = new MatTableDataSource<BankerModel>(dataWithDefaultValues);
            }
        });
    }

    assignBanker() {
        this.agencyBankService.assignBanker(this.data, this.currentBankerId).subscribe({
            next: (res) => {
                if (res.message === 'adding source successfully') {
                    const msg = "L'agence a été affectée avec succès"
                    this.ekSnackBar.openSnackBar(msg, ' ', 'error-snackbar')
                    this.dialogRef.close()
                } else if(res.message === 'entity.already.exists'){
                    const msg = "Cette agence est déjà attribuée à deux banquiers"
                    this.ekSnackBar.openSnackBar(msg, ' ', 'error-snackbar')
                    this.dialogRef.close()
                }
                else {
                    const msg = "Une erreur est survenu"
                    this.ekSnackBar.openSnackBar(msg, ' ', 'error-snackbar')
                    this.dialogRef.close()
                }
            },
            error: (error) => {
                const msg = "Une erreur est survenu"
                this.ekSnackBar.openSnackBar(msg, ' ', 'error-snackbar')
                this.dialogRef.close()
            }
        })

    }

    closeDialog() {
        this.dialogRef.close()
    }

    selectRow(event: any, row: any) {
        this.currentBankerId = row.id
    }
}
