import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';

@Component({
  templateUrl: './ek-e-commerce.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EkECommerceComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
