import {Injectable} from "@angular/core";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {AbstractService} from "../../services/abstract-service.service";
import {ocrInfo} from "../ek-models/document-ocr-information";
import {Response} from "../ek-models/response.model";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {SocialProfessional} from "../ek-models/social-professional";
import { saveAs } from 'file-saver';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {ClientInfoScoringDTO} from "../ek-models/scoring.model";

@Injectable()
export class ClientOcrInformationService extends AbstractService<ocrInfo> {

    url: string;

    //client social information change
    loadingSocialProfessionalSubject = new BehaviorSubject<SocialProfessional>(null);
    readonly loadingSocialProfessional$ = this.loadingSocialProfessionalSubject.asObservable();

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}clientInformation`;
    }


    public downloadRecapInformations(idFolder:number): Observable<Response<any>> {
        const url = `${this.url}/${idFolder}/download`;
        return this.http.get<Response<any>>(url);

    }
    public downloadRecapInformationsText(idFolder:number) : void   {
        const url = `${this.url}/${idFolder}/download-text`;
        this.http.get(url, { responseType: 'blob' })
      .subscribe((blob: Blob) => {
        const downloadUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = 'Recap_Information.txt';
        link.click();
      });
    }


    public getSocialProfessionalInformation(idFolder:number): Observable<Response<SocialProfessional>> {
        const url = `${this.url}/getSocialProfessional/${idFolder}`;
        return this.http.get<Response<SocialProfessional>>(url);
    }

    public changeSocialProfessionalInformation(idFolder:number, contractType:string ,professionalClientCategory:string , socialClientCategory:string ) {
        const url = `${this.url}/changeSocialProfessional/${idFolder}`;

        let socialInfoObj : SocialProfessional = {
            contractType: contractType,
            professionalClientCategory: professionalClientCategory,
            socialClientCategory: socialClientCategory
        }

        return this.http.patch<Response<SocialProfessional>>(url,socialInfoObj).pipe(map(({body}) => body));
    }


    public updateClientInfos(entity: ocrInfo): Observable<ocrInfo> {
        const url = `${this.url}/update`;
        return this.http.put<Response<ocrInfo>>(url, entity).pipe(map(({body}) => body));
    }


    public folderScoring(folderId: number, orderId: number) {
        const url = `${this.url}/getScoring/${folderId}/${orderId}`;
        return this.http.get<Response<ClientInfoScoringDTO>>(url).pipe(map(({body}) => body));
    }

    public downloadScoringSimulation(folderId: number, orderId: number): Observable<any> {
        const url = `${this.url}/rating/${folderId}/${orderId}`;
        return this.http.get(url);
    }
}


