import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {EkTown} from "../ek-models/ek-town";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {AbstractService} from "../../services/abstract-service.service";
import {AbstractControl, ValidatorFn} from "@angular/forms";

export interface Wilaya  {
  name:string;
  value:string;
  id:string
}

const wilayas = [
  { name: 'Adrar' ,value: "ADRAR",id:"1"},
  { name: 'Chlef', value: "CHLEF" ,id:"2"},
  { name: 'Laghouat',value: "LAGHOUAT" ,id:"3"},
  { name: 'Oum El Bouaghi' ,value: "OUM_EL_BOUAGHI" ,id:"4"},
  { name: 'Batna',value: "BATNA" ,id:"5"},
  { name: 'Béjaïa',value: "BEJAIA" ,id:"6"},
  { name: 'Biskra',value: "BISKRA" ,id:"7"},
  { name: 'Béchar' ,value: "BECHAR",id:"8"},
  { name: 'Blida' ,value:"BLIDA",id:"9"},
  { name: 'Bouira',value: "BOUIRA" ,id:"10"},
  { name: 'Tamanrasset',value: "TAMANRASSET" ,id:"11"},
  { name: 'Tébessa',value: "TEBESSA",id:"12"},
  { name: 'Tlemcen',value: "TLEMCEN" ,id:"13"},
  { name: 'Tiaret',value: "TIARET" ,id:"14"},
  { name: 'Tizi Ouzou',value: "TIZI_OUZOU" ,id:"15"},
  { name: 'Alger',value: "ALGER" ,id:"16"},
  { name: 'Djelfa',value: "DJELFA" ,id:"17"},
  { name: 'Jijel', value: "JIJEL",id:"18"},
  { name: 'Sétif',value: "SETIF" ,id:"19"},
  { name: 'Saïda',value: "SAIDA",id:"20"},
  { name: 'Skikda',value: "SKIKDA" ,id:"21"},
  { name: 'Sidi Bel Abbès',value:"SIDI_BEL_ABBES",id:"22"},
  { name: 'Annaba',value: "ANNABA" ,id:"23"},
  { name: 'Guelma',value: "GUELMA" ,id:"24"},
  { name: 'Constantine', value: "CONSTANTINE" ,id:"25"},
  { name: 'Médéa',value: "MEDEA" ,id:"26"},
  { name: 'Mostaganem',value: "MOSTAGANEM" ,id:"27"},
  { name: 'M\'Sila',value: "MSILA" ,id:"28"},
  { name: 'Mascara',value: "MASCARA" ,id:"29"},
  { name: 'Ouargla',value: "OUARGLA" ,id:"30"},
  { name: 'Oran',value: "ORAN",id:"31"},
  { name: 'El Bayadh',value: "EL_BAYADH",id:"32"},
  { name: 'Illizi',value: "ILLIZI" ,id:"33"},
  { name: 'Bordj Bou Arreridj' ,value: "BORDJ_BOU_ARRERIDJ",id:"34"},
  { name: 'Boumerdès',value: "BOUMERDES" ,id:"35"},
  { name: 'El Tarf' ,value: "EL_TARF",id:"36"},
  { name: 'Tindouf',value: "TINDOUF" ,id:"37"},
  { name: 'Tissemsilt' ,value: "TISSEMSILT",id:"38"},
  { name: 'El Oued',value: "EL_OUED" ,id:"39"},
  { name: 'Khenchela',value: "KHENCHELA" ,id:"40"},
  { name: 'Souk Ahras',value: "SOUK_AHRAS" ,id:"41"},
  { name: 'Tipaza' ,value: "TIPAZA",id:"42"},
  { name: 'Mila',value: "MILA" ,id:"43"},
  { name: 'Aïn Defla',value: "AIN_DEFLA" ,id:"44"},
  { name: 'Naâma',value: "NAAMA" ,id:"45"},
  { name: 'Aïn Témouchent', value: "AIN_TEMOUCHENT" ,id:"46"},
  { name: 'Ghardaïa',value: "GHARDAIA" ,id:"47"},
  { name: 'Relizane',value: "RELIZANE" ,id:"48"}
]

@Injectable({
  providedIn: 'root'
})
export class EkWilayaService extends AbstractService<any> {


  url:string;


  constructor(protected http: HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}town`;

  }

  public getWilayasItems(): Wilaya[]{
    return wilayas;
  }

  public getTownsByWilayaId (wilayaId: number): Observable<EkTown[]> {
    const options = {
      params: new HttpParams()
          .append('wilayaId', wilayaId.toString())
    };
    const url = `${this.url}/wilaya`;
    return this.http.get<Response<EkTown[]>>(url, options).pipe(
        map((response: Response<EkTown[]>) => {
          if (response.body) {
            return response.body;
          } else {
            throw new Error();
          }
        })
    );
  }
}

export function forbiddenProvinceValidator(provinces: Wilaya[]): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if(wilayas.length > 0) {    // below findIndex will check if control.value is equal to one of our options or not
      const index = wilayas.findIndex(province => {
        return (new RegExp('\^' + province.value + '\$')).test(control.value);
      });
      return index < 0 ? {'forbiddenProvinces': {value: control.value}} : null;
    }
  };
}



