import {BaseDataSource, QueryResultsModel} from "../../_base/crud";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {
    selectNotificationSettingsInStore,
    selectNotificationSettingsPageLoading,
    selectNotificationSettingsShowInitWaitingMessage
} from "../ek-selectors/notificationMnagement.selectors";

export class NotificationManagementDatasource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();

        this.loading$ = this.store.pipe(
            select(selectNotificationSettingsPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectNotificationSettingsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectNotificationSettingsInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });

    }

}
