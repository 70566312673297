import {Injectable} from '@angular/core';
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient, HttpEvent, HttpParams, HttpRequest} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";
import {FooterFilesModel, FooterFileType} from "../_models/footerFiles.model";

@Injectable()
export class FooterFileService extends AbstractService<FooterFilesModel> {
    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}footerFiles`;
        // this.questionsUrl = `${ADMIN_CONFIG.apiEndpoint}questions`;
    }

    protected url: string;
    private questionsUrl: string;

    public get(): Observable<FooterFilesModel> {
        const url = `${this.url}`;
        return this.http.get<Response<FooterFilesModel>>(url).pipe(map(({body}) => body));
    }

    updateLegalMention(file,footerFileType : FooterFileType): Observable<FooterFilesModel[]>
        {
            const formdata: any = new FormData();
            formdata.append('file', file.item(0));
            formdata.append('footerFileType',footerFileType);
            const url = `${this.url}/upload`;
        return this.http.post<FooterFilesModel[]>(url , formdata).pipe(map(res => {
            return res;
        }));
   }

    public filterByType(footerFileType :FooterFileType): Observable<FooterFilesModel []> {
        const url = `${this.url}/all/${footerFileType}`;
        return this.http.get<Response<FooterFilesModel []>>(url).pipe(map(({body}) => body));
    }

    public changeActivateFile(id: number): Observable<Response<FooterFilesModel>> {
        const url = `${this.url}/${id}`;
        return this.http.patch<Response<FooterFilesModel>>(url,"").pipe(
            map((response: Response<FooterFilesModel>) => {
                if (response.body) {
                    return response;
                } else {
                    throw new Error();
                }
            })
        );
    }
}
