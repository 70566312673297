import {createAction, props} from "@ngrx/store";
import {Address} from "../_models/address.model";

export const AddressCreated = createAction('[Edit Address Dialog] Address On Server Created', props<{address: Address}>());
export const AddressCreatedSuccessfully = createAction('[Address API] Address Created successfully',props<{address: Address}>());
export const AddressCreationFailed = createAction('[Address API] Address Creation Failed',props<{error: any}>());


export const OneAddressDeleted = createAction('[Address List Page] One Address Deleted',props<{id: number}>());
export const OneAddressDeletedSuccessfully = createAction('[Address API] One Address Deleted Successfully',props<{id: number}>());
export const OneAddressDeleteFailed = createAction('[[Address API] One Address Deleted',props<{error: any}>());

export const AddressesRequested = createAction('[Address List Page] Addresses  Requested',props<{customerId: number}>());
export const AddressesLoadedSuccessfully = createAction('[Address API] Addresses  Loaded Successfully', props<{addresses: Address[]}>());
export const AddressesLoadingFailed = createAction('[Address API] Addresses  Loading Failed',props<{error: any}>());

