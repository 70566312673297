import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {Response} from "../../_base/crud/models/response";
import {Action, createReducer, on} from "@ngrx/store";
import * as OrderActions from "../_actions/order.action";
import {OrderModel2} from "../_models/order2.model";

export interface OrderState extends EntityState<OrderModel2> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedOrderId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
    areOrdersLoaded: boolean;
}

export const adapter: EntityAdapter<OrderModel2> =
    createEntityAdapter<OrderModel2>();

export const initialOrderState: OrderState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedOrderId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: "",
    areOrdersLoaded: false,
});

const orderReducer = createReducer(
    initialOrderState,

    //all order with filters
    on(OrderActions.OrdersAllPageRequested, (state, action) => ({
        ...state,
        actionsLoading: true,
        listLoading: true,
        showInitWaitingMessage: true,
        error: null,
        lastAction: "",
        lastQuery: action.page,
    })),
    on(OrderActions.OrdersAllPageLoadedSuccessfully, (state, action) =>
        adapter.addAll(action.orders, {
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            totalCount: action.totalCount,
            areOrdersLoaded: true,
        })),
    on(OrderActions.OrdersAllPageLoadFailed, (state, action) => ({
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
        error: action.error,
    })),
// all orders archived
    on(OrderActions.OrdersArchivedAllPageRequested, (state, action) => ({
        ...state,
        actionsLoading: true,
        listLoading: true,
        showInitWaitingMessage: true,
        error: null,
        lastAction: "",
        lastQuery: action.page,
    })),
    on(OrderActions.OrdersArchivedAllPageLoadedSuccessfully, (state, action) =>
        adapter.addAll(action.order, {
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            totalCount: action.totalCount,
            areOrdersLoaded: true,
        })),
    on(OrderActions.OrdersArchivedAllPageLoadFailed, (state, action) => ({
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
        error: action.error,
    })),
    //all order by Client
    on(OrderActions.OrdersByClientPageRequested, (state, action) => ({
        ...state,
        actionsLoading: true,
        listLoading: true,
        showInitWaitingMessage: true,
        error: null,
        lastAction: "",
        lastQuery: action.page,
    })),
    on(OrderActions.OrdersByClientPageLoadedSuccessfully, (state, action) =>
        adapter.addAll(action.orders, {
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            totalCount: action.totalCount,
            areOrdersLoaded: true,
        })),
    on(OrderActions.OrdersByClientPageLoadFailed, (state, action) => ({
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
        error: action.error,
    })),


    //creation
    on(OrderActions.OrderCreated, (state) => ({
        ...state,
        actionsLoading: true,
        lastAction: "",
    })),
    on(OrderActions.OrderCreatedSuccessfully, (state, action) =>
        adapter.addOne(action.order, {
            ...state,
            actionsLoading: true,
            lastCreatedOrderId: action.order.id,
            lastAction: "EDIT.ADD_MESSAGE",
        })
    ),
    on(OrderActions.OrderCreationFailed, (state, action) => ({
        ...state,
        actionsLoading: false,
        error: action.error,
    })),
    //Update
    on(OrderActions.OrderUpdated, (state) => ({
        ...state,
        actionsLoading: true,
        lastAction: "",
    })),
    on(OrderActions.OrderUpdatedSuccessfully, (state, action) =>
        adapter.updateOne(action.partialOrder, {
            ...state,
            actionsLoading: false,
            lastAction: "EDIT.UPDATE_MESSAGE",
        })
    ),
    on(OrderActions.OrderUpdateFailed, (state, action) => ({
        ...state,
        actionsLoading: false,
        error: action.error,
    })),
    //Delete
    on(OrderActions.OrderDeleted, (state, action) => ({
        ...state,
        actionsLoading: true,
        lastAction: "",
    })),
    on(OrderActions.OrderDeletedSuccessfully, (state, action) =>
        adapter.removeOne(action.orderId, {
            ...state,
            actionsLoading: false,
            lastAction: "DELETE.DELETE_ORDER_SIMPLE.DELETE_SUCCESS",
        })
    ),
    on(OrderActions.OrderDeleteFailed, (state, action) => ({
        ...state,
        error: action.error,
        actionsLoading: false,
    })),
    on(OrderActions.OrdersByWilayaRequested, (state, action) => ({
        ...state,
        actionsLoading: true,
        listLoading: true,
        showInitWaitingMessage: true,
        error: null,
        lastAction: "",
    })),
    on(OrderActions.OrdersByWilayaRequestedSuccessfuly, (state, action) =>
        adapter.addAll(action.orders, {
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            totalCount: action.totalCount,
            areOrdersLoaded: true
        })
    ),
    on(OrderActions.OrdersByWilayaRequestedFailed, (state, action) => ({
        ...state,
        actionsLoading: false,
        listLoading: false,
        showInitWaitingMessage: false,
        error: action.error,
    })),
    on(OrderActions.OrderStateFilterRequested, (state, action) => ({
        ...state,
        actionsLoading: true,
        listLoading: true,
        showInitWaitingMessage: true,
        error: null,
        lastAction: "",
    })),
    on(OrderActions.OrderStateFilterRequestedSucessfuly, (state, action) =>
        adapter.addAll(action.orders, {
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            totalCount: action.totalCount,
            areOrdersLoaded: true
        }))
);

export function ordersReducer(state: OrderState | undefined, action: Action) {
    return orderReducer(state, action);
}
