import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "MonthlyPaymentWithTax",
})
export class MonthlyPayCPAPipe implements PipeTransform {
  transform(value: number, numberOfMonths: number): number {
    numberOfMonths=numberOfMonths-2;
    const NUMBER_OF_TERMS_IN_A_YEAR = 12;
    const ANNUAL_RATE = 0.0952;

    let monthlyRate = ANNUAL_RATE / NUMBER_OF_TERMS_IN_A_YEAR;
    // let hamicheElDjidia = value*0.1;
    let hamicheElDjidia = 0;
    let total = value;
    let fundingAmount = total - hamicheElDjidia;
    let monthlyPayement =
        ((fundingAmount * monthlyRate) /
            (1 - Math.pow(1 + monthlyRate, -numberOfMonths)));
    return monthlyPayement;
  }
}
