import {Injectable} from '@angular/core';
import {act, Actions, createEffect, ofType} from '@ngrx/effects';
import {CommentService} from '../_services/comment.service';
import {Store} from '@ngrx/store';
import {AppState} from '../../reducers';
import * as Commentactions from '../_actions/comment.action';
import {catchError, map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs';
import * as ConfigurationsActions from "../_actions/configurations.actions";
import * as CarouselActions from "../../home-page/carousel/carousel.action";


@Injectable()

export class CommentEffects {


    constructor(private actions$: Actions, private commentService: CommentService, private store: Store<AppState>) {
    }

    CommentsPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(Commentactions.CommentsPageRequested),
            switchMap((action) =>
                this.commentService.getByProductId(action.page.pageNumber, action.page.pageSize,
                    action.page.sortField, action.page.sortOrder,action.id)
                    .pipe(
                        map(commentPage =>{
                           return   Commentactions.CommentsPageLoadedSuccessfully(
                                {

                                    comments: commentPage.content,
                                    page: commentPage.totalElements,
                                    totalCount: commentPage.totalElements

                                })
                        } ),
                        catchError(error => of(Commentactions.CommentsPageLoadFailed({error})))
                    ))));

    CommentsCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(Commentactions.CommentsCreated),
            switchMap((action) =>
                this.commentService.addComment(action.Comment, action.image)
                    .pipe(
                        map(res => Commentactions.CommentsCreatedSuccessfully({Comment:res })),
                        catchError(error => of(Commentactions.CommentsCreationFailed({error})))
                    ))));
    CommentsUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(Commentactions.CommentsUpdated),
            switchMap((action) =>
                this.commentService.commentUpdate(action.Comment, action.clientName, action.image)
                    .pipe(
                        map(comment => Commentactions.CommentsUpdatedSuccessfully({
                            Comment:action.Comment,
                            partialComments: action.partialComments
                        })),
                        catchError(error => of(Commentactions.CommentsUpdateFailed({error})))
                    ))));

    CommentsDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(Commentactions.CommentsDeleted),
            switchMap((action) =>
                this.commentService.delete(action.commentId)
                    .pipe(
                        map(() => Commentactions.CommentsDeletedSuccessfully({commentId: action.commentId})),
                        catchError(error => of(Commentactions.CommentsDeleteFailed({error})))
                    ))));

    CommentsFilterByDateRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(Commentactions.CommentsFilterByDateRequested),
            switchMap((action) =>
                this.commentService.filterByDate(action.id,action.date,action.date1, action.page.sortField)
                    .pipe(
                        map(Comments => Commentactions.CommentsFilterByDateLoadedSuccessfully(
                            {
                                Comment:Comments.content,
                                page: Comments.totalElements,
                                totalCount: Comments.totalElements,
                                lastRequest: action.page
                            })),
                        catchError(error => of(Commentactions.CommentsFilterByDateLoadFailed({error})))
                    ))));
    CommentsSearchRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(Commentactions.CommentsSearchRequested),
            switchMap((action) =>
                this.commentService.searchsorted(action.page.sortOrder,action.page.sortField,action.page.pageNumber,action.page.pageSize,action.page.filter,action.id)
                    .pipe(
                        map(Comments => Commentactions.CommentsSearchLoadedSuccessfully(
                            {
                                Comment:Comments.content,
                                page: Comments.totalElements,
                                totalCount: Comments.totalElements,
                                lastRequest: action.page
                            })),
                        catchError(error => of(Commentactions.CommentsSearchLoadFailed({error})))
                    ))));
    ImageDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(Commentactions.ImageDeleted),
            switchMap((action) =>
                this.commentService.deleteImg(action.Id)
                    .pipe(
                        map(() => Commentactions.ImageDeletedSuccessfully({Id: action.Id})),
                        catchError(error => of(Commentactions.ImageDeleteFailed({error})))
                    ))));

}
