import {BaseDataSource, QueryResultsModel} from "../../_base/crud";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers";

import {
    selectSmsPageLoading,
    selectSmsShowInitWaitingMessage,
    selectSmsInStore
} from "../ek-selectors/sms-history.selector";

export class SmsHistoryDatasource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();
        this.loading$ = this.store.pipe(
            select(selectSmsPageLoading),
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectSmsShowInitWaitingMessage)
        );

        this.store.pipe(
            select(selectSmsInStore),
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }
}
