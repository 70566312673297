import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {OrderModel2} from "../../../../../../core/e-commerce/_models/order2.model";
import {OrdersDatasource} from "../../../../../../core/e-commerce/_data-sources/orders.datasource";
import {ActivatedRoute,  Router} from "@angular/router";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import {OrdersListDialogComponent} from "../orders-list-dialog/orders-list-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {LayoutUtilsService, MessageType, QueryParamsModel} from "../../../../../../core/_base/crud";
import {OrdersService} from "../../../../../../core/e-commerce/_services/orders.service";
import {
  OrdersArchivedAllPageRequested
} from "../../../../../../core/e-commerce/_actions/order.action";
import {FormControl} from "@angular/forms";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {NgbDate} from "@ng-bootstrap/ng-bootstrap";
import {BehaviorSubject, fromEvent, merge, Observable, Subscription} from "rxjs";
import {debounceTime, distinctUntilChanged, map, skip, tap} from "rxjs/operators";
import {OrderModel} from "../../../../../../core/e-commerce/_models/order.model";
import {ConfigurationsDatasource} from "../../../../../../core/e-commerce/_data-sources/configuration.datasource";
import {selectProductsPageLastQuerys} from "../../../../../../core/e-commerce/_selectors/configuration.selector";

@Component({
  selector: 'kt-order-archive',
  templateUrl: './order-archive.component.html',
  styleUrls: ['./order-archive.component.scss']
})
export class OrderArchiveComponent implements OnInit {
  defaultWidth = 200;
  datedefaultWidth = 140
  matTableMaxHeight;
  checkedOrders = [];
  selectedStatus = new FormControl();
  selectedRegion = new FormControl();
  dataSource: OrdersDatasource;
  fromDate: NgbDate | null = null;
  toDate: NgbDate | null = null;
  archiveResult: OrderModel2[] = [];
  lastQuery: QueryParamsModel;

  columns = [
    {
      "columnDef": 'start',
      width: this.defaultWidth,
      header: '',
      cell: (element: OrderModel2) => '',
      selected: true
    },
    {
      columnDef: 'orderType',
      width: this.defaultWidth,
      type: '',
      header: 'Type',
      cell: (element: OrderModel2) => `${element.orderType?element.orderType=="GUEST"?"Achat-Direct":element.orderType:''}`,
      selected: false
    },
    {
      columnDef: 'createdAt',
      width: this.datedefaultWidth,
      header: 'Date d\'entrée',
      type: 'Date',
      cell: (element: OrderModel2) => `${element.createdAt}`,
      selected: false
    },
    {
      columnDef: 'id',
      width: 80,
      header: 'ID',
      type: 'number',
      cell: (element: OrderModel2) => `${element.id}`,
      selected: true
    },
    {
      columnDef: 'delivery.deliveryType',
      width: 140,
      type: '',
      header: 'Type de livraison',
      cell: (element: OrderModel2) => `${element.delivery.deliveryType}`,
      selected: true
    },
    {
      columnDef: 'source',
      width: this.defaultWidth,
      type: '',
      header: 'Source',
      cell: (element: OrderModel2) => `${element.source}`,
      selected: false
    },
    {
      columnDef: 'delivery.deliveryAddress.wilaya',
      width: this.defaultWidth,
      type: '',
      header: 'Wilaya',
      cell: (element: OrderModel2) => `${element.delivery.deliveryAddress.wilaya}`,
      selected: false
    },
    {
      columnDef: 'lastcall',
      width: this.defaultWidth,
      type: '',
      header: 'Dernier Appel',
      cell: (element: OrderModel2) => `${element.calls[element.calls.length - 1]}`,
      selected: false
    },
    {
      columnDef: 'delivery.deliveryAddress.firstname',
      width: this.defaultWidth,
      type: '',
      header: 'Client',
      cell: (element: OrderModel2) => `${element.delivery.deliveryAddress.firstname} `,
      selected: true
    },
    {
      columnDef: 'updater',
      width: this.defaultWidth,
      header: 'Updater',
      type: '',
      cell: (element: OrderModel2) => `${element.updater}`,
      selected: true
    },
    {
      columnDef: 'tracking',
      width: this.defaultWidth,
      header: 'Tracking',
      type: '',
      cell: (element: OrderModel2) => `${element.tracking}`,
      selected: true
    },
    {
      columnDef: 'paymentState',
      width: this.defaultWidth,
      header: 'Type de paiement',
      type: '',
      cell: (element: OrderModel2) => `${element.paymentState}`,
      selected: true
    },
    {
      columnDef: 'paymentMethod',
      width: this.defaultWidth,
      header: 'Type de paiement',
      type: 'case',
      cell: (element: OrderModel2) => `${element.paymentMethod}`,
      selected: true
    },
    {
      columnDef: 'total',
      width: this.defaultWidth,
      type: '',
      header: 'Total',
      cell: (element: OrderModel2) => `${element.totalTTC}`,
      selected: true
    },
    {
      columnDef: 'products',
      width: this.defaultWidth,
      type: 'products',
      header: 'Produits',
      cell: (element: OrderModel2) => `${(element.orderItems[0] != null) ? element.orderItems[0].configurationTO.name : 'ProductName'}`,
      selected: true
    },
    {
      columnDef: 'state',
      width: 150,
      type: 'case',
      header: 'Statut',
      cell: (element: OrderModel2) => `${element.state}`,
      selected: true
    },
    {
      columnDef: 'deliveryDate',
      width: this.datedefaultWidth,
      type: 'Date',
      header: 'Date de livraison',
      cell: (element: any) => `${element.deliveryDate}`,
      selected: false
    },
    {
      columnDef: 'delivery.deliveryAddress.phone',
      width: this.defaultWidth,
      type: '',
      header: 'Téléphone',
      cell: (element: OrderModel2) => `${element.delivery.deliveryAddress.phone}`,
      selected: false
    },
    {
      columnDef: 'benefit',
      width: this.defaultWidth,
      type: '',
      header: 'bénéfice',
      cell: (element: OrderModel2) => `${element.beneficiaryMargin}`,
      selected: false
    },
    {
      columnDef: 'callHistories',
      width: this.defaultWidth,
      type: '',
      header: 'Historiques des appels',
      cell: (element: OrderModel2) => `${
          element.calls[0] ? element.calls[0].callActionType : '-'}`,
      selected: false
    },
    {
      columnDef: 'priceProduct',
      width: this.defaultWidth,
      type: '',
      header: 'Prix de produit',
      cell: (element: OrderModel2) => `${element.orderItems[0].sellingPrice}`,
      selected: true
    },

    {
      columnDef: 'confirmationDate',
      width: this.datedefaultWidth,
      type: 'Date',
      header: 'date de confirmation',
      cell: (element: OrderModel2) => `${element.confirmationDate}`,
      selected: false
    },
    {
      columnDef: 'updatedAt',
      width: this.datedefaultWidth,
      type: 'Date',
      header: 'Date de changements',
      cell: (element: OrderModel2) => `${element.updatedAt}`,
      selected: false
    },
    {
      columnDef: 'delivery.deliveryPrice',
      width: this.defaultWidth,
      type: '',
      header: 'coût de livraison',
      cell: (element: OrderModel2) => `${element.delivery.deliveryPrice}`,
      selected: false
    },
    {
      columnDef: 'action',
      width: this.defaultWidth,
      type: '',
      header: 'desarchiver commande',
      cell: (element: OrderModel2) => ``,
      selected: true
    },

    // {
    //     columnDef: 'totalDollar',
    //     width: this.defaultWidth,
    //     type: '',
    //     header: 'Total Dollar',
    //     cell: (element: OrderModel2) => `${element.orderItems[0].configurationTO.buyingPriceDollar}`,
    //     selected: false
    // },
    // {
    //     columnDef: 'totalEuro',
    //     width: this.defaultWidth,
    //     type: '',
    //     header: 'Total Euro',
    //     cell: (element: OrderModel2) => `${element.orderItems[0].configurationTO.buyingPriceEuro}`,
    //     selected: false
    // },
  ];
  displayedColumns = this.columns.filter(e => e.selected).map(c => c.columnDef);
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
  private subscriptions: Subscription[] = [];
  loading$: Observable<boolean>;
  loadingSubject = new BehaviorSubject<boolean>(true);
  orderArchivedResult : OrderModel2[] =[];


  constructor(                private router: Router,
                              private store: Store<AppState>,
                              private dialog: MatDialog,
                              private orderService: OrdersService,
                              private activatedRoute: ActivatedRoute,
                              private layoutUtilsService: LayoutUtilsService,


  ) { }

  ngOnInit(): void {

    this.paginator._changePageSize(15);
    const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    this.subscriptions.push(sortSubscription);
    const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
        tap(() => this.loadOrderArchive())
    )
        .subscribe();
    this.subscriptions.push(paginatorSubscriptions);
    // Filtration, bind to searchInput
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((v) => {
          this.paginator.pageIndex = 0;
          this.loadOrderArchive();
        })
    ).subscribe();
    this.subscriptions.push(searchSubscription);
    this.dataSource = new OrdersDatasource(this.store);
    const entitiesSubscription = this.dataSource.entitySubject.pipe(
        skip(1),
        distinctUntilChanged()
    ).subscribe(res => {
      this.orderArchivedResult = res;
    });
    this.subscriptions.push(entitiesSubscription);

    // Read from URL itemId, for restore previous state
    const routeSubscription = this.activatedRoute.queryParams.subscribe(params => {
      // First load
      this.loadOrderArchive();
    });
    this.subscriptions.push(routeSubscription);




  }
  getTableMaxHeight() {
    if (localStorage.getItem('order-matTableMaxHeight'))
      this.matTableMaxHeight = Number(localStorage.getItem('order-matTableMaxHeight'))
  }
  loadOrderArchive(){
    const queryParams = new QueryParamsModel(
        this.filterConfiguration().query,
        this.sort.direction,
        this.sort.active,
        this.paginator.pageIndex,
        this.paginator.pageSize,
    );

    this.store.dispatch(OrdersArchivedAllPageRequested({
      page: queryParams,
      orderState: this.selectedStatus.value,
      region: this.selectedRegion.value,
      startingDate: this.fromDate ? this.formatDate(this.fromDate) : null,
      endingDate: this.toDate ? this.formatDate(this.toDate) : null
    }));
  }
  filterConfiguration(): any {
    const filter: any = {};
    const searchText: string = this.searchInput.nativeElement.value;
    filter.query = searchText.trim();
    return filter;
  }
  onResizeTable(event) {
    localStorage.setItem('order-matTableMaxHeight', event.rectangle.height)
    this.matTableMaxHeight = event.rectangle.height;
  }
  rowData(id) {
    this.router.navigateByUrl("/ecommerce/orders/" + id)


  }
  formatDate(date: NgbDate) {
    var month = '' + (date.month),
        day = '' + date.day,
        year = date.year;
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('/');
  }
  desarchiverCommande(_item: OrderModel) {
    const _title = 'Desarchivage de commande ==> ' + _item.id;
    const _description = 'Voulez-vous vraiment desarchiver ce commande ?';
    const _waitDesciption = 'DésArchivage en cours...';
    const btnTitle = 'desarchiver';
    const dialogRef = this.layoutUtilsService.archiveElement(
        _title,
        _description,
        _waitDesciption,
        btnTitle
    );
    dialogRef.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }
      this.orderService.unarchiveOrder(_item.id).subscribe((data) => {
        this.layoutUtilsService.showActionNotification(
            'La commande est desarchivé',

            MessageType.Update
        );
        this.loadOrderArchive();
      });
    });
  }
  showSettings() {
    const dialogRef = this.dialog.open(OrdersListDialogComponent, {
      width: '600px',
      data: this.columns,
    });

    dialogRef.afterClosed().subscribe(rep => {
      if (rep) {
        this.columns = rep;
        localStorage.setItem('saved-config', JSON.stringify(this.columns));
        this.displayedColumns = this.columns.filter(e => e.selected).map(c => c.columnDef);
      }
    })
  }

}
