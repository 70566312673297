import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {AbstractService} from "../../services/abstract-service.service";
import {BehaviorSubject, Observable} from "rxjs";
import {map} from "rxjs/operators";
import {Response} from "../../_base/crud/models/response";
import {CommentFolderModel} from "../ek-models/CommentFolder.model";
import {Page} from "../../_base/crud/models/page";

@Injectable({
    providedIn: 'root'
})
export class CommentFolderService extends AbstractService<CommentFolderModel> {

    url: string;
    url2:string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}CommentFolder`;
        this.url2 = `${ADMIN_CONFIG.apiEndpoint}CommentFolder/new`;
    }


    public addCommentFolder(comment: CommentFolderModel): Observable<CommentFolderModel> {
        const url = `${this.url2}`;
        return this.http.post<Response<CommentFolderModel>>(url, comment).pipe(map(({body}) => body));
    }


    public getAllComments(folderId: number): Observable<Page<CommentFolderModel>> {
        const url = `${this.url + '/folder/'}${folderId}`;
        return this.http.get<Response<Page<CommentFolderModel>>>(url).pipe(map(({body}) => body));
    }

    public deleteCommentById(id: number): Observable<Response<any>> {
        const url = `${this.url2}/${id}`;
        return this.http.delete<Response<void>>(url);
    }


    public updateComment(comment: CommentFolderModel): Observable<CommentFolderModel> {
        const url = `${this.url2}`;
        return this.http.put<Response<CommentFolderModel>>(url, comment).pipe(map(({body}) => body));
    }


}


