import {SelectionModel} from '@angular/cdk/collections';
import {ApplicationRef, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {NgbCalendar, NgbDate, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import {Store} from '@ngrx/store';
import {BehaviorSubject, fromEvent, merge, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged, map, tap} from 'rxjs/operators';


import {LayoutUtilsService, MessageType, QueryParamsModel} from '../../../../../../core/_base/crud';
import {Wilaya} from '../../../../../../core/e-commerce/_services/wilaya.service';
import {foldersListingPageRequested} from '../../../../../../core/ek-e-commerce/ek-actions/folder.action';
import {FoldersDatasource} from '../../../../../../core/ek-e-commerce/ek-data-sources/folders.datasource';
import {ListingModel} from '../../../../../../core/ek-e-commerce/ek-models/listing-model';
import {PointOfSaleModel} from '../../../../../../core/ek-e-commerce/ek-models/point-of-sale.model';
import {OrderSplitService} from '../../../../../../core/ek-e-commerce/ek-services/order-split.service';
import {PointOfSaleService} from '../../../../../../core/ek-e-commerce/ek-services/point-of-sale.service';
import {AppState} from '../../../../../../core/reducers';
import {ClientsListPopUpComponent} from '../clients-list-pop-up/clients-list-pop-up.component';
import {OrderSplitChangeStateComponent} from '../order-split-change-state/order-split-change-state.component';
import {AnalystListPopupComponent} from "./analyst-list-popup/analyst-list-popup.component";
import {ORDER_SPLIT_STATE} from "../../../Shared/Constants/orderSplit.states";
import {FolderStateChangeComponent} from "../folder-state-change/folder-state-change.component";
import {
    DownloadFileDialoguComponent
} from "../folder-steps/folder-step-two/download-file-dialogu/download-file-dialogu.component";
import {FolderStatesSelected} from '../../../../../../core/ek-e-commerce/ek-models/folders-states-selected.model';
import {MenuConfigService} from "../../../../../../core/_base/layout";
import {FoldersService} from "../../../../../../core/ek-e-commerce/ek-services/folders.service";
import {AgenciesBankPopupComponent} from './agencies-bank-popup/agencies-bank-popup.component';
import {AddCommentComponent} from "../folder-steps/folder-step-eight/add-comment/add-comment.component";
import {AgencyBankService} from "../../../../../../core/ek-e-commerce/ek-services/agency-bank.service";
import {
    FolderStateChangeDetailsComponent
} from "../../folder-state-change-history/folder-state-change-details/folder-state-change-details.component";
import {CommentsPopUpComponent} from "../comments-pop-up/comments-pop-up.component";
import {BANKER_STATES} from "../../../../../../../assets/constancies/banker.states";
import {ENUMERATE_FOLDER_STATES} from "../../../Shared/Constants/enumerateFolderStates";
import {ExcelService} from "../../../../../../core/ek-e-commerce/ek-services/excel.service";

@Component({
    selector: 'kt-folders-list',
    templateUrl: './folders-list.component.html',
    styleUrls: ['./folders-list.component.scss'],
})
export class FoldersListComponent implements OnInit {
    // Filter fields
    @ViewChild('searchInput', {static: true}) searchInput: ElementRef;
    //paging table
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    //sorting table
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    viewLoading$ = null;
    public listingTitle: String;
    hoveredDate: NgbDate | null = null;
    fromDate: NgbDate | null = null;
    toDate: NgbDate | null = null;
    filterByDateActivated = false;
    showDatePicker = true;
    loadingData = false;
    //mat table
    dataSource: FoldersDatasource;
    selection = new SelectionModel<ListingModel>(true, []);
    wilayas: Wilaya[];
    sameStates: boolean;
    folderState: string | null = null;
    selectedPosId : number | null;



    loadingReceptSubject = new BehaviorSubject<boolean>(false);
    readonly loadingRecap$ = this.loadingReceptSubject.asObservable();


    displayedColumns: string[] = ['select', 'folderId', 'Client', 'createdBy', 'attributeTo', 'folderState', 'createdAt', 'updatedAt', 'phone', 'state', 'wilayaDelivery', 'updater', 'agency', 'actions'];

    private subscriptions: Subscription[] = [];

    selectedAgency = new FormControl();
    selectedFolderState = new FormControl();
    selectedOrderState = new FormControl();
    selectedArchive = new FormControl();
    selectedPOS = new FormControl();
    selectedCreatorType = new FormControl();

    lastQuery: QueryParamsModel;

    selectedFoldersIds$: BehaviorSubject<number[]> = new BehaviorSubject<number[]>([])
    selectedFoldersCount$: BehaviorSubject<number> = new BehaviorSubject<number>(0)
    folderStatesSelected: FolderStatesSelected[] = []
    pointOfSaleList: PointOfSaleModel[] = [];


    creatorTypeList = [
        {name: 'Client', value: 'CLIENT'},
        {name: 'Admin', value: 'ADMIN'},
        {name: 'Point Vente', value: 'POS'}
    ]
    agencyList = [];

    folderStateList = ENUMERATE_FOLDER_STATES;

    orderStateList = ORDER_SPLIT_STATE;

    currentRole: string = '';
    userPointOfSaleList: PointOfSaleModel[] = [];
    currentUserId: number = null;
    currentClickedFolderId: number | null = null;
    routeFolderState = "";
    selectedFoldersIds: number[];
    selectedFolderIds: number[] = []
    selectedState: string = "";
    loadingStateChange: boolean;

    constructor(public formatter: NgbDateParserFormatter,
                private calendar: NgbCalendar,
                private store: Store<AppState>,
                private activatedRoute: ActivatedRoute,
                private router: Router,
                public dialog: MatDialog,
                private orderSplitService: OrderSplitService,
                private pointOfSaleService: PointOfSaleService,
                private layoutUtilsService: LayoutUtilsService,
                private menuConfigService: MenuConfigService,
                private ref: ApplicationRef,
                public folderService: FoldersService,
                private cdr: ChangeDetectorRef,
                private agencyBankService: AgencyBankService,
                private excelExport : ExcelService
    ) {
        // Access the current query parameters
        this.activatedRoute.queryParams.subscribe((queryParams) => {
            // Retrieve the folder state from queryParams
            this.routeFolderState = queryParams['folderState'];
            this.selectedFolderState.patchValue(this.routeFolderState);
        });

        this.currentRole = JSON.parse(localStorage.getItem('currentUser')).roles;
        if ((this.currentRole === 'ROLE_SUPERADMIN') || (this.currentRole === 'ROLE_ADMIN') || (this.currentRole === 'ROLE_BANKER_EK')) {
            this.displayedColumns = ['select', 'folderId', 'Docs', 'Client', 'createdBy', 'attributeTo', 'folderState', 'createdAt', 'updatedAt', 'phone', 'state', 'wilayaDelivery', 'updater', 'agency', 'actions'];

        }

        this.currentUserId = JSON.parse(localStorage.getItem('currentUser')).id;
        this.getPOSByIdUser();
    }

    ngOnInit(): void {

        //init dataSource
        this.dataSource = new FoldersDatasource(this.store);

        //this.wilayas = this.wilayaService.getWilayasItems();

        this.paginator._changePageSize(50);

        this.activatedRoute.paramMap.subscribe((res: ParamMap) => {
            this.listingTitle = this.activatedRoute.snapshot.data['title'];
            this.getAllPaged();
        });
        this.getAllPOS();
        // If the user changes the sort order, reset back to the first page.
        const sortSubscription = this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
        this.subscriptions.push(sortSubscription);

        const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
            debounceTime(500), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
            distinctUntilChanged(), // This operator will eliminate duplicate values
            map(() => {
                this.paginator.pageIndex = 0;
                this.getAllPaged();
            })).subscribe();
        this.subscriptions.push(searchSubscription);


        const paginatorSubscriptions = merge(this.sort.sortChange, this.paginator.page).pipe(
            tap(() => {
                this.getAllPaged();
            })
        ).subscribe();
        this.subscriptions.push(paginatorSubscriptions);


        this.orderSplitService.orderSplitState$.subscribe(change => {
            if (change) this.getAllPaged();
        });

        this.fieldListener();

        this.selection.changed.subscribe(selectedRows => {
            let folderIds: number[] = []
            let folderStates: FolderStatesSelected[] = []
            selectedRows.source.selected.forEach(folder => {
                if (folder && selectedRows.source.selected.length > 0) {
                    let folderId = folder.folderId
                    let status = folder.folderState
                    folderIds.push(folder.folderId)
                    folderStates.push({folderId: folderId, folderState: status})
                }
            })
            const numberOfFolders = folderIds.length
            this.selectedFoldersIds$.next(folderIds)
            this.selectedFolderIds = folderIds
            this.selectedFoldersCount$.next(numberOfFolders)
            this.folderStatesSelected = folderStates
            this.folderStatesSelected[0]?.folderState ? this.selectedState = this.folderStatesSelected[0]?.folderState : this.selectedState = ""
            this.isButtonHidden(this.selectedState);
            this.sameStates = this.areAllFolderStatesSame(this.folderStatesSelected);
            this.selectedFoldersIds = folderIds
        });
        this.getListAgencyBank();

        this.activatedRoute.queryParams.subscribe(params => {
            this.folderState = params['folderState'] || null;
        });

    }

    openAnalystsList() {
        this.dialog.open(AnalystListPopupComponent, {
            width: '950px',
            height: '600px',
            panelClass: 'analysts-popup',
            data: this.selectedFoldersIds
        })
    }

    openAgencyList() {
        let dialogRef = this.dialog.open(AgenciesBankPopupComponent, {
            width: '950px',
            height: '600px',
            panelClass: 'analysts-popup',
            data: this.selectedFoldersIds
        })
        dialogRef.afterClosed().subscribe(result => {
            if (result) this.getAllPaged();
        });
    }

    areAllFolderStatesSame(data: FolderStatesSelected[]): boolean {
        if (data.length === 0) return true;

        const firstFolderState = data[0].folderState;

        for (let i = 1; i < data.length; i++) {
            if (data[i].folderState !== firstFolderState) {
                return false;
            }
        }

        return true;
    }

    closeAtt() {
        this.selection.clear();
        // this.selectedFoldersCount$.next(0);
        // this.selectedFoldersIds$.next([]);
    }


    fieldListener() {
        this.selectedAgency.valueChanges.subscribe(value => {
            this.getAllPaged();
        });

        this.selectedFolderState.valueChanges.subscribe(value => {
            this.getAllPaged();
        });

        this.selectedOrderState.valueChanges.subscribe(value => {
            this.getAllPaged();
        });


        this.selectedArchive.valueChanges.subscribe(value => {
            this.getAllPaged();
        });
        this.selectedPOS.valueChanges.subscribe(value => {
            this.selectedPosId = this.selectedPOS.value;
            this.getAllPaged();
        });
        this.selectedCreatorType.valueChanges.subscribe(value => {
            this.getAllPaged();
        })

    }

    filterConfiguration(): string {
        return this.searchInput.nativeElement.value;
    }

    getAllPaged() {
        const queryParams = new QueryParamsModel(
            this.filterConfiguration(),
            this.sort.direction,
            this.sort.active,
            this.paginator.pageIndex,
            this.paginator.pageSize,
        );

        this.store.dispatch(foldersListingPageRequested({
            page: queryParams,
            folderState: this.selectedFolderState.value,
            orderState: this.selectedOrderState.value,
            agencyId: this.selectedAgency.value,
            firstDate: this.fromDate ? this.formatDate(this.fromDate) : null,
            lastDate: this.toDate ? this.formatDate(this.toDate) : null,
            archived: this.selectedArchive.value,
            idPos: this.selectedPOS.value,
            creatorType: this.selectedCreatorType.value
        }));
    }

    formatDate(date: NgbDate) {
        var month = '' + (date.month),
            day = '' + date.day,
            year = date.year;
        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
        const parsed = this.formatter.parse(input);
        return parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    }

    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || (this.toDate && date.equals(this.toDate)) || this.isInside(date) || this.isHovered(date);
    }

    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
        } else if (this.fromDate && !this.toDate && date && date.after(this.fromDate)) {
            this.toDate = date;
            this.filterByDateActivated = true;
            this.showDatePicker = false;
            this.getAllPaged();
        } else {
            this.toDate = null;
            this.fromDate = date;
        }
    }

    downloadExcelFolder(){
        this.excelExport.downloadExcelFolder(this.folderState,this.selectedPosId);
    }


    getFoldersStatisticsForMenuConfigs() {

        this.folderService.FoldersStatistics().subscribe({
            next: (res) => {
                this.menuConfigService.statisticsSubject$.next(null as any);
                this.menuConfigService.statisticsSubject$.next(res.countFolderState);
                this.cdr.detectChanges();
                this.ref.tick();
            }
        });

        if (this.currentRole === 'ROLE_ADMIN'
            || this.currentRole === 'ROLE_SUPERADMIN'
            || this.currentRole === 'ROLE_BANKER_EK'
        ) {
            this.folderService.GlobalFolderStatistics().subscribe({
                next: (res) => {
                    this.menuConfigService.GlobalFolderStatisticsSubject$.next(null as any);
                    this.menuConfigService.GlobalFolderStatisticsSubject$.next(res.countFolderState);
                    this.cdr.detectChanges();
                    this.ref.tick();
                }
            });
        }
    }

    getAllPOS() {
        this.pointOfSaleService.getPosListForFilter().subscribe({
            next: (points) => {
                if (points) {
                    //if any item in the list does not match the expected format
                    if (this.isListValid(points)) {
                        this.pointOfSaleList = this.sortItemsByPrefix(points);
                    } else {
                        this.pointOfSaleList = points;
                    }
                }
            },
            error: () => {
                this.pointOfSaleList = [];
            }
        });
    }


    disableFilterByDate(event) {
        if (event.checked == false) {
            this.loadingData = false;
            this.filterByDateActivated = false;
            this.toDate = null;
            this.fromDate = null;
            this.getAllPaged();
        }
    }

    //Check all rows are selected
    isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.entitySubject.value.length;
        return numSelected === numRows;
    }

    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.dataSource.entitySubject.value.forEach(row => this.selection.select(row));
        }
    }

    editFolder(c: ListingModel) {
        //folder/edit/:idOrder/:idFolder
        this.router.navigateByUrl('/ek-ecommerce/folder/edit/' + c.id + '/' + c.folderId + '?step=1')
    }

    openClientPopUp() {
        this.dialog.open(ClientsListPopUpComponent, {
            width: '92rem',
        });
    }


    openOrderSplitChangeStatePopUp(folder: ListingModel) {

        if (folder.state === 'EK_CLIENT_ORDER_CANCELED'
            || folder.state === 'EK_ORDER_DELIVERED'
            || this.currentRole === 'ROLE_BANKER_EK'
            || this.currentRole === 'ROLE_COMMERCIAL_REGION_MANAGER'
            || this.currentRole === 'ROLE_CONFORMITY_MANAGER'
            || this.currentRole === 'ROLE_GUEST_ADMIN') {
            return;

        } else {

            let dialogRef = this.dialog.open(OrderSplitChangeStateComponent, {
                data: {
                    idOrderSplit: folder.id,
                    idFolder: folder.folderId,
                    stateOrderSplit: folder.state
                },
                width: "37rem",
            });

            dialogRef.afterClosed().subscribe(res => {
                if (res && res === true) {
                    this.getAllPaged();
                }
            });
        }

    }


    openFolderChangeStatePopUp(folder: ListingModel) {

        if (this.currentRole !== 'ROLE_SUPERADMIN' || folder.folderState === 'EK_FOLDER_CREATED') {
            return;

        } else {

            let dialogRef = this.dialog.open(FolderStateChangeComponent, {
                data: {
                    idFolder: folder.folderId,
                    stateFolder: folder.folderState
                },
                width: "37rem",
            });

            dialogRef.afterClosed().subscribe(res => {
                if (res && res === true) {
                    this.getAllPaged();
                }
            });
        }

    }

    getPOSByIdUser() {
        this.pointOfSaleService.getAllPOSByIdUser(this.currentUserId).subscribe({
            next: (points) => {
                this.userPointOfSaleList = points ? points : [];
            },
            error: () => {
                this.userPointOfSaleList = [];
            }
        });
    }

    downloadDocs(folder: ListingModel) {
        const dialogRef = this.dialog.open(DownloadFileDialoguComponent, {
                width: '51rem',
                height: '45rem',
                data: {
                    idFolder: folder.folderId,
                    requestNumber: folder.requestNumber,
                    client: folder.firstNameClient + " " + folder.lastNameClient,
                }
            }
        );
    }

    isButtonHidden(status: any) {
        if (this.selectedState) {
            switch (status) {
                case 'CANCEL':
                    return false;
                case 'RETURN':
                    //
                    return !(this.selectedState === 'EK_BANK_IN_PROGRESS'
                    );

                case 'REFUSE':
                    return !(this.selectedState === 'EK_BANK_IN_PROGRESS'
                    );

                case 'VALIDATE':
                    //if document non verified , pos can not validate folder
                    if (this.currentRole === 'ROLE_POS_EK' && this.selectedState === 'EK_FOLDER_CREATED') {
                        return true;
                    }
                    return !(
                        this.selectedState === 'EK_BANK_IN_PROGRESS'
                        || this.selectedState === 'EK_BANK_RESERVE'
                        || this.selectedState === 'BANK_NOTIFICATION'
                        || this.selectedState === 'EK_GUARTENTEES_SIGNED_IN_PROGRESS'
                    );
            }

        }
    }

    changeOrderStatus(status: any) {

        // this.folderStateLoadObs$.next(true);

        switch (status) {

            //refuse folder
            case 'REFUSE' :

                switch (this.selectedState) {
                    case 'EK_BANK_IN_PROGRESS':
                        // supposed res :
                        this.multiStateChange('EK_BANK_REFUSE_FOLDER');
                        break;
                }
                break;
            //return folder
            case 'RETURN' :
                switch (this.selectedState) {
                    case 'EK_BANK_IN_PROGRESS':
                        this.openCommentDialog();
                        // supposed res : EK_BANK_RESERVE
                        //  this.multiStateChange(  'EK_BANK_RETURN_FOLDER');
                        break;
                }
                break;
            //validate folder
            case 'VALIDATE' :
                switch (this.selectedState) {
                    case 'EK_BANK_RESERVE':
                        // supposed res : CENTRAL_RISK_CONSULTATION
                        this.multiStateChange('EK_BANK_RESERVE_TO_CENTRAL_RISK_CONSULTATION')
                        break;
                    case 'EK_BANK_IN_PROGRESS':
                        // supposed res : BANK_NOTIFICATION
                        this.multiStateChange('EK_BANK_IN_PROGRESS_TO_BANK_NOTIFICATION')
                        break;
                    case 'BANK_NOTIFICATION':
                        // supposed res : EK_GUARTENTEES_PENDING_SIGNATURE
                        this.multiStateChange('BANK_NOTIFICATION_TO_EK_GUARTENTEES_PENDING_SIGNATURE')
                        break;
                    case 'EK_GUARTENTEES_SIGNED_IN_PROGRESS':
                        // supposed res : FINANCIAL_FOLDER
                        this.multiStateChange('EK_GUARTENTEES_SIGNED_IN_PROGRESS_TO_FINANCIAL_FOLDER')
                        break;

                }
                break;
        }
    }

    canAddNewFolder() {
        return this.currentRole === 'ROLE_SUPERADMIN' || this.currentRole === 'ROLE_ADMIN' || this.currentRole === 'ROLE_POS_EK';
    }

    multiStateChange(event: string, comment?: string) {
        this.loadingStateChange = true;
        this.folderService.multiStateChange(this.selectedFolderIds, event, comment).subscribe({
            next: () => {
                this.getAllPaged();
                this.selectedFoldersCount$.next(0);
                this.selectedState = ''
                this.folderStatesSelected = []
                this.selection.clear();
                this.loadingStateChange = false;
                this.selectedFolderIds = []
                const message = `les statuts des dossiers ont bien été modifié !`;
                this.layoutUtilsService.showActionNotification(
                    message,
                    MessageType.Create,
                    5000,
                    false,
                    false
                );

                //reload aside left folders list
                this.getFoldersStatisticsForMenuConfigs();
            },
            error: () => {
                this.loadingStateChange = false;
            }
        })
        this.cdr.detectChanges()
        this.ref.tick();
    }

    openCommentDialog() {

        let title = 'Commentaire';

        const dialogRef = this.dialog.open(AddCommentComponent, {
                width: '480PX',
                data: {
                    cancelingOrder: false,
                    reserveBulk: true,
                    idFolder: null,
                    idOrder: null,
                    title: title,
                    folderState: '',
                    nextFolderState: ''
                }
            }
        );

        dialogRef.afterClosed().subscribe((comment: string) => {

            if (comment) {

                switch (this.selectedState) {

                    case "EK_FOLDER_IN_PROGRESS":
                        //supposed res : EK_FOLDER_RESERVE
                        this.multiStateChange('EK_RETURN_FOLDER', comment);
                        break;

                    case 'EK_BANK_IN_PROGRESS':
                        // supposed res : EK_BANK_RESERVE
                        this.multiStateChange('EK_BANK_RETURN_FOLDER', comment);
                        break;

                    case 'EK_GUARTENTEES_PENDING_SIGNATURE':
                        // supposed res : GUARANTEES_TO_CORRECT
                        this.multiStateChange('EK_GUARTENTEES_PENDING_SIGNATURE_TO_BANK_NOTIFICATION', comment);
                        break;

                    case 'FINANCIAL_FOLDER':
                        //supposed res : EK_BANK_PHYSICAL_FOLDER_RESERVE
                        this.multiStateChange('FINANCIAL_FOLDER_TO_EK_GUARTENTEES_SIGNED_IN_PROGRESS', comment);
                        break;
                    case 'BANK_NOTIFICATION':
                        //supposed res : EK_BANK_PHYSICAL_FOLDER_RESERVE
                        this.multiStateChange('BANK_NOTIFICATION_TO_EK_BANK_RESERVE', comment);
                        break;
                }

            } else {
                const message = `vous devez insérer un commentaire avant de faire cette opération !`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
            }
        });
    }

    public getListAgencyBank() {

        this.agencyBankService.getAgencyNames().subscribe({
            next: (agencyResp) => {
                this.agencyList = agencyResp.body
            },
            error: (err) => {
            }
        })

    }

    openFolderDetails(item: ListingModel) {
        let dialogRef = this.dialog.open(FolderStateChangeDetailsComponent, {
            data: {
                folderId: item.folderId,
                clientFirstName: item.firstNameClient,
                clientLastName: item.lastNameClient,
                requestNumber: item.requestNumber,
            },
            width: "80rem",
            // panelClass: 'history-details'
        });
    }

    commentFolder(folder: ListingModel) {
        const commentDialogRef = this.dialog.open(CommentsPopUpComponent, {
            data: {
                folderId: folder.folderId,
                requestNumber: folder.requestNumber,
            },
            width: '80rem',
        });

        commentDialogRef.afterClosed().subscribe(data => {
            this.getAllPaged();
        });
    }

    canManageComments(folder: ListingModel) {

        return !(this.currentRole === 'ROLE_BANKER_EK' && !BANKER_STATES.includes(folder.folderState));

    }

    isListValid(items: PointOfSaleModel[]): boolean {
        return items.every(item => this.extractNumber(item.namePos) !== Infinity);
    }

    sortItemsByPrefix(items: PointOfSaleModel[]): PointOfSaleModel[] {
        return items.sort((a, b) => {
            const numA = this.extractNumber(a.namePos);
            const numB = this.extractNumber(b.namePos);
            return numA - numB;
        });
    }

    extractNumber(item: string): number {
        const match = item.match(/^(\d+)-/);
        return match ? parseInt(match[1], 10) : Infinity;
    }
}
