import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {Action, createReducer, on} from "@ngrx/store";
// actions
import * as CategoryActions from '../_actions/category-popular.action';
import {Response} from "../../_base/crud/models/response";
import {CategoryPopularModel} from "../_models/category-popular.model";

export interface CategoryPopularState extends EntityState<CategoryPopularModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedCategoryId: number;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
    progress: number;
}

export const adapter: EntityAdapter<CategoryPopularModel> = createEntityAdapter<CategoryPopularModel>();

export const initialCategoryPopularState: CategoryPopularState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedCategoryId: undefined,
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
    progress: 0
});

const categoryPopularReducer = createReducer(
    initialCategoryPopularState,
    on(CategoryActions.CategoriesPopularPageRequested,
        (state, action) => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: '',
            progress: 0,
        })),
    on(CategoryActions.CategoriesPopularPageLoadedSuccessfully,
        (state, action) => (adapter.addAll(action.categories,
            {
                ...state,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                totalCount: action.totalCount,
            }))),
    on(CategoryActions.CategoriesPopularPageLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    on(CategoryActions.CategoryPopularUpdatedActivate,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(CategoryActions.CategoryPopularUpdatedActivateSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialCategory, {
                ...state,
                actionsLoading: false,
                lastAction: 'EDIT.UPDATE_MESSAGE'
            }))),
    on(CategoryActions.CategoryPopularUpdateActivateFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),

);

export function categoriesPopularReducer(state: CategoryPopularState | undefined, action: Action) {
    return categoryPopularReducer(state, action)
}
