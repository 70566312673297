/**
 * Datasource that doesnt use NGRX
 * check this course https://blog.angular-university.io/angular-material-data-table/
 */
import {BaseDataSource, QueryResultsModel} from "../../_base/crud";
import {select, Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import {selectFAQsInitWaitingMessage, selectFAQsInStore, selectFAQsPageLoading} from "./faq.selectors";


export class QuestionAnswerDatasource extends BaseDataSource {
    constructor( private store: Store<AppState>) {
        super();
        this.loading$ = this.store.pipe(
            select(selectFAQsPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectFAQsInitWaitingMessage)
        );

        this.store.pipe(
            select(selectFAQsInStore)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }
}

