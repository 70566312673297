import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient, HttpEvent, HttpRequest} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {CarouselModel} from "./carousel.model";
import {Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";


@Injectable()
export class CarouselsService extends AbstractService<CarouselModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}images-carousel`;
    }


    pushFileToStorage(image: CarouselModel): Observable<HttpEvent<{
        body: {
            body: {
                imageSrc: string,
                id: number,
                route: string,
            }
        }
    }>> {
        const data: FormData = new FormData();
        data.append('file', image.file);
        data.append('route', image.route);
        const newRequest = new HttpRequest('POST', this.url, data, {
            reportProgress: true,
            responseType: 'json'
        });
        return this.http.request(newRequest);
    }

    public changeOrderImage(id: number, order: number, position: number): Observable<Response<CarouselModel>> {
        const url = `${this.url}/${id}/${order}/${position}`;
        return this.http.patch<Response<CarouselModel>>(url, "").pipe(
            map((response: Response<CarouselModel>) => {
                if (response.body) {
                    return response;
                } else {
                    throw new Error();
                }
            })
        );
    }

}
