import {Component, Inject, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {OrderSplitService} from "../../../../../../core/ek-e-commerce/ek-services/order-split.service";
import {LayoutUtilsService, MessageType} from "../../../../../../core/_base/crud";
import {BehaviorSubject} from "rxjs";
import {AddCommentComponent} from "../folder-steps/folder-step-eight/add-comment/add-comment.component";
import {ORDER_SPLIT_STATE} from "../../../Shared/Constants/orderSplit.states";

@Component({
    selector: 'kt-order-split-change-state',
    templateUrl: './order-split-change-state.component.html',
    styleUrls: ['./order-split-change-state.component.scss']
})
export class OrderSplitChangeStateComponent implements OnInit {

    form: FormGroup;
    stateSplit: string = '';
    event: string = '';

    loadingSubject$ = new BehaviorSubject<boolean>(false);
    readonly loading$ = this.loadingSubject$.asObservable();

    loading = false;

    orderStateList = ORDER_SPLIT_STATE ;


    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { idOrderSplit: number, idFolder: number,  stateOrderSplit: string },
        private orderSplitService: OrderSplitService,
        public dialogRef: MatDialogRef<OrderSplitChangeStateComponent>,
        private layoutUtilsService: LayoutUtilsService,
        public dialog: MatDialog,
    ) {

    }

    ngOnInit(): void {
        this.stateSplit = this.data.stateOrderSplit;
    }

    isRadioDisabled(OrderState): boolean {
        switch (OrderState) {

            case 'EK_ORDER_IN_PREPARATION': {
                if (this.data.stateOrderSplit === 'EK_ORDER_CREATED' || this.data.stateOrderSplit === 'EK_ORDER_PENDING') {
                    return true;
                }
                return false;
                break;
            }

            case 'EK_ORDER_IN_DELIVERY': {
                if (this.data.stateOrderSplit === 'EK_ORDER_IN_PREPARATION') {
                    return true;
                }
                return false;
                break;
            }


            case 'EK_ORDER_DELIVERED': {
                if (this.data.stateOrderSplit === 'DELIVERED_POS') {
                    return true;
                }
                return false;
                break;
            }

            case 'EK_CLIENT_ORDER_CANCELED': {
                if (this.data.stateOrderSplit === 'EK_ORDER_CREATED' || this.data.stateOrderSplit === 'EK_ORDER_PENDING' || this.data.stateOrderSplit === 'EK_ORDER_IN_PREPARATION' || this.data.stateOrderSplit === 'DELIVERED_POS' || this.data.stateOrderSplit === 'EK_ORDER_IN_DELIVERY') {
                    return true;
                }
                return false;
                break;
            }

            case 'DELIVERED_POS': {
                     if(this.data.stateOrderSplit === 'EK_ORDER_IN_DELIVERY'){
                         return true;
                }
                     return false;
            }
        }
    }

    close() {
        this.dialogRef.close(false);
    }

    save() {
        if (this.stateSplit === this.data.stateOrderSplit) {
            return;
        }

        this.loadingSubject$.next(true);
        this.loading = true;
        switch (this.stateSplit) {
            case 'EK_ORDER_IN_PREPARATION': {
                this.event = 'EK_PREPARE_ORDER';
                break;
            }

            case 'EK_ORDER_IN_DELIVERY': {
                this.event = 'EK_ORDER_IN_PREPARATION_TO_EK_ORDER_IN_PREPARATION';
                break;
            }


            case 'EK_ORDER_DELIVERED': {
                this.event = 'DELIVERED_POS_TO_EK_ORDER_DELIVERED';
                break;
            }

            case 'EK_CLIENT_ORDER_CANCELED': {
                this.event = 'EK_CANCEL_ORDER';
                break;
            }

            case 'DELIVERED_POS': {
                this.event = 'EK_ORDER_IN_DELIVERY_TO_DELIVERED_POS';
                break;
            }
        }


        if (this.event === 'EK_CANCEL_ORDER') {
            this.openDialog();
            return;
        }



        this.orderSplitService.changeStateOrderSplit(this.data.idOrderSplit, this.event).subscribe({
            next: () => {
                    const message = `le statut de la commande a été bien modifié!`;
                    this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
                    this.loadingSubject$.next(false);
                    this.loading = false;
                    this.dialogRef.close(true);
            },
            error: () => {
                    const message = `Impossible de changer le status , vérifiez le status de dossier!`;
                    this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
                    this.loadingSubject$.next(false);
                    this.dialogRef.close(false);
                }
            });
    }


    openDialog() {

        let title = 'Motif d\'annulation';

        const dialogRef = this.dialog.open(AddCommentComponent, {
                width: '480PX',
                data: {
                    cancelingOrderFromListing: true,
                    idFolder: this.data.idFolder,
                    idOrder: this.data.idOrderSplit,
                    title: title,
                    orderState: this.data.stateOrderSplit,
                }
            }
        );

        this.dialogRef.close(false);
    }
}
