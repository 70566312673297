import {Injectable} from '@angular/core';
import {AbstractService} from "../../services/abstract-service.service";
import {ClientModel} from "../ek-models/client.model";
import {HttpClient} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class TownService extends AbstractService<ClientModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}town`;
    }

    public getWilayaByTownName(townId: number): Observable<string> {
        const url = `${this.url}/wilaya/${townId}`;
        return this.http.get<Response<string>>(url).pipe(
            map((response: any) => {
                if (response) {
                    return response.body;
                } else {
                    throw new Error();
                }
            }));


    }
}