import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";
import {OptimisedTrendingConfModel} from "../_models/optimised-trending-conf.model";
import {Response} from "../../_base/crud/models/response";
import {Action, createReducer, on} from "@ngrx/store";
import * as ConfigurationsActions from "../_actions/product-trending.actions";

export interface TrendingConfState extends EntityState<OptimisedTrendingConfModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedConfigurationId2: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
}

export const adapter: EntityAdapter<OptimisedTrendingConfModel> = createEntityAdapter<OptimisedTrendingConfModel>();

export const initialTrendingConfigurationState: TrendingConfState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedConfigurationId2: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
});

const configurationReducer = createReducer(
    initialTrendingConfigurationState,
    on(ConfigurationsActions.TrendingPageRequested,
        (state, action) => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: '',
            lastQuery: action.page
        })),
    on(ConfigurationsActions.TrendingPageLoadedSuccessfully,
        (state, action) => (
            adapter.addAll(action.trending,
                {
                    entities: undefined, ids: undefined,
                    ...state,
                    actionsLoading: false,
                    listLoading: false,
                    showInitWaitingMessage: false,
                    totalCount: action.totalCount
                }))),
    on(ConfigurationsActions.TrendingPageLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    on(ConfigurationsActions.TrendingUpdated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),

    on(ConfigurationsActions.TrendingUpdatedSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialTrending, {
                ...state,
                actionsLoading: false,
                lastAction: 'EDIT.UPDATE_MESSAGE'
            }))),
    on(ConfigurationsActions.TrendingUpdateFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),

);

export function trendingConfigurationsReducer(state: TrendingConfState | undefined, action: Action) {
    return configurationReducer(state, action)
}
