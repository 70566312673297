import {ChangeDetectorRef, Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ParcelModel} from "../../../../../../core/e-commerce/_models/parcel.model";

@Component({
    selector: 'kt-parcel-details-dialog',
    templateUrl: './parcel-details-dialog.component.html',
    styleUrls: ['./parcel-details-dialog.component.scss']
})
export class ParcelDetailsDialogComponent implements OnInit {

    parcel: ParcelModel

    constructor(private dialogRef: MatDialogRef<ParcelDetailsDialogComponent>,
                @Inject(MAT_DIALOG_DATA) public data: { order: ParcelModel }) {
    }

    ngOnInit(): void {
        this.parcel = this.data.order;
    }

    close() {
        this.dialogRef.close()
    }

}
