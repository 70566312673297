// NGRX
import {Action, createFeatureSelector, createReducer, on} from '@ngrx/store';
import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
// Actions
import * as UserActions from '../_actions/user.actions';
// CRUD
import { QueryParamsModel } from '../../_base/crud';
// Models
import { User } from '../_models/user.model';

// tslint:disable-next-line:no-empty-interface
export interface UsersState extends EntityState<User> {
    listLoading: boolean;
    actionsloading: boolean;
    totalCount: number;
    lastCreatedUserId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
}

export const adapter: EntityAdapter<User> = createEntityAdapter<User>();

export const initialUsersState: UsersState = adapter.getInitialState({
    listLoading: false,
    actionsloading: false,
    totalCount: 0,
    lastQuery:  new QueryParamsModel({}),
    lastCreatedUserId: undefined,
    showInitWaitingMessage: true,
    error: null
});

const userReducer = createReducer(
    initialUsersState,
    on(UserActions.AllUsersRequested,
        (state,action) => ({...state, listLoading: true, actionsloading: true, showInitWaitingMessage: true ,lastQuery: action.page })),
    on(UserActions.AllUsersLoaded,
        (state, action) => (adapter.addAll(action.users,
            {...state, listLoading: false, actionsloading: false, showInitWaitingMessage: false , totalCount: action.totalCount}))),
    on(UserActions.UserDeleted,
        (state,action) => (state)),
    on(UserActions.UserDeletedSuccessfully,
        (state,action)=> (adapter.removeOne(action.id, state))),
    on(UserActions.UserOnServerCreated,
        (state, action)=> ({...state, actionsloading: true})),
    on(UserActions.UserAddedSuccessfully,
        (state, action)=>(adapter.addOne(action.user,{...state, actionsloading:false, lastCreatedUserId: action.user.id}))),
    on(UserActions.AddUserFailed,
        (state, action)=>({...state, actionsloading: false, error: action.error})),
    on(UserActions.UserUpdated,
        (state, action) =>({...state, actionsloading:true})),
    on(UserActions.UserUpdatedSuccessfully,
        (state, action)=>(adapter.updateOne(action.partialUser, {...state, actionsloading:false}))),
    on(UserActions.UserUpdateFailed,
        (state, action)=> ({...state, actionsloading: false, error: action.error})),
    on(UserActions.UserPwdUpdated,
    (state, action) =>({...state, actionsloading:true})),
    on(UserActions.UserPwdUpdatedSuccessfully,
        (state, action)=>(adapter.updateOne(action.partialUser, {...state, actionsloading:false}))),
    on(UserActions.UserPwdUpdateFailed,
        (state, action)=> ({...state, actionsloading: false, error: action.error}))
);

// export function usersReducer(state = initialUsersState, action: UserActions): UsersState {
//     switch  (action.type) {

//         case UserActionTypes.UserOnServerCreated: return {
//             ...state
//         };
//         case UserActionTypes.UserCreated: return adapter.addOne(action.payload.user, {
//             ...state, lastCreatedUserId: action.payload.user.id
//         });
//         case UserActionTypes.UserUpdated: return adapter.updateOne(action.payload.partialUser, state);
//         case UserActionTypes.UserDeleted: return adapter.removeOne(action.payload.id, state);
//         case UserActionTypes.UsersPageCancelled: return {
//             ...state, listLoading: false, lastQuery: new QueryParamsModel({})
//         };
//         case UserActionTypes.UsersPageLoaded: {
//             return adapter.addMany(action.payload.users, {
//                 ...initialUsersState,
//                 totalCount: action.payload.totalCount,
//                 lastQuery: action.payload.page,
//                 listLoading: false,
//                 showInitWaitingMessage: false
//             });
//         }
//         default: return state;
//     }
// }

export function usersReducer(state: UsersState| undefined, action: Action) {
    return userReducer(state, action);
}
