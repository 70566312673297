import {Injectable} from '@angular/core';
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient, HttpEvent, HttpParams, HttpRequest} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";
import {EkFooterFilesModel, EkFooterFileType} from "../ek-models/EkFooterFiles.model";

@Injectable()
export class EkFooterFileService extends AbstractService<EkFooterFilesModel> {
    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}EK/footerFiles`;
        // this.questionsUrl = `${ADMIN_CONFIG.apiEndpoint}questions`;
    }

    protected url: string;
    private questionsUrl: string;

    public get(): Observable<EkFooterFilesModel> {
        const url = `${this.url}`;
        return this.http.get<Response<EkFooterFilesModel>>(url).pipe(map(({body}) => body));
    }

    updateLegalMention(file,footerFileType : EkFooterFileType): Observable<EkFooterFilesModel[]>
        {
            const formdata: any = new FormData();
            formdata.append('file', file.item(0));
            formdata.append('footerFileType',footerFileType);
            const url = `${this.url}/upload`;
        return this.http.post<EkFooterFilesModel[]>(url , formdata).pipe(map(res => {
            return res;
        }));
   }

    public filterByType(footerFileType :EkFooterFileType): Observable<EkFooterFilesModel []> {
        const url = `${this.url}/all/${footerFileType}`;
        return this.http.get<Response<EkFooterFilesModel []>>(url).pipe(map(({body}) => body));
    }

    public changeActivateFile(id: number): Observable<Response<EkFooterFilesModel>> {
        const url = `${this.url}/${id}`;
        return this.http.patch<Response<EkFooterFilesModel>>(url,"").pipe(
            map((response: Response<EkFooterFilesModel>) => {
                if (response.body) {
                    return response;
                } else {
                    throw new Error();
                }
            })
        );
    }
}
