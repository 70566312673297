import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {ADMIN_CONFIG} from "../../../../environments/environment";
import {AbstractService} from "../../services/abstract-service.service";
import {BehaviorSubject, Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {CallModel} from "../_models/call.model";


@Injectable()
export class CallService extends AbstractService<CallModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}calls`;
    }


    public getCallsByOrderId(){
        const url = `${this.url}//`;
        return this.http.get<Observable<Response<any>>>(url);
    }



}


