import {createAction, props} from "@ngrx/store";
import {CharacteristicModel} from "../_models/characteristic.model";
import {Update} from "@ngrx/entity";
import {QueryParamsModel} from "../../_base/crud";



export const CharacteristicsPageRequested = createAction('[Characteristic List Page] Characteristics Page Requested ',props<{page: number, size: number}>());
export const CharacteristicsPageLoadedSuccessfully = createAction('[Characteristic API] Characteristics Page Loaded Successfully ',props<{characteristics: CharacteristicModel[],totalCount: number, page: number}>());
export const CharacteristicsPageLoadFailed = createAction('[Characteristic API] Characteristics Page Request Failed ',props<{error: any}>());

export const CharacteristicCreated = createAction('[Characteristic List Page] Characteristic Created ',props<{characteristic: CharacteristicModel}>());
export const CharacteristicCreatedSuccessfully = createAction('[Characteristic List Page] Characteristic Created Successfully ',props<{characteristic: CharacteristicModel}>());
export const CharacteristicCreationFailed = createAction('[Characteristic List Page] Characteristic Creation Failed ',props<{error: any}>());

export const CharacteristicUpdated = createAction('[Characteristic List Page] Characteristic Updated ',props<{characteristic: CharacteristicModel, partialCharacteristic: Update<CharacteristicModel>}>());
export const CharacteristicUpdatedSuccessfully = createAction('[Characteristic List Page] Characteristic Updated Successfully ',props<{characteristic: CharacteristicModel, partialCharacteristic: Update<CharacteristicModel>}>());
export const CharacteristicUpdateFailed = createAction('[Characteristic List Page] Characteristic Update Failed ',props<{error: any}>());

export const CharacteristicDeleted = createAction('[Characteristic List Page] Characteristic Deleted ',props<{characteristicId: number}>());
export const CharacteristicDeletedSuccessfully = createAction('[Characteristic List Page] Characteristic deleted Successfully ',props<{characteristicId: number}>());
export const CharacteristicDeleteFailed = createAction('[Characteristic List Page] Characteristic delete Failed ',props<{error: any}>());

export const CharacteristicsPageRequestedOfSearch = createAction('[Characteristic List Page] Characteristics Page Requested ',props<{page:QueryParamsModel}>());
export const CharacteristicsPageLoadedSuccessfullyOfSearch = createAction('[Characteristic API] Characteristics Page Loaded Successfully ',props<{characteristics: CharacteristicModel[],totalCount: number,page:number,lastRequest:QueryParamsModel }>());
export const CharacteristicsPageLoadFailedOfSearch = createAction('[Characteristic API] Characteristics Page Request Failed ',props<{error:any}>());
