import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import { saveAs } from 'file-saver';


@Injectable({
  providedIn: 'root'
})
export class ExcelService {

  url: string;

  constructor(private http : HttpClient) {
    this.url = `${ADMIN_CONFIG.apiEndpoint}`;
  }

  downloadExcelFolder(folderState: string | null, pointOfSaleId: number | null): void {
    let params = new HttpParams();
    params = folderState ? params.append('folderState', folderState) : params;
    params = pointOfSaleId ? params.append('pointOfSaleId', pointOfSaleId.toString()) : params;

    const apiUrl = `${this.url}folder/get-folders-excel`;

    const options = {
      params: params,
      responseType: 'arraybuffer' as 'arraybuffer'
    };

    this.http.get(apiUrl, options).subscribe((response: ArrayBuffer) => {
      const blob = new Blob([response], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const translatedState = this.transform(folderState);
      let fileName ='';
      if(pointOfSaleId === null || pointOfSaleId === undefined){
        fileName = folderState ? `EkiClik dossiers ${translatedState}.xlsx`.replace(/[\s\/]/g, '_') : 'EkiClik dossiers.xlsx'.replace(/[\s\/]/g, '_');
      }else{
        fileName = folderState ? `EkiClik dossiers ${translatedState} filtrés par point de vente.xlsx`.replace(/[\s\/]/g, '_') : 'EkiClik dossiers filtrés par point de vente.xlsx'.replace(/[\s\/]/g, '_');
      }

      saveAs(blob, fileName);
    });
  }

  private transform(State: string): string {
    switch (State) {
        //folder status
      case "EK_FOLDER_CREATED":
        return "En cours de création";
      case "EK_FOLDER_IN_PROGRESS":
        return "En cours de traitement Ekiclik";
      case "EK_FOLDER_RESERVE":
        return "Réservé Ekiclik";
      case "EK_FOLDER_REFUSED":
        return "Dossier refusé Ekiclik";
      case "EK_BANK_IN_PROGRESS":
        return "Pré-notification banque";
      case "EK_GUARTENTEES_PENDING_SIGNATURE":
        return "Garanties en cours de signature";
      case "GUARTENTEES_SIGNED_IN_PROGRESS_BANK":
        return "Garanties signés";
      case "EK_ADMIN_FOLDER_CANCELED":
        return "Dossier annulé (ADMIN)";
      case "EK_CLIENT_FOLDER_CANCELED":
        return "Dossier annulé (CLIENT)";
      case "EK_BANK_RESERVE":
        return "Dossier réserve banque";
      case "BANK_NOTIFICATION":
        return "Notification de la banque (Accord)";
      case "EK_BANK_REFUSED":
        return "Dossier Rejeté Banque";
      case "FINANCIAL_FOLDER":
        return "Dossiers Financés";
      case "EK_CONTROL_BEFORE_GUARANTEES_PRINTING":
        return "Control avant impression des garnaties EkiClik";

        //order status ...
      case "EK_ORDER_CREATED":
        return "Commande créée";
      case "EK_ORDER_PENDING":
        return "Commande en attente";
      case "EK_ORDER_IN_PREPARATION":
        return "Commande en préparation";
      case "EK_ORDER_IN_DELIVERY":
        return "Livraison en cours";
      case "EK_ORDER_DELIVERED":
        return "Client livré";
      case "EK_CLIENT_ORDER_CANCELED":
        return "Commande Annulée (Client)";
      case 'DELIVERED_POS':
        return 'Pos livré';
      default:
        return State;
    }
  }



}
