import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator, MatSort} from "@angular/material";
import {CategoryModel} from "../../../../../../core/e-commerce/_models/category.model";
import {CategoriesService, CategoryActions} from "../../../../../../core/e-commerce";
import {CategoriesPopularDatasource} from "../../../../../../core/e-commerce/_data-sources/categories-popular.datasource";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import * as CategoryPopularActions from "../../../../../../core/e-commerce/_actions/category-popular.action";
import {CategoryPopularModel} from "../../../../../../core/e-commerce/_models/category-popular.model";
import {Update} from "@ngrx/entity";
import {MessageType} from "../../../../../../core/_base/crud";

@Component({
  selector: 'kt-relevant-categories',
  templateUrl: './relevant-categories.component.html',
  styleUrls: ['./relevant-categories.component.scss']
})
export class RelevantCategoriesComponent implements OnInit {
  viewLoading$ = null;
  updater: string;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  pageSize: number;

  columns = [
    {"columnDef": 'start', header: '', cell: (element: CategoryPopularModel) => '', selected: true},

    {
      columnDef: 'id',
      header: 'ID',
      type: 'number',
      cell: (element: CategoryPopularModel) => `${element.id}`,
      selected: true
    },
    {
      columnDef: 'creationDate',
      header: 'createdAt',
      type: 'Date',
      cell: (element: CategoryPopularModel) => `${element.createdAt}`,
      selected: true
    },
    {
      columnDef: 'name',
      type: '',
      header: 'Category Name',
      cell: (element: CategoryPopularModel) => `${element.label}`,
      selected: true
    },
    {
      columnDef: 'image',
      type: '',
      header: 'Image',
      cell: (element: CategoryPopularModel) => `${element.image}`,
      selected: true
    },
    {
      columnDef: 'parentName',
      type: '',
      header: 'Parent Name',
      cell: (element: CategoryPopularModel) => `${element.parentName}`,
      selected: true
    },
   /* {
      columnDef: 'actions',
      type: '',
      header: 'Actions',
      selected: true
    },*/

    {columnDef: 'end', header: '', cell: (element: any) => ``, selected: true},
  ];

  displayedColumns = this.columns.filter(e => e.selected).map(c => c.columnDef);
  dataSource: CategoriesPopularDatasource;

  constructor(
      private categoriesService: CategoriesService,
      private store: Store<AppState>
  ) {
  }

  ngOnInit(): void {
    this.updater=JSON.parse(localStorage.getItem('currentUser')).username;
    this.dataSource = new CategoriesPopularDatasource(this.store);
    this.loadRelevantCategories();
  }
  loadRelevantCategories() {
    this.store.dispatch(CategoryPopularActions.CategoriesPopularPageRequested());
  }
  OnChangeActivateCategory(value ,category , updater){
    const _category = new CategoryPopularModel();
    _category.id = category.id;
    _category.activate = value.checked ;
    _category.updater=updater;
    const updateCategory: Update<CategoryPopularModel> = {
      id: category.id,
      changes: _category
    };
    this.store.dispatch(CategoryPopularActions.CategoryPopularUpdatedActivate({
      category: _category,
      partialCategory: updateCategory,
      activate: value.checked,
      updater:updater
    }));
  }
}
