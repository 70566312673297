// Angular
import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {DragDropModule} from '@angular/cdk/drag-drop';
// Translate Module
import {TranslateModule} from '@ngx-translate/core';
// NGRX
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
// UI
import {PartialsModule} from '../../../partials/partials.module';
// Core
// Auth
import {ModuleGuard} from '../../../../core/auth';
// Core => Services
import {
    DiscountCustomerEffects,
    customerDiscountReducers,
    BestSellerEffects,
    bestSellersReducer,
    BrandEffects,
    brandsReducer,
    categoriesReducer, CollaboratorEffects, collaboratorsReducer, CommentEffects, commentReducer1,
    customerAddressesReducer,categoriesPopularReducer,
    CustomerEffects,
    customersReducer,
    CustomersService,
    NewArrivalEffects,
    ProductEffects,
    productsReducer,
    ProductsService, PromoCodeEffects, PromoCodeService, promoCodesReducer,
    discountsReducer, DiscountEffect, CategoryPopularEffects
} from '../../../../core/e-commerce';
// Core => Utils
import {HttpUtilsService, InterceptService, LayoutUtilsService, TypesUtilsService} from '../../../../core/_base/crud';
// Shared
import {
    ActionNotificationComponent,
    DeleteEntityDialogComponent,
    FetchEntityDialogComponent,
    UpdateStatusDialogComponent
} from '../../../partials/content/crud';
// Components
import {ECommerceComponent} from './e-commerce.component';
// Customers
// Products

// Orders

// Material
import {
    MAT_DATE_LOCALE,
    MAT_DIALOG_DEFAULT_OPTIONS,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatTooltipModule,

} from '@angular/material';
import {NgbDatepickerModule, NgbDropdownModule, NgbProgressbarModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxPermissionsGuard, NgxPermissionsModule} from 'ngx-permissions';
import { MatSlideToggleModule, MatSlideToggle } from '@angular/material/slide-toggle'

import {CustomerAddressEffects} from '../../../../core/e-commerce/_effects/customer-address.effects';
import {CategoryEffects} from '../../../../core/e-commerce/_effects/category.effects';
import {configurationsReducer} from '../../../../core/e-commerce/_reducers/configuration.reducer';
import {ConfigurationEffects} from '../../../../core/e-commerce/_effects/configuration.effects';
import {configurationCharacteristicsReducer} from '../../../../core/e-commerce/_reducers/configuration-characteristic.reducers';
import {ConfigurationCharacteristicEffects} from '../../../../core/e-commerce/_effects/configuration-characteristic.effects';
import {characteristicsReducer} from '../../../../core/e-commerce/_reducers/characteristic.reducers';
import {CharacteristicEffects} from '../../../../core/e-commerce/_effects/characteristic.effects';
import {imagesReducer} from '../../../../core/e-commerce/_reducers/image.reducers';
import {ImageEffects} from '../../../../core/e-commerce/_effects/image.effects';

import {GalleryModule} from '@ngx-gallery/core';
import {LightboxModule} from '@ngx-gallery/lightbox';
import {GallerizeModule} from '@ngx-gallery/gallerize';
import {ordersReducer} from '../../../../core/e-commerce/_reducers/order.reducers';
import {OrderEffects} from '../../../../core/e-commerce/_effects/order.effects';
import {MatDividerModule} from '@angular/material/divider';
import {HomePageModule} from './home-page/home-page.module';
import {ProductsTrendingComponent} from './home-page/products-trending/products-trending.component';
import {CarouselImagesComponent} from './home-page/carousel-images/carousel-images.component';
import {BestSellerService} from '../../../../core/e-commerce/_services/bestSeller.service';
import {ProductsBestSellerComponent} from './home-page/products-best-seller/products-best-seller.component';
import {NewArrivalService} from '../../../../core/e-commerce/_services/newArrival.service';
import {newArrivalsReducer} from '../../../../core/e-commerce/_reducers/newArrival.reducers';
import {ProductsNewArrivalComponent} from './home-page/products-new-arrival/products-new-arrival.component';
import {carouselsReducer} from '../../../../core/home-page/carousel/carousel.reducers';
import {CarouselEffects} from '../../../../core/home-page/carousel/carousel.effects';
import {CdkTableModule} from '@angular/cdk/table';
import {OrdersListDialogComponent} from './orders2/orders-list-dialog/orders-list-dialog.component';
import {MatTableExporterModule} from 'mat-table-exporter';
import {OrderControlComponent} from './orders2/order-control/order-control.component';
import {ClientDetailComponent} from './orders2/order-control/client-detail/client-detail.component';
import {OrderProductsComponent} from './orders2/order-control/order-products/order-products.component';
import {OrderHistoryComponent} from './orders2/order-control/order-history/order-history.component';
import {AddOrderProductDialogComponent} from './orders2/order-control/order-products/add-order-product-dialog/add-order-product-dialog.component';
import {OrderProductDetailsComponent} from './orders2/order-control/order-products/order-product-details/order-product-details.component';
import {PointRelaitComponent} from './point-relait/point-relait.component';
import {BillComponent} from './orders2/bill/bill.component';
import {FooterComponent} from './home-page/footer/footer.component';
import {QuestionAnswerComponent} from './home-page/footer/question-answer/question-answer.component';
import {PromotionComponent} from './home-page/promotion/promotion.component';
import {ConfigurationsEffects} from '../../../../core/e-commerce/_effects/configurations.effects';
import {configurationssReducer} from '../../../../core/e-commerce/_reducers/configurations.reducer';
import {QuestionAnswerEditComponent} from './home-page/footer/question-answer/question-answer-edit/question-answer-edit.component';
import {bestSellsReducer} from '../../../../core/e-commerce/_reducers/bestSells.reducer';
import {BestSellsEffects} from '../../../../core/e-commerce/_effects/bestSells.effects';
import {RelevantCategoriesComponent} from './home-page/relevant-categories/relevant-categories.component';
import {ClipboardModule} from 'ngx-clipboard';
import {OrdersListComponent} from './orders2/orders-list/orders-list.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import {ResizableModule} from 'angular-resizable-element';
import {MatChipsModule} from '@angular/material/chips';
// tslint:disable-next-line:class-name
import { AngularEditorModule } from '@kolkov/angular-editor';
import { RegionComponent } from './region/region.component';
import { RegionEditComponent } from './region/region-edit/region-edit.component';
import {CoreModule} from '../../../../core/core.module';
import {ParcelComponent} from './parcel/parcel.component';
import { ParcelDetailsDialogComponent } from './parcel/parcel-details-dialog/parcel-details-dialog.component';
import { ParcelStatesComponent } from './parcel/parcel-states/parcel-states.component';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import { ArchiveComponent } from './archive/archive.component';
import {FooterFilesComponent} from './home-page/footer/footer-files/footer-files.component';
import {FooterFileService} from '../../../../core/e-commerce/_services/footer-file.service';
import { OrderExcelMonthlyComponent } from './orders2/order-excel-monthly/order-excel-monthly.component';
import { WrongOrdersBillComponent } from './orders2/wrong-orders-bill/wrong-orders-bill.component';
import {MatListModule} from "@angular/material/list";
import {MatButtonToggleModule} from "@angular/material/button-toggle";
import { ApplyPromoCodeComponent } from './orders2/order-control/order-products/apply-promo-code/apply-promo-code.component';
import { ProductsExcelMonthlyComponent } from './orders2/products-excel-monthly/products-excel-monthly.component';
import {DislpayComponent} from "./home-page/dislpay/dislpay.component";
import { LicenseKeysPopUpComponent } from './orders2/order-control/order-products/license-keys-pop-up/license-keys-pop-up.component';
import {trendingConfigurationsReducer} from "../../../../core/e-commerce/_reducers/product-trending.reducer";
import {ProductTrendingEffects} from "../../../../core/e-commerce/_effects/product-trending.effects";
import {bestSellerConfigurationsReducer} from "../../../../core/e-commerce/_reducers/products-best-seller.reducer";
import {ProductsBestSellerEffects} from "../../../../core/e-commerce/_effects/products-best-seller.effects";
import {VenteFlashComponent} from "./home-page/vente-flash/vente-flash.component";
import {OrderExcelProductVenduComponent} from './orders2/order-excel-product-vendu/order-excel-product-vendu.component';
import { OrderArchiveComponent } from './orders2/order-archive/order-archive.component';

const routes: Routes = [
    {
        path: '',
        component: ECommerceComponent,
        // canActivate: [ModuleGuard],
        // data: { moduleName: 'ecommerce' },
        children: [
            {
                path: '',
                redirectTo: 'orders',
                pathMatch: 'full'
            },
            // Archive
            {
                path: 'archive',
                component: ArchiveComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        redirectTo: '/error/403'
                    }
                }
            },

            // Order
            {
                path: 'orders',
                component: OrdersListComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_ORDER', 'READ_ORDER'],
                        redirectTo: '/error/403',
                    },
                    title : 'Commandes'
                }
            },
            // Order
            {
                path: 'orders/client/:id',
                component: OrdersListComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_ORDER', 'READ_ORDER'],
                        redirectTo: '/error/403'
                    },
                    title : 'Commandes > historiques client'
                }
            },
            {
                path: 'PointRelait',
                component: PointRelaitComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_POINTRELAIT', 'READ_POINTRELAIT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'parcels',
                component: ParcelComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        // only: ['ALL_POINTRELAIT', 'READ_POINTRELAIT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'orderArchive',
                component: OrderArchiveComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        // only: ['ALL_POINTRELAIT', 'READ_POINTRELAIT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'parcels/states/:tracking',
                component: ParcelStatesComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        // only: ['ALL_POINTRELAIT', 'READ_POINTRELAIT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'orders/:id',
                component: OrderControlComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_ORDER', 'READ_ORDER'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'addOrder/orderdetail/:id',
                component: OrderControlComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_ORDER', 'READ_ORDER'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'home-page/trending',
                component: ProductsTrendingComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_HOME'],
                        redirectTo: '/error/403',
                    }
                }
            },
            {
                path: 'home-page/best-sellers',
                component: ProductsBestSellerComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_HOME'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'home-page/promotion',
                component: PromotionComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_CLIENT', 'READ_CLIENT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'home-page/VenteFlash',
                component: VenteFlashComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_CLIENT', 'READ_CLIENT'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'home-page/new-arrivals',
                component: ProductsNewArrivalComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_HOME'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'home-page/relevant-categories',
                component: RelevantCategoriesComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_HOME'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'home-page/carousel',
                component: CarouselImagesComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_HOME', 'READ_IMAGE'],
                        redirectTo: '/error/403'
                    }
                }
            },{
                path: 'home-page/display',
                component: DislpayComponent,
            },

            {
                path: 'home-page/footer',
                component: FooterComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_HOME'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'home-page/footer/question-answer',
                component: QuestionAnswerComponent,
                canActivate: [NgxPermissionsGuard],
                data: {
                    permissions: {
                        only: ['ALL_HOME', 'READ_HOME'],
                        redirectTo: '/error/403'
                    }
                }
            },
            {
                path: 'home-page/footer/question-answer/add',
                component: QuestionAnswerEditComponent
            },
            {
                path: 'home-page/footer/question-answer/edit',
                component: QuestionAnswerEditComponent
            },
            {
                path: 'home-page/footer/question-answer/edit/:id',
                component: QuestionAnswerEditComponent
            },
            {
                path: 'home-page/footer/:footerFileType',
                component: FooterFilesComponent,
                data: {
                    title: 'Footer - '
                }
            },
        ]
    }
];

// @ts-ignore
@NgModule({
    imports: [

        MatInputModule,
        MatSortModule,
        MatTableModule,
        AutocompleteLibModule,
        GalleryModule,
        LightboxModule,
        GallerizeModule,
        MatSlideToggleModule,
        DragDropModule,
        CoreModule,
        ResizableModule,
        CommonModule,
        AngularEditorModule,
        PartialsModule,
        HttpClientModule,
        NgxPermissionsModule.forChild(),
        RouterModule.forChild(routes),
        NgbProgressbarModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        MatDialogModule,
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatInputModule,
        MatTableModule,
        MatAutocompleteModule,
        MatRadioModule,
        MatIconModule,
        MatNativeDateModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatCardModule,
        MatPaginatorModule,
        MatChipsModule,
        MatCheckboxModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        MatTabsModule,
        MatTooltipModule,
        MatDividerModule,
        StoreModule.forFeature('products', productsReducer),
        EffectsModule.forFeature([ProductEffects]),
        StoreModule.forFeature('customers', customersReducer),
        EffectsModule.forFeature([CustomerEffects]),
        StoreModule.forFeature('customerAddresses', customerAddressesReducer),
        EffectsModule.forFeature([CustomerAddressEffects]),
        StoreModule.forFeature('categories', categoriesReducer),
        EffectsModule.forFeature([CategoryEffects]),
        StoreModule.forFeature('brands', brandsReducer),
        EffectsModule.forFeature([BrandEffects]),
        StoreModule.forFeature('promoCodes', promoCodesReducer),
        EffectsModule.forFeature([PromoCodeEffects]),
        StoreModule.forFeature('collaborators', collaboratorsReducer),
        EffectsModule.forFeature([CollaboratorEffects]),
        StoreModule.forFeature('configurations', configurationsReducer),
        EffectsModule.forFeature([ConfigurationEffects]),
        StoreModule.forFeature('bestSells', bestSellsReducer),
        EffectsModule.forFeature([BestSellsEffects]),

        StoreModule.forFeature('configuration', configurationssReducer),
        EffectsModule.forFeature([ConfigurationsEffects]),

        StoreModule.forFeature('characteristics', characteristicsReducer),
        EffectsModule.forFeature([CharacteristicEffects]),
        StoreModule.forFeature('configurationCharacteristics', configurationCharacteristicsReducer),
        EffectsModule.forFeature([ConfigurationCharacteristicEffects]),
        StoreModule.forFeature('images', imagesReducer),
        EffectsModule.forFeature([ImageEffects]),
        StoreModule.forFeature('orders', ordersReducer),
        EffectsModule.forFeature([OrderEffects]),
        StoreModule.forFeature('bestSeller', bestSellersReducer),
        EffectsModule.forFeature([BestSellerEffects]),
        StoreModule.forFeature('newArrival', newArrivalsReducer),
        EffectsModule.forFeature([NewArrivalEffects]),

        StoreModule.forFeature('carousels', carouselsReducer),
        EffectsModule.forFeature([CarouselEffects]),
        StoreModule.forFeature('comment', commentReducer1),
        EffectsModule.forFeature([CommentEffects]),

        StoreModule.forFeature('discount', discountsReducer),
        EffectsModule.forFeature([DiscountEffect]),

        StoreModule.forFeature('discountCustomers', customerDiscountReducers),
        EffectsModule.forFeature([DiscountCustomerEffects]),
        StoreModule.forFeature('trending-configuration', trendingConfigurationsReducer),
        EffectsModule.forFeature([ProductTrendingEffects]),

        StoreModule.forFeature('best-seller-configuration', bestSellerConfigurationsReducer),
        EffectsModule.forFeature([ProductsBestSellerEffects]),
        StoreModule.forFeature('categories-popular', categoriesPopularReducer),
        EffectsModule.forFeature([CategoryPopularEffects]),
        HomePageModule,
        CdkTableModule,
        MatTableExporterModule,
        NgbDatepickerModule,
        ClipboardModule,
        ResizableModule,
        PerfectScrollbarModule,
        NgbDropdownModule,
        MatListModule,
        MatButtonToggleModule,
    ],
    providers: [
        ModuleGuard,
        InterceptService,
        DatePipe,

        {provide: MAT_DATE_LOCALE, useValue: 'en-GB'},
        {
            provide: HTTP_INTERCEPTORS,
            useClass: InterceptService,
            multi: true
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: true,
                panelClass: 'kt-mat-dialog-container__wrapper',
                height: 'auto',
                width: '900px'
            }
        },
        TypesUtilsService,
        LayoutUtilsService,
        HttpUtilsService,
        CustomersService,
        ProductsService,
        BestSellerService,
        NewArrivalService,
        TypesUtilsService,
        LayoutUtilsService,
        FooterFileService,

    ],
    entryComponents: [
        ActionNotificationComponent,
        DeleteEntityDialogComponent,
        FetchEntityDialogComponent,
        UpdateStatusDialogComponent,
        OrderExcelMonthlyComponent,
    ],

    declarations: [
        // Address
        ECommerceComponent,
        // Customers
        OrdersListComponent,
        OrdersListDialogComponent,
        PointRelaitComponent,
        OrderControlComponent,
        ClientDetailComponent,
        OrderProductsComponent,
        OrderHistoryComponent,
        AddOrderProductDialogComponent,
        OrderProductDetailsComponent,
        BillComponent,
        RegionComponent,
        RegionEditComponent,
        ParcelComponent,
        ParcelDetailsDialogComponent,
        ParcelStatesComponent,
        ArchiveComponent,
        OrderExcelMonthlyComponent,
        WrongOrdersBillComponent,
        ApplyPromoCodeComponent,
        ProductsExcelMonthlyComponent,
        LicenseKeysPopUpComponent,
        OrderExcelProductVenduComponent,
        OrderArchiveComponent,


        //DateBtmComponent,
    ]
})
export class ECommerceModule {
}
