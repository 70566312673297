import {createFeatureSelector, createSelector} from "@ngrx/store";
import {each} from "lodash";
import {HttpExtenstionsModel, QueryResultsModel} from "../../_base/crud";
import {SmsHistoryDetailsState} from "../ek-reducers/sms-history-details.reducers";
import {SmsHistoryModel} from "../ek-models/sms-history-model";


export const selectSmsDetailsState = createFeatureSelector<SmsHistoryDetailsState>('history-sms-details');

export const selectSmsById = (smsId: number) => createSelector(
    selectSmsDetailsState,
    SmsDetailsState => {
        if (SmsDetailsState) return SmsDetailsState.entities[smsId];
    }
);

export const selectSmsDetailsPageLoading = createSelector(
    selectSmsDetailsState,
    SmsDetailsState => SmsDetailsState.listLoading
);

export const selectError = createSelector(
    selectSmsDetailsState,
    SmsDetailsState => SmsDetailsState.error
);

export const selectLastAction = createSelector(
    selectSmsDetailsState,
    SmsDetailsState => SmsDetailsState.lastAction
);


export const selectSmsDetailsActionLoading = createSelector(
    selectSmsDetailsState,
    SmsDetailsState => SmsDetailsState.actionsLoading
);

export const selectLastCreatedSmsId = createSelector(
    selectSmsDetailsState,
    SmsDetailsState => SmsDetailsState.lastCreatedSmsId
);

export const selectSmsDetailsShowInitWaitingMessage = createSelector(
    selectSmsDetailsState,
    SmsDetailsState => SmsDetailsState.showInitWaitingMessage
);

export const selectSmsDetailsPageLastQuerys = createSelector(
    selectSmsDetailsState,
    SmsDetailsState => SmsDetailsState.lastQuery
);

export const selectSmsDetailsInStore = createSelector(
    selectSmsDetailsState,
    SmsDetailsState => {
        const items: SmsHistoryModel[] = [];
        each(SmsDetailsState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: SmsHistoryModel[] = httpExtension.sortArray(items, SmsDetailsState.lastQuery.sortField, SmsDetailsState.lastQuery.sortOrder);
        return new QueryResultsModel(result, SmsDetailsState.totalCount, '');
    }
);
