import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient, HttpParams} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";
import {PromotionCodeModel} from "../_models/promotionCode.model";
import {Page} from "../../_base/crud/models/page";


@Injectable()
export class PromoCodeService extends AbstractService<PromotionCodeModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}promoCode`;
    }


    public isPromoCodePossible(name: string, id: number): Observable<any> {
        const url = `${this.url}/isPromoCodePossible/${name}/${id}`;
        return this.http.get<Response<PromotionCodeModel>>(url).pipe(
            map(res => {
                if (res) {
                    return res;
                }
            })
        );
    }

    public savePromoCode(entity: any) {
        return this.http.post<Response<any>>(this.url, entity);
    }

    public changeActivatePromoCode(id: number, activate: boolean, updater: string): Observable<Response<PromotionCodeModel>> {
        const url = `${this.url}/${id}/${activate}/${updater}`;
        return this.http.patch<Response<PromotionCodeModel>>(url, "").pipe(
            map((response: Response<PromotionCodeModel>) => {
                if (response.body) {
                    return response;
                } else {
                    throw new Error();
                }
            })
        );
    }

    public getAllPagesSearch(query: string = '', sorting: string, direction: string = 'desc', pageNumber: number = 0, pageSize: number = 100, promoType: string = '', state: string = '', startingDate: string, endingDate: string): Observable<Page<PromotionCodeModel>> {

        let params = new HttpParams();

        params = params.append('query', query);
        params = params.append('direction', direction);
        params = params.append('pageNo', pageNumber.toString());
        params = params.append('pageSize', pageSize.toString());
        params = promoType ? params.append('type', promoType) : params;
        params = state ? params.append('etat', state) : params;
        params = startingDate ? params.append('startingDate', startingDate) : params;
        params = endingDate ? params.append('endingDate', endingDate) : params;


        const options = {params};

        const url = `${this.url}/search`;
        return this.http.get<Response<Page<PromotionCodeModel>>>(url, options).pipe(map(({body}) => body));
    }

    public filterPromoByDate(page: number = 0, size: number = 100, startDate: string, endDate: string): Observable<Page<PromotionCodeModel>> {

        let params = new HttpParams();

        params = startDate ? params.append('date1', startDate) : params;
        params = endDate ? params.append('date2', endDate) : params;
        params = page ? params.append('pageNumber', page.toString()) : params;
        params = size ? params.append('pageSize', size.toString()) : params;

        const options = {params};

        const url = `${this.url}/filter`;
        return this.http.get<Response<Page<PromotionCodeModel>>>(url, options).pipe(map(({body}) => body));
    }

    public deletePromoCode(id: number): Observable<Response<any>> {
        const url = `${this.url}/${id}`;
        return this.http.delete<Response<any>>(url).pipe(
            map((response: any) => {
                if (response) {
                    return response;
                } else {
                    throw new Error();
                }
            })
        )
    }
}
