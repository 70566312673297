import {Component, Inject, OnInit} from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE , MAT_DIALOG_DATA} from "@angular/material";
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter} from "@angular/material-moment-adapter";
import {MY_FORMATS, WrongOrders} from "../order-excel-monthly/order-excel-monthly.component";
import {OrdersService} from "../../../../../../core/e-commerce/_services/orders.service";
import {DatePipe} from "@angular/common";
import {MatDialogRef} from "@angular/material/dialog";
import {finalize} from "rxjs/operators";
import * as XLSX from "xlsx";
import {FormControl, FormGroup} from "@angular/forms";
import {log} from "util";

@Component({
  selector: 'kt-products-excel-monthly',
  templateUrl: './products-excel-monthly.component.html',
  styleUrls: ['./products-excel-monthly.component.scss'],
  providers: [

    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ProductsExcelMonthlyComponent implements OnInit {
  startDate =  new Date();
  endDate =  new Date();
  dateFilter = new FormGroup({
    startDate: new FormControl(''),
    endDate: new FormControl(''),
  });
  dateSystem = new Date();
  private monthSysytem: number;
  public myDate = new Date();
  systemDate: Date;
  laodingExcel = false;
  messageSuccess = true;
  constructor(private dialogRef: MatDialogRef<ProductsExcelMonthlyComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private orderService: OrdersService,
              private datePipe: DatePipe
  ) {
  }
  date: Date;
  error: string = '';
  wrongOrders: WrongOrders [];
  fileName = "export-wrong-orders-monthly.xlsx";

  ngOnInit(): void {
    this.datePipe.transform(this.dateSystem,"yyyy-MM-dd");
    this.monthSysytem  = this.dateSystem.getMonth();
  }
  /**
  monthSelected(event, dp, input) {
    dp.close();
    input.value = event.toISOString().split('-').join('/').substr(0, 7);
    const d = new Date();
    d.setFullYear(input.value.toString().substr(0, 4))
    d.setMonth(input.value.toString().substr(5, 6));
    d.setMonth(d.getMonth()-1);
    this.date = d;
    this.error = '';
  }**/

  exportProductsExcelMonthly() {
    this.startDate =    new Date(this.dateFilter.get('startDate').value),
        this.endDate =    new Date(this.dateFilter.get('endDate').value),
    this.laodingExcel=true;
    this.orderService.ExportExcelProductsMonthly(this.startDate,  this.endDate).pipe(
        finalize(()=>this.laodingExcel=false)).subscribe((response) => {
          if (response.message == 'success' && response.body) {
            const byteCharacters = atob(response.body);
            const byteNumbers = new Array(byteCharacters.length);
            for (let i = 0; i < byteCharacters.length; i++) {
              byteNumbers [i] = byteCharacters.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            let blob = new Blob([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(blob);
              return;
            }
            const url = window.URL.createObjectURL(blob);
            const anchor = document.createElement('a');
            anchor.download = `ProduitsVendu.xlsx`;
            anchor.href = url;

            anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
            this.laodingExcel=false;
            setTimeout(function () {
              window.URL.revokeObjectURL(url);

              anchor.remove();

            }, 100)
          }
        },
    );
  }
  /**
    if (this.date) {

      if(this.date.getMonth() < this.monthSysytem){
        this.startDate =    new Date(this.dateFilter.get('startDate').value),
            this.endDate =    new Date(this.dateFilter.get('endDate').value),
            console.log('///////////////////')
        this.laodingExcel=true;
        this.orderService.ExportExcelProductsMonthly(this.startDate,  this.endDate).pipe(finalize(()=>this.laodingExcel=false)).subscribe((response) => {
              if (response.message == 'success' && response.body) {
                const byteCharacters = atob(response.body);
                const byteNumbers = new Array(byteCharacters.length);
                for (let i = 0; i < byteCharacters.length; i++) {
                  byteNumbers [i] = byteCharacters.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                let blob = new Blob([byteArray], {type: 'application / vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                  window.navigator.msSaveOrOpenBlob(blob);
                  return;
                }
                const url = window.URL.createObjectURL(blob);
                const anchor = document.createElement('a');
                anchor.download = `Produits.xlsx`;
                anchor.href = url;

                anchor.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
                this.laodingExcel=false;
                setTimeout(function () {
                  window.URL.revokeObjectURL(url);

                  anchor.remove();

                }, 100)
              }
            },
        );
      } else {
        this.error = "Vous devez attendre la fin du mois sélectionné pour pouvoir créer le rapport ou sélectionné le mois qui précède le mois actuel";
      }
    }
    else{
      this.error="Le mois et l'année sont vides "
    }
  }**/
}
