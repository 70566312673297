import {createAction, props} from "@ngrx/store";
import {Address} from "../../e-commerce/_models/address.model";

export const EkAddressCreated = createAction('[Edit Address Dialog] Address On Server Created', props<{address: Address}>());
export const EkAddressCreatedSuccessfully = createAction('[Address API] Address Created successfully',props<{address: Address}>());
export const EkAddressCreationFailed = createAction('[Address API] Address Creation Failed',props<{error: any}>());


export const EkOneAddressDeleted = createAction('[Address List Page] One Address Deleted',props<{id: number}>());
export const EkOneAddressDeletedSuccessfully = createAction('[Address API] One Address Deleted Successfully',props<{id: number}>());
export const EkOneAddressDeleteFailed = createAction('[[Address API] One Address Deleted',props<{error: any}>());

export const EkAddressesRequested = createAction('[Address List Page] Addresses  Requested',props<{customerId: number}>());
export const EkAddressesLoadedSuccessfully = createAction('[Address API] Addresses  Loaded Successfully', props<{addresses: Address[]}>());
export const EkAddressesLoadingFailed = createAction('[Address API] Addresses  Loading Failed',props<{error: any}>());

