import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RegionService} from "../../../../../../core/ek-e-commerce/ek-services/region.service";

@Component({
  selector: 'kt-agency-list-pop-up',
  templateUrl: './agency-list-pop-up.component.html',
  styleUrls: ['./agency-list-pop-up.component.scss']
})
export class AgencyListPopUpComponent implements OnInit {

  listOfAgencies = [];
  constructor(private dialogRef: MatDialogRef<AgencyListPopUpComponent>,
              private regionService: RegionService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit(): void {
    this.regionService.getAgencyListbyRegion(this.data.idRegion).subscribe({
      next : (data) => {
        this.listOfAgencies = data;
      }
    });
  }

  close() {
    this.dialogRef.close();
  }
}
