import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {ImageModel} from "../../../../../../../core/e-commerce/_models/image.model";
import {Update} from "@ngrx/entity";
import {
    ConfigurationUpdated
} from "../../../../../../../core/e-commerce/_actions/configuration.action";
import {LayoutUtilsService, MessageType} from "../../../../../../../core/_base/crud";
import {ImagesService} from "../../../../../../../core/e-commerce/_services/images.service";
import {BehaviorSubject} from "rxjs";
import {ConfigurationCharacteristicModel} from "../../../../../../../core/e-commerce/_models/configuration-characteristic.model";
import {AngularEditorConfig} from "@kolkov/angular-editor";
import {selectConfigurationById} from "../../../../../../../core/e-commerce/_selectors/configuration.selector";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../../core/reducers";
import {ConfigurationModel} from "../../../../../../../core/ek-e-commerce/ek-models/configuration.model";
@Component({
  selector: 'kt-characteristics',
  templateUrl: './characteristics.component.html',
  styleUrls: ['./characteristics.component.scss']
})
export class EkCharacteristicsComponent implements OnInit {
    public selectedImageUrl: string;
    displayedColumns = [ 'name','prix','actions'];
    index: number = 0;
    order_:number =0;
    parentCount = 0;
    configurationId:number;
    configuration:ConfigurationModel;

    imgSended:ImageModel []= [];

    characteristicsSendid : ConfigurationCharacteristicModel [] = [];

    imgs :string [] = [

    ];

    imageToAdd: ImageModel;
    images_ = new BehaviorSubject<ImageModel[]>([]);
    htmlContent:string;
    editorConfig: AngularEditorConfig = {

        editable: true,
        spellcheck: true,
        height: 'auto',
        minHeight: '0',
        maxHeight: 'auto',
        width: 'auto',
        minWidth: '0',
        translate: 'no',
        enableToolbar: true,
        showToolbar: true,
        placeholder: 'Enter description ici......',
        defaultParagraphSeparator: '',
        defaultFontName: 'no',
        defaultFontSize: 'no',
        fonts: [],
        uploadWithCredentials: false,
        sanitize: false,
        toolbarPosition: 'top',
        toolbarHiddenButtons: [
            ['bold', 'insertImage'],
            ['insertVideo']
        ]
    };
    constructor( private imagesService: ImagesService, private layoutUtilsService: LayoutUtilsService,private dialogRef: MatDialogRef<EkCharacteristicsComponent>,private store: Store<AppState>,
                 @Optional() @Inject(MAT_DIALOG_DATA) public data: ConfigurationModel
    ) { }

  ngOnInit(): void {
      this.imageToAdd = new ImageModel();

     this.configurationId = this.data.id;
     if(this.configurationId&&this.configurationId){
         const confSub$ = this.store.select(selectConfigurationById(this.configurationId)).subscribe(
             res => {
                 this.configuration = res;
                 this.htmlContent = res.description;


             }
         );
     }

  }

    saveSpecifique(){
        this.data.images = this.imgSended;
        this.data.description = this.htmlContent;
        this.data.productCharacteristics = this.characteristicsSendid;


        this.dialogRef.close({data:this.data});

    }
    displayCharacteristique(char){
        this.characteristicsSendid = char;

    }
    displayImage(img){
        this.imgSended = img;

    }

  updateDescription(){
        this.configuration.description = this.htmlContent;
      const updatedConfiguration: Update<ConfigurationModel> = {
          id: this.configurationId,
          changes: this.configuration
      };

      this.store.dispatch(ConfigurationUpdated({
          partialConfiguration: updatedConfiguration,
          configuration: this.configuration
      }));
      const message = ` Sauvegarde effectuer avec sucée  `;
      this.layoutUtilsService.showActionNotification(message, MessageType.Create, 10000, true, true);
  }


    exist(){
        this.dialogRef.close();
    }

}


