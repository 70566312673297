
import { QueryResultsModel, BaseDataSource } from '../../_base/crud';
// State
import { Store, select } from '@ngrx/store';
import { AppState } from '../../reducers';
// Selectors
import {selectTrendingPageLoading,selectTrendingInStore,selectTrendingInitWaitingMessage} from "../_selectors/product-trending.selector";

export class ProductTrendingDataSource extends BaseDataSource {
    constructor(private store: Store<AppState>) {
        super();
        this.loading$ = this.store.pipe(
            select(selectTrendingPageLoading)
        );

        this.isPreloadTextViewed$ = this.store.pipe(
            select(selectTrendingInitWaitingMessage)
        );

        this.store.pipe(
            select(selectTrendingInStore)
        ).subscribe((response: QueryResultsModel) => {
            this.paginatorTotalSubject.next(response.totalCount);
            this.entitySubject.next(response.items);
        });
    }
}
