import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FooterFilesModel, FooterFileType} from "../../../../../../../core/e-commerce/_models/footerFiles.model";
import {MatTableDataSource} from "@angular/material/table";
import {FooterFileService} from "../../../../../../../core/e-commerce/_services/footer-file.service";
import {MatSort} from "@angular/material/sort";
import {MatPaginator} from "@angular/material/paginator";
import {SelectionModel} from "@angular/cdk/collections";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";

@Component({
    selector: 'kt-legal-mention',
    templateUrl: './footer-files.component.html',
    styleUrls: ['./footer-files.component.scss']
})
export class FooterFilesComponent implements OnInit {

    displayedColumns = ["select", "Date", 'fileName', 'state', 'action'];
    dataSource: MatTableDataSource<FooterFilesModel> = new MatTableDataSource([]);
    @ViewChild(MatPaginator) paginator: MatPaginator;
    footerFile: string;
    pageSize = 100;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    totalElements: number;
    @ViewChild("fileUpload", {static: false}) fileUpload: ElementRef;
    private fileType;
    private footerTitle ;
    // private legalTitle  = "Mentions légales";
    // private conditionTitle  = "Mentions légales";
    checked: boolean = true;
    radioSelected :Boolean ;

    files_print_multi: FooterFilesModel [] = [];
    checkedFiles = [];
    footerFileType :FooterFileType;

    canEdit = false;
    files: any[] = [];
    footerFiles : FooterFilesModel[] =[];
    selection = new SelectionModel<FooterFilesModel>(true, []);


    private _isChrome: boolean = false;
    private _isSafari: boolean = false;

    constructor(private footerFileService: FooterFileService, private activatedRoute: ActivatedRoute,public router:Router) {
    }

    ngOnInit(): void {
        this.activatedRoute.paramMap.subscribe((res: ParamMap) => {
            this.fileType = res.get("footerFileType");
            // this.footerTitle = this.activatedRoute.snapshot.data['title'];
            if (res.get("footerFileType") === "legal-mention") {
                this.getAllFooterFiles(FooterFileType.LegalMention);
                // this.footerFile = this.footerTitle + this.legalTitle;
            }

            if ((res.get("footerFileType") === "general-condition")) {
                this.getAllFooterFiles(FooterFileType.GeneralCondition);
                this.footerFile = this.footerTitle + 'Conditions Générale';
            }
        });
    }

    getAllFooterFiles(footerFileType: FooterFileType) {
        this.footerFileService.filterByType(footerFileType).subscribe(res => {
            this.dataSource.data = res;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        });
    }

    uploadfiles(file) {
        this.activatedRoute.paramMap.subscribe((res: ParamMap) => {
            this.fileType = res.get("footerFileType");

            if (res.get("footerFileType") === "legal-mention") {
                this.footerFileService.updateLegalMention(file, FooterFileType.LegalMention).subscribe(() => {
                    this.getAllFooterFiles(FooterFileType.LegalMention);
                });
            }

            if ((res.get("footerFileType") === "general-condition")) {
                this.footerFileService.updateLegalMention(file, FooterFileType.GeneralCondition).subscribe(() => {
                    this.getAllFooterFiles(FooterFileType.GeneralCondition);
                });
            }
        });
    }

    deleteFooterFile(id: number) {
        this.footerFileService.delete(id).subscribe(res => {
            this.getAllFooterFileType();
        });
    }

    onChange(value,file :FooterFilesModel) {
        if (value.checked) {
            this.footerFileService.changeActivateFile(file.id).subscribe(
                res => {
                    this.getAllFooterFileType();
            });
        }
    }

    getAllFooterFileType(){
        this.activatedRoute.paramMap.subscribe((res: ParamMap) => {
            this.fileType = res.get("footerFileType");
            if (res.get("footerFileType") === "legal-mention") {
                this.getAllFooterFiles(FooterFileType.LegalMention);
            }
            if ((res.get("footerFileType") === "general-condition")) {
                this.getAllFooterFiles(FooterFileType.GeneralCondition);
            }
        });
    }

    public download(downloadUrl: string): void {
        window.open(downloadUrl, '_self');
    }

    /**
     * Check all rows are selected
     */
    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.footerFiles.length;
        return numSelected === numRows;
    }

    /**
     * Selects all rows if they are not all selected; otherwise clear selection
     */
    masterToggle() {
        if (this.isAllSelected()) {
            this.selection.clear();
        } else {
            this.dataSource.data.forEach(row => this.selection.select(row));
        }
    }


    selectRow(files: FooterFilesModel ) {
        if (this.checkedFiles.includes(files)) {
            const index = this.checkedFiles.indexOf(files, 0);
            if (index > -1) {
                this.checkedFiles.splice(index, 1);
                this.files_print_multi.splice(index, 1);
            }
        } else {
            this.checkedFiles.push(files);
            this.files_print_multi.push(files);
        }
    }

    public downloadMultipleFiles(){
        for (let i = 0; i < this.files_print_multi.length; i++) {
                var links  = window.location.href = this.files_print_multi[i].url;
                window.open(links)
                window.close();
        }
    }

    getItemCssClassByStatus(status: boolean = false): string {
        switch (status) {
            case true:
                return 'success';
            case false:
                return 'metal';
        }
        return '';
    }
}

