import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {QueryParamsModel} from '../../_base/crud';
import {Response} from '../../_base/crud/models/response';
import {Action, createReducer, on} from '@ngrx/store';
import * as Commentactions from '../_actions/comment.action';
import {CommentModel} from '../_models/comment.model';
import * as ConfigurationsActions from "../_actions/configurations.actions";
import {log} from "util";


// Actions

export interface CommentState extends EntityState <CommentModel>{
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedCommentId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
    progress: number;
}
export const adapter: EntityAdapter<CommentModel> = createEntityAdapter<CommentModel>();

export const initialCommentState: CommentState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedCommentId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
    progress: 0,
});

export const commentReducer = createReducer(
    initialCommentState,
    on(Commentactions.CommentsPageRequested,
        (state, action) => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: '',
            lastQuery: action.page
        })),
on(Commentactions.CommentsUploadProgressed,
        (state, action) => ({...state, progress: action.progress})),
    on(Commentactions.CommentsPageLoadedSuccessfully,
        (state, action
        ) => (adapter.addAll(action.comments,
            {
                ...state,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                totalCount: action.totalCount,

            }))),
    on(Commentactions.CommentsPageLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    //creation
    on(Commentactions.CommentsCreated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(Commentactions.CommentsCreatedSuccessfully,
        (state, action) => (adapter.addOne(action.Comment, {
            ...state,
            actionsLoading: false,

            lastCreatedCommentId:action.Comment.id,
          //  lastAction: '.ADD_MESSAGE'
        }))),
    on(Commentactions.CommentsCreationFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),

    on(Commentactions.CommentsUpdated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(Commentactions.CommentsUpdatedSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialComments, {
                ...state,
                actionsLoading: false,
                lastAction: 'EDIT.UPDATE_MESSAGE'
            }))),
    on(Commentactions.CommentsUpdateFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
   //datefilter

    on(Commentactions.CommentsFilterByDateRequested,
        state => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: ''
        })),
    on(Commentactions.CommentsFilterByDateLoadedSuccessfully,
        (state, action) => (adapter.addAll(action.Comment,
            {
                ...state,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                totalCount: action.totalCount,
                lastQuery:action.lastRequest
            }))),
    on(Commentactions.CommentsFilterByDateLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
//search

    on(Commentactions.CommentsSearchRequested,
        state => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: ''
        })),
    on(Commentactions.CommentsSearchLoadedSuccessfully,
        (state, action) => (adapter.addAll(action.Comment,
            {
                ...state,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                totalCount: action.totalCount,
                lastQuery:action.lastRequest
            }))),
    on(Commentactions.CommentsSearchLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    //Delete image
    on(Commentactions.ImageDeleted,
        (state, action) => ({...state, actionsLoading: true, lastAction: ''})),
    on(Commentactions.ImageDeletedSuccessfully,
        (state, action) => (adapter.removeOne(action.Id, {
            ...state,
            actionsLoading: false,
            lastAction: 'DELETE_IMAGE.DELETE_SUCCESS'
        }))),
    on(Commentactions.ImageDeleteFailed,
        (state, action) => ({
            ...state, error: action.error, actionsLoading: false})),
//delete comment
    on(Commentactions.CommentsDeleted ,
    (state, action) => ({...state, actionsLoading: true, lastAction: ''})),
    on(Commentactions.CommentsDeletedSuccessfully,
        (state, action) => ({     ...state,
            actionsLoading: false,
            lastAction: 'DELETE_COMMENT.DELETE_SUCCESS'})),
    on(Commentactions.CommentsDeleteFailed,
        (state, action) => ({
            ...state, error: action.error, actionsLoading: false})),

);
export function commentReducer1( state: CommentState | undefined, action: Action) {
    return commentReducer(state, action);
}



