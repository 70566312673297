import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {CallModel} from "../../../../../../../core/e-commerce/_models/call.model";
import {UserService} from "../../../../../../../core/services/user.service";
import {OrderModel2} from "../../../../../../../core/e-commerce/_models/order2.model";
import {FormControl} from "@angular/forms";
import {OrdersService} from "../../../../../../../core/e-commerce/_services/orders.service";
import {MatTableDataSource} from "@angular/material/table";
import {CallService} from "../../../../../../../core/e-commerce/_services/calls.service";
import {NgxPermissionsService} from "ngx-permissions";
import {LayoutUtilsService, MessageType} from "../../../../../../../core/_base/crud";
import {ParcelService} from "../../../../../../../core/e-commerce/_services/parcel.service";
import {MatSnackBar} from "@angular/material";
import {OrderItemModel} from "../../../../../../../core/e-commerce/_models/OrderItem.model";
import {MatDialog, MatDialogConfig, MatDialogRef} from "@angular/material/dialog";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../../core/reducers";
import {OrderPrecedentStateChangeRequested} from "../../../../../../../core/e-commerce/_actions/order.action";
import {Actions, ofType} from "@ngrx/effects";
import {OrderActions} from "../../../../../../../core/e-commerce";

@Component({
    selector: 'kt-order-history',
    templateUrl: './order-history.component.html',
    styleUrls: ['./order-history.component.scss']
})
export class OrderHistoryComponent implements OnInit {

    @ViewChild('reasonForCancelContainer') cancelReasonDialog: TemplateRef<any>;
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    order: OrderModel2;
    userId = JSON.parse(localStorage.getItem('currentUser')).id;
    // Table fields
    dataSource = new MatTableDataSource<CallModel>();
    displayedColumns = ['date', 'time', 'caller', 'action', 'status',];
    orderStatus = new FormControl();
    remarkStatus = new FormControl();
    modeEditReason: boolean = false;
    //orderStatusList: string[] = ['NOT_PAID', 'CONFIRMED', 'NOT_PAID_IN_DELIVERY', 'PAID_DELIVERED', 'NOT_PAID_RETURNED', 'CANCELED'];
    showPrecedent: boolean;
    clientIsReplied = false;
    private cancelReasonDialogRef: MatDialogRef<TemplateRef<any>>;

    //Permissions
    PERMISSIONS = ['ALL_ORDER', 'UPDATE_ORDER'];
    canEdit = false;
    roles: string [] = [];
    isSuperAdmin = false;
    isAdmin = false;

    constructor(private orderService: OrdersService,
                private userService: UserService,
                private changeDetectorRefs: ChangeDetectorRef,
                private callService: CallService,
                private ngxPermissionService: NgxPermissionsService,
                private layoutUtilsService: LayoutUtilsService,
                private dialog: MatDialog,
                private parcelService: ParcelService,
                private snackBar: MatSnackBar,
                private store: Store<AppState>,
                private actions$: Actions
    ) {
    }

    ngOnInit() {
        this.roles = JSON.parse(localStorage.getItem('currentUser')).roles;
        this.roles.forEach(role => {
            if (role.includes('ROLE_SUPERADMIN'))
                this.isSuperAdmin = true
            if (role.includes('ROLE_ADMIN'))
                this.isAdmin = true;
        });
        this.orderService.selectedOrder$.subscribe(order => {
            this.order = order;
            this.orderStatus.setValue(this.order.state);
            this.remarkStatus.setValue(this.order.remarkStatus);
            (order.state === 'NOT_PAID' || order.state === 'NOT_PAID_NOT_DELIVERY') ? this.showPrecedent = false : this.showPrecedent = true;

            // retrieve callers name
            this.order.calls.forEach(c => {
                this.userService.findById(c.callerId).subscribe(name => {
                    c.callerName = name;
                    this.changeDetectorRefs.detectChanges();
                });
            })

            this.checkPermissionToUpdate();

            this.dataSource.data = this.order.calls;
            this.changeDetectorRefs.detectChanges();
        });
    }


    changeOrderStatus(status: string) {
        switch (status) {
            case 'PREPARE' :
                this.orderService.changeOrderState(this.order.id, 'DELAY_PAYMENT').subscribe(e => this.updateOrderState(e.body));
                break;
            case 'CONFIRMED' :
                this.orderService.changeOrderState(this.order.id, 'CONFIRM').subscribe(e => this.updateOrderState(e.body));
                break;
            case 'NOT_PAID_PREPARED' :
                this.orderService.changeOrderState(this.order.id, 'PREPARE').subscribe(e => this.updateOrderState(e.body));
                break;
            case 'NOT_PAID_IN_DELIVERY' :
                this.orderService.changeOrderState(this.order.id, 'PREPARE_DELIVERY').subscribe(e => this.updateOrderState(e.body));
                break;
            case 'PAID_DELIVERED' :
                if (this.order.delivery.deliveryType == 'RECUPERATION_AU_NIVEAU_DU_MAGASIN') {
                    this.orderService.changeOrderState(this.order.id, 'DELIVER').subscribe(e => this.updateOrderState(e.body));
                    break;
                }
                this.orderService.changeOrderState(this.order.id, 'DELIVER_AND_PAY').subscribe(e => this.updateOrderState(e.body));
                break;
            case 'NOT_PAID_RETURNED' :
                this.orderService.changeOrderState(this.order.id, 'RETURN').subscribe(e => this.updateOrderState(e.body));
                break;
        }
    }


    openCancelReasonDialog(isReplied: boolean) {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.restoreFocus = false;
        dialogConfig.autoFocus = false;
        dialogConfig.role = 'dialog';
        this.clientIsReplied = isReplied;
        this.cancelReasonDialogRef = this.dialog.open(this.cancelReasonDialog, dialogConfig);
        this.cancelReasonDialogRef.afterClosed().subscribe(res => {
            if (this.remarkStatus.value == null) {
                const message = `Motif d'annulation est obligatoire pour annuler la commande!`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
            }
        });
    }


    changeOrderRemarkStatus(close?: boolean) {
        this.orderService.changeOrderRemarkState(this.order.id, this.remarkStatus.value).subscribe(res => {
            if (res) {
                this.modeEditReason = false;

                //change order state to canceled state
                this.orderService.changeOrderState(this.order.id, 'CANCEL').subscribe(e => {
                    this.updateOrderState(e.body);
                    if (this.clientIsReplied) {
                        this.statusNoAction('Replied', 'CANCELED');
                    } else {
                        this.statusNoAction('Missed', 'CANCELED')
                    }

                });

                const message = `motif statut modifié avec succés.`;
                this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, true);
            }
        });
        if (close) {
            this.cancelReasonDialogRef.close();
        }
    }


    updateOrderState(orderState: string) {
        const order = this.orderService.selectedOrderSubject.value;
        order.state = orderState;
        if (orderState == 'CONFIRMED') {
            order.calls.forEach(cal => {
                cal.callActionType = 'Replied';
            });
        }
        this.orderService.selectedOrderSubject.next(order);
        this.refresh();

    }

    statusNoAction(action, event) {
        const noAction = new CallModel(null, action, this.userId, event, this.order.id);
        this.callService.save(noAction).subscribe(e => {
            this.order.calls.unshift(e);
            this.orderService.selectedOrderSubject.next(this.order)
        })
    }

    /**
     * Disable the form if the used doesnt have the permission to update
     */
    checkPermissionToUpdate() {
        this.ngxPermissionService.hasPermission(this.PERMISSIONS).then(hasPermission => {
            this.canEdit = hasPermission;
        });
    }


    isOptionDisabled(status: string) {

        switch (status) {
            case 'NOT_PAID_PREPARED':
                if (this.order.state === 'NOT_PAID_PREPARED'
                    || this.order.state === 'NOT_PAID'
                    || this.order.state === 'CREATED'
                    || this.order.state === 'NOT_PAID_IN_DELIVERY'
                    || this.order.state === 'PAID_DELIVERED'
                    || this.order.state === 'NOT_PAID_RETURNED'
                    || this.order.state === 'WAITING_FOR_CLIENT'
                    || this.order.state === 'CANCELED'
                    || this.order.state === 'NOT_PAID_NOT_DELIVERY'
                    || this.order.state === 'PAID_NOT_DELIVERED'
                    || this.order.state === 'PAID_FAILED_NOT_DELIVERED') {
                    return true;
                } else return false;
                break;
            case 'CONFIRMED':
                if (this.order.state === 'NOT_PAID_IN_DELIVERY'
                    || this.order.state === 'CONFIRMED'
                    || this.order.state === 'NOT_PAID_PREPARED'
                    || this.order.state === 'PAID_DELIVERED'
                    || this.order.state === 'NOT_PAID_RETURNED'
                    || this.order.state === 'CANCELED'
                    || this.order.state === 'WAITING_FOR_CLIENT'
                ) {
                    return true;
                } else return false;
                break;
            case 'NOT_PAID_IN_DELIVERY':
                if (this.order.delivery.deliveryType == 'DELIVERY_BY_TAXI' || this.order.delivery.deliveryType == 'RECUPERATION_AU_NIVEAU_DU_MAGASIN') {
                    if ((this.order.state === 'NOT_PAID'
                        || this.order.state === 'CREATED'
                        || this.order.state === 'NOT_PAID_IN_DELIVERY'
                        || this.order.state === 'PAID_DELIVERED'
                        || this.order.state === 'NOT_PAID_RETURNED'
                        || this.order.state === 'CANCELED'
                        || this.order.state === 'NOT_PAID_PREPARED'
                        || this.order.state === 'NOT_PAID_NOT_DELIVERY'
                        || this.order.state === 'PAID_NOT_DELIVERED'
                        || this.order.state === 'PAID_FAILED_NOT_DELIVERED')) {
                        return true;
                    } else return false;
                    break;
                } else {
                    if ((this.order.state === 'NOT_PAID'
                        || this.order.state === 'CREATED'
                        || this.order.state === 'NOT_PAID_IN_DELIVERY'
                        || this.order.state === 'CONFIRMED'
                        || this.order.state === 'PAID_DELIVERED'
                        || this.order.state === 'NOT_PAID_RETURNED'
                        || this.order.state === 'CANCELED'
                        || this.order.state === 'NOT_PAID_NOT_DELIVERY'
                        || this.order.state === 'PAID_NOT_DELIVERED'
                        || this.order.state === 'PAID_FAILED_NOT_DELIVERED')) {
                        return true;
                    } else return false;
                    break;
                }
            case 'PAID_DELIVERED':
                if (this.order.delivery.deliveryType == 'RECUPERATION_AU_NIVEAU_DU_MAGASIN') {
                    if (this.order.state === 'NOT_PAID'
                        || this.order.state === 'CREATED'
                        || this.order.state === 'NOT_PAID_PREPARED'
                        || this.order.state === 'PAID_DELIVERED'
                        || this.order.state === 'CANCELED'
                        || this.order.state === 'NOT_PAID_RETURNED'
                        || this.order.state === 'NOT_PAID_NOT_DELIVERY'
                        || this.order.state === 'PAID_NOT_DELIVERED'
                        || this.order.state === 'PAID_FAILED_NOT_DELIVERED'
                    ) {
                        return true;
                    } else return false;
                    break;
                } else {
                    if (this.order.state === 'NOT_PAID'
                        || this.order.state === 'CREATED'
                        || this.order.state === 'NOT_PAID_PREPARED'
                        || this.order.state === 'PAID_DELIVERED'
                        || this.order.state === 'CANCELED'
                        || this.order.state === 'NOT_PAID_RETURNED'
                        || this.order.state === 'CONFIRMED'
                        || this.order.state === 'WAITING_FOR_CLIENT'
                        || this.order.state === 'NOT_PAID_NOT_DELIVERY'
                        || this.order.state === 'PAID_NOT_DELIVERED'
                        || this.order.state === 'PAID_FAILED_NOT_DELIVERED'
                    ) {
                        return true;
                    } else return false;
                    break;
                }
            case 'NOT_PAID_RETURNED':
                if (this.order.state === 'CONFIRMED'
                    || this.order.state === 'NOT_PAID'
                    || this.order.state === 'CREATED'
                    || this.order.state === 'PAID_DELIVERED'
                    || this.order.state === 'CANCELED'
                    || this.order.state === 'NOT_PAID_PREPARED'
                    || this.order.state === 'NOT_PAID_RETURNED'
                    || this.order.state === 'NOT_PAID_NOT_DELIVERY'
                    || this.order.state === 'PAID_NOT_DELIVERED'
                    || this.order.state === 'PAID_FAILED_NOT_DELIVERED'
                ) {
                    return true;
                } else return false;
                break;
            case 'CANCELED':
                if (this.order.state === 'CANCELED'
                    || this.order.state === 'NOT_PAID_IN_DELIVERY'
                    || this.order.state === 'PAID_DELIVERED'
                    || this.order.state === 'NOT_PAID_RETURNED'
                    || this.order.state === 'WAITING_FOR_CLIENT'
                ) {
                    return true;
                } else return false;
                break;
        }
    }

    updateTrackingCode(trackingCode) {
        this.orderService.updateTrackingCode(this.order.id, trackingCode).subscribe(res => {
            if (res.body) {
                const message = `le code tracking a été modifié avec succès `;
                this.layoutUtilsService.showActionNotification(message, MessageType.Create, 5000, false, false);
            }
        })
    }

    addCall(callActionType: string) {
        switch (callActionType) {
            case 'Replied' :
                const callReplied = new CallModel(null, 'Replied', this.userId, this.order.state, this.order.id);
                this.callService.save(callReplied).subscribe(e => {
                    this.order.calls.unshift(e);
                    this.orderService.selectedOrderSubject.next(this.order);
                });
                break;
            case 'Missed' :
                const callMissed = new CallModel(null, 'Missed', this.userId, this.order.state, this.order.id);
                this.callService.save(callMissed).subscribe(e => {
                    this.order.calls.unshift(e);
                    this.orderService.selectedOrderSubject.next(this.order);
                });
                break;
            case 'VoiceMailed' :
                const call = new CallModel(null, 'VoiceMailed', this.userId, this.order.state, this.order.id);
                this.callService.save(call).subscribe(e => {
                    this.order.calls.unshift(e);
                    this.orderService.selectedOrderSubject.next(this.order);
                });
                break;
            case 'Ended' :
                const call3 = new CallModel(null, 'Ended', this.userId, this.order.state, this.order.id);
                this.callService.save(call3).subscribe(e => {
                    this.order.calls.unshift(e);
                    this.orderService.selectedOrderSubject.next(this.order);
                });
                break;
            default:
                break;
        }
    }

    cancelOrder() {
        this.orderService.changeOrderState(this.order.id, 'CANCEL').subscribe((e) => {
            this.updateOrderState(e.body);
            this.addCall('Replied')
        });
    }

    confirmOrder() {

        this.orderService.changeOrderState(this.order.id, 'CONFIRM').subscribe((e) => {
            this.updateOrderState(e.body);
            this.addCall('Replied');
        });

    }

    showStatus(status) {
        switch (status) {
            case 'CREATED':
                return 'En attente';
                break;
            case 'NOT_PAID':
                return 'En attente';
                break;
            case 'CONFIRMED':
                return 'Confirmée';
                break;
            case 'NOT_PAID_PREPARED':
                return 'En préparation';
                break;
            case 'NOT_PAID_IN_DELIVERY':
                return 'En cours de livraison';
                break;
            case 'PAID_DELIVERED':
                return 'Livrée';
                break;
            case 'NOT_PAID_RETURNED':
                return 'Retourné';
                break;
            case 'WAITING_FOR_CLIENT':
                return 'En attente du client';
                break;
            case 'CLIENT_NOT_RESPONDING':
                return 'Client ne répond pas';
                break;
            case 'CANCELED':
                return 'Annulée';
                break;
        }
    }

    showPayStatus(status) {
        switch (status) {
            case 'PAYED':
                return 'Payé';
                break;
            case 'NOT_READY':
                return 'En attente de paiement';
                break;
            case 'FAILED':
                return 'Paiement échoué';
                break;
            default:
                return '';
                break;
        }
    }


    /**
     * add Parcel from Wissal to Yalidine
     */
    addParcel() {
        this.parcelService.addParcel(this.order.id).subscribe(body => {
            if (body != null) {
                this.layoutUtilsService.showActionNotification('le colis est ajouté avec succés !', MessageType.Create, 10000, true, false);
                this.updateOrderState('NOT_PAID_PREPARED');
            } else {
                this.orderService.changeOrderState(this.order.id, 'PREPARE').subscribe(e => this.updateOrderState(e.body));
                this.snackBar.open(' Erreur, colis non crée, veuillez crée ce colis sur Yaliddine, puis copier/coller le code tracking de ce colis ici!  ', '', {
                    duration: 10000,
                    panelClass: ['error-snackbar']
                });


            }
        }, (error) => {
            this.updateOrderState('NOT_PAID_PREPARED');
            this.snackBar.open(' Erreur, colis non crée, veuillez crée ce colis sur Yaliddine, puis copier/coller le code tracking de ce colis ici! ', '', {
                duration: 10000,
                panelClass: ['error-snackbar']
            });
        });
    }

    refresh() {
        this.orderService.getById(this.order.id).subscribe(res => {
            if (res) {
                this.orderService.selectedOrderSubject.next(res);
            }
        });
    }

    sentActivationKey() {
        let orderItemsDone: OrderItemModel [] = [];

        //check if all order items have all licence keys
        this.order.orderItems.forEach(item => {
            if (item.licenseKeyTOSet.length >= 0 && item.licenseKeyTOSet.length === item.quantity) {
                orderItemsDone.push(item);
            }
        });

        //in case there is missing license keys
        if (!orderItemsDone || (orderItemsDone.length !== this.order.orderItems.length)) {
            let message = "Vérifiez que vous avez saisie toutes les clés d'activation pour cette commande!"
            this.layoutUtilsService.showActionNotification(message, MessageType.Update, 10000, true, false);
            return;
        }

        this.orderService.addActivationKey(this.order.orderItems).subscribe(res => {
            if (res != null) {
                this.layoutUtilsService.showActionNotification('les clés d\'activation ont été ajoutées avec succès!', MessageType.Update, 10000, true, false);
                if (this.order.state === 'CONFIRMED') {
                    this.changeStateToDelivered()
                }
                this.refreshOrder();
            }
        });

    }


    refreshOrder() {
        this.orderService.getById(this.order.id).subscribe(res => {
            this.orderService.selectedOrderSubject.next(res);
        });
    }

    changeStateToDelivered() {
        //
        this.orderService.changeOrderState(this.order.id, 'PREPARE_DELIVERY').subscribe(e => {
            this.updateOrderState(e.body);
            this.statusNoAction('NoAction', 'NOT_PAID_IN_DELIVERY')

            if (e.body) {
                //
                this.orderService.changeOrderState(this.order.id, 'DELIVER_AND_PAY').subscribe(e => {
                    this.updateOrderState(e.body);
                    this.statusNoAction('Replied', 'PAID_DELIVERED');
                });
            }
        });

    }

    setPrecedentState(orderId: number, status: string) {
        let event: string;
        let state: string;
        switch (status) {
            case 'PAID_DELIVERED':
                if (this.order.delivery.deliveryType == 'RECUPERATION_AU_NIVEAU_DU_MAGASIN' ||
                    this.order.delivery.deliveryType == 'DELIVERY_BY_TAXI' ||
                    this.order.delivery.deliveryType == 'RECUPERATION_AU_NIVEAU_DU_MAGASIN' ||
                    this.order.delivery.deliveryType == 'NO_DELIVERY') {
                    event = 'VIRTUAL_RETURN_IN_CONFIRMATION'
                    state = 'CONFIRMED'
                } else {
                    event = 'RETURN_IN_PAYMENT'
                    state = 'NOT_PAID_IN_DELIVERY'
                }
                break;
            case 'NOT_PAID_IN_DELIVERY':
                event = 'RETURN_IN_DELIVERY'
                state = 'NOT_PAID_PREPARED'
                break;
            case 'CONFIRMED':
                    event = 'RETURN_IN_CONFIRMATION'
                    state = 'NOT_PAID'
                break;
            case 'NOT_PAID_PREPARED':
                event = 'RETURN_IN_PREPARATION'
                state = 'CONFIRMED'
                break;
            case 'PAID_NOT_DELIVERED':
                event = 'CANCEL_PAYMENT'
                state = 'NOT_PAID_NOT_DELIVERY'
                break;
            case 'CANCELED':
                event = 'RETURN_IN_CANCELLATION'
                state = 'CONFIRMED'
                break;
            case 'NOT_PAID':
                this.showPrecedent = false;
                break;
            case 'CREATED':
                this.showPrecedent = false;
                break;
            case 'NOT_PAID_NOT_DELIVERY':
                this.showPrecedent = false;
                break;
        }
        this.statusNoAction('NoAction', state);
        this.store.dispatch(OrderPrecedentStateChangeRequested({orderId, event}))
        this.actions$.pipe(ofType(OrderActions.OrderPrecedentStateChangeRequestedSucessfuly)).subscribe((data) => {
            if (data) {
                this.updateOrderState(data.state.body);
            }
        });

    }
}
