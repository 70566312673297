import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {ConfigurationService} from "../../../../../../core/e-commerce";
import {Store} from "@ngrx/store";
import {AppState} from "../../../../../../core/reducers";
import {QueryParamsModel, QueryResultsModel} from "../../../../../../core/_base/crud";
import * as ConfigurationsActions from "../../../../../../core/e-commerce/_actions/product-trending.actions";
import {ProductTrendingDataSource} from "../../../../../../core/e-commerce/_data-sources/product-trending.datasource";
import {Update} from "@ngrx/entity";
import {OptimisedTrendingConfModel} from "../../../../../../core/e-commerce/_models/optimised-trending-conf.model";

interface DisplayFilter {
    value: Boolean;
    viewValue: String;
}

@Component({
    selector: 'kt-products-trending',
    templateUrl: './products-trending.component.html',
    styleUrls: ['./products-trending.component.scss']
})
export class ProductsTrendingComponent implements OnInit {
    viewLoading$ = null;
    updater: string;
    totalElements: number;
    pageSize: number;
    filterOptions: DisplayFilter[] = [
        {viewValue: 'Toutes', value: null},
        {viewValue: 'Affichage Activé', value: true},
        {viewValue: 'Affichage désactivé', value: false}
    ];

    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    configuration: OptimisedTrendingConfModel;
    columns = [
        {"columnDef": 'start', header: '', cell: (element: OptimisedTrendingConfModel) => '', selected: true},

        {
            columnDef: 'id',
            header: 'ID',
            type: 'number',
            cell: (element: OptimisedTrendingConfModel) => `${element.id}`,
            selected: true
        },
        {
            columnDef: 'creationDate',
            header: 'Date de création du produit',
            type: 'Date',
            cell: (element: OptimisedTrendingConfModel) => `${element.createdAt}`,
            selected: true
        },
        {
            columnDef: 'name',
            type: '',
            header: 'Nom du produit',
            cell: (element: OptimisedTrendingConfModel) => `${element.name}`,
            selected: true
        },
        {
            columnDef: 'updatedAt',
            type: '',
            header: 'Date de modification',
            cell: (element: OptimisedTrendingConfModel) => `${element?.updatedAt}`,
            selected: true
        },
        {
            columnDef: 'updater',
            type: '',
            header: 'updater',
            cell: (element: OptimisedTrendingConfModel) => `${element.updater}`,
            selected: true
        },
        {
            columnDef: 'actions',
            type: '',
            header: 'Actions',
            selected: true
        },

        {columnDef: 'end', header: '', cell: (element: any) => ``, selected: true},
    ];

    displayedColumns = this.columns.filter(e => e.selected).map(c => c.columnDef);
    dataSource: ProductTrendingDataSource;
    constructor(private configurationService: ConfigurationService,
                private store: Store<AppState>) {}

    ngOnInit(): void {
        this.loadTrendingList();
        this.updater = JSON.parse(localStorage.getItem('currentUser')).username;
        this.dataSource = new ProductTrendingDataSource(this.store);
    }
    loadTrendingList(event?) {
        const queryParams = new QueryParamsModel(
             event ? event?.value : null,
            this.sort.direction,
            this.sort.active,
           0,
            10
        );
        this.store.dispatch(ConfigurationsActions.TrendingPageRequested({page: queryParams}));
    }


    onChange(value, trending: OptimisedTrendingConfModel, updater: string) {
        updater = this.updater;
        const updatedTrending: Update<OptimisedTrendingConfModel> = {
            id: trending.id,
            changes: trending
        };
        this.store.dispatch((ConfigurationsActions.TrendingUpdated({trendingId: trending.id,partialTrending: updatedTrending, display: value.checked, updater: updater})));
    }
}
