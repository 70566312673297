import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {DeliveryAddressModel} from "../_models/delivery-address.model";


@Injectable()
export class DeliveryAddressService extends AbstractService<DeliveryAddressModel> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}delivery-address`;
    }


}
