import {of} from 'rxjs';
// Angular
import {Injectable} from '@angular/core';
// RxJS
import {catchError, map, switchMap} from 'rxjs/operators';
// NGRX
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
// CRUD
import {LayoutUtilsService} from '../../_base/crud';
// Services
import {CustomersService} from "../_services/customers.service";
// State
import {AppState} from '../../../core/reducers';
// Actions
import * as CustomerActions from '../_actions/customer.actions';

@Injectable()
export class CustomerEffects {

    constructor(private actions$: Actions, private customersService: CustomersService, private store: Store<AppState>, private layoutUtilsService: LayoutUtilsService,) {
    }

    CustomersPageRequested$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerActions.CustomersPageRequested),
            switchMap((action) =>
                this.customersService.getAllSorted(action.page.pageNumber, action.page.pageSize, action.page.sortField, action.page.sortOrder, action.page.filter, action.startDate, action.endDate, action.clientStatus)
                    .pipe(
                        map(customersPage => CustomerActions.CustomersPageLoadedSuccessfully(
                            {
                                customers: customersPage.content,
                                page: customersPage.totalElements,
                                totalCount: customersPage.totalElements
                            })),
                        catchError(error => of(CustomerActions.CustomersPageLoadingFailed({error})))
                    ))));

    CustomerCreated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerActions.CustomerCreated),
            switchMap((action) =>
                this.customersService.save(action.customer)
                    .pipe(
                        map(customer => CustomerActions.CustomerCreatedSuccessfully({customer})),
                        catchError(error => of(CustomerActions.CustomerCreationFailed({error})))
                    ))));

    CustomerUpdated$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerActions.CustomerUpdated),
            switchMap((action) =>
                this.customersService.update(action.customer)
                    .pipe(
                        map(customer => CustomerActions.CustomerUpdatedSuccessfully({
                            customer,
                            partialCustomer: action.partialCustomer
                        })),
                        catchError(error => of(CustomerActions.CustomerUpdateFailed({error})))
                    ))));

    CustomerDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerActions.OneCustomerDeleted),
            switchMap((action) =>
                this.customersService.delete(action.id)
                    .pipe(
                        map(() => CustomerActions.OneCustomerDeletedSuccessfully({id: action.id})),
                        catchError(error => of(CustomerActions.OneCustomerDeleteFailed({error})))
                    ))));
}


