// Angular
import { Injectable } from '@angular/core';
// RxJS
import {mergeMap, map, tap, switchMap, catchError} from 'rxjs/operators';
import { defer, Observable, of } from 'rxjs';
// NGRX
import {Effect, Actions, ofType, createEffect} from '@ngrx/effects';
import { Action } from '@ngrx/store';

// Actions
import * as PermissionActions from '../_actions/permission.actions';



@Injectable()
export class PermissionEffects {
    @Effect()
    init$: Observable<Action> = defer(() => {
         return of(PermissionActions.AllPermissionsRequested());
        // return PermissionActions.AllPermissionsRequested;
    });

    constructor(private actions$: Actions) { }
}
