import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ProductModel} from "../../../../../../../../core/e-commerce";
import {ConfigurationModel} from "../../../../../../../../core/e-commerce/_models/configuration.model";
import {OrdersService} from "../../../../../../../../core/e-commerce/_services/orders.service";
import {LayoutUtilsService, MessageType} from "../../../../../../../../core/_base/crud";

@Component({
    selector: 'kt-order-product-details',
    templateUrl: './order-product-details.component.html',
    styleUrls: ['./order-product-details.component.scss']
})
export class OrderProductDetailsComponent implements OnInit {

    public selectedConfiguration: ConfigurationModel;
    public selectedQuantity: number = 1;
    public selectedImageUrl: string;
    addingOrder = false;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: { product: ProductModel, orderId: number, addingOrder: boolean },
        private dialogRef: MatDialogRef<OrderProductDetailsComponent>,
        private layoutUtilsService: LayoutUtilsService,
        private orderService: OrdersService,
    ) {
    }

    ngOnInit(): void {
        this.addingOrder = this.data.addingOrder
        this.selectedConfiguration = this.data.product.configurations[0];
        if (this.selectedConfiguration.images[0])
            this.selectedImageUrl = this.selectedConfiguration.images[0].url;
    }

    selectConfiguration(selectedConfiguration: ConfigurationModel) {
        this.selectedConfiguration = selectedConfiguration;
    }

    //View Control
    selectImage(imageUrl: string) {
        this.selectedImageUrl = imageUrl;
    }

    changeQuantity(quantity: number): number {
        this.selectedQuantity = quantity;
        return quantity;
    }

    checkIfSelected(imgUrl: string): string {
        if (imgUrl == this.selectedImageUrl)
            return 'selected';
        else
            return '';
    }


    submite() {
        if (this.addingOrder) {

            if (this.selectedConfiguration.discount){

                const discountStart = new Date(this.selectedConfiguration.discount.startingDate);
                const discountEnd = new Date(this.selectedConfiguration.discount.endingDate);
                const today = new Date();

                if ((discountEnd >= discountStart) && (today >= discountStart) && (today <= discountEnd)) {
                    this.selectedConfiguration.price = this.selectedConfiguration.price * (1 - (this.selectedConfiguration.discount.value / 100));
                }
            }

            this.dialogRef.close({configuration: this.selectedConfiguration, quantity: this.selectedQuantity})

        } else {
            this.dialogRef.close();
            this.orderService.addProductToOrder(this.data.orderId, this.selectedConfiguration.id, this.selectedQuantity).subscribe((e) => {
                // this.data.orderItems.push(e);
                // this.orderService.getById(this.data.id).subscribe(o => this.orderService.selectedOrderSubject.next(o));
                this.layoutUtilsService.showActionNotification('Le porduit a été ajouté à la commande', MessageType.Create);
            });
        }
    }

    onCancel() {
        this.dialogRef.close();
    }

}
