import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {AbstractService} from "../../services/abstract-service.service";
import {DisplayHomeSections} from "../_models/display-home-sections";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import { Observable } from 'rxjs';
import {Response} from "../../_base/crud/models/response";

@Injectable({
  providedIn: 'root'
})
export class DisplayService extends AbstractService<DisplayHomeSections>{
  protected url: string;
  constructor( http:HttpClient) {
    super(http);
    this.url = `${ADMIN_CONFIG.apiEndpoint}DisplayHomeSections`;  }

    public updateDisplay(id: number , display : boolean): Observable<DisplayHomeSections>{
      const options = new HttpParams().append('display' ,display.toString());
      const url = `${this.url}/updateDisplay/${id}`;
      return this.http.patch<any>(url,options);
    }

    public updateName(id: number , displayName : string): Observable<DisplayHomeSections>{
      const options = new HttpParams().append('displayName' ,displayName.toUpperCase());
      const url = `${this.url}/updatedisplayName/${id}`;
      return this.http.patch<any>(url,options);
    }



}
