// NGRX
import {Action, createAction, props} from '@ngrx/store';
import { Update } from '@ngrx/entity';
// Models
import { CommentModel } from '../_models/comment.model';
import {QueryParamsModel} from "../../_base/crud";


export const CommentsPageRequested = createAction('[Comments List Page] Comments Page Requested ',
    props <{id: number ,page:QueryParamsModel }>());
export const CommentsPageLoadedSuccessfully = createAction('[Comment API] Comments Page Loaded Successfully ',
    props <{comments: CommentModel[],totalCount: number, page: number }>());
export const CommentsPageLoadFailed = createAction('[Comment API] Comments Page Request Failed ', props <{error: any}>());

export const CommentsCreated = createAction('[Comment List Page] Comment Created ', props <{Comment: CommentModel, image: any }>());
export const CommentsCreatedSuccessfully = createAction('[Comment List Page] Comment Created Successfully ',
    props <{Comment: CommentModel}>());
export const CommentsCreationFailed = createAction('[Comment List Page] Comment Creation Failed ', props <{error: any}>());

export const CommentsUpdated = createAction('[CommentList Page] Comment Updated ', props <{Comment: CommentModel,image:any,
    clientName:string, partialComments: Update<CommentModel>}>());
export const CommentsUpdatedSuccessfully = createAction('[Comment List Page] Comment Updated Successfully ',
    props <{Comment: CommentModel, partialComments: Update<CommentModel>}>());
export const CommentsUpdateFailed = createAction('[Comment List Page] Comment Update Failed ', props <{error: any}>());

export const CommentsDeleted = createAction('[Comment List Page] Comment Deleted ', props<{commentId: number}>());
export const CommentsDeletedSuccessfully = createAction('[Comment List Page] Comment deleted Successfully ', props <{commentId: number}>());
export const CommentsDeleteFailed = createAction('[Comment List Page] Comment delete Failed ', props <{error: any}>());
export const CommentsUploadProgressed = createAction('[Comment API] comments Upload Progressed', props<{progress: number}>());

export const CommentsFilterByDateRequested = createAction('[Comments List Page] Comments Page Filtered By Date Requested ',props<{page: QueryParamsModel, date: string, date1: string, id:number}>());
export const CommentsFilterByDateLoadedSuccessfully = createAction('[Comments API] Comments Page Filtered By Date Loaded Successfully ',props<{Comment: CommentModel[],totalCount: number, page: number, lastRequest: QueryParamsModel}>());
export const CommentsFilterByDateLoadFailed = createAction('[Comments API] Comments Page Filtered By Date Request Failed ',props<{error: any}>());

export const CommentsSearchRequested = createAction('[Comments List Page] Comments Page Filtered By Date Requested ',props<{page: QueryParamsModel,  id:number}>());
export const CommentsSearchLoadedSuccessfully = createAction('[Comments API] Comments Page Filtered By Date Loaded Successfully ',props<{Comment: CommentModel[],totalCount: number, page: number, lastRequest: QueryParamsModel}>());
export const CommentsSearchLoadFailed = createAction('[Comments API] Comments Page Filtered By Date Request Failed ',props<{error: any}>());

export const ImageDeleted = createAction('[Image List Page] Image Deleted ', props<{ Id: number }>());
export const ImageDeletedSuccessfully = createAction('[Image List Page] Image deleted Successfully ', props<{ Id: number }>());
export const ImageDeleteFailed = createAction('[Image List Page] Image delete Failed ', props<{ error: any }>());
