// NGRX
import { createFeatureSelector, createSelector } from '@ngrx/store';
// Lodash
import { each } from 'lodash';
// CRUD
import { QueryResultsModel, HttpExtenstionsModel } from '../../_base/crud';
// State

import {NewArrivalState} from "../_reducers/newArrival.reducers";
import {NewArrivalModel} from "../_models/newArrival.model";

export const selectNewArrivalState = createFeatureSelector<NewArrivalState>('newArrival');

export const selectNewArrivalById = (newArrivalId: number) => createSelector(
    selectNewArrivalState,
    newArrivalState => newArrivalState.entities[newArrivalId]
);

export const selectNewArrivalPageLoading = createSelector(
    selectNewArrivalState,
    newArrivalState => newArrivalState.listLoading
);

export const selectNewArrivalActionLoading = createSelector(
    selectNewArrivalState,
    newArrivalState => newArrivalState.actionsLoading
);

export const selectNewArrivalPageLastQuery = createSelector(
    selectNewArrivalState,
    newArrivalState => newArrivalState.lastQuery
);

export const selectLastCreatedNewArrivalId = createSelector(
    selectNewArrivalState,
    newArrivalState => newArrivalState.lastCreatedNewArrivalId
);

export const selectNewArrivalInitWaitingMessage = createSelector(
    selectNewArrivalState,
    newArrivalState => newArrivalState.showInitWaitingMessage
);

export const selectNewArrivalInStore = createSelector(
    selectNewArrivalState,
    newArrivalState => {
        const items: NewArrivalModel[] = [];
        each(newArrivalState.entities, element => {
            items.push(element);
        });
        const httpExtension = new HttpExtenstionsModel();
        const result: NewArrivalModel[] = httpExtension.sortArray(items, newArrivalState.lastQuery.sortField, newArrivalState.lastQuery.sortOrder);
        return new QueryResultsModel(result, newArrivalState.totalCount, '');
    }
);

export const selectHasNewArrivalInStore = createSelector(
    selectNewArrivalInStore,
    queryResult => {
        if (!queryResult.totalCount) {
            return false;
        }

        return true;
    }
);
