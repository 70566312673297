import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {Store} from "@ngrx/store";
import {AppState} from "../../reducers";
import * as CustomerAddressesActions from "../_actions/customer-address.actions";
import {catchError, map, switchMap} from "rxjs/operators";
import {of} from "rxjs";
import {AddressesService} from "../_services/addresses.service";


@Injectable()
export class CustomerAddressEffects {

    constructor(private actions$: Actions, private customerAddressesService: AddressesService, private store: Store<AppState>) {
    }



    CustomerAddressesRequested = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerAddressesActions.AddressesRequested),
            switchMap((action) =>
                this.customerAddressesService.getByClientId(action.customerId)
                    .pipe(
                        map(result => CustomerAddressesActions.AddressesLoadedSuccessfully(
                            {addresses:result})),
                        catchError(error => of(CustomerAddressesActions.AddressesLoadingFailed({error})))
                    ))));

    CustomerAddressCreated$ = createEffect(() =>
       {
           return this.actions$.pipe(
            ofType(CustomerAddressesActions.AddressCreated),
            switchMap((action) =>
                this.customerAddressesService.save(action.address)
                    .pipe(
                        map(address => CustomerAddressesActions.AddressCreatedSuccessfully({address})),
                        catchError(error => of(CustomerAddressesActions.AddressCreationFailed({error})))
                    )))});
    CustomerAddressDeleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CustomerAddressesActions.OneAddressDeleted),
            switchMap((action) =>
                this.customerAddressesService.delete(action.id)
                    .pipe(
                        map(() => CustomerAddressesActions.OneAddressDeletedSuccessfully({id:action.id})),
                        catchError(error => of(CustomerAddressesActions.OneAddressDeleteFailed({error})))
                    ))));

}

