import {createEntityAdapter, EntityAdapter, EntityState} from "@ngrx/entity";
import {ConfigurationModel} from "../_models/configuration.model";
import {QueryParamsModel} from "../../_base/crud";
import {Response} from "../../_base/crud/models/response";
import {Action, createReducer, on} from "@ngrx/store";
import * as ConfigurationActions from "../_actions/configuration.action";


export interface ConfigurationState extends EntityState<ConfigurationModel> {
    listLoading: boolean;
    actionsLoading: boolean;
    totalCount: number;
    lastCreatedConfigurationId: number;
    lastQuery: QueryParamsModel;
    showInitWaitingMessage: boolean;
    error: any;
    lastAction: string;
}

export const adapter: EntityAdapter<ConfigurationModel> = createEntityAdapter<ConfigurationModel>();

export const initialConfigurationState: ConfigurationState = adapter.getInitialState({
    listLoading: false,
    actionsLoading: false,
    totalCount: 0,
    lastCreatedConfigurationId: undefined,
    lastQuery: new QueryParamsModel({}),
    showInitWaitingMessage: true,
    error: Response,
    lastAction: '',
});

const configurationReducer = createReducer(
    initialConfigurationState,
    on(ConfigurationActions.ConfigurationsPageRequested,
        state => ({
            ...state,
            actionsLoading: true,
            listLoading: true,
            showInitWaitingMessage: true,
            error: null,
            lastAction: ''
        })),
    on(ConfigurationActions.ConfigurationsPageLoadedSuccessfully,
        (state, action) => (adapter.addAll(action.configurations,
            {
                ...state,
                actionsLoading: false,
                listLoading: false,
                showInitWaitingMessage: false,
                // totalCount: action.totalCount
            }))),
    //
    // on(ConfigurationActions.ConfigurationsPageLoadedSuccessfully1,
    //     (state, action) => (adapter.addAll(action.configurations,
    //         {
    //             ...state,
    //             actionsLoading: false,
    //             listLoading: false,
    //             showInitWaitingMessage: false,
    //             totalCount: action.totalCount
    //         }))),
    on(ConfigurationActions.ConfigurationsPageLoadFailed,
        (state, action) => ({
            ...state,
            actionsLoading: false,
            listLoading: false,
            showInitWaitingMessage: false,
            error: action.error
        })),
    //creation
    on(ConfigurationActions.ConfigurationCreated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(ConfigurationActions.ConfigurationCreatedSuccessfully,
        (state, action) => (adapter.addOne(action.configuration, {
            ...state,
            actionsLoading: true,
            lastCreatedConfigurationId: action.configuration.id,
            lastAction: 'EDIT.ADD_MESSAGE'
        }))),
    on(ConfigurationActions.ConfigurationCreationFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    //Update
    on(ConfigurationActions.ConfigurationUpdated,
        (state) => ({...state, actionsLoading: true, lastAction: ''})),
    on(ConfigurationActions.ConfigurationUpdatedSuccessfully,
        (state, action) =>
            (adapter.updateOne(action.partialConfiguration, {
                ...state,
                actionsLoading: false,
                lastAction: 'EDIT.UPDATE_MESSAGE'
            }))),
    on(ConfigurationActions.ConfigurationUpdateFailed,
        (state, action) => ({...state, actionsLoading: false, error: action.error})),
    // //Update Activate
    // on(ConfigurationActions.ConfigurationUpdatedActivate,
    //     (state) => ({...state, actionsLoading: true, lastAction: ''})),
    // on(ConfigurationActions.ConfigurationUpdatedActivateSuccessfully,
    //     (state, action) =>
    //         (adapter.updateOne(action.partialConfiguration, {
    //             ...state,
    //             actionsLoading: false,
    //             lastAction: 'EDIT.UPDATE_MESSAGE'
    //         }))),
    // on(ConfigurationActions.ConfigurationUpdateActivateFailed,
    //     (state, action) => ({...state, actionsLoading: false, error: action.error})),
    //Delete
    on(ConfigurationActions.ConfigurationDeleted,
        (state, action) => ({...state, actionsLoading: true, lastAction: ''})),
    on(ConfigurationActions.ConfigurationDeletedSuccessfully,
        (state, action) => (adapter.removeOne(action.configurationId, {
            ...state,
            actionsLoading: false,
            lastAction: 'DELETE.DELETE_CONFIGURATION_SIMPLE.DELETE_SUCCESS'
        }))),
    on(ConfigurationActions.ConfigurationDeleteFailed,
        (state, action) => ({...state, error: action.error, actionsLoading: false}))
);

export function configurationsReducer(state: ConfigurationState | undefined, action: Action) {
    return configurationReducer(state, action)
}
