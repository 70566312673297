// Models
import {User} from '../_models/user.model';
import {Action, createReducer, on} from "@ngrx/store";
import {Token} from "../_models/token";
import * as AuthActions from '../_actions/auth.actions';
// JwtHelper
import {JwtHelperService} from "@auth0/angular-jwt";

export const authFeatureKey = 'auth';


export interface AuthState {
    loggedIn: boolean;
    authToken: Token;
    user: User;
    isUserLoaded: boolean;
    isLoading: boolean;
    error: any;
}

export const initialAuthState: AuthState = {
    loggedIn: haveToken(),
    authToken: getToken(),
    user: undefined,
    isUserLoaded: false,
    isLoading: false,
    error: null,
};

const featureReducer = createReducer(
    initialAuthState,
    on(AuthActions.login,
        state => ({...state, isLoading: true, error: null})),
    on(AuthActions.loginSucceeded,
        (state, {token}) => ({...state, authToken: token, loggedIn: true})),
    on(AuthActions.loginFailed,
        (state, {error}) => ({...state, isLoading: false, error: error})),
    on(AuthActions.getLoggedInUserSucceed,
        (state, {user}) => ({...state, user, isLoading: false, isUserLoaded: true})),
    on(AuthActions.getLoggedInUserFailed,
        (state, {error}) => ({...state, error})),
    on(AuthActions.logout,
        state => ({
            ...state,
            authToken: null,
            loggedIn: false,
            isLoading: false,
            error: null,
            isUserLoaded: false,
            user: null
        }))
);

export function authReducer(state: AuthState | undefined, action: Action) {
    return featureReducer(state, action)
}

export function getToken(): Token {
    const helper = new JwtHelperService();
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken != "null" && accessToken != null) {
        const decodedToken = helper.decodeToken(accessToken);
        return new Token(decodedToken, '', decodedToken.exp, decodedToken.scope, decodedToken.jti);
    } else
        return undefined;

}

export function haveToken(): boolean {
    const helper = new JwtHelperService();
    const token = localStorage.getItem('accessToken');
    if (token == "null")
        return false;

    const isExpired = helper.isTokenExpired(token);
    return (!isExpired);
}

