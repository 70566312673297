import {Component, Input, OnInit} from '@angular/core';
import {OrderModel2} from "../../../../../../core/e-commerce/_models/order2.model";
import {
    CustomerModel,
    CustomersService,
    FooterInformationService,
    ProductModel,
    ProductsService
} from "../../../../../../core/e-commerce";
import {BehaviorSubject, Subscription} from "rxjs";
import {Store} from "@ngrx/store";
import {ActivatedRoute, Router} from "@angular/router";
import {OrdersService} from "../../../../../../core/e-commerce/_services/orders.service";
import {selectOrderById} from "../../../../../../core/e-commerce/_selectors/order.selector";
import {distinctUntilChanged} from "rxjs/operators";

@Component({
  selector: 'kt-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.scss']
})
export class BillComponent implements OnInit {
    order: OrderModel2;
    customer: CustomerModel;
    products: ProductModel[] = [];
    products$ = new BehaviorSubject([])
    date = new Date();
    @Input()order_print: OrderModel2;
    @Input() facture = undefined;

    //private properties
    private subscriptions: Subscription[] = [];
    private orderId: number;
    phoneNumber:string;
    fixNumber:string;


    constructor(
        private store: Store<OrderModel2>,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private customersService: CustomersService,
        private productsService: ProductsService,
        private orderService: OrdersService,
        private footerService: FooterInformationService,
    ) {

    }

    ngOnInit() {

		this.loadWissalInformations();
        // const routerSub = this.activatedRoute.params.subscribe(params => {
        //     this.orderId = + params['orderId'];
        // });
        // this.subscriptions.push(routerSub);
        this.getOrderInfo();
		// this.router.navigate(['/ecommerce/orders-print',this.order_print.id]);
	}
    //todo get the order id from the router
    private getOrderInfo() {

        const orderSub = this.store.select(selectOrderById(this.order_print.id)).subscribe(res => {
            this.order = res;
            if (this.order === undefined) {
                this.getOrderFromService(this.order_print.id);
            }
            else{
                this.getProductInfo();
                this.getCustomerInfo();
            }
        });
        this.subscriptions.push(orderSub);
    }

    private getOrderFromService(orderId: number) {
        this.orderService.getById(orderId).subscribe(res => {
            this.order = res;
            this.getCustomerInfo();
            this.getProductInfo();
        })
    }

    getCustomerInfo() {
        const customerSub = this.customersService.getById(this.order.clientId).subscribe(res => {
            this.customer = res;
        });
        this.subscriptions.push(customerSub)
    }

    getProductInfo() {
        this.order.orderItems.forEach(item => {
            const productSub = this.productsService.getById(item.configurationTO.productId).pipe(distinctUntilChanged(),).subscribe(res => {
                this.products.push(res)
                this.products$.next(this.products)
            });
            this.subscriptions.push(productSub);
        });
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(res => res.unsubscribe());
    }


    loadWissalInformations() {
        this.footerService.getById(1).subscribe(res => {
            this.phoneNumber=res.wissalPhoneNumber;
            this.fixNumber=res.wissalPhoneNumber2;
        });
    }

	convertNumberToWords(nombre, U=null, D=null) {

			var letter = {
				0: "zéro",
				1: "un",
				2: "deux",
				3: "trois",
				4: "quatre",
				5: "cinq",
				6: "six",
				7: "sept",
				8: "huit",
				9: "neuf",
				10: "dix",
				11: "onze",
				12: "douze",
				13: "treize",
				14: "quatorze",
				15: "quinze",
				16: "seize",
				17: "dix-sept",
				18: "dix-huit",
				19: "dix-neuf",
				20: "vingt",
				30: "trente",
				40: "quarante",
				50: "cinquante",
				60: "soixante",
				70: "soixante-dix",
				80: "quatre-vingt",
				90: "quatre-vingt-dix",
			};

			var i, j, n, quotient, reste, nb;
			var ch
			var numberToLetter = '';
			//__________________________________

			if (nombre.toString().replace(/ /gi, "").length > 15) return "dépassement de capacité";
			if (isNaN(nombre.toString().replace(/ /gi, ""))) return "Nombre non valide";

			nb = parseFloat(nombre.toString().replace(/ /gi, ""));
			//if (Math.ceil(nb) != nb) return "Nombre avec virgule non géré.";
			if(Math.ceil(nb) != nb){
				nb = nombre.toString().split('.');
				//return NumberToLetter(nb[0]) + " virgule " + NumberToLetter(nb[1]);
				return this.convertNumberToWords(nb[0]) + (U ? " " + U + " et " : " virgule ") + this.convertNumberToWords(nb[1]) + (D ? " " + D : "");
			}

			n = nb.toString().length;
			switch (n) {
				case 1:
					numberToLetter = letter[nb];
					break;
				case 2:
					if (nb > 19) {
						quotient = Math.floor(nb / 10);
						reste = nb % 10;
						if (nb < 71 || (nb > 79 && nb < 91)) {
							if (reste == 0) numberToLetter = letter[quotient * 10];
							if (reste == 1) numberToLetter = letter[quotient * 10] + "-et-" + letter[reste];
							if (reste > 1) numberToLetter = letter[quotient * 10] + "-" + letter[reste];
						} else numberToLetter = letter[(quotient - 1) * 10] + "-" + letter[10 + reste];
					} else numberToLetter = letter[nb];
					break;
				case 3:
					quotient = Math.floor(nb / 100);
					reste = nb % 100;
					if (quotient == 1 && reste == 0) numberToLetter = "cent";
					if (quotient == 1 && reste != 0) numberToLetter = "cent" + " " + this.convertNumberToWords(reste);
					if (quotient > 1 && reste == 0) numberToLetter = letter[quotient] + " cents";
					if (quotient > 1 && reste != 0) numberToLetter = letter[quotient] + " cent " + this.convertNumberToWords(reste);
					break;
				case 4 :
				case 5 :
				case 6 :
					quotient = Math.floor(nb / 1000);
					reste = nb - quotient * 1000;
					if (quotient == 1 && reste == 0) numberToLetter = "mille";
					if (quotient == 1 && reste != 0) numberToLetter = "mille" + " " + this.convertNumberToWords(reste);
					if (quotient > 1 && reste == 0) numberToLetter = this.convertNumberToWords(quotient) + " mille";
					if (quotient > 1 && reste != 0) numberToLetter = this.convertNumberToWords(quotient) + " mille " + this.convertNumberToWords(reste);
					break;
				case 7:
				case 8:
				case 9:
					quotient = Math.floor(nb / 1000000);
					reste = nb % 1000000;
					if (quotient == 1 && reste == 0) numberToLetter = "un million";
					if (quotient == 1 && reste != 0) numberToLetter = "un million" + " " + this.convertNumberToWords(reste);
					if (quotient > 1 && reste == 0) numberToLetter = this.convertNumberToWords(quotient) + " millions";
					if (quotient > 1 && reste != 0) numberToLetter = this.convertNumberToWords(quotient) + " millions " + this.convertNumberToWords(reste);
					break;
				case 10:
				case 11:
				case 12:
					quotient = Math.floor(nb / 1000000000);
					reste = nb - quotient * 1000000000;
					if (quotient == 1 && reste == 0) numberToLetter = "un milliard";
					if (quotient == 1 && reste != 0) numberToLetter = "un milliard" + " " + this.convertNumberToWords(reste);
					if (quotient > 1 && reste == 0) numberToLetter = this.convertNumberToWords(quotient) + " milliards";
					if (quotient > 1 && reste != 0) numberToLetter = this.convertNumberToWords(quotient) + " milliards " + this.convertNumberToWords(reste);
					break;
				case 13:
				case 14:
				case 15:
					quotient = Math.floor(nb / 1000000000000);
					reste = nb - quotient * 1000000000000;
					if (quotient == 1 && reste == 0) numberToLetter = "un billion";
					if (quotient == 1 && reste != 0) numberToLetter = "un billion" + " " + this.convertNumberToWords(reste);
					if (quotient > 1 && reste == 0) numberToLetter = this.convertNumberToWords(quotient) + " billions";
					if (quotient > 1 && reste != 0) numberToLetter = this.convertNumberToWords(quotient) + " billions " + this.convertNumberToWords(reste);
					break;
			}//fin switch
			/*respect de l'accord de quatre-vingt*/
			if (numberToLetter.substr(numberToLetter.length - "quatre-vingt".length, "quatre-vingt".length) == "quatre-vingt") numberToLetter = numberToLetter + "s";

			return numberToLetter;

		}

}
