// Angular
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
// CRUD
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {AbstractService} from "../../services/abstract-service.service";
import { Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";
import {Page} from "../../_base/crud/models/page";
import {PointRelaitModel} from "../_models/point-relait.model";


@Injectable()
export class PointRelaitService extends AbstractService<PointRelaitModel> {




    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}pointrelait`;
    }



    public getAll(): Observable<PointRelaitModel[]> {
        const url = `${this.url}`;
        const res =  this.http.get<Response<PointRelaitModel[]>>(url).pipe(map(({body}) =>
            body));
        res.subscribe(res =>{
           // console.log(JSON.stringify(res));
        })
        return  res;
    }

    public updateDiscountById(pointRelaitId: number, discountHome: number,discountDesk : number, updater: string): Observable<Response<PointRelaitModel>> {
        const url = `${this.url}/updatediscountbyid/${pointRelaitId}/${discountHome}/${discountDesk}/${updater}`;
        return this.http.put<Response<PointRelaitModel>>(url,"").pipe(
            map((response: Response<PointRelaitModel>) => {
                if (response.body) {
                    return response;
                } else {
                    throw new Error();
                }
            })
        );
    }

}