import {BaseModel} from "../../_base/crud";

export enum EkFooterFileType {
    LegalMention='LegalMention',
    GeneralCondition='GeneralCondition'
}

export class EkFooterFilesModel extends BaseModel{
    id: number;
    status: string;
    fileName: string;
    url: string;
    type: EkFooterFileType;
}


