// Angular
import {Component, Input, OnInit} from '@angular/core';
// RxJS
import {BehaviorSubject, Observable, of} from 'rxjs';
// NGRX
import {Store} from '@ngrx/store';
// Lodash
import {each, find, remove} from 'lodash';
// State
import {AppState} from '../../../../../core/reducers';
// Auth
import {Role, User} from '../../../../../core/auth';
import {RolesTable} from "../../../../../core/auth/_server/roles.table";
import {NgxPermissionsService} from "ngx-permissions";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "../../../../../core/auth/_services/user.service";

@Component({
    selector: 'kt-ek-user-roles',
    templateUrl: './ek-user-roles.component.html',
    styleUrls: ['./ek-user-roles.component.scss']
})
export class EkUserRolesComponent implements OnInit {

    // Public properties
    // Incoming data
    @Input() loadingSubject = new BehaviorSubject<boolean>(false);
    @Input() rolesSubject: BehaviorSubject<string>;
    @Input() User: User
    // Roles
    allUserRoles$: Observable<Role[]>;
    allRoles: Role[] = [];
    unassignedRoles: Role[] = [];
    prevAssignedRole: Role = null;
    roleIdForAdding: string;

    /**
     * Component constructor
     *
     * @param store: Store<AppState>
     */
    constructor(private store: Store<AppState>,
                private activatedRoute: ActivatedRoute,
                private userService: UserService,
                private ngxPermissions: NgxPermissionsService) {
    }

    /**
     * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
     */

    /**
     * On init
     */
    ngOnInit() {
        const id = this.activatedRoute.snapshot.paramMap.get('id');
        let num = Number(id);

        this.userService.getUserById(num).subscribe({
            next: (res) => {

                let userRole = res.roles;

                this.rolesSubject.next(userRole);

                //prev role...
                this.prevAssignedRole = RolesTable.roles.find(role => role.id === userRole);
            }
        });

        this.ngxPermissions.hasPermission("ROLE_SUPERADMIN").then(permission => {

            //if super admin display all roles
            if (permission) {
                this.allUserRoles$ = of(RolesTable.roles);
            } else {
                this.allUserRoles$ = of(RolesTable.roles.filter(role => role.id != 'ROLE_SUPERADMIN'));
            }

            //get all roles list
            this.allUserRoles$.subscribe((res: Role[]) => {
                each(res, (_role: Role) => {
                    this.allRoles.push(_role);
                    this.unassignedRoles.push(_role);
                });

                each(this.rolesSubject.value, (roleId: string) => {
                    const role = find(this.allRoles, (_role: Role) => {
                        return _role.id === roleId;
                    });
                    if (role) {
                        this.prevAssignedRole = role;
                        remove(this.unassignedRoles, (el) => el.id === role.id);
                    }
                });
            });
        });
    }

    /**
     * Assign role
     */
    assignRole() {

        this.unassignedRoles = this.allRoles;

        if (this.roleIdForAdding === undefined) {
            return;
        }

        const role = find(this.allRoles, (_role: Role) => {
            return _role.id === (this.roleIdForAdding);
        });

        if (role) {
            this.prevAssignedRole = role;
            this.roleIdForAdding = '';
            this.updateRoles(role);
        }

    }

    /**
     * Unassign role
     *
     * @param role: Role
     */
    unassingRole(role: Role) {
        this.roleIdForAdding = '';
        this.prevAssignedRole = null;
        this.updateRoles(role);
    }

    /**
     * Update roles
     */
    updateRoles(role: Role) {
        this.rolesSubject.next(role.id);
    }

}
