import {Injectable} from "@angular/core";
import {AbstractService} from "../../services/abstract-service.service";
import {HttpClient} from "@angular/common/http";
import {ADMIN_CONFIG} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Response} from "../../_base/crud/models/response";
import {map} from "rxjs/operators";
import {EkClientAddress} from "../ek-models/ek-client-address";


@Injectable()
export class EkClientAddressService extends AbstractService<EkClientAddress> {

    url: string;

    constructor(protected http: HttpClient) {
        super(http);
        this.url = `${ADMIN_CONFIG.apiEndpoint}addresses`;

    }

    public getByClientId(id: number): Observable<EkClientAddress[]> {
        const url = `${this.url}/client/${id}`;
        return this.http.get<Response<EkClientAddress[]>>(url).pipe(map(({body}) => body));
    }

    public addClientAddress(newAddress: EkClientAddress): Observable<EkClientAddress> {
        return this.save(newAddress).pipe(
            map(response => {
                return response;
            })
        );
        return null;
    }


    public updateClientAddress(updatedAddress): Observable<EkClientAddress> {
        return this.update(updatedAddress).pipe(
            map(response => {
                return response;
            })
        );
        return null;
    }
}
