import {createAction, props} from "@ngrx/store";
import {QueryParamsModel} from "../../_base/crud";
import {AgencyBankModel} from "../ek-models/agency-bank-model";
import {Update} from "@ngrx/entity";



// load  Agency Bank
export const AgencyBankPageRequested = createAction('[AgencyBank List Page] AgencyBank Page Requested ', props<{ page: QueryParamsModel, regionId : string}>());
export const AgencyBankPageLoadedSuccessfully = createAction('[AgencyBank API] AgencyBank Page Loaded Successfully ', props<{ agencyBank: AgencyBankModel[]; totalCount: number; page: number }>());
export const AgencyBankPageLoadFailed = createAction('[AgencyBank API] AgencyBank Page Request Failed ', props<{ error: any }>());

// create new Agency Bank
export const AgencyBankCreated = createAction('[Edit AgencyBank Dialog] AgencyBank On Server Created', props<{ agencyBank: AgencyBankModel }>());
export const AgencyBankCreatedSuccessfully = createAction('[AgencyBanks API] AgencyBank Created successfully', props<{ agencyBank: AgencyBankModel }>());
export const AgencyBankCreationFailed = createAction('[AgencyBanks API] AgencyBank Creation Failed', props<{ error: any }>());

// update Agency Bank
export const AgencyBankUpdated = createAction('[Edit AgencyBank Dialog] AgencyBank Updated', props<{ agencyBank: AgencyBankModel, partialAgencyBank: Update<AgencyBankModel> }>());
export const AgencyBankUpdatedSuccessfully = createAction('[AgencyBanks API] AgencyBanks Status Updated Successfully', props<{ agencyBank: AgencyBankModel, partialAgencyBank: Update<AgencyBankModel> }>());
export const AgencyBankUpdateFailed = createAction('[AgencyBanks API] AgencyBank Update Failed', props<{ error: any }>());

// delete  Agency Bank
export const OneAgencyBankDeleted = createAction('[AgencyBanks List Page] One AgencyBank Deleted', props<{ id: number }>());
export const OneAgencyBankDeletedSuccessfully = createAction('[AgencyBanks API] One AgencyBank Deleted Successfully', props<{ id: number }>());
export const OneAgencyBankDeleteFailed = createAction('[[AgencyBanks API] One AgencyBank Deleted', props<{ error: any }>());

